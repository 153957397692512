import Modal from './Modal';
import { withTranslation } from 'react-i18next';
// import delete_img from "../../webroot/images/alert.svg";

const ConfirmationModal = (props) => {

    const submitResponse = (response) => {
        props.confirmationModalResponse({ response, type: props.confirmationData.type });
    }

    const hideModalReplicate = () => {
        props.confirmationModalResponse({ response: false, type: props.confirmationData.type });
     }

    let { show, confirmationText = '' } = props.confirmationData;

    return (
        <div className="model-popup-outer confirmation-popup">
            <Modal show={show} handleClose={hideModalReplicate}>

            <div className="modal-header">
                <h2>{confirmationText}</h2>
            </div>
            <div className="modal-body" >
                <div className='confirmation-btn'>
                    <button className='btn-line m-md-r' onClick={()=> submitResponse(false)}>No</button>
                    <button className='btn-primary' onClick={()=> submitResponse(true)}>Yes</button>
                </div>
            </div>

            </Modal>
        </div>
    );
};
export default withTranslation('common')(ConfirmationModal);
