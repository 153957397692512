import React, { useEffect, useState } from 'react'
import Modal from '../../common/Modal';
import { useDispatch } from "react-redux";
import { CommonActions } from "../../../store/action";
import { toast } from "react-toastify";
import DateFormat from 'dateformat';
import { useTranslation } from "react-i18next";
import { IncentiveService } from "../../../services";

function PayoutDetailsHistory(props) {
    const dispatch = useDispatch();
    const [historylist, setList] = useState([]);
    const [inputField, setInputField] = useState({
        id: '',
        comment: ''
    })
    const [errField, setErrField] = useState({});
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common");


    useEffect(() => {
        setInputField({ ...inputField, id: props.id })
        getHistoryList();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getHistoryList = () => {
        setLoading(true)
        dispatch(CommonActions.incentiveHistoryList({ incentive_user_id: props.id, type: props.type })).then(function (response) {
            setLoading(false)

            if (response && response.status === 200) {
                setList(response.data);
            }
        }).catch(err=> setLoading(false));
    }
    const validForm = () => {
        let formIsValid = true;
        setErrField({ comment: "" });
        if ((typeof inputField.comment !== "undefined" && inputField.comment.trim() === "") || !inputField.comment) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState, comment: t('VALIDATION.REMARK')
            }));
        }
        return formIsValid;
    };
    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };
    const submitButton = async () => {
        setLoading(true);
        if (validForm()) {
            IncentiveService.saveIncentiveHistory(inputField).then(function (response) {
                setLoading(false);
                if (response.data.status === 200) {
                    setInputField({ ...inputField,comment: "" })
                    toast.success(response.data.message);
                    getHistoryList();
                } else {
                    toast.error(response.data.message);
                }
            }).catch(function () {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="view-timeline-popup">
                <Modal show={props.viewTimeLine} handleClose={() => props.showModalViewTimeLine(false)} >
                    {
                        (historylist.length || props.type === 'LIST') ?
                            <div className="modal-header">
                                <h2>
                                    {
                                        props.type ==='REMARK' ? 
                                        t('DETAIL.REMARK_HISTORY') :
                                        t('DETAIL.HISTORY')
                                    } 
                                    </h2>
                            </div>
                            : null
                    }

                    <div className="modal-body" >
                        <div className="timeline-history-tabel">
                            <table>
                                <tbody>
                                    {
                                        !loading && historylist.length ? historylist.map((item) => {
                                            return (<tr key={item.id}>
                                                <td>
                                                    <span className="date">{DateFormat(item.created_at, "dd mmm")}</span>
                                                    <span className="time">{DateFormat(item.created_at, "h:MM TT")}</span>
                                                </td>
                                                <td>
                                                    <span className="heading wordWrap">{item.comment || 'NA'}</span>
                                                    {
                                                        item.created_by || item.created_user
                                                        ?
                                                            <span className="Subheading">{'By ' + (item.created_by || item.created_user)}</span>
                                                        :
                                                            ""
                                                    }
                                                </td>

                                            </tr>)
                                        })
                                            : ( props.type === 'LIST' ) ? <tr><td colSpan={2} className="no-data">{loading ? 'loading....' : "No Data Found"}</td></tr> : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            props.type === 'REMARK' ?
                                <>
                                    <fieldset className="form-filed">
                                        <span className="remark-heading-txt">{t('DETAIL.ADD_REMARK')}</span>
                                        <div className="material">
                                            <textarea placeholder=" " name="comment" className="form-input resize" rows={3} onChange={inputHandler} maxLength={1000} value={inputField['comment']}></textarea>
                                            <label data-label="Add Remarks" className="form-label"></label>
                                            {errField.comment && (
                                                <span className="input-error">
                                                    {errField.comment}
                                                </span>
                                            )}
                                        </div>
                                    </fieldset>
                                    <button className="btn-primary m-md-t" onClick={submitButton} disabled={loading}>{t('DETAIL.SUBMIT')}</button>
                                </>
                                : null
                        }


                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default PayoutDetailsHistory