
const validateDataForZero = (data) => {
        
    let zeroNotExist = false;
    if (data.form_config.USER_INCENTIVE_TYPE_CARD && data.user_incentive_type) {
        data.user_incentive_type.forEach((element) => {
            if(element.disb_minimum_count ==="0" || element.disb_per==="0" || element.incentive_amount==="0") {
                zeroNotExist = true;
            }
        });
    }
    if(data.form_config.INCENTIVE_AMOUNT_CARD) {
        if(data.incentive_amount==="0"){
            zeroNotExist = true;
        }
    }
    if(data.form_config.INCENTIVE_DISBURSAL_CARD) {
        if (+data.incentive_amount === 0 || +data.min_disbursal === 0) {
            zeroNotExist = true;
        }
    } 
    if (data.form_config.BM_CONFIGURATION_CARD && data.bm_config) {
        data.bm_config.forEach((element) => {
            if (element.target_amt === "0") {
                zeroNotExist = true;
            }
        });

    }
    if (data.form_config.USER_INCENTIVE_TYPE_CARD_2 && data.user_incentive_type_2) {
        data.user_incentive_type_2.forEach((element) => {
            if (element.disb_minimum_count === "0" || element.disb_per=== "0") {
                zeroNotExist = true;
            }
            
        });
    }
    if (data.form_config.USER_INCENTIVE_TYPE_CARD_2_REGULAR && data.user_incentive_type_2) {
        data.user_incentive_type_2.forEach((element) => {
            if (element.disb_minimum_count === "0" || element.disb_per=== "0") {
                zeroNotExist = true;
            }
            
        });
    }
    if (data.form_config.FINANCIER_CONFIGURATION_CARD && data.financier_config) {
          
        data.financier_config.forEach((element) => { 
            if(element.status !== 0 || element.status ===1){
                if(data.form_config.USER_INCENTIVE_TYPE_CARD_2_REGULAR && Number(element.regular_amt) === 0){        
                    zeroNotExist = true;
                }
        
                else if(!data.form_config.USER_INCENTIVE_TYPE_CARD_2_REGULAR){            
                    if (Number(element.senior_amt) === 0 || Number(element.junior_amt) === 0 || Number(element.regular_amt) === 0) {
                        zeroNotExist = true;
                    }
                }
            }
        });

    }
    if(data.form_config.APP_BOOSTER_CARD && data.form_config.EXTRA_BOOSTER &&  data.extra_booster) {
       
        data.extra_booster.forEach((element) => {
            if (Number(element.from_value) === 0 || Number(element.to_value) ===0 ||Number(element.app_disbursed_range) === 0) {
                zeroNotExist = true;
            }
           
        });
    }

   if (data.form_config.ALLOWANCE_CARD) {
        data.user_allowances.forEach((element, index) => {
            if (Number(element.allowance) === 0 || Number(element.disb_minimum_count) ===0 ) {
                zeroNotExist = true;
            } 
        });
    }
    
    if(data.form_config.DISBURSAL_ROLE && data.disbursal_role) {
        data.disbursal_role.forEach((element) => {
            if (Number(element.min_disbursal) === 0 || Number(element.junior_amt) ===0 || Number(element.senior_amt) === 0  || Number(element.regular_amt) === 0) {
                zeroNotExist = true;
            }
           
        });
    }
    return zeroNotExist;
};

export default validateDataForZero;