import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation} from 'react-router-dom';
const MonthYear = (props) => {

    // For taking value from url
    let { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    let month  = queryParams.get('month'); 
   
    const [startDate, setStartDate] = useState(month || new Date());

    useEffect(() => {
        if (queryParams.get("month") && !props.searchFilters.month) {
            setStartDate(queryParams.get("month"));
        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.get("month"), props.searchFilters.month])


    useEffect(()=>{

        if(props.action === 'dashboard' && props.searchFilters.month){
            changeHandler(props.searchFilters.month);
        }else if (queryParams.get("month")) {
            changeHandler(queryParams.get("month"));
        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.action])


    useEffect(()=>{
        if(props.resetStateFilter && queryParams.get('month') && props.action === 'payoutDetails'){
            changeHandler(new Date(queryParams.get('month')), props.resetStateFilter);

        }else if(props.action === 'dashboard' && props.searchFilters.month && props.resetStateFilter){
            setStartDate(props.searchFilters.month);
            props.getUploadedDocs(new Date(props.searchFilters.month))

        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetStateFilter])


    const changeHandler = (date, resetFlag=false) => {
        let selectedFiltersObj = props.selectedFilters;
    
        if(!resetFlag){
            //RESET DATE DEPENDANT KEYS FROM FILTERS
            delete selectedFiltersObj['upload_csv'];
            delete selectedFiltersObj['reporting_id'];
        }
        
        /**EXPERIMENTAL CODE */
        date = new Date(date) ;
        date.setTime( date.getTime() - new Date().getTimezoneOffset()*60*1000 );
        /**EXPERIMENTAL CODE END*/
        
        setStartDate(date);

        props.updateFilters({ ...selectedFiltersObj, "month": new Date(date).toISOString() })
        props.getUploadedDocs(new Date(date))
        props.getReportingManagers({month: new Date(date)})

    }
    
    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate ? new Date(startDate) : null}
                onChange={(date) => changeHandler(date)}
                dateFormat="MM/yyyy"
                placeholderText="Month"
                showMonthYearPicker
                maxDate={new Date()}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
            
            />
        </div>
    );
}



export { MonthYear, }