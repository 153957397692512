import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ConfirmationModal from "../common/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import { CommonActions } from "../../store/action";
import { toast } from "react-toastify";
import DealerDataExport from './DealerDataExport';

const DealerHeader = (props) => {
   
    const commonRedux = useSelector((state) => state.common)
    const [confimationPopUp, setConfirmationPopup] = useState({ status: false, msg: '' });
    const [exportedPopUp, setExportedPopUp] = useState(false);
    const [teamMember, setTeamMember] = useState('');
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    useEffect(() => {

        setTeamMember('');
    }, [props.refresh])

    const showModalConfimationPopUp = (params) => {
        let { status } = params
        setConfirmationPopup(params)
        // setApproveRejectMsg(msg)
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }


    }


    const showModalExportedPopUp = () => {
        setExportedPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalExportedPopUp = () => {
        setExportedPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }


    const apiResponse = (val) => {
        if (val.key === 'Enter') {
            props.getDealerDetailsList({ ...props.searchFilters, search_key: teamMember, page_no: 1 })
        }
    }

    const setSearchkey=(val)=>{
        setTeamMember(val);
        props.setFilter({ search_key: val })
   }

    const confirmationModalResponse = (params) => {
        let value = (confimationPopUp.approve) ? 1 : 2;
        //CALL SERVICE TO UPDATE STATUS
        if (params.response) {
            props.setLoading(true)
            let updateStatusParams = { status: value};

            let updatedUsers = commonRedux.getMultiselectedPayouts;
        
            updateStatusParams['dealer_reward_id'] = updatedUsers.toString();
            updateStatusParams['is_all'] =  commonRedux.checkedallStatus; 
            updateStatusParams['month']  = props.searchFilters?.month ? props.searchFilters.month : new Date();
            
            if(updatedUsers.length){
                dispatch(CommonActions.updateDealerStatus(updateStatusParams)).then((res) => {
                    props.setLoading(false)
                    dispatch(CommonActions.getMultiSelectedLoans([]));
                    dispatch(CommonActions.checkedAllStatus(false));
                    dispatch(CommonActions.updateApproveButtonStatus(false));
                    if (res.status === 200) {
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                    props.getDealerDetailsList(props.searchFilters);
                });
            }else{
                toast.error("Please select at least one record");
            }
            
        }

        showModalConfimationPopUp(params)
    }
    
    return <>

        <div className="sub-heading p-lg-t p-lg-b">
            <h2>{t("DEALER.DEALER_INCENTIVE")}</h2>
            <div className="report-heading-right-panel">
                {
                    commonRedux.approveButton && props.showActionButtons
                        ?
                        <>
                            <button className="btn-line btn-export m-sm-r" onClick={() => showModalConfimationPopUp({ status: true, approve: true, msg: t("DEALER.APPROVE_MESSAGE")})}> <i className="ic-check"></i>{t('FORM.APPROVE')}</button>
                            <button className="btn-line btn-export m-sm-r" onClick={() => showModalConfimationPopUp({ status: true, reject: true, msg: t("DEALER.REJECT_MESSAGE")})}> <i className="ic-clearclose"></i>{t('FORM.REJECT')}</button>
                        </>
                        :
                        ""
                }
                 <div className='search-bx m-sm-r'>
                        <div className='material'>
                            <input type="text" placeholder='Search by Dealer ID, Name, Phone' className='form-input' maxLength={200} value={teamMember}
                                onKeyPress={(e) => apiResponse(e)}
                                onChange={(e) => setSearchkey(e.target.value)}
                            />
                            <i className='ic-search'></i>
                        </div>
                </div>
                {
                  commonRedux && commonRedux.dealerDetails && commonRedux.dealerDetails.data.length? 
                  <button className="btn-line btn-export m-sm-l" disabled={false} onClick={showModalExportedPopUp} > <i className="ic-export"></i>{t("DEALER.EXPORT")}</ button>: 
                  <button className="btn-line btn-export m-sm-l" disabled={true} > <i className="ic-export"></i>{t("DEALER.EXPORT")} </ button>
                }
                 
            </div>
            
        </div>

        <ConfirmationModal confirmationData={{ show: confimationPopUp.status, confirmationText: confimationPopUp.msg }} confirmationModalResponse={confirmationModalResponse} />

        {
            exportedPopUp && <DealerDataExport show={exportedPopUp}  handleClose={hideModalExportedPopUp} />
        } 
     
    </>
        ;
}

export default DealerHeader