import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelect from '../common/MultiSelect';
import ToggleSwitch from "../common/ToggleSwitch";
import FullPageLoader from "../common/FullPageLoader";
import CurrencyInputField from "../common/CurrencyInputField";


const DeviationLimitTabs = (props) => {

    const { t } = useTranslation('common');

    let {financierList, deviationConfigList, amList, tabKey, loading, amListSelected, tempErrors, idsToExclude} = props;
    amList = amList.filter(item => !idsToExclude.includes(item.id));

    return (
       <>
       <FullPageLoader show={loading} />
       <div className="deviation-am">
                    <label>{t("DEALER.VALID_FOR_AM")}</label>
                    <fieldset className="single-select m-sm-l">
                        <div className="material">
                            <MultiSelect 
                                isMulti ={true}
                                value={amList.filter(ob=> amListSelected.includes(ob.value))}
                                onChange={(e) => props.handleChangeAreaAM(e,tabKey)}
                                options={amList}
                                isDisabled={false}
                                placeholder={"Search & select AM"}
                                className="react-select"
                                classNamePrefix="react-select"
                            /> 
                            {
                               (tempErrors && tempErrors["am_ids_" + tabKey]) ? <span className="error show validation-error">{tempErrors["am_ids_" + tabKey ]}</span> : ''
                            }
                        </div>
                        
                    </fieldset>
                    <span data-tooltip-id="my-tooltip-1" title={t('DEALER.AM_ICON_MSG')} className="tooltio-ic"><i className="ic-info-icon1"></i></span>
                    </div>
                    <div className="modal-body ">
                 
                    <ul className="financier-group-config">
                        {financierList.length > 0 && financierList.map((fin, i) => {
                            let deviationObj = deviationConfigList.find((el) => +el.financier_id === +fin.value);
                
                            const isInputDisable = deviationObj && deviationObj.status ? false : true;
                        
                            return (
                              <li key={i}>
                                <span className="financer-group-label">{fin.label}</span>
                                <div className="on-off-switch m-sm-l m-sm-r">
                                    {
                                       <ToggleSwitch
                                       status={deviationObj?.status || 0}
                                       onChange={(e,val,r) => {props.changeHandler(val, fin, "toggle", tabKey)}}
                                       />
                                    }
                                </div>
                                <fieldset className="input m-sm-r">
                                <div className="material">
                                    <CurrencyInputField
                                        inputProps={{
                                            id: "amount_" + tabKey + "_" + fin.value,
                                            type: "text",
                                            name: "amount",
                                            placeholder: " ",
                                            value: isInputDisable ? '' : deviationObj?.amount,
                                            className: "form-input",
                                            disabled: isInputDisable,
                                            maxLength : 10
                                        }}
                                        onChange={(val) => {props.changeHandler(val, fin, "amount", tabKey)}}
                                    />

                                    {/* <input type="text" 
                                         id= {"amount_" + tabKey + "_" + fin.value}
                                         name= "amount"
                                         className="form-input"
                                         value= { deviationObj?.amount || ''}
                                         onChange={(val) => {props.changeHandler(val, fin, "amount", tabKey)}}
                                         disabled = {isInputDisable}
                                        /> */}
                                    {
                                        (tempErrors && tempErrors["amount_" + tabKey + "_" + fin.value]) ? <span className="error show validation-error">{tempErrors["amount_" + tabKey + "_" + fin.value]}</span> : ''
                                    }
                                </div>
                                </fieldset>
                              
                              </li>
                           
                            )
                        })

                        }
                    </ul>
                   
                    </div>
                    {
                    financierList.length > 0 &&
                    <div className="modal-footer m-lg-t t-center">
                        {
                            !loading
                                ?
                                <button className="btn-primary"  onClick={() => {props.saveDeviationConfig(deviationConfigList, tabKey, amListSelected)}}>{t("DEALER.SAVE_DETAILS")}</button>
                                :
                                <button className="btn-primary" type='button'>{t('DEALER.PLEASE_WAIT')}</button>

                        }
                    </div>
                   }
       </>
    )
}
export default DeviationLimitTabs;