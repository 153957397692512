import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';

class UserIncentiveTypeCard extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    onChange = (event) => {
        let { name, value } = event.target
        this.setState({ [name]: value })
        this.props.onChange(name, value, '', this.props.element)
    }

    render() { 
        const { user_level } = this.props.incentive_config
        const { element, tempErrors,t } = this.props  

        return (
            <React.Fragment>
                <div className='extra-incentive-outer add-incentive-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('FORM.USER_LEVEL')}</th>
                                <th>{t('FORM.MIN_DISBURSAL')+'*'}
                                    <span>{t('FORM.TO_BE_ELIGIBLE_FOR_SCHEME')}</span>
                                </th>
                                <th>{t('FORM.PER_DISB')+'*'}
                                    <span>{t('FORM.COUNT_OF_DISBURSAL_LOWEST_VALUE')}</span>
                                </th>
                                <th>{t('FORM.INCENTIVE')+'*'}
                                    <span>{t('FORM.LOWEST_VALUE_PER_DISB')}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                element.user_incentive_type.map((data, index) => (
                                    <tr key={index}>
                                         <td>{user_level.filter((e)=>(e.id === data.user_level)).map(({user_name}) => user_name)}</td> 
                                        <td>
                                            <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "disb_minimum_count_" + index,
                                                            type: "text",
                                                            name: "disb_minimum_count_" + index,
                                                            placeholder: " ",
                                                            value: this.state["disb_minimum_count_" + index] || data.disb_minimum_count || "",
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                    />
                                                </div> 
                                                {
                                                    (tempErrors && tempErrors["disb_minimum_count_" + index]) ? <span className="error show">{tempErrors["disb_minimum_count_" + index]}</span> : <span>&nbsp;</span>
                                                }

                                            </fieldset>
                                        </td>

                                        <td>
                                            <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "disb_per_" + index,
                                                            type: "text",
                                                            name: "disb_per_" + index,
                                                            placeholder: " ",
                                                            value: this.state["disb_per_" + index] || data.disb_per || "",
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                    />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["disb_per_" + index]) ? <span className="error show">{tempErrors["disb_per_" + index]}</span> : <span>&nbsp;</span>
                                                }
                                            </fieldset>
                                        </td>
                                        <td>
                                            <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "incentive_amount_" + index,
                                                            type: "text",
                                                            name: "incentive_amount_" + index,
                                                            placeholder: " ",
                                                            value: this.state["incentive_amount_" + index] || data.incentive_amount || "",
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                    />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["incentive_amount_" + index]) ? <span className="error show">{tempErrors["incentive_amount_" + index]}</span> : <span>&nbsp;</span>
                                                }
                                            </fieldset>
                                        </td>
                                    </tr>
                                ))
                            }
 
                        </tbody>
                    </table>
                </div>

            </React.Fragment>
        )
    }
}
export default withTranslation('common')(UserIncentiveTypeCard); 