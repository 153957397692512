import React from "react";

const DisbursalCountAmountCard = (props) => {
    const disbursal_Count_amount = (name,index,event,value,tab='') => {
        props.disbursal_Count_amount(name,index,event,value,tab);    
    }

    return (
    <React.Fragment>    
        <tr key="000">
            <td>
                <fieldset className='filed-group'>
                    <div className="material">
                        <div className="custom-control custom-checkbox">
                            <input 
                                checked={(+props.elm.is_count_amount===1)?true:false} 
                                onChange={(e) => disbursal_Count_amount("is_count_amount_" + props.index,props.index,e,1,props.tab?props.tab :"")} 
                                id={"is_count_amount" + props.index + "_1" } 
                                name={"is_count_amount_" + props.index}
                                type="checkbox" 
                                value="1"
                                className="custom-control-input "  
                                disabled={props.element.is_expired || false} 
                            />
                            <label className="custom-control-label disbursalConfig">Disbursal Count <span className="financierspan">(Flat Amount)</span> </label>
                        </div>
                    </div> 
                </fieldset>        
                
            </td>
            <td colspan="2">
                <fieldset className='filed-group disbursalamount'>
                    <div className="material">
                        <div className="custom-control custom-checkbox">
                            <input  
                                checked={(+props.elm.is_count_amount===2)?true:false} 
                                onChange={(e) => disbursal_Count_amount("is_count_amount_" + props.index,props.index,e,2,props.tab?props.tab :"")} 
                                id={"is_count_amount_" + props.index + "_2" }
                                name={"is_count_amount_" + props.index}
                                type="checkbox" 
                                value="2"
                                className="custom-control-input "  
                                disabled={props.element.is_expired || false} 
                            />
                            <label className="custom-control-label disbursalConfig">Disbursal Amount<span className="financierspan">(%)</span> </label>
                        </div>
                    </div> 
                </fieldset>       
            </td>
        </tr>
    </React.Fragment>  
    );

};

export default DisbursalCountAmountCard;