import MasterService from './MasterService';


export const PointService = {
    ...MasterService,

    updatePoint(body){ 
        return this.post("pointConfig/updatePoint",body)
    },
    savePoint(body){ 
        return this.post("pointConfig/savePointConfig",body)
    },
    getPointAll(body){ 
        return this.post("pointConfig/getPointAll",body)
    },
    updateScore(body){ 
        return this.post("scoreConfig/updateScore",body)
    },
    saveScore(body){ 
        return this.post("scoreConfig/saveScoreConfig",body)
    },
    getScoreAll(body){ 
        return this.post("scoreConfig/getScoreAll",body)
    },
    deleteFinancierDetails(body) {
        return this.post("scoreConfig/removeScoreDetail",body)
    },
}