import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputField from "../common/InputField";
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";
import FileUpload from "./DisbursalUpload";
import { ARO_USER_ROLE } from "../../config/constant";

const DisbursalRoleCard = (props) => {
    const [refresh, setRefresh] = useState(false);
    const [financerOption, setFinancerOption] = useState({});
    const [disbursalFormListData, setDisbursalFormListData] = useState([]);
    const commonRedux = useSelector((state) => state.common);
    const [selecteApplicationTypes, setSelecteApplicationTypes] = useState([]);

    useEffect(() => {
        if (
            selecteApplicationTypes &&
            selecteApplicationTypes.length &&
            commonRedux &&
            commonRedux.financierList &&
            commonRedux.financierList.length
        ) {
            let allFinanciers = {};
            for (let appType of selecteApplicationTypes) {
                appType = appType.toLowerCase();

                let getFinancierListProductWise = commonRedux.financierList.filter(
                    (elm) => appType === elm.application_type_name.toLowerCase()
                );
                if (getFinancierListProductWise && getFinancierListProductWise.length) {
                    allFinanciers[appType.toLowerCase()] =
                        getFinancierListProductWise[0]["financier"];
                }
            }
            setFinancerOption(allFinanciers);
        }
    }, [commonRedux, selecteApplicationTypes]);


   
    useEffect(() => {
        if (
            props.element.disbursal_role &&
            props.element.disbursal_role.length
        ) {
            setDisbursalFormListData(props.element.disbursal_role);
        } 
        else {
            let option = {
                junior_amt: "",
                regular_amt: "",
                senior_amt: "",
                fixed_amt: "",
                min_disbursal: "",
                common_id: []
            }
            props.element.disbursal_role = [option];
            setDisbursalFormListData([option])
        }

    }, [props]);

  


    const inputChangeHandler = (event,index) => {  
        let record = props.element.disbursal_role; 
        record[index][event.target.name] = event.target.value;
     
        setDisbursalFormListData(record)
        setRefresh(refresh + 1); 
    }

  

    const addMoreDisbursal = ( tab) => {

        let temp_object = {
            junior_amt: "",
            regular_amt: "",
            senior_amt: "",
            fixed_amt: "",
            min_disbursal: "",
            common_id: []
        }; 
        props.element.disbursal_role = [
            ...disbursalFormListData,
            temp_object
        ];
        setDisbursalFormListData(props.element.disbursal_role);
        setRefresh(refresh + 1); 
    };


    const removeDisbursal = ( tab,index) => {
        let records = disbursalFormListData;
        records.splice(index,1); 
        props.element.disbursal_role =  records;

        if(Object.keys(props.tempErrors).length){
            for (let key in props.tempErrors ) {
                if (props.tempErrors.hasOwnProperty(key)) {
                    console.log(key + ": " + props.tempErrors[key]);
                    props.tempErrors[key] = ""
                }
            }
        }
        setDisbursalFormListData(records);
        setRefresh(refresh + 1); 
    };



    const { t } = useTranslation("common");
    let { tempErrors, element } = props;  
  
    return (
        <React.Fragment>
            <div className="add-banner-btn-outer">
          <div className="add-banner-txt">
            <h3>  {element.form_config.ALLOWANCE_TEXT ? t("COMMON.DISB_AMT_RANGE.ALLOWANCE_AMT") : t("COMMON.DISB_AMT_RANGE.UPLOAD_AMOUNT")}</h3>
            {tempErrors.image && (
              <span className="error">{tempErrors.image}</span>
            )}
          </div>
          <div className="browse-btn">
          <FileUpload setDisbursalFormListData={setDisbursalFormListData} element={props.element}></FileUpload>
          </div>
          
        </div>
            {/* {element.application_type && (
                <span className="config-heading">
                    {element.user_role == 3 ?  t("COMMON.DISB_AMT_RANGE.HEADING") :  t("COMMON.DISB_AMT_RANGE.HEADING")}
                </span>
            )} */}
            <div className="et3-config-filed-outer" >
                <table>
                    <thead>
                        <tr>
                            <th>{+element.form_config.USER_ROLE === ARO_USER_ROLE ? t('COMMON.DISB_AMT_RANGE.SCORE') : t('COMMON.DISB_AMT_RANGE.DISBURSAL')}</th>
                            <th>{+element.form_config.USER_ROLE === ARO_USER_ROLE ? t('COMMON.DISB_AMT_RANGE.SR_ARO') : t('COMMON.DISB_AMT_RANGE.SR_BRO')}</th>
                            <th>{+element.form_config.USER_ROLE === ARO_USER_ROLE ? t('COMMON.DISB_AMT_RANGE.ARO') : t('COMMON.DISB_AMT_RANGE.BRO') }</th>
                            <th>{+element.form_config.USER_ROLE === ARO_USER_ROLE ? t('COMMON.DISB_AMT_RANGE.JR_ARO') : t('COMMON.DISB_AMT_RANGE.JR_BRO')}</th>
                            {
                                element.applicable_to?.includes('fixed_income') ?
                                <th>{t('COMMON.DISB_AMT_RANGE.FIXED_INCOME')}</th>: <th> </th>
                            }
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>

                        {disbursalFormListData.length ? disbursalFormListData.map((elm, key) => {
                            return (
                                <tr key={key}>
                                    <td>
                                        <fieldset>
                                            <div className="material">

                                                <InputField
                                                    inputProps={{
                                                        type: "text",
                                                        name: "min_disbursal",
                                                        placeholder: " ",
                                                        value: elm.min_disbursal,
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                    }}
                                                    onChange={(e) => inputChangeHandler(e, key)}
                                                />
                                            </div>

                                            {
                                                tempErrors && Object.keys(tempErrors).length && tempErrors["min_disbursal_" + key] ? (
                                                    <span className="error show">
                                                        {tempErrors["min_disbursal_" + key]}
                                                    </span>
                                                ) : (
                                                    <span>&nbsp;</span>
                                                )
                                            }
                                        </fieldset>
                                    </td>
                                    <td>
                                        <fieldset>
                                            <div className="material">

                                                <InputField
                                                    inputProps={{
                                                        type: "text",
                                                        name: "senior_amt",
                                                        placeholder: " ",
                                                        value: elm.senior_amt,
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                    }}
                                                    onChange={(e) => inputChangeHandler(e, key)}
                                                />
                                            </div>

                                            {
                                                tempErrors && Object.keys(tempErrors).length && tempErrors["senior_amt_" + key] ? (
                                                    <span className="error show">
                                                        {tempErrors["senior_amt_" + key]}
                                                    </span>
                                                ) : (
                                                    <span>&nbsp;</span>
                                                )
                                            }
                                        </fieldset>
                                    </td>
                                    <td>
                                        <fieldset>
                                            <div className="material">

                                                <InputField
                                                    inputProps={{
                                                        type: "text",
                                                        name: "regular_amt",
                                                        placeholder: " ",
                                                        value: elm.regular_amt,
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                    }}
                                                    onChange={(e) => inputChangeHandler(e, key)}
                                                />
                                            </div>
                                            {
                                                tempErrors && Object.keys(tempErrors).length && tempErrors["regular_amt_" + key] ? (
                                                    <span className="error show">
                                                        {tempErrors["regular_amt_" + key]}
                                                    </span>
                                                ) : (
                                                    <span>&nbsp;</span>
                                                )
                                            }
                                        </fieldset>
                                    </td>
                                    <td>
                                        <fieldset>
                                            <div className="material">

                                                <InputField
                                                    inputProps={{
                                                        type: "text",
                                                        name: "junior_amt",
                                                        placeholder: " ",
                                                        value: elm.junior_amt,
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                    }}
                                                    onChange={(e) => inputChangeHandler(e, key)}
                                                />
                                            </div>
                                            {
                                                tempErrors && Object.keys(tempErrors).length && tempErrors["junior_amt_" + key] ? (
                                                    <span className="error show">
                                                        {tempErrors["junior_amt_" + key]}
                                                    </span>
                                                ) : (
                                                    <span>&nbsp;</span>
                                                )
                                            }
                                        </fieldset>
                                    </td>
                                    {
                                        element.applicable_to?.includes('fixed_income') ?
                                        <td>
                                            <fieldset>
                                                <div className="material">

                                                    <InputField
                                                        inputProps={{
                                                            type: "text",
                                                            name: "fixed_amt",
                                                            placeholder: " ",
                                                            value: elm.fixed_amt,
                                                            className: "form-input",
                                                            disabled: element.is_expired || false,
                                                        }}
                                                        onChange={(e) => inputChangeHandler(e, key)}
                                                    />
                                                </div>
                                                {
                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["fixed_amt_" + key] ? (
                                                        <span className="error show">
                                                            {tempErrors["fixed_amt_" + key]}
                                                        </span>
                                                    ) : (
                                                        <span>&nbsp;</span>
                                                    )
                                                }
                                            </fieldset>
                                        </td>: <td></td>
                                    }
                                    <td>
                                    
                                        {
                                    +key === 0 ?
                                        <span className="add-minus" onClick={() => {
                                            addMoreDisbursal( 1);
                                        }}><BiPlusCircle size="1.4em" /> </span>
                                        :
                                        <span className="add-minus" onClick={() => removeDisbursal(1, key)}> <BiMinusCircle size="1.4em" /></span>

                                }
                                    </td>

                                </tr>
                            );
                        })
                            :
                            <tr>
                                <td></td>
                            </tr>
                        }

                        
                    </tbody>
                </table>

                <div>&nbsp;</div>
            </div>
        </React.Fragment>
    );
}


export default DisbursalRoleCard;
