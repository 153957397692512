import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';
 
class AppsSubmitted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh:1
        };
    }


    onChange = (event,index) => {
        let { value } = event.target
        this.props.element.app_submitted[index]['incentive_amount'] = value; 
        this.setState({  refresh: this.state.refresh + 1 }) 
    }

    render() {
        const { agent_level } = this.props.incentive_config
        const { element, tempErrors } = this.props  
        return (
            <React.Fragment>
                <div className='app-submit-outer add-incentive-table'>

                    <table>
                        <thead>
                            <tr>
                                <th>{this.props.t('FORM.AGENT_LEVEL')}</th>
                                <th>{this.props.t('FORM.INCENTIVE_AMOUNT')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {element.app_submitted && element.app_submitted.filter(ob=> +ob.agent_level_id !==3).map((data, index) => (
                                <tr key={index}>
                                    <td>{agent_level.filter((e)=>(e.id === data.agent_level_id)).map(({agent_name}) => agent_name)}  * </td>
                                    
                                        <td>
                                            <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "incentive_amount_" + index,
                                                            type: "text",
                                                            name: "incentive_amount_" + index,
                                                            placeholder: " ",
                                                            value: data.incentive_amount,
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e) => this.onChange(e,index)}
                                                    />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["incentive_amount_" + index]) ? <span className="error show">{tempErrors["incentive_amount_" + index]}</span> : ''
                                                }
                                            </fieldset>
                                        </td> 
                                    {/* ))} */}
                                </tr>
                            ))}
                        </tbody>
                    </table>



                </div>

            </React.Fragment>
        )
    }
} 
export default withTranslation('common')(AppsSubmitted);
