import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

class AddBannerButton extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChageImage = (event) => {
    let self = this;
    let { name } = event.target;
    let filesInfo = Array.from(event.target.files);

    if (filesInfo && filesInfo.length) {
      filesInfo.forEach((file, index) => {
        let fileType = file.type.split("/");
        if (["jpg", "jpeg", "png"].includes(fileType[1])) {
          self.setState({ file });
          let browse_url = URL.createObjectURL(file);
          //   file.setName(Date.now());
        
          const renamedFile = new File([file], Date.now() + "." + fileType[1], {
            type: "image/" + fileType[1],
          });
        
          self.props.onChange(name, browse_url, "", this.props.element);
          self.props.onChange("file", renamedFile, "", this.props.element);
        } else {
          toast.error(this.props.t("VALIDATION.FILE_TYPE_INVALID"));
        }
      });
    }
  };

  handleDeleteImage = (element) => {
    let self = this;
    self.props.onDelete(element);
  };

  render() {
    let { element, tempErrors } = this.props;
    return (
      <React.Fragment>
        <div className="add-banner-btn-outer">
          <div className="add-banner-txt">
            <h3>{this.props.t("FORM.ADD_BANNER_IMAGE")} </h3>
            <span> {this.props.t("VALIDATION.IMAGE_VALID")} </span>
            {tempErrors.image && (
              <span className="error">{tempErrors.image}</span>
            )}
          </div>
          <div className="browse-btn">
            <span className="label">{this.props.t("FORM.BROWSE_FILE")}</span>
            {
              (!element.is_expired)?
                <input
                type="file"
                name="image"
                disabled={element.is_expired}
                id="upload-image-input"
                className="upload-box"
                accept="image/*"
                onChange={(e) => this.handleChageImage(e)}
              />:
                <input
                name="image"
                disabled={element.is_expired}
                id="upload-image-input"
                className="upload-box"
                accept="image/*"
              />
            }
            
          </div>
          {" "}
          {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
           { element.image ? <h1 className="ic-delete-icn" style={{fontSize:"35px",cursor:"pointer"}}  onClick={(e) => this.handleDeleteImage(element)}></h1> : ''} 
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation("common")(AddBannerButton);
