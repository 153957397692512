import { default as config } from './config';
import secureStorage from './../config/encrypt';
import { ARO_ROLE_ID,BRO_ROLE_ID, AGENT_ROLE_ID, ARCO_ROLE_ID } from "../config/constant";

export const DateFormat = (d) => {
    return d.getFullYear() + '-' + ((d.getMonth() < 9) ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + ((d.getDate() < 10) ? '0' + (d.getDate()) : (d.getDate()));
}

export const addCurrencyFormatting = (number, countryCode = '') => {
    number = (number !== '' && number !== undefined && number !== null) ? number.toString().replace(/,/g, '') :''
    return config.constants.CURRENCY_SYMBOL + ' ' + (+number ? (+number).toLocaleString("en") : 0);
}

export const removeCurrencyFormatting = (number) => {
    if (number !== '' && number !== undefined && number !== null) {
        var result = ('' + number).replace(/[Rp]+/g, "").replace(/[₱]+/g, "").replace(/[AED]+/g, "").replace(/[^0-9]/g, "");
        return result.toString().trim()
    }

}

export const isUserHasAccessPage = (routerPath='', stringData='') => {
    let currentPath = [];
    let authUserInfo = secureStorage.getItem('authUserInfo');
    let stringMenuData = stringData ? stringData : ((authUserInfo && authUserInfo['headerMenu']) || '') //else get auth user menus
    
    //CHECK FROM URL
    if(routerPath === ''){
      currentPath = window.location.pathname.split('/');
      currentPath = currentPath.filter(path=> path) //FILTER URL PATH

    }else{ //CHECK MANUAL ROUTE
      currentPath = [routerPath]
    }

    let isAuthorized = true
    if(currentPath.length && stringMenuData){

      //MATCH URL WITH NAME OR ACTION FOR ACTIVE CLASS
      if( stringMenuData.indexOf('"action":"'+currentPath[0]+'"') === -1){
        isAuthorized = false
      }

    }

    return isAuthorized;
}

export const numberFormat = (number, countryCode = '') => {
    number = (number)? number.toString().replace(/,/g, '') :''
    return (+number ? (+number).toLocaleString("en") : 0);
}

/**
 * GET ROLE BY TYPE/ID
 * @param {*} param 
 * @returns 
 */
 export const GET_USER_ROLE = (value) => {
    let result = "";
    // let { key,val } = param;

    if(typeof value === "string"){
        result = (value === "bro") ? BRO_ROLE_ID :( ((value === "aro") ? ARO_ROLE_ID : ((value === "agent") ? AGENT_ROLE_ID : ((value === "arco") ? ARCO_ROLE_ID : 0)))  )
    }else {
        result = (value === 54) ? "bro" :( ((value === 57) ? "aro" : ((value === 53) ? "agent" : ((value === 59) ? "arco" : "")))  )
    }

    return result;
}