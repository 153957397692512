import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputField from '../common/InputField';
import { CommonActions } from "../../store/action";
import MultiSelect from '../common/MultiSelect'

const BMConfigurationCard = (props) => {
    const [bmFormListData, setBMFormListData] = useState([{ "bm_id": [] }])
    const commonRedux = useSelector((state) => state.common)
    const dispatch = useDispatch();
    let financerOption = (commonRedux.bmUserList)? commonRedux.bmUserList:[]
 
    useEffect(() => {
        if (!commonRedux.bmUserList || !commonRedux.bmUserList.length) { 
            dispatch(CommonActions.bmUserList({ 'role_id': 52 })).then((res)=>{
            });      
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
       
        if (props.element.bm_config && props.element.bm_config.length) {
            setBMFormListData(props.element.bm_config)
        }

    }, [props])

    const getDropdownButtonLabel = (event, financierIndex) => {
        let { placeholderButtonLabel, value } = event;

        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            let actualVals = value.filter(val=> (val.value !== "all" && Object.keys(val).length)) 

            return `${actualVals.length} selected`;
        }
        else
            return `${placeholderButtonLabel}`;

    }

    /**
     * CHANGE HANDLER FOR SELECTBOX/INPUTBOX
     * @param {*} name 
     * @param {*} value 
     * @param {*} financierIndex 
     */
    const changeHandler = (name, event, value, financierIndex, finacierOptionUpdated) => {
      
        if (!bmFormListData[financierIndex]) bmFormListData[financierIndex] = {}
        
        let OrigName = (name.split('_'))
        OrigName.pop()
        OrigName = OrigName.join('_');
        
         
            if (event.action === "select-option" && event.option.value === "all") {

                bmFormListData[financierIndex][OrigName] = finacierOptionUpdated.map((elm) => "" + elm.value);
                
            }else if(event.action === "deselect-option" && event.option.value === "all"){
                bmFormListData[financierIndex][OrigName] = [];
            }
            else if (event.action === "deselect-option") {
                bmFormListData[financierIndex][OrigName] = (value.filter((o) => o.value !== "all" && o.value !== event.option.value )).map(v=> ""+v.value);
            } 
            else {
    
                bmFormListData[financierIndex][OrigName] = value.map(
                    (elm) => "" + elm.value
                );
                
                value = value.filter(v=> Object.keys(v).length && v.value !== 'all');
    
                if (value.length === finacierOptionUpdated.length) {
                    bmFormListData[financierIndex][OrigName] = [...bmFormListData[financierIndex][OrigName]];
                }
            }

        setBMFormListData(bmFormListData);
        props.onChange(name, bmFormListData[financierIndex][OrigName], '', props.element)
    };

    const inputChangeHandler = (name, value, financierIndex, appType) => {
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");

        if (!bmFormListData[financierIndex])
            bmFormListData[financierIndex] = {};

        bmFormListData[financierIndex][OrigName] = +value.target.value;

        setBMFormListData(bmFormListData);

        props.onChange(name, bmFormListData[financierIndex][OrigName], "", props.element);
    }
   
    /**
     * ADD NEW FINANCER
     */
    const addNewBMConfig = () => {
        let temp_object = {
            bm_id: [],
            target_amt: ""
        }
        props.onChange('addNewBMConfig', temp_object, '', props.element)
    }

    const { t } = useTranslation('common');

    let { tempErrors, element } = props;
    let allSelectedValues = []
    return (
        <React.Fragment>
            <span className="config-heading">{t('COMMON.BM_CONFIG_HEADING')}</span>
            <div className='bm-configuration-filed-outer'>
                <table>
                    <thead>
                        <tr>
                            <th>{t('FORM.BUSINESS_MANAGER')+'*'}</th>
                            <th>{t('FORM.TARGET')+'*'}</th> 
                        </tr>
                    </thead>
                    <tbody>

                        {

                            (bmFormListData).map((elm, key) => {
                                let financierExclude = [];
                                bmFormListData.forEach((item, k) => {
                                    if (k !== key && item.bm_id) {
                                        financierExclude = [...financierExclude, ...item.bm_id]
                                    }
                                });
                                
                                const finacierOptionUpdated = financerOption && financierExclude
                                                ? 
                                                    financerOption.filter(
                                                        (ob) => (ob.value === 'all' || !financierExclude.includes("" + ob.value))
                                                    )
                                                
                                                : 
                                                    financerOption 
                                                        ? financerOption
                                                        : []
                                                ;

                                const selectedValues = (bmFormListData[key] && bmFormListData[key]["bm_id"]) && financerOption
                                ? 
                                        
                                        (bmFormListData[key]["bm_id"].length === finacierOptionUpdated.length) ? 
                                            [ 
                                                ...financerOption.filter((opt) => bmFormListData[key]["bm_id"].includes("" + opt.value)) 
                                            ] :  
                                            
                                            financerOption.filter((opt) => bmFormListData[key]["bm_id"].includes("" + opt.value)) 
                                    
                                : [];

                                allSelectedValues = [...allSelectedValues , ...bmFormListData[key]["bm_id"]]

                                return (
                                    <tr key={key}>
                                        <td>
                                        <fieldset className="single-select">  
                                                <div className="material">
                                                    <MultiSelect
                                                        isMulti={true}
                                                        options={finacierOptionUpdated}
                                                        // options={financerOption.filter(ob => !financierExclude.includes('' + ob.value))}
                                                        placeholder="Select BM"
                                                        iconAfter="false"
                                                        getDropdownButtonLabel={(value) => getDropdownButtonLabel(value, key)}
                                                        value={selectedValues}
                                                        isDisabled={element.is_expired || false}
                                                        onChange={(val, event) => changeHandler((('bm_id_' + key)), event, val, key, finacierOptionUpdated)}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                         
                                                    />
                                                </div>

                                                {
                                                    (tempErrors && tempErrors["bm_id_" + key]) ? <span className="error show">{tempErrors["bm_id_" + key]}</span> : <span>&nbsp;</span>
                                                }
                                            </fieldset>
                                            
                                        </td>
                                        <td>
                                            <fieldset>
                                                <div className="material"> 
                                                    <InputField
                                                        inputProps={{
                                                            id: "target_amt",
                                                            type: "text",
                                                            name: "target_amt",
                                                            placeholder: " ",
                                                            value: (bmFormListData[key] && ''+bmFormListData[key]['target_amt']) ? bmFormListData[key]['target_amt']:'', 
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e, i, q, u) => inputChangeHandler(('target_amt_' + key), e, key)}
                                                    />
                                                </div>

                                                {
                                                    (tempErrors && tempErrors["target_amt_" + key]) ? <span className="error show">{tempErrors["target_amt_" + key]}</span> : <span>&nbsp;</span>
                                                }

                                            </fieldset>
                                        </td> 
                                    </tr>
                                )
                            }

                            )
                        }

                    </tbody>
                </table>

                <div className="btn-save-remarks">
                    {
                        ((financerOption && financerOption.length !== allSelectedValues.length))
                        ?
                            <div className="btn-save-remarks">
                                <button onClick={(e) => { addNewBMConfig() }} type="button" className="btn-primary"> {t("FORM.ADD_NEW")} </button>
                            </div>
                        :
                            ""
                    }
                    
                    {
                        (tempErrors && tempErrors["selectAllBM"]) ? <span className="error show">{tempErrors["selectAllBM"]}</span> : ''
                    }
                </div>
            </div>
        </React.Fragment>
    )
    // }
}
export default BMConfigurationCard;