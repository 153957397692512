import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField'; 
import { Calendra } from './DatePicker'
import { DateFormat } from "../../config/helper";
class IncentiveAmountCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh : 1
        };
    }


    onChange = (event) => {
        let { name, value } = event.target 
        this.props.element[name] = value; 
        this.setState({ refresh: this.state.refresh+1 })  
    }
    onChangeDate = (date)=>{
        this.props.element.mid_month = DateFormat(date);
        this.setState({ refresh: this.state.refresh+1 })  
    }

    render() { 
        const { element, tempErrors,incentive_config } = this.props  
        let maxDate, minDate = null;  
        if(incentive_config.scheme_duration && incentive_config.scheme_duration.length && element.scheme_duration){   
            let record = incentive_config.scheme_duration.filter(ob=>ob.value === +element.scheme_duration) 
            if(record.length){
                minDate = record[0].start;
                maxDate = record[0].end;
            }
        } 

        return (
            <React.Fragment>
                <div className='app-submit-outer add-incentive-table'>

                    <table>
                        <thead>
                            <tr> 
                                <th>{this.props.t('FORM.INCENTIVE_AMOUNT')+'*'}</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr>  
                                <td className="pd20">
                                    <fieldset>
                                        <div className="material">
                                            <InputField
                                                inputProps={{
                                                    id: "incentive_amount",
                                                    type: "text",
                                                    name: "incentive_amount",
                                                    placeholder: " ",
                                                    value: this.state["incentive_amount"] || element.incentive_amount || "",
                                                    className: "form-input",
                                                    disabled: element.is_expired || false
                                                }}
                                                onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                            />
                                        </div>
                                        {
                                            (tempErrors && tempErrors["incentive_amount"]) ? <span className="error show">{tempErrors["incentive_amount"]}</span> : ''
                                        }
                                    </fieldset>
                                </td> 

                                <td> 
                                    <fieldset>
                                        <div className="material">
                                            <Calendra 
                                            element={element} 
                                            dateFormat="dd/MM/yyyy" 
                                            label="Mid Month Date"
                                            minDate={new Date(minDate)}
                                            maxDate={new Date(maxDate)}
                                            onChange={(e) => { this.onChangeDate(e) }} selected={element.mid_month || null} />
                                        </div>
                                        {
                                            (tempErrors && tempErrors["mid_month"]) ? <span className="error show">{tempErrors["mid_month"]}</span> : ''
                                        }
                                    </fieldset> 
                                </td> 
                            </tr> 
                        </tbody>
                    </table>



                </div>

            </React.Fragment>
        )
    }
} 
export default withTranslation('common')(IncentiveAmountCard);
