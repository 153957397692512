import React, {Component} from 'react';
import * as HELPER from './../../config/helper';

class CurrencyInputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            type: '',
            name: '',
            label:'',
            dataerror:'',
            value:'',
            onChange: '',
            className:'form-control',
            maxLength : 9,
            integer : 0
        }
    }

    changeHandel = (event)=>{
        if(typeof this.props.onChange === 'function' && this.props.inputProps && +this.props.inputProps.integer === 1){
            /* input for handle integer without decimal from 0 to 1,000,000,000 */
            let { value } = event.target;
            value = HELPER.removeCurrencyFormatting(value)
            value = (typeof value !== "undefined") ? value : '';
            const validated = /^\d*$/.test(value);
           if (validated  && value <= 1000000000 ) {
               this.setState({value})
               this.props.onChange(event)
           }
        } else if (typeof this.props.onChange === 'function') {
            let { value } = event.target;
            value = HELPER.removeCurrencyFormatting(value)
            value = (typeof value !== "undefined") ? value : ''

            if(!isNaN(value)){
                this.setState({value})

                this.props.onChange(event);
            }
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedState = prevState;
        let { value } = nextProps.inputProps;
        value = HELPER.removeCurrencyFormatting(value)

        let incomingValue = (!isNaN(value)) ? value : '';

        if(prevState.id !== nextProps.inputProps.id || (incomingValue !== (prevState.value))/*&& nextProps.inputProps.value*/){
            updatedState = {...nextProps.inputProps, value: incomingValue ? HELPER.addCurrencyFormatting(incomingValue) : ''};            
            return updatedState;
        }
        return null;
    }

    chkValFocusOut = (event) => {
        if(event.target.value !== '' &&  event.target.value !== 'undefined' && event.target.value !== null){
            event.target.value = HELPER.removeCurrencyFormatting(event.target.value);
            event.target.value = HELPER.addCurrencyFormatting(event.target.value);
            this.setState({value: event.target.value})
            this.props.onChange(event)
    
        }
        
    }
    chkValFocus = (event) => {
        if(event.target.value !== '' &&  event.target.value !== 'undefined' && event.target.value !== null){
            event.target.value = HELPER.removeCurrencyFormatting(event.target.value)
            this.setState({value: event.target.value})
    
            this.props.onChange(event)
        }
        
    }
        
    render() {
        let props = this.props;
        return (

                <>  
                    <input {...this.state} onBlur={this.chkValFocusOut} onFocus={this.chkValFocus} onChange={this.changeHandel} className={props.inputProps.className || this.state.className} autoComplete="off"  disabled={props.inputProps.disabled}/>          
                </>
        );
    }
}

export default CurrencyInputField;
