import React, { Component } from 'react';
import Select from 'react-select'; 
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';  
import MultiSelect from '../common/MultiSelect'

class AreaFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            AreaOption: null,
            MapIndex: this.props.map_index,
            Area: []
        };
    }

    componentDidMount = () => {
        let { top3_per_area_per_month , elm,dynamicAreaAM} = this.props
        this.setArea()
        this.setAM(elm,dynamicAreaAM)
        let { Area } = this.state
        if (top3_per_area_per_month && top3_per_area_per_month.length > 0) {
            top3_per_area_per_month.forEach((element, index) => {
                let check_existing = Area.findIndex(x => x.value === element.area_name);
                if (check_existing > -1) {
                    Area[check_existing].isDisabled = true
                }
            });
            this.setState({ Area })
        }
    }

    setAM = (elm,dynamicAreaAM) => {
        let amArray = []
        if(elm){
            if(elm.top3_per_area_per_month){
                let record = elm.top3_per_area_per_month;     
                for (let index = 0; index < record.length; index++) {
                    let element = record[index]
                    let amIds = element && element.am_ids && element.am_ids.length > 0 ? element.am_ids.split(",") : []
                    let data = dynamicAreaAM.filter(({ value}) => amIds.includes(value))
                    if(data){
                        for (let j = 0; j < data.length; j++) {
                            amArray.push({
                                ...data[j],
                                map_index:index
                            })
                        }
                    }                   
                 }
             }
        }
        this.props.setUsedAM(amArray)
    }

    handleChangeArea = (AreaOption, index) => {
        let { Area } = this.state 
        if(this.props.elm.form_config.AREA_FILTER_TOP3) {
            // Area.forEach((element1, index) => {
            //     if (element1.value === AreaOption.value) {
            //         Area[index].isDisabled = true
            //     } else {
            //         Area[index].isDisabled = false
            //     }
            // })

            this.setState({ AreaOption, Area });
            this.props.handleTop3IncentiveChange("area_name_" + index, AreaOption, '', this.props.element)

        } 
        
        
    };

    onChange = (event) => {
        let { name, value } = event.target 
        this.setState({ [name]: value }) 
        if(this.props.elm.form_config.AREA_FILTER_TOP2){
            this.props.handleTop2IncentiveChange(name, value, '', this.props.element)
        }else{
            this.props.handleTop3IncentiveChange(name, value, '', this.props.element)
        }
    }

    setArea = () => {
        let { Area } = this.state
        let { area_list } = this.props.incentive_config
        area_list.forEach((element, index) => {
            Area.push({ value: element.area, label: "Area " + (parseInt(index) + 1) + " - " + element.area })
        });
        this.setState({ Area })
    }
    positionOrder = (type)=>{
        let data= {
            1: '1st',2:'2nd',3:'3rd',4:'4th',5:'5th'
        }
        return data[type]
    }

    handleChangeAreaAM = (AreaOption,element_index, MapIndex) => {
        this.props.handleTop3AreaChange("area_name_" + element_index,AreaOption,MapIndex,this.props.element)
    };

    filterOutUsed = (AreaAM,used_area_am,mapindex,am_ids) => {
        let result = used_area_am.filter(({ map_index}) => map_index != mapindex)  // eslint-disable-line
        if(result && result.length > 0){
            let data = []
            for (let i = 0; i < AreaAM.length; i++) {
                let record = result.filter(({ value}) => value == AreaAM[i].value)  // eslint-disable-line
                if(record && record.length > 0){
                  //  data.push({...AreaAM[i],isDisabled:true})
                }else{
                    data.push({...AreaAM[i],isDisabled:false})
                }
            }
            return data
        }else{
            return AreaAM
        }
    };
 
    render() {
        let { element,map_index, element_index, tempErrors,is_expired ,dynamicAreaAM ,elm,UsedAreaAm } = this.props
        let { city_list } = this.props.incentive_config

        if(!is_expired){
            is_expired = element.is_expired
        }
        
        let { Area } = this.state;
        if (element.area_name !== "" && Area.length > 0) {
            let check_existing = Area.findIndex(x => x.value === element.area_name);
            if (check_existing > -1) {
                Area[check_existing].isDisabled = true
            }
        } 

        Area = Area.map((element1, index) =>  {
            return {...element1, isDisabled : true}
        })
        if(elm.product_type ==='UCF'){
            Area = Area.splice(0,4);
        } else if(elm.product_type ==='UBRF'){
            Area = Area.splice(0,1);
        } 
        return (
            <React.Fragment>
                {element.per_quarter &&
                
                    <div className='area-detail-filter-outer'>

                        {   element.form_config.AREA_FILTER_TOP2 &&
                            <div className='area-drop-downfiled'>
                                <span className='filed-heading'>{this.props.t('FORM.SELECT_AREA')}</span>
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            value={city_list[0]}
                                            onChange={(e) => { this.handleChangeArea(e, element_index) }}
                                            options={city_list}
                                            placeholder="Area"
                                            isDisabled={is_expired || false}
                                            isSearchable={false}
                                        />
                                    </div>
                                    {
                                        (tempErrors && tempErrors["area_name_" + map_index]) ? <span className="error show">{tempErrors["area_name_" + map_index]}</span> : ''
                                    }
                                </fieldset>

                            </div>
                        }

                        <div className='form-filed'>
                            <span className='filed-heading'>{this.props.t('FORM.POSITION')}</span>
    
                            {element.per_quarter.map((data, index) => (
                               
                                <fieldset key={index}>
                                    <div className="material">
                                        <input disabled={true} id="" type="text" placeholder=" " value={this.positionOrder(this.state["position_" + index] || data.position)} name={"position_" + index} key={"position_" + index} className="form-input"  onChange={()=>{}} />
                                        <span className="area-span">&nbsp;</span>
                                    </div>
                                   
                                </fieldset>
                            ))}
                        </div>

                        <div className='form-filed'>
                            <span className='filed-heading'>{this.props.t('FORM.INCENTIVE_AMOUNT')+'*'}</span>
                            {element.per_quarter.map((data, index) => (
                                <fieldset key={index}>
                                    <div className="material">
                                        {/* <input id="" type="number" placeholder=" " value={this.state["incentive_amount_" + index] || data.incentive_amount} onChange={(e, i, q, u) => this.onChange(e, i, q, u)} name={"incentive_amount_" + index} className="form-input" disabled={is_expired || false} /> */}
                                        <InputField
                                            inputProps={{
                                                id: "incentive_amount_" + index,
                                                type: "text",
                                                name: "incentive_amount_" + index,
                                                placeholder: " ",
                                                value: this.state["incentive_amount_" + index] || data.incentive_amount,
                                                className: "form-input",
                                                disabled: is_expired || false
                                            }}
                                            onChange={(e, i, q, u) =>this.onChange(e, i, q, u)}
                                        />
                                        {
                                        (tempErrors && tempErrors["incentive_amount_" + index]) ? <span className="error show area-span">{tempErrors["incentive_amount_" + index]}</span> : <span className="area-span">&nbsp;</span>
                                    }
                                    </div>
                                  
                                </fieldset>
                            ))}

                        </div>

                        
                    </div>
                }
                {element.items && element.items.length > 0 &&
                    <div className='area-detail-filter-outer'>
                        <div className='area-drop-downfiled'>
                            <span className='filed-heading'>{this.props.t('FORM.SELECT_AM')}</span>       
                            <fieldset className="single-select">  
                                <div className="material">
                                    <MultiSelect
                                        isMulti={true}
                                        options={this.filterOutUsed(dynamicAreaAM,UsedAreaAm,this.state.MapIndex,element.am_ids)}
                                        placeholder="Area"
                                        value={element.am_ids ? dynamicAreaAM.filter(({ value }) => element.am_ids.includes(value)): []}
                                        onChange={(e) => { this.handleChangeAreaAM(e,element_index,this.state.MapIndex) }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                    />
                                </div>
                                    {
                                        (tempErrors && tempErrors["area_name_" + map_index]) ? <span className="error show">{tempErrors["area_name_" + map_index]}</span> : ''
                                    }
                            </fieldset>
                            </div>
                        {element.items.length > 0 &&
                            <div className='form-filed'>
                                <span className='filed-heading'>{this.props.t('FORM.POSITION')}</span>
                                {element.area_name && element.items.length > 0 && element.items.map((area, area_index) => (
                                    <fieldset key={area_index}>
                                        <div className="material">
                                            <input disabled={true} id="" type="text" placeholder=" " value={this.positionOrder(area.position)} name={"position_" + area_index} key={"position_" + area_index} className="form-input" onChange={()=>{}}/>
                                            <span className="area-span">&nbsp;</span>
                                        </div>
                                    </fieldset>
                                ))}
                            </div>
                        }

                        {element.items.length > 0 &&
                            <div className='form-filed'>
                                <span className='filed-heading'>{this.props.t('FORM.INCENTIVE_AMOUNT')+"*"}</span>
                                {element.area_name && element.items.length > 0 && element.items.map((area, area_index) => (
                                    <fieldset key={area_index}>
                                        <div className="material">
                                            {/* <input id="" type="number" placeholder=" " value={ this.state["incentive_amount_" + area_index] || area.incentive_amount} onChange={(e, i, q, u) => this.onChange(e, i, q, u)} name={"incentive_amount_" + area_index} className="form-input" disabled={is_expired || false} /> */}

                                            <InputField
                                                inputProps={{
                                                    id: "incentive_amount_" + area_index,
                                                    type: "text",
                                                    name: "incentive_amount_" + area_index,
                                                    placeholder: " ",
                                                    value: this.state["incentive_amount_" + area_index] || area.incentive_amount,
                                                    className: "form-input",
                                                    disabled: is_expired || false
                                                }}
                                                onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                            />

                                        </div>
                                        {
                                            ( (!this.state["incentive_amount_" + area_index] && !area.incentive_amount) && tempErrors && tempErrors["incentive_amount_" + area_index]) ? <span className="error show area-span">{tempErrors["incentive_amount_" + area_index]}</span> : <span className="area-span">&nbsp;</span>
                                        }
                                    </fieldset>
                                ))}
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
} 

export default withTranslation('common')(AreaFilter);