
import React, { Component } from 'react';
class ToggleSwitch extends Component {
    constructor() {
        super();

        this.state = {
        };

        this.onToggleSwitchChange = this.onToggleSwitchChange.bind(this);
    }

    onToggleSwitchChange() {
        let { status, is_expired } = this.props
        if(!is_expired){
            this.props.onChange("status", !status,false)
        }
    }

    render() {
        let { status,is_expired } = this.props
        return (
            <div className='ToggleSwitch ToggleSwitch__rounded'>
                <div className='ToggleSwitch__wrapper'>
                    {
                      (!is_expired)?
                      <div className={`Slider ${status && 'isChecked'}`} onClick={this.onToggleSwitchChange}></div>: <div className={`Slider ${status && 'isChecked'}`}></div>
                    }
                    
                </div>
            </div>
        );
    }
}

export default ToggleSwitch