import React from 'react';
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';
import CustomValueContainer from '../common/CustomValueContainer' ; 
import Select from 'react-select';
import ReactTreeDropdown, {  OPTION_STYLE  } from "react-multi-style-dropdown-tree";
import "react-multi-style-dropdown-tree/lib/index.css";

const PointDetails = (props) => {
   
    let  {configData,element, tempErrors, financierList,financierDropDown,is_expired}  = props;
    const application_type = configData.product_type.filter(ob=>ob.application_type===1);
    
   

    const onChange = (app_type, index, event) => {
        let { checked } = event.target;
        props.onChange(app_type, checked, 'status' , index)
    }

    const onValueChange = (app_type, index, event) => {
        let {  value } = event.target;
        props.onChange(app_type, value, 'config_value' , index)
    }

    const handleChangeParameter = (app_type, index, ParameterType) => {
        props.onChange(app_type, Number(ParameterType.value), 'parameter_type', index)
    };
  
    const handleChangeFinancier = (selectedNodes,event,app_type,index) => {
        const finacierArr=financierList[app_type];
        let financierId=[];
        for(let data of selectedNodes){
            if(data.id==='all'){
                let Ids=finacierArr.map(ob => ob.id);
                financierId=[...Ids,...financierId];
            }else if(data.id==='Branch Partnership'){
                let Ids=finacierArr.filter(el => el.type ===data.id).map(ob => ob.id);
                financierId=[...Ids,...financierId];
                
            }else if(data.id==='HO Partnership'){
                let Ids=finacierArr.filter(el => el.type ===data.id).map(ob => ob.id);
                financierId=[...Ids,...financierId];

            }else if(data.id==='Others'){
                let Ids=finacierArr.filter(el => el.type ===data.id).map(ob => ob.id);
                financierId=[...Ids,...financierId];
            }else{
                financierId.push(data.id);
            }

        }
        let finanicer_ids=(financierId.length)?financierId.join(',') : '';
        props.onChange(app_type, finanicer_ids, 'finanicer_ids', index);
    };

        return (
            <React.Fragment>
               <div className='left-right-panel-outer app-point-outer'>
                    <div className='add-incenative-left-panel'>
                        <div className="add-incentive-table">
                            <div>
                            <h3 className="h3">{props.t('POINT_CONFIG_DETAIL.HEADING')} </h3>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{props.t('POINT_CONFIG_DETAIL.FIELDS.AT')}</th>
                                        <th>{props.t('POINT_CONFIG_DETAIL.FIELDS.ST')} </th>
                                        <th>{props.t('POINT_CONFIG_DETAIL.FIELDS.PM')} </th>
                                        <th>{props.t('POINT_CONFIG_DETAIL.FIELDS.F')} </th>
                                        <th>{props.t('POINT_CONFIG_DETAIL.FIELDS.P')} </th>
                                        <th>{props.t('POINT_CONFIG_DETAIL.FIELDS.V')} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {  
                                    application_type && application_type.map((el, index) => {
                                        let data=(element.length>0)?element.find(e=>e.application_type===el.value):'';

                                        let finanicer_ids=(data && data.finanicer_ids)?data.finanicer_ids.split(',').map(Number):[];
                                      
                                        let financierData=financierDropDown?financierDropDown[el.value]:'';
                                        let fListData=financierList[el.value];

                                        let selectedData=finanicer_ids.length && fListData ?fListData.filter(el=>finanicer_ids.includes(el.id)):[]

                                        let dropDownvalue=(selectedData.length)?[{id:'all',value:`${selectedData.length} selected`}]:[];
                                      
                                        return(
                                            financierData && <tr key={index}>
                                            <td>{el.label}</td>
                                            <td>
                                                <fieldset>
                                                    <div className="custom-control custom-checkbox">
                                                        <input 
                                                        checked={data && data.status ? true : false }  
                                                        onChange={onChange.bind(null, el.value, props.index)} 
                                                        id={index} 
                                                        type="checkbox" className="custom-control-input " 
                                                        name={"status"} 
                                                        disabled={is_expired}
                                                          />
                                                        <label className="custom-control-label"></label>
                                                    </div>
                                                      
                                                </fieldset>
                                            </td>
                                            <td>
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select components={{ ValueContainer: CustomValueContainer }}
                                                            value={data && data.parameter_type ? configData.parameter_type.filter(({ value }) => value === data.parameter_type) : ''}
                                                            options={configData.parameter_type}
                                                            onChange={handleChangeParameter.bind(null, el.value, props.index)}
                                                            placeholder={props.t('POINT_CONFIG_DETAIL.FIELDS.PM')}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            isSearchable={false}
                                                            isDisabled={!data || !data.status || is_expired}
                                                        />
                                                    </div>
                                                    {
                                                        (tempErrors && tempErrors["parameter_type_" + el.label]) ? <span className="error show">{tempErrors["parameter_type_" + el.label]}</span> : <span>&nbsp;</span>
                                                    }
                                                </fieldset>
                                            </td>
                                            <td>
                                            <fieldset className="single-select point-config">
                                                <div className={"material "+ ((!data || !data.status || is_expired)?'financier-dropdown':'')} id={"Id"+props.index+"_"+(index+1)}>

                                                <ReactTreeDropdown
                                                    value={dropDownvalue}
                                                    initializeValue={selectedData}
                                                    dropdownOptions={financierData}
                                                    optionStyle={OPTION_STYLE.VERTICAL}
                                                    placeholder={props.t('POINT_CONFIG_DETAIL.FIELDS.F')}
                                                    handleValueChange={(value, event) =>
                                                        handleChangeFinancier(
                                                            value,event, el.value,props.index
                                                        )
                                                    }
                                                /> 
                                                </div> 
                                                {
                                                    (tempErrors && tempErrors["finanicer_ids_" + el.label]) ? <span className="error show">{tempErrors["finanicer_ids_" + el.label]}</span> : <span>&nbsp;</span>
                                                    }
                                            </fieldset>
                                            </td>
                                            <td>
                                                <fieldset>
                                                    <div className="material">
                                                        <InputField
                                                            inputProps={{
                                                                type: "text",
                                                                placeholder: " ",
                                                                value: data && data.point ? data.point : 1,
                                                                className: "form-input",
                                                                disabled: true
                                                            }}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </td>
                                            <td>
                                                <fieldset>
                                                    <div className="material">
                                                        <InputField
                                                            inputProps={{
                                                                type: "text",
                                                                placeholder: props.t('FORM.VALUE'),
                                                                value: data && data.config_value ? data.config_value : "" ,
                                                                className: "form-input",
                                                                disabled: !data || !data.status || is_expired
                                                            }}
                                                            onChange={onValueChange.bind(null, el.value, props.index)}
                                                        />
                                                    </div>
                                                    {
                                                        (tempErrors && tempErrors["config_value_" + el.label]) ? <span className="error-value show">{tempErrors["config_value_" + el.label]}</span> : <span>&nbsp;</span>
                                                    }
                                                </fieldset>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            {
                                (tempErrors && tempErrors["point_config"]) ? <span className="error show">{tempErrors["point_config"]}</span> : <span>&nbsp;</span>
                            }
                        </div>    
                    </div>
                </div>
            </React.Fragment>
        )
   
} 
export default withTranslation('common')(PointDetails);
