import React, { useEffect,useState} from 'react'
import Modal from "../common/Modal";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IncentiveService } from "../../services";
import { toast } from "react-toastify";
import FullPageLoader from '../common/FullPageLoader';
import * as HELPER from "./../../config/helper";

function DealerDataExport(props) { 
    const [buttonDisable, setButtonDisable] = useState(true);
    const [leadData, setLeadData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common");
    const commonRedux = useSelector((state) => state.common);
    
    const leadheaders = [
        { label: t('DEALER.DEALER_ID'), key: "id" },
        { label: t('DEALER.PRODUCT_TYPE'), key: "product" },
        { label: t('DEALER.LOAN_ID'), key: "loanId" },
        { label: t('DEALER.TYPE'), key: "type" },
        { label: t('DEALER.UPLOADED_ON'), key: "uploaded_on" },
        { label: t('DEALER.AMOUNT_IN_RP'), key: "amount" },
        { label: t('DEALER.STATUS'), key: "approval_status" },
    ];
    
 
    useEffect(() => {
        let params= commonRedux.dealerReqParams ? JSON.parse(JSON.stringify(commonRedux.dealerReqParams)) : {};
            params.pagination=false;  
        exportDealerdata(params);
        // eslint-disable-next-line
    },[])

    const exportDealerdata = (params) => {
        setLoading(true);
        IncentiveService.getDealerDetails(params).then(function (response) {
            setLoading(false);
            if (response.data.status === 200) { 
                if(response.data.data && response.data.data.data){
                  let totaldata=response.data.data.data;
                    if(totaldata.length>0){
                        setButtonDisable(false);
                        let leadArray = [];
                        for(let i=0;i<totaldata.length;i++){ 
                            let userRecord = totaldata[i];
                           
                            userRecord.items.forEach((ob)=>{
                               
                                let leadRecord = {
                                    id: userRecord.dealer_id,
                                    product: ob.product,
                                    loanId:ob.loanId,
                                    type: ob.isCaclulated ? "Calculated" : "Bulk Upload",
                                    uploaded_on:ob.uploaded_on,
                                    amount: (HELPER.numberFormat(ob.amount)),
                                    approval_status: ob.status_name
                                    
                                };
                                leadArray.push(leadRecord);
                            });
                        }   
                        setLeadData(leadArray);
                    }
                }
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch(function () {
            setLoading(false);
        });
    };
    return (
        <div className="model-popup-outer confirmation-popup">
            <FullPageLoader show={loading} />
            <Modal show={props.show} handleClose={props.handleClose} >
                <div className="modal-header">
                    <h2>{t('DETAIL.DATA_EXPORT_AS')}</h2>
                </div>
                <div className="modal-body" >
                    <div className='confirmation-btn'>
                        <CSVLink data={leadData} headers={leadheaders} filename={"lead-data.csv"}>
                            <button className='btn-primary' disabled={buttonDisable}>{t('FORM.DEALER_DATA')}</button>
                        </CSVLink> 
                        
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DealerDataExport