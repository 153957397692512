import Modal from "../common/Modal";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { CommonActions } from "../../store/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch, } from "react-redux";
import FullPageLoader from "../common/FullPageLoader";
import { IncentiveService } from "../../services";
import DeviationLimitTabs from "./DeviationLimitTabs";
import { Tab, Nav } from "react-bootstrap";
import { AM_ROLE_ID } from "../../config/constant";
import * as HELPER from "./../../config/helper";


function DeviationLimitConfig(props) {
    const { t } = useTranslation('common');
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deviationConfigList , setDeviationConfigList] = useState([]); 
    const [deviationOthersList , setDeviationOthersList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const commonRedux = useSelector((state) => state.common);
    const [amList, setAmList] = useState([]);
    const [amListForJabod, setAmListForJabod] = useState([]);
    const [amListForOthers, setAmListForOthers] = useState([]);
    const [tempErrors, setTempErrors] = useState([]);
    const dispatch = useDispatch();



    useEffect(() => {
        getDeviationLimitConfig();
        getAreaAM();
        // eslint-disable-next-line
    }, []);


    const showModalPopUp = async() => {
        setShowPopup(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalPopUp = async() => {
        setShowPopup(false)
        setTempErrors({});
        document.body.classList.remove("overflow-hidden");
    }

    const changeHandler = async(event, data, name, key) => {
        let deviationData = key === 1 ? deviationConfigList : deviationOthersList;
        const index = deviationData.findIndex(obj => +obj.financier_id === +data.value);
        if (index !== -1) {
            if(name === "amount"){
                let { value } = event.target;
                if(value !== '' && value != undefined && value !== null){
                    value = HELPER.removeCurrencyFormatting(value);
                    const validated = /^\d*$/.test(value)
                   if (validated && value <= 1000000000) {
                    deviationData[index].amount = value;
                   }
                } else {
                    deviationData[index].amount = '';

                }
              
            } else if(name === "toggle"){
                deviationData[index].status = event ? 1 : 0;
                deviationData[index].amount = event ? deviationData[index].amount: '';
            }
        } else {
            deviationData.push({financier_id : data.value, status : 1, amount : '', area_id : key });
        }
        if(key === 1){
            setDeviationConfigList(deviationData);
        } else {
            setDeviationOthersList(deviationData);
        }
        setRefresh(refresh+1);
    }

    const handleChangeAreaAM = async(event, key) => {
        const selected = event.length ? event.map((el) => el.id) : [];
        if(key === 1){
            setAmListForJabod(selected);
        } else {
            setAmListForOthers(selected);
        }
    }


    const dataValidation = async(data, tabKey) => {
        let formIsValid = true;
        let tempErrors = {};
        let len = data.length;
        for(let i = 0; i< len ; i++){
            if(data[i].status === 1 && (data[i].amount === '' || data[i].amount === 'undefined' || data[i].amount === null)){
                formIsValid = false;
                tempErrors["amount_" + tabKey + "_" + data[i].financier_id] = t("VALIDATION.AMOUNT_REQ");
            }
        }
        if(tabKey === 1 && amListForJabod.length<1){
            formIsValid = false;
            tempErrors["am_ids_" + tabKey] = t("VALIDATION.AM_IDS");
        } else if(tabKey === 2 && amListForOthers.length<1){
            formIsValid = false;
            tempErrors["am_ids_" + tabKey] = t("VALIDATION.AM_IDS");
        }
        
        setTempErrors(tempErrors);
        return formIsValid;
    }

    

    const saveDeviationConfig = async(data, tabKey, amList) => {
        let isValid = await dataValidation(data, tabKey);
        if (isValid) {
            setLoading(true);
            CommonActions.saveDeviationConfig({deviation_config : data, am_ids : amList?.join(",")}).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    getDeviationLimitConfig();
                    toast.success(res.message);
                } else {
                    setDeviationConfigList(commonRedux.deviationLimitConfig.jabodList);
                    setDeviationOthersList(commonRedux.deviationLimitConfig.othersList);
                    toast.error(res.message);
                }
            }).catch(err => {
                setLoading(false)
                toast.error(err)
            });
        }
    }

    const getDeviationLimitConfig = ()=>{
        setLoading(true);
        dispatch(CommonActions.getDeviationLimitConfig()).then((res) => {
            if (res && res.status === 200 && res.data) {
                setDeviationConfigList(res.data.jabodList || []);
                setDeviationOthersList(res.data.othersList || []);
                if(res.data.jabodList?.length){
                    const amIds = res.data.jabodList[0].am_ids?.split(",");
                    setAmListForJabod(amIds || []);
                } 
                if(res.data.othersList?.length){
                    const amIds = res.data.othersList[0].am_ids?.split(",");
                    setAmListForOthers(amIds || []);
                }
               
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }


    const getAreaAM = async() => {
        setLoading(true);
        IncentiveService.getSchemeAreaList({role_id: [AM_ROLE_ID]})
        .then(function (response) {
            if (response.data.status === 200) {
                if (Object.keys(response.data.data).length > 0) {
                    setAmList(response.data.data);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        })
        .catch(function (response) { });
    }

    

    
    let { financierList } = props;
    return (

        <>
            <button className="btn-line m-sm-r" onClick={showModalPopUp}>{t("DEALER.DEVIATION_TITLE")}</button>
            <FullPageLoader show={loading} />
            {showPopup &&
               <div className="model-popup-outer dealer-financer-popup">
               <Modal show={showPopup} handleClose={() => hideModalPopUp()}>
                   <div className="modal-header">
                       <h2>{t("DEALER.DEVIATION_HEADING")}</h2>
                   </div>
                   <div className='deviation-model-tabs'>
                       <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                           <Nav variant="pills" className="flex-column tab-bg">
                               <div className="tab-list">
                                   <Nav.Item>
                                       <Nav.Link eventKey="first">{t("DEALER.JABODETABEK")}</Nav.Link>
                                   </Nav.Item>
                                   <Nav.Item>
                                       <Nav.Link eventKey="second">{t("DEALER.OTHERS")} </Nav.Link>
                                   </Nav.Item>

                               </div>

                           </Nav>

                        {
                            financierList.length > 0 ?
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <DeviationLimitTabs
                                            financierList={financierList}
                                            deviationConfigList={deviationConfigList}
                                            tabKey={1}
                                            amList={amList}
                                            amListSelected={amListForJabod}
                                            idsToExclude={amListForOthers}
                                            changeHandler={(val, fin, name, key) => changeHandler(val, fin, name, key)}
                                            saveDeviationConfig={(e, key, ams) => saveDeviationConfig(e, key, ams)}
                                            handleChangeAreaAM={(e, key) => handleChangeAreaAM(e, key)}
                                            tempErrors={tempErrors}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <DeviationLimitTabs
                                            financierList={financierList}
                                            deviationConfigList={deviationOthersList}
                                            tabKey={2}
                                            amList={amList}
                                            amListSelected={amListForOthers}
                                            idsToExclude={amListForJabod}
                                            changeHandler={(val, fin, name, key) => changeHandler(val, fin, name, key)}
                                            saveDeviationConfig={(e, key, ams) => saveDeviationConfig(e, key, ams)}
                                            handleChangeAreaAM={(e, key) => handleChangeAreaAM(e, key)}
                                            tempErrors={tempErrors}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                                : (loading) ? null : <div className="no-data">{t("DEALER.NO_DATA_FOUND")}</div>
                        }
                          

                       </Tab.Container>
                   </div>
               </Modal>
           </div>
            }
        </>

    );
}

export default DeviationLimitConfig;