import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';
 
class ExtraIncentiveCard extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    onChange = (event) => {
        let { name, value } = event.target
        this.setState({ [name]: value })
        this.props.onChange(name, value, '', this.props.element)
    }

    render() {
        const { agent_level } = this.props.incentive_config
        const { element, tempErrors } = this.props
        return (
            <React.Fragment>
                <div className='extra-incentive-outer add-incentive-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>{this.props.t('FORM.AGENT_LEVEL')}</th>
                                <th>{this.props.t('FORM.ADDITIONAL_DISB_FEE') + '*'}
                                    <span> {this.props.t('FORM.PER_APPLICATION')}</span>
                                </th>
                                <th>{this.props.t('FORM.MINIMUM_DISB_COUNT') + '*'}
                                    <span>{this.props.t('FORM.PER_MONTH')}</span>
                                </th>
                                <th>{this.props.t('FORM.ADDITIONAL_INCENTIVE') + '*'}
                                    <span>{this.props.t('FORM.PER_MONTH')}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {element.extra_incentive && element.extra_incentive.map((data, index) => (
                                <tr key={index}>
                                    <td>{agent_level.filter((e) => (e.id === data.agent_level_id)).map(({ agent_name }) => agent_name)}</td>
                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "add_disbursed_fee_" + index,
                                                        type: "text",
                                                        name: "add_disbursed_fee_" + index,
                                                        placeholder: " ",
                                                        value: this.state["add_disbursed_fee_" + index] || data.add_disbursed_fee || "",
                                                        className: "form-input",
                                                        disabled: element.is_expired || false
                                                    }}
                                                    onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                />
                                            </div>
                                            {
                                                (tempErrors && tempErrors["add_disbursed_fee_" + index]) ? <span className="error show">{tempErrors["add_disbursed_fee_" + index]}</span> : <span>&nbsp;</span>
                                            }
                                        </fieldset>
                                    </td>

                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "min_disbursed_fee_" + index,
                                                        type: "text",
                                                        name: "min_disbursed_fee_" + index,
                                                        placeholder: " ",
                                                        value: this.state["min_disbursed_fee_" + index] || data.min_disbursed_fee || "",
                                                        className: "form-input",
                                                        disabled: element.is_expired || false
                                                    }}
                                                    onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                />
                                            </div>
                                            {
                                                (tempErrors && tempErrors["min_disbursed_fee_" + index]) ? <span className="error show">{tempErrors["min_disbursed_fee_" + index]}</span> : <span>&nbsp;</span>
                                            }
                                        </fieldset>
                                    </td>
                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "add_incentive_" + index,
                                                        type: "text",
                                                        name: "add_incentive_" + index,
                                                        placeholder: " ",
                                                        value: this.state["add_incentive_" + index] || data.add_incentive || "",
                                                        className: "form-input",
                                                        disabled: element.is_expired || false
                                                    }}
                                                    onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                />
                                            </div>
                                            {
                                                (tempErrors && tempErrors["add_incentive_" + index]) ? <span className="error show">{tempErrors["add_incentive_" + index]}</span> : <span>&nbsp;</span>
                                            }
                                        </fieldset>
                                    </td>
                                </tr>
                            ))}


                        </tbody>
                    </table>



                </div>

            </React.Fragment>
        )
    }
} 
export default withTranslation('common')(ExtraIncentiveCard);
