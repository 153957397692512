import React, { useState, useEffect } from "react";
import Select from "react-select"; 
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../store/action";
import { toast } from "react-toastify";
import CustomValueContainer from "../common/CustomValueContainer"; 
import { useParams, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BulkUpload from "./DealerBulkUpload"; 
import MultiSelect from '../common/MultiSelect';
import { useTranslation } from 'react-i18next';
import CalculateButton from './CalculateButton'

const DealerFilter = (props) => {
    const { t } = useTranslation('common');
    const [dealerIds, setDealerIds] = useState([]);
    const [dealerName, setDealerName] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(props.searchFilters || {});
    const { roleName } = useParams();

    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);
    let productOptions = (commonRedux.dealerConfigDetail?.productTypes) ? commonRedux.dealerConfigDetail.productTypes : [];

    // For taking value from URL
    let { search } = useLocation();
    const queryParams = new URLSearchParams(search); 


    let month = queryParams.get('month');

    //CREATE DEFAULT DATE FOR MONTH FILTER
    let makeDate = month ? new Date(month) : (props.searchFilters.month ? new Date(props.searchFilters.month) : new Date());
    const [startDate, setStartDate] = useState(makeDate || new Date());


    useEffect(() => {

        if(selectedFilters.product_type && productOptions.length){
            let selectedRole = productOptions.filter(r => +r.label === +selectedFilters.product_type);
            
            dispatch(CommonActions.schemeList({ product_type: selectedRole[0]['product_type'], name: selectedRole[0]['name'] })).then((res) => {
                setDealerIds(res);
            });
        }
    // eslint-disable-next-line
    }, [roleName, productOptions]);



    useEffect(() => {
        if (commonRedux && commonRedux.dealerConfigDetail ) {
            if(commonRedux.dealerConfigDetail.dealerIds){
                setDealerIds(commonRedux.dealerConfigDetail.dealerIds);
            } 
            if(commonRedux.dealerConfigDetail.dealerNames){
                setDealerName(commonRedux.dealerConfigDetail.dealerNames);
            }   
        }
    // eslint-disable-next-line
    }, [commonRedux]);

   
    useEffect(() => {

        dispatch(CommonActions.getDealerConfig());
        // eslint-disable-next-line
    }, []);


    const changeHandler = (value, name) => {
        let selectedFiltersObj = selectedFilters;
        let searchFiltersObj = props.searchFilters;
        if (name === "product_type") {
            // If condition run Normal case, else condition for cross button 
            if (value) {
                selectedFiltersObj[name] = value.label;
            
                setSelectedFilters({ ...selectedFiltersObj});
                
                props.setSearchFilters(searchFiltersObj);

            }
            else {
                value = undefined;
                let newRecord = selectedFilters; 

                // Delete record from payload when we click cross icon
          
                delete newRecord.product_type;
                delete newRecord.dealer_id;
                delete newRecord.dealer_name;

                delete searchFiltersObj.product_type;
                delete searchFiltersObj.dealer_id;
                delete searchFiltersObj.dealer_name;

                props.setSearchFilters(searchFiltersObj);
                setSelectedFilters(newRecord);
            }
        }


        if (["dealer_name", "dealer_id"].includes(name)) {
            let labelValue = value.map((v) => v.label);
            let labelName = value.map((v) => v.value);
            if(name === "dealer_name"){
                setSelectedFilters((prev) => {
                    return { ...prev, [name]: labelValue, "dealer_id" : labelName  };
                });
            } else {
                setSelectedFilters((prev) => {
                    return { ...prev, [name]: labelValue, "dealer_name" : labelName };
                });
            }
        }

        if(name === "date"){
            /**EXPERIMENTAL CODE */
            value = new Date(value) ;
            value.setTime( value.getTime() - new Date().getTimezoneOffset()*60*1000 );
            /**EXPERIMENTAL CODE END*/
            
            setStartDate(value);
    
            updateFilters({ ...selectedFiltersObj, "month": new Date(value).toISOString() })
        }

    };

    /**
     * UPDATE FILTERS
     * @param {*} params 
     */
    const updateFilters = (params) => {
        let parseMonth = makeDate;
        //IF NO FILTER SELECTED SET DEFAULT DATE
        if (!Object.keys(params).length) {
            params = { month: parseMonth }
        }
        props.setSearchFilters(params);
        setSelectedFilters(params);
        
    };


    /**
     * RESET FILTER
     */

    const resetFilters = () => {
        let date =  new Date();

        let newRecord = selectedFilters; 
        newRecord.month = date;
        delete newRecord.product_type;
        delete newRecord.dealer_id;
        delete newRecord.dealer_name;

        props.setSearchFilters(newRecord);
        setSelectedFilters(newRecord);
     
        updateFilters({})
        setSelectedFilters({ month: date, status: 0, reset: true, pagination: true })
        props.getDealerDetailsList({ month: date, page_no: 1, status: 0, reset: true, pagination: true }) 

        setStartDate(date);
    }


    const searchFilter = () => { 
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        if(Object.keys(selectedFilters).length){
            props.setProductType("all");
            props.getDealerDetailsList({...selectedFilters, page_no: 1, status : props.searchFilters?.status}) 
        } else {
            toast.error("Please select any filter");
        }
    } 
   
    const resetFilter = async () => {
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        if(props.resetSearchFilter){
            props.resetSearchFilter();
        } 
        resetFilters()

    }

    return (
        <React.Fragment>
            <div className="lead-list-filter dealer-filters">
                <div className="dealer-filter-list">
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                        components={{ ValueContainer: CustomValueContainer }}
                            value={
                                (selectedFilters.product_type)
                                    ? productOptions.filter(
                                        (product) => (product.label === selectedFilters.product_type)
                                    )
                                    : null
                            }
                            onChange={(val) => changeHandler(val, "product_type")}
                            options={productOptions}
                            placeholder="Product Type"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">  
                        <div className="material">
                        <MultiSelect
                            isMulti={true}
                            options={dealerIds}
                            placeholder="Dealer Id"
                            iconAfter="false"
                            value={
                                selectedFilters.dealer_id
                                    ? dealerIds.filter((id) =>
                                        selectedFilters.dealer_id.includes(id.label)
                                    )
                                     : []
                            }
                            onChange={(val, event) =>
                                changeHandler(val, "dealer_id", event)
                            }
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">  
                        <div className="material">
                        <MultiSelect
                            isMulti={true}
                            options={dealerName}
                            placeholder="Dealer Name"
                            iconAfter="false"
                            value={
                                selectedFilters.dealer_name
                                    ? dealerName.filter((name) =>
                                        selectedFilters.dealer_name.includes(name.label)
                                    )
                                     : []
                            }
                            onChange={(val, event) =>
                                changeHandler(val, "dealer_name", event)
                            }
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>

                <fieldset className="dateragefiled">
                    <div className="material">

                        <div className="datepicker">
                            <DatePicker
                                selected={startDate ? new Date(startDate) : null}
                                onChange={(date) => changeHandler(date, "date")}
                                dateFormat="MM/yyyy"
                                placeholderText="Month"
                                showMonthYearPicker
                                maxDate={new Date()}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}

                            />
                        </div>
                    </div>
                </fieldset>

                <div className="btn-search-reset">
                    <button className="btn-primary" onClick={searchFilter}>{t("DEALER.SEARCH")}</button>
                    <button className="btn-reset" onClick={resetFilter} style ={{cursor:'pointer'}}>{t("DEALER.RESET")}</button>
                </div>
                </div>
                <div className="d-flex btn-calculate-upload">
                    <CalculateButton/>
                    <BulkUpload month={props.searchFilters ? props.searchFilters.month : null} getDealerDetailsList={props.getDealerDetailsList}  searchFilters={props.searchFilters} />
                </div>
                
                {/* SEARCH RESET BUTTON  */}
              
            </div>
        </React.Fragment>
    );
};
export default DealerFilter;
