import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputField from "../common/InputField";
import BasicDateRangeFilter from './BasicDateRangeFilter';
import DisbursalCountAmountCard from "../cards/DisbursalCountAmountCard";
import MultiSelect from '../common/MultiSelect'

const FinancerBoosterScheme = (props) => {
    const [financerOption, setFinancerOption] = useState({});
    const [financierFormListData, setFinancierFormListData] = useState([
        { financier_id: [] },
    ]);
    const commonRedux = useSelector((state) => state.common);
    const [selecteApplicationTypes, setSelecteApplicationTypes] = useState([]);
    const [allSelectedFinanciers, setAllSelectedFinanciers] = useState({});

    useEffect(() => {
        if (
            selecteApplicationTypes &&
            selecteApplicationTypes.length &&
            commonRedux &&
            commonRedux.financierList &&
            commonRedux.financierList.length
        ) {
            let allFinanciers = {};

            //DEFAULT FINANCIER DATA BY DATE AND APPTYPE
            if(props.element && props.element.date_range){
                for (let i = 0; i < props.element.date_range.length; i++) {
                    selecteApplicationTypes.forEach((appType) => {

                        let getFinancierListProductWise = commonRedux.financierList.filter(
                            (elm) => appType.toLowerCase() === elm.application_type_name.toLowerCase()
                        );

                        if (getFinancierListProductWise && getFinancierListProductWise.length) {
                            if (!allFinanciers[i + 1]) allFinanciers[i + 1] = {};

                            allFinanciers[i + 1][appType.toLowerCase()] =
                                getFinancierListProductWise[0]["financier"];
                        }
                    })

                }

                setFinancerOption(allFinanciers);
            }
        }
    }, [commonRedux, selecteApplicationTypes, props]);

    useEffect(() => {
        if (
            props.element &&
            props.element.application_type &&
            props.element.application_type !== selecteApplicationTypes.join(",")
        ) {
            setSelecteApplicationTypes(props.element.application_type.split(","));
        }
        if (props.element && props.element.date_range && props.element.date_range.length) {
            let selectedFinanciers = {};
            //ALL SELECTED FINACIERS DATE & APP TYPE BASED
            for (let i = 0; i < props.element.date_range.length; i++) {
                let tempOb = {}
                selecteApplicationTypes.forEach((appType) => {
                    let record = props.element.financier_config.filter((item) => (item.tab === i + 1 && item.application_type === appType.toLowerCase()));
                    let fIdArray = [];
                    for (let list = 0; list < record.length; list++) {
                        fIdArray = [...fIdArray, ...record[list].financier_id]
                    }
                    tempOb[appType.toLowerCase()] = fIdArray
                })
                selectedFinanciers[i + 1] = tempOb
            }
            setAllSelectedFinanciers(selectedFinanciers);
        }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        if (
            props.element.financier_config &&
            props.element.financier_config.length
        ) {
            setFinancierFormListData(props.element.financier_config);
        }
    }, [props]);

    const getDropdownButtonLabel = (event, financierIndex) => {
        let { placeholderButtonLabel, value } = event;

        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            let actualVals = value.filter(val => (val.value !== "all" && Object.keys(val).length))
            return `${actualVals.length} selected`;
        } else return `${placeholderButtonLabel}`;
    };

    /**
     * CHANGE HANDLER FOR SELECTBOX/INPUTBOX
     * @param {*} name
     * @param {*} value
     * @param {*} financierIndex
     */
    const changeHandler = (name, event, value, financierIndex, appType, finacierOptionUpdated, tab) => {
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");

        //UPDATE PARTICULAR INDEX IN ARRAY FOR SINGLE/MULTI SELECT/UNSELECT
        financierFormListData.filter(element => element.tab === tab).map((el, key) => {
            if (key === financierIndex) {
                if (event.action === "select-option" && event.option.value === "all") {
                    el[OrigName] = finacierOptionUpdated.map((elm) => "" + elm.value)
                }
                else if (event.action === "deselect-option" && event.option.value === "all") {
                    el[OrigName] = []
                } else if (event.action === "deselect-option") {
                    el[OrigName] = (value.filter((o) => o.value !== "all" && o.value !== event.option.value)).map(v => "" + v.value)

                } else {
                    el[OrigName] = value.map((elm) => "" + elm.value)
                }
            }
            return true;
        })

        setFinancierFormListData(financierFormListData);
        props.onChange('financier_config', financierFormListData, "", props.element);
    };

    /**
     * INPUT BUTTON CHANGE HANDLER
     * @param {*} name 
     * @param {*} value 
     * @param {*} financierIndex 
     * @param {*} appType 
     * @param {*} tab 
     */
    const inputChangeHandler = (name, value, financierIndex, appType, tab) => {
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");

        //UPDATE PARTICULAR AMOUNT OF SELECTED TAB INDEX
        financierFormListData.filter(element => element.tab === tab).map((el, key) => {
            if (key === financierIndex) {
                el[OrigName] = value.target.value
            }
            return true;
        })


        setFinancierFormListData(financierFormListData);

        props.onChange('financier_config', financierFormListData, "", props.element);
    }

    /**
     * ADD NEW FINANCER
     */
    const addNewFinancierConfig = (appType, tab) => {
        let temp_object = {
            financier_id: [],
            junior_amt: "",
            regular_amt: "",
            senior_amt: "",
            fixed_amt:"",
            application_type: appType.toLowerCase(),
            tab: tab
        };
        if(props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT && +props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT===1){
            temp_object.is_count_amount=1
        }
        props.onChange("addNewFinacierConfig", temp_object, "", props.element);
    };

    const addMore = (countArray) => {

        let params = {
            tab: countArray.length + 1,
            index: props.element_index,
            type: 'EXTRA_BOOSTER_SCHEME',
            scheme: props.element.scheme_type_id
        }
        if(props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT && +props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT===1){
            params.is_count_amount=1
        }
        props.addMoreIncentive(params)
    }

    /**
     * CHANGE HANDLER FOR DISBURSAL COUNT/AMOUNT
     * @param {*} name
     * @param {*} value
     * @param {*} financierIndex
     */
    const disbursal_Count_amount = (name,index,event,value,tab) => {
     
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");
        if(event.target.checked){
           
            financierFormListData.filter(element => element.tab === tab).forEach((el, key) => {
                if (key === index) {
                    el[OrigName] = value
                    el["financier_id"]=[]
                    el["junior_amt"]=''
                    el["regular_amt"]=''
                    el["senior_amt"]=''
                    el["fixed_amt"]=''
                }
            })
            setFinancierFormListData(financierFormListData);
            props.onChange('financier_config', financierFormListData, "", props.element);    
        }
    }
    
    const { t } = useTranslation("common");

    let { tempErrors, element } = props;

    // let allSelectedValues = {}
    let dateArray = [1]
    if (element.financier_config.length) {
        dateArray = [...new Set(element.financier_config.map(item => item.tab))];
    }  
    return (
        <React.Fragment>
            {element.application_type && (
                <span className="config-heading">
                    {t("COMMON.FINANCIER_CONFIG_HEADING")}
                </span>
            )}
            {
                dateArray.length && dateArray.map((tab, k) => {
                    return (<div key={k}>
                        {(element.form_config.DATE_RANGE_FILTER && element.application_type && selecteApplicationTypes) ?
                            <BasicDateRangeFilter element={element} onChange={(e, i, q, u) => { props.onChange(e, i, q, u) }} tempErrors={tempErrors} tab={tab} /> : ""
                        }
                        {element.application_type &&
                            selecteApplicationTypes &&
                            selecteApplicationTypes.map((appType, index) => {
                                appType = appType.toLowerCase();

                                return (<div className="configuration-filed-outer" key={index}>
                                    <table>
                                        <thead>
                                            <tr key={index}>
                                                <th>
                                                    {appType} {t("FORM.FINANCIER") + '*'}
                                                </th>
                                                <th>{t("FORM.SENIOR") + '*'}</th>
                                                <th>{t("FORM.REGULAR") + '*'}</th>
                                                <th>{t("FORM.JUNIOR") + '*'}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {financierFormListData.length && financierFormListData.filter(ob => ob.tab === tab).map((elm, key) => {
                                                let incrementedKey = key;
                                                let tabIndexKey = tab+'_'+incrementedKey;
                                                if (elm.tab >= tab) {
                                                    key = key + financierFormListData.filter(ob => ob.tab < tab).length
                                                }
                                                if (
                                                    elm.application_type &&
                                                    elm.application_type.toLowerCase() !==
                                                    appType
                                                ) {
                                                    return <tr key={key}></tr>;
                                                }

                                                //FROM SELECTED FINANCIERS GET EXCLUDED VALUES FOR NEXT SELECTBOX
                                                let financierExclude = [];
                                                if (allSelectedFinanciers[tab] && allSelectedFinanciers[tab][appType]) {
                                                    financierExclude = allSelectedFinanciers[tab][appType].filter((type) => !elm.financier_id.includes(type));
                                                }

                                              

                                                //GET UNSELECTED FINANCIERS TO SHOW IN SELECTBOX TAB & APP TYPE GROUP BY
                                                const finacierOptionUpdated = financerOption && financerOption[tab] && financerOption[tab][appType] && financierExclude
                                                    ?
                                                    financerOption[tab][appType].filter(
                                                        (ob) => (ob.value === 'all' || !financierExclude.includes("" + ob.value))
                                                    )

                                                    :
                                                    financerOption && financerOption[tab] &&
                                                        financerOption[tab][appType]
                                                        ? financerOption[tab][appType]
                                                        : []
                                                    ;

                                                let selectedValues = financerOption[tab] && financerOption[tab][appType] ? financerOption[tab][appType].filter(opt => elm.financier_id.includes('' + opt.value)) : []
 
                                               

                                                return (
                                                    <>
                                                    {
                                                        (props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT)? <DisbursalCountAmountCard elm={elm} index={incrementedKey} element={element} tab={tab} disbursal_Count_amount={disbursal_Count_amount}  />:''      
                                                    }
                                                    <tr key={key}>
                                                        <td>
                                                            <fieldset className="single-select">  
                                                                <div className="material" key={key}>
                                                                    <input
                                                                        type="hidden"
                                                                        value={elm.application_type}
                                                                        name="application_type"
                                                                    />

                                                                    <MultiSelect
                                                                        isMulti={true}
                                                                        options={finacierOptionUpdated}
                                                                        placeholder="Financer"
                                                                        iconAfter="false"
                                                                        getDropdownButtonLabel={(value) =>
                                                                            getDropdownButtonLabel(value, key)
                                                                        }
                                                                        value={
                                                                            selectedValues//elm.selectedValues
                                                                        }
                                                                        isDisabled={element.is_expired || false}
                                                                        onChange={(value, event) =>
                                                                            changeHandler(
                                                                                "financier_id_" + key,
                                                                                event,
                                                                                value,
                                                                                incrementedKey,
                                                                                appType,
                                                                                finacierOptionUpdated,
                                                                                tab
                                                                            )
                                                                        }
                                                                        className="react-select"
                                                                        classNamePrefix="react-select"
                                                                    />
                                                                </div>

                                                                {tempErrors && Object.keys(tempErrors).length && tempErrors["financier_id_" + tabIndexKey] ? (
                                                                    <span className="error show">
                                                                        {tempErrors["financier_id_" + tabIndexKey]}
                                                                    </span>
                                                                ) : (
                                                                    <span>&nbsp;</span>
                                                                )}
                                                            </fieldset>
                                                        </td>
                                                        <td>
                                                            <fieldset>
                                                                <div className="material">

                                                                    <InputField
                                                                        inputProps={{
                                                                            id: "senior",
                                                                            type: "text",
                                                                            name: "senior",
                                                                            placeholder: " ",
                                                                            value: elm.senior_amt || "", //getInputValues("senior_amt", key),
                                                                            className: "form-input",
                                                                            disabled: element.is_expired || false,
                                                                            percentage:(elm.is_count_amount && elm.is_count_amount===2)?1:0
                                                                        }}
                                                                        onChange={(e, i, q, u) =>
                                                                            inputChangeHandler("senior_amt_" + key, e, incrementedKey, appType, tab)
                                                                        }
                                                                    />
                                                                </div>

                                                                {
                                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["senior_amt_" + tabIndexKey] ? (
                                                                        <span className="error show">
                                                                            {tempErrors["senior_amt_" +tabIndexKey]}
                                                                        </span>
                                                                    ) : (
                                                                        <span>&nbsp;</span>
                                                                    )
                                                                }
                                                            </fieldset>
                                                        </td>
                                                        <td>
                                                            <fieldset>
                                                                <div className="material">

                                                                    <InputField
                                                                        inputProps={{
                                                                            id: "regular",
                                                                            type: "text",
                                                                            name: "regular",
                                                                            placeholder: " ",
                                                                            value: elm.regular_amt || "", //getInputValues("regular_amt", key),
                                                                            className: "form-input",
                                                                            disabled: element.is_expired || false,
                                                                            percentage:(elm.is_count_amount && elm.is_count_amount===2)?1:0
                                                                        }}
                                                                        onChange={(e, i, q, u) =>
                                                                            inputChangeHandler("regular_amt_" + key, e, incrementedKey, appType, tab)
                                                                        }
                                                                    />
                                                                </div> 
                                                                {
                                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["regular_amt_" + tabIndexKey] ? (
                                                                        <span className="error show">
                                                                            {tempErrors["regular_amt_" + tabIndexKey]}
                                                                        </span>
                                                                    ) : (
                                                                        <span>&nbsp;</span>
                                                                    )
                                                                }
                                                            </fieldset>
                                                        </td>
                                                        <td>
                                                            <fieldset>
                                                                <div className="material">

                                                                    <InputField
                                                                        inputProps={{
                                                                            id: "junior",
                                                                            type: "text",
                                                                            name: "junior",
                                                                            placeholder: " ",
                                                                            value: elm.junior_amt || "", //getInputValues("junior_amt", key),
                                                                            className: "form-input",
                                                                            disabled: element.is_expired || false,
                                                                            percentage:(elm.is_count_amount && elm.is_count_amount===2)?1:0
                                                                        }}
                                                                        onChange={(e, i, q, u) =>
                                                                            inputChangeHandler("junior_amt_" + key, e, incrementedKey, appType, tab)
                                                                        }
                                                                    />
                                                                </div> 
                                                                {
                                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["junior_amt_" + tabIndexKey] ? (
                                                                        <span className="error show">
                                                                            {tempErrors["junior_amt_" + tabIndexKey]}
                                                                        </span>
                                                                    ) : (
                                                                        <span>&nbsp;</span>
                                                                    )
                                                                }
                                                            </fieldset>
                                                        </td>

                                                    </tr>
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    {

                                        (allSelectedFinanciers[tab] && financerOption[tab] && financerOption[tab][appType] && allSelectedFinanciers[tab][appType] && allSelectedFinanciers[tab][appType].length === financerOption[tab][appType].length)
                                            ? "" :
                                            <div className="btn-save-remarks">
                                                <button
                                                    onClick={(e) => {
                                                        addNewFinancierConfig(appType, tab);
                                                    }}
                                                    type="button"
                                                    className="btn-primary"
                                                >{t("FORM.ADD_NEW")}</button>
                                                {tempErrors && Object.keys(tempErrors).length &&
                                                    tempErrors["selectAllFinanciers" +tab+ appType]? (
                                                    <span className="error show">
                                                        {tempErrors["selectAllFinanciers"+tab + appType]}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                    }

                                    <div>&nbsp;</div>
                                </div>)
                            }
                            )}
                        <div className="Configuration-filter"></div>
                    </div>
                    )
                })
            }
            {
                props.element && props.element.application_type && props.element.application_type.length && <div> <button type="button" className="btn-primary" onClick={() => addMore(dateArray)}>{t("FORM.ADD_MORE")}</button></div>
            }
        </React.Fragment>
    );
};
export default FinancerBoosterScheme;
