import React, { Component } from 'react';
import {
    AccordionItemPanel,
} from 'react-accessible-accordion';

import BasicFilter from "../cards/BasicDetailFilter"
import AddBannerButton from "../cards/AddBannerBtn"
import DescriptionCard from "../cards/DescriptionCard"
import BannerPreview from "../cards/BannerPreview"
import BasicDateFilter from '../cards/BasicDateFilter';
import AreaFilter from '../cards/AreaIncentiveFilter';
import AppsSubmitted from '../cards/AppsSubmittedCard';
import ExtraIncentiveCard from '../cards/ExtraIncentiveCard';
import AppsBoosterCard from '../cards/AppsBoosterCard';
import UserIncentiveTypeCard from '../cards/UserIncentiveTypeCard';
import AllowanceCard from '../cards/AllowanceCard'
import { withTranslation } from 'react-i18next';
import FinancerConfigurationFilter from "../cards/FinancerConfigurationFilter";
import FinancerBoosterScheme from "../cards/FinancerBoosterScheme";
import UserIncentiveTypeCard2 from '../cards/UserIncentiveTypeCard2';
import BMConfigurationCard from '../cards/BMConfigurationCard'
import IncentiveAmountCard from '../cards/IncentiveAmountCard'
import BoosterPerCard from '../cards/BoosterPerCard' 
import ExtraBoosterType2 from '../cards/ExtraBoosterType2'
import { AREA_SIZE } from "../../config/constant";
import DisbursalAmountFinancierCard from '../cards/DisbursalAmountFinancierCard'
import DisbursalRoleCard from '../cards/DisbursalRoleCard'
import IncentiveDisbursalCard from '../cards/IncentiveDisbursalCard'


class IncentiveAccordian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top3_per_area_per_month: {},           
            UsedAreaAm :[],
            incentive_list_top3_per_area: (props.record && props.record[0] && props.record[0]['form_config']['TOP3_PER_AREA_PER_MONTH']) ? props.record : [],
            description: ''
        };
    }

    setUsedAM = (usedam) => {
      this.setState({ UsedAreaAm:usedam});
    };

    handleTop3AreaChange = (name, value,MapIndex, element, element_index, parent_element, parent_element_index) => {
    
        let {UsedAreaAm } = this.state
        let selected = []
        //value = value && value.length > 0 && value[0].value == '0' ? AreaAM : value
        for (let i = 0; i < value.length; i++) {
            selected.push({...value[i],map_index:MapIndex})
        }
        let used_area_am = UsedAreaAm.filter(({ map_index }) => map_index != MapIndex)  // eslint-disable-line
         UsedAreaAm = [...used_area_am,...selected]
         const unique = UsedAreaAm.filter((obj, index) => {
            return index === UsedAreaAm.findIndex(o => obj.value === o.value);
        });
        
        this.setState({ UsedAreaAm:unique});

         let { incentive_list_top3_per_area } = this.state;
         for (let index = 0; index < incentive_list_top3_per_area.length; index++) {
            if(index == parent_element_index){ // eslint-disable-line
                let { top3_per_area_per_month } = incentive_list_top3_per_area[index]
                    for (let j = 0; j < top3_per_area_per_month.length; j++) {
                        if(j == MapIndex){  // eslint-disable-line
                            let used_area_am = UsedAreaAm.filter(({ map_index }) => map_index == MapIndex) // eslint-disable-line
                            let used_area_string = ''
                            if(used_area_am.length > 0){
                                for (let index = 0; index < used_area_am.length; index++) {
                                    let comma = used_area_string ? ',':'';
                                    used_area_string = used_area_string + comma + used_area_am[index].id;
                                }
                            }
                            top3_per_area_per_month[j].am_ids = used_area_string
                        }
                    }
            }
            
         }
         this.setState({ incentive_list_top3_per_area });
    };
    
    handleUpdate = (element) => {
        this.props.handleUpdate(element)
    }

    handleDeleteImage = (element) => {
        this.props.handleDeleteImage(element)
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {

        if(nextProps.element && nextProps.element.form_config && nextProps.element.form_config.TOP3_PER_AREA_PER_MONTH && nextProps.record && JSON.stringify(prevState.incentive_list_top3_per_area)  !== JSON.stringify(nextProps.record)){
            return ({ incentive_list_top3_per_area: nextProps.record })
        } 
        return null;
    }

    handleTop3IncentiveChange = (name, value, is_confirm, element, element_index, parent_element, parent_element_index) => {

        let { incentive_list_top3_per_area } = this.state;
        // let { incentive_list } = this.props;

        // let incentive_config = this.state.incentive_config || this.props.incentive_config
        let { form_config } = parent_element;
        if (form_config.TOP3_PER_AREA_PER_MONTH) {

            if (!Object.keys(incentive_list_top3_per_area).length && this.props.incentive_list && this.props.incentive_list[4]) {
                incentive_list_top3_per_area = this.props.incentive_list[4]
            }
            let element_child_index = name.charAt(name.length - 3);
            let element_agent_index = name.charAt(name.length - 1);
            let element_name = name.slice(0, -2);
            let element_child_name = name.slice(0, -4);

            // let area_data = incentive_config.area_list.filter(e => e.area === element.area_name)

            incentive_list_top3_per_area[parent_element_index].top3_per_area_per_month[element_index].area = element_index + 1;

            if (element_name === "area_name") {
                incentive_list_top3_per_area[parent_element_index].top3_per_area_per_month[element_index][element_name] = value.value;
                // area_data = incentive_config.area_list.filter(e => e.area === value.value)
                incentive_list_top3_per_area[parent_element_index].top3_per_area_per_month[element_index].area_id = element_index + 1;

            } else if (element_name === "incentive_amount") {
                incentive_list_top3_per_area[parent_element_index]['top3_per_area_per_month'][element_index].items[element_agent_index][
                    element_name
                ] = '' + value;
            } else if (
                element_child_name === "checked" ||
                element_child_name === "amount"
            ) {
                incentive_list_top3_per_area[parent_element_index].top3_per_area_per_month[element_index].items[
                    element_child_index
                ].agent_level_info[element_agent_index][element_child_name] = value;

                if (element_child_name === "checked") {
                    incentive_list_top3_per_area[parent_element_index].top3_per_area_per_month[element_index].items[
                        element_child_index
                    ].agent_level_info[element_agent_index].amount = 0

                }
            }

            for (let i = 0; i < incentive_list_top3_per_area.length; i++) {
                for (let j = 0; j < incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'].length; j++) {

                    incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['is_paltinum'] = incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['agent_level_info'][0]['checked'] ? 1 : 0
                    incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['paltinum_amount'] = incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['agent_level_info'][0]['amount']

                    incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['is_gold'] = incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['agent_level_info'][1]['checked'] ? 1 : 0
                    incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['gold_amount'] = incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['agent_level_info'][1]['amount']

                    incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['is_regular'] = incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['agent_level_info'][2]['checked'] ? 1 : 0
                    incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['regular_amount'] = incentive_list_top3_per_area[i].top3_per_area_per_month[0]['items'][j]['agent_level_info'][2]['amount']

                }
            }

            this.setState({ incentive_list_top3_per_area });
        }
    };

    render() {
        let { element, tempErrors, incentive_config, element_index, loading, addMoreIncentive,removeMoreIncentive } = this.props
        let { UsedAreaAm } = this.state

        let areaLimit = AREA_SIZE
        return (
            <React.Fragment>
                <AccordionItemPanel>
                    <h3 className="h3">{this.props.t('COMMON.BASIC_DETAIL')} </h3>
                    <div className={element.form_config.BASIC_DATE_FILTER ? 'basic-top-filter-outer' : ""}>
                        {Object.keys(incentive_config).length > 0 &&
                            <BasicFilter element={element} incentive_config={incentive_config} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} {...this.props} />
                        }

                        {element.form_config.BASIC_DATE_FILTER &&
                            <BasicDateFilter element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                        }
                        {/* {element.form_config.DATE_RANGE_FILTER ?
                            <BasicDateRangeFilter element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} /> : ""
                        } */}
                    </div>
                    <div className='left-right-panel-outer'>
                        <div className='add-incenative-left-panel'>
                            {element.form_config.APP_BOOSTER_CARD &&
                                <AppsBoosterCard element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} element_index={element_index} key={element_index}  addMoreIncentive = {addMoreIncentive}  removeMoreIncentive = {removeMoreIncentive}/>
                            }
                            {element.form_config.AREA_FILTER_TOP3 && Object.keys(incentive_config).length > 0 &&
                                <div className="area-detail-filter br-bottom" style={{ flexDirection: 'column' }}>
                                    {element.top3_per_area_per_month && element.top3_per_area_per_month.length > 0 && element.top3_per_area_per_month.map((map_element, map_index) => (
                                       
                                        <div style={{ display: 'flex' }} key={map_index}>

                                            <AreaFilter top3_per_area_per_month={element.top3_per_area_per_month} is_expired={element.is_expired} map_index={map_index} element_index={element_index} element={map_element} elm={element} incentive_config={incentive_config} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} handleTop3IncentiveChange={(e, i, q, u) => { this.handleTop3IncentiveChange(e, i, q, u, map_index, element, element_index) }} tempErrors={tempErrors} handleTop3AreaChange={(e, i, q, u) => { this.handleTop3AreaChange(e, i, q,u,map_index, element, element_index) }} dynamicAreaAM={this.props.AreaAM} UsedAreaAm={UsedAreaAm} setUsedAM={(e, i, q, u) => { this.setUsedAM(e, i, q,u,element,element_index) }}/>

                                            {/* <PlatinumGoldFiled top3_per_area_per_month={element.top3_per_area_per_month} is_expired={element.is_expired} element={map_element} elm={element} incentive_config={incentive_config} handleAddArea={(e) => { this.props.handleAddArea(element_index, element.scheme_type_id) }} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} handleTop3IncentiveChange={(e, i, q, u) => { this.handleTop3IncentiveChange(e, i, q, u, map_index, element, element_index) }} tempErrors={tempErrors} basicDetails={element} /> */}
                                        </div>
                                        
                                        
                                    ))}
                                </div>
                            }


                            {element.form_config.AREA_FILTER_TOP3 && element.top3_per_area_per_month && element.top3_per_area_per_month.length < areaLimit && this.props.AreaAM.length > UsedAreaAm.length &&
                                <div className='add-new-area-btn p-lg-b m-lg-b'>
                                    <button onClick={() => { this.props.handleAddArea(element_index, element.scheme_type_id) }} disabled={element.is_expired} className='btn-line p-lg-l p-lg-r'>{this.props.t('FORM.ADD_NEW_AREA')}</button>
                                    {
                                        (tempErrors && tempErrors["top3_per_area_per_month"]) ? <div><span className="error show">{(element.top3_per_area_per_month.length === 0) ? tempErrors["top3_per_area_per_month"] : ''}</span></div> : ''
                                    }
                                </div>
                            }
                            {element.form_config.AREA_FILTER_TOP2 && Object.keys(incentive_config).length > 0 &&
                                <div className="area-detail-filter m-lg-b p-lg-b">
                                    <AreaFilter element={element} elm={element} incentive_config={incentive_config} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} handleTop2IncentiveChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u, 0, element, element_index) }} handleTop3AreaChange={(e, i, q, u) => { this.handleTop3AreaChange(e, i, q,u,element_index, element, element_index) }} dynamicAreaAM={this.props.AreaAM} map_index={element_index} setUsedAM={(e, i, q, u) => { this.setUsedAM(e, i, q,u,element,element_index) }}/>

                                </div>
                            }
                            {element.form_config.APP_SUBMITTED_CARD &&
                                <AppsSubmitted incentive_config={incentive_config} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            }
                            {/* {element.form_config.REDEEMABLE_POINTS_CARD &&
                                <RedeemablePointsCard element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            } */}
                            {element.form_config.EXTRA_INCENTIVE_CARD &&
                                <ExtraIncentiveCard incentive_config={incentive_config} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            }
                            {element.form_config.USER_INCENTIVE_TYPE_CARD &&
                                <UserIncentiveTypeCard incentive_config={incentive_config} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            }
                            {element.form_config.USER_INCENTIVE_TYPE_CARD_2 &&
                                <UserIncentiveTypeCard2 incentive_config={incentive_config} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            }
                            {element.form_config.ALLOWANCE_CARD &&
                                <AllowanceCard incentive_config={incentive_config} element_index={element_index} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} removeMoreIncentive = {removeMoreIncentive} tempErrors={tempErrors} />
                            }
                            {element.form_config.BOOSTER_PER_CARD &&
                                <BoosterPerCard incentive_config={incentive_config} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            }
                            {element.form_config.INCENTIVE_AMOUNT_CARD &&
                                <IncentiveAmountCard incentive_config={incentive_config} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            }
                            {
                                (element.form_config.FINANCIER_CONFIGURATION_CARD && !element.form_config.DATE_RANGE_FILTER) &&
                                <div className='Configuration-filter'>
                                    <FinancerConfigurationFilter element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} addMoreIncentive = {addMoreIncentive} removeMoreIncentive = {removeMoreIncentive} element_index={element_index}/>
                                </div>
                            }
                            {
                                element.form_config.EXTRA_BOOSTER_TYPE2 && element.application_type &&
                                <div className='Configuration-filter'>
                                  {/*   <ExtraBoosterType2 element={element} tempErrors={tempErrors} element_index={element_index}/> */}
                                  <ExtraBoosterType2 element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} addMoreIncentive = {addMoreIncentive} removeMoreIncentive = {removeMoreIncentive} element_index={element_index}/>
                                </div>
                            }
                            {
                               !element.form_config.EXTRA_BOOSTER_TYPE2 && element.form_config.FINANCIER_CONFIGURATION_CARD && element.form_config.DATE_RANGE_FILTER &&
                                <div className='Configuration-filter'>
                                    <FinancerBoosterScheme element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} addMoreIncentive = {addMoreIncentive} removeMoreIncentive = {removeMoreIncentive} element_index={element_index}/>
                                </div>
                            }
                            {
                                element.form_config.BM_CONFIGURATION_CARD &&
                                <div className='Configuration-filter'>
                                    <BMConfigurationCard element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                                </div>
                            }
                            {
                                (element.form_config.SPECIAL_SCHEME_CARD) &&
                                <div className='Configuration-filter'>
                                    <DisbursalAmountFinancierCard element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors}  element_index={element_index} removeMoreIncentive = {removeMoreIncentive}/>
                                </div>
                            }
                            {
                                (element.form_config.DISBURSAL_ROLE) &&
                                <div className='Configuration-filter'>
                                    <DisbursalRoleCard element={element} tempErrors={tempErrors}  element_index={element_index} removeMoreIncentive = {removeMoreIncentive}/>
                                </div>
                            }

                            {element.form_config.INCENTIVE_DISBURSAL_CARD &&
                                <IncentiveDisbursalCard incentive_config={incentive_config} element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                            }

                            <AddBannerButton element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} onDelete={() => { this.handleDeleteImage(element) }} tempErrors={tempErrors} />
                            <DescriptionCard element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} onUpdate={() => { this.handleUpdate(element) }} onCancel={() => { this.props.handleCancel(element) }} loading={loading} />

                            <div className='incentive-login-msg'>
                                <i className='ic-info-icon1'></i>
                                {this.props.t('FORM.NOTES')}
                            </div>


                        </div>

                        <div className='add-incenative-right-panel'>
                            <BannerPreview element={element} onChange={(e, i, q, u) => { this.props.handleChange(e, i, q, u) }} tempErrors={tempErrors} />
                        </div>
                    </div>

                </AccordionItemPanel>
            </React.Fragment>
        )
    }
}
export default withTranslation('common')(IncentiveAccordian);