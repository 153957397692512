import React, { useEffect, useState } from "react"; 
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputField from "../common/InputField";
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";
import BasicDateRangeFilter from './BasicDateRangeFilter';

const ExtraBoosterType2 = (props) => {
    const [financerOption, setFinancerOption] = useState({}); 
    const commonRedux = useSelector((state) => state.common);
    const [selecteApplicationTypes, setSelecteApplicationTypes] = useState([]);
    const [allSelectedFinanciers, setAllSelectedFinanciers] = useState({});
    const [refresh, setRefresh] = useState(0);
    const { t } = useTranslation("common");

    useEffect(() => {
        if ( 
            commonRedux &&
            commonRedux.financierList &&
            commonRedux.financierList.length
        ) {
            let allFinanciers = {}; 
            commonRedux.financierList.forEach((ob)=>{
                allFinanciers[ob.application_type_name.toLowerCase()] = ob.financier;
            })
            setFinancerOption(allFinanciers); 
        }
    }, [commonRedux]); 

    useEffect(() => {
        if (
            props.element &&
            props.element.application_type &&
            props.element.application_type !== selecteApplicationTypes.join(",")
        ) {
            setSelecteApplicationTypes(props.element.application_type.split(","));
        }
        if (props.element && props.element.date_range && props.element.date_range.length) {
            let selectedFinanciers = {};
            //ALL SELECTED FINACIERS DATE & APP TYPE BASED
            for (let i = 0; i < props.element.date_range.length; i++) {
                let tempOb = {}
                selecteApplicationTypes.forEach((appType) => {
                    let record = props.element.financier_config.filter((item) => (+item.tab === i + 1 && item.application_type === appType.toLowerCase()));
                    let fIdArray = [];
                    for (let list = 0; list < record.length; list++) {
                        fIdArray = [...fIdArray, ...record[list].financier_id]
                    }
                    tempOb[appType.toLowerCase()] = fIdArray
                })
                selectedFinanciers[i + 1] = tempOb
            }
            setAllSelectedFinanciers(selectedFinanciers);
        }
    }, [props]); // eslint-disable-line
  
 
    const inputChangeHandler = (event,appType,tab,index) => {  
        let itemRecord =  JSON.parse(JSON.stringify(props.element.financier_config))
        let INDEX = '';
        for(let i=0;i<=index;i++){
            let checkIndex = itemRecord.findIndex((ob=>ob.application_type === appType && ob.tab ==tab))   // eslint-disable-line
            if(checkIndex>=0){
                INDEX = checkIndex;
                itemRecord[checkIndex]['application_type'] = 'NA'
            }
        } 
        props.element.financier_config[INDEX][event.target.name] = event.target.value;
        setRefresh(refresh + 1); 
    }
 
    const addMoreDisbursal = (appType, tab) => {
        let temp_object = {
            financier_id: [],
            junior_amt: "",
            regular_amt: "",
            senior_amt: "",
            fixed_amt: "",
            application_type: appType.toLowerCase(),
            min_disbursal: "",
            tab: tab
        };
        props.element.financier_config = [
            ...props.element.financier_config,
            temp_object
        ]
        setRefresh(refresh + 1); 
    };

    const removeDisbursal = (appType, tab,index) => {
        let itemRecord =  JSON.parse(JSON.stringify(props.element.financier_config))
        let INDEX = '';
        for(let i=0;i<=index;i++){
            let checkIndex = itemRecord.findIndex((ob=>ob.application_type ==appType && ob.tab ==tab))   // eslint-disable-line
            if(checkIndex>=0){
                INDEX = checkIndex;
                itemRecord[checkIndex]['application_type'] = 'NA'
            }
        } 
        let records = props.element.financier_config;
        records.splice(INDEX,1) 
        props.element.financier_config =  records;
        setRefresh(refresh + 1); 
    };

    const addMore = (countArray) => { 
        let tab = countArray.length + 1; 
        let tempOb = [] 
        for(let newConfig of props.element.financier_config){
            let ifExists = tempOb.length>0 ? tempOb.filter(op=> (op.tab==tab && op.application_type==newConfig.application_type) ) : []  // eslint-disable-line
            if(!ifExists.length){
                tempOb.push({
                    financier_id:[],
                    junior_amt:'',regular_amt:'',senior_amt:'',fixed_amt:'',
                    application_type:newConfig.application_type,
                    min_disbursal : "",tab
                })
            }
        } 
        let dateRangeArray = []
        for(let itemdt of props.element.date_range){
            let ifExists = dateRangeArray.length>0 ? dateRangeArray.filter(op=> (op.tab==tab && op.application_type==itemdt.application_type) ) : []  // eslint-disable-line
            if(!ifExists.length){
                dateRangeArray.push({
                    financier_id:[],
                    receive_from:'',receive_to:'',disb_from:'',disb_to:'',
                    application_type: itemdt.application_type,
                    tab
                })
            }
        } 
        props.element.date_range = [ ...props.element.date_range,...dateRangeArray] 
        props.element.financier_config = [ ...props.element.financier_config,...tempOb]
        setRefresh(refresh + 1); 
    }

    const datehanlder = (name, value,index)=>{ 

        let splitted_name = name.split("_");
        let inputFieldName = JSON.parse(JSON.stringify(splitted_name)); 
        inputFieldName.pop();
        let front_name = inputFieldName.join('_') 

        let obItem = {}
        if(front_name ==='received_date'){ 
            obItem = {    receive_from : value[0], receive_to : value[1] } 
        }
        if(front_name ==='disbursed_date'){ 
            obItem = {   disb_from : value[0], disb_to : value[1] }  
        } 
        props.element.date_range[index] =  {
            ...props.element.date_range[index],
            ...obItem
        }
        setRefresh(refresh + 1); 
    } 

    let { tempErrors, element } = props;
 
    let dateArray = [1]
    if (element.financier_config.length) {
        dateArray = [...new Set(element.financier_config.map(item => item.tab))];
    }    
    return (
        <React.Fragment>
            {element.application_type && (
                <span className="config-heading">
                    {t("COMMON.APP_DISBURSED")}
                </span>
            )}
            {
                element.date_range.length && element.date_range.map((dateOb, k) => {

                    let tab = (dateOb.tab) ? dateOb.tab : 1   
                    let appType = (dateOb.application_type) ? dateOb.application_type : '';  
                    let disbursalArray = element.financier_config.filter(ob => ob.application_type == appType && ob.tab ==tab)  // eslint-disable-line
                    let errorList = (tempErrors && tempErrors.date_range && tempErrors.date_range[k])? tempErrors.date_range[k] : {} 
                    let financerList = financerOption[appType]
                    return (<div key={k}>
                        {(element.application_type && selecteApplicationTypes) ?
                            <React.Fragment>
                                <h3>{appType.toUpperCase()} Financier*</h3>
                                <BasicDateRangeFilter element={element} onChange={(e, i) => datehanlder(e, i,k)} tempErrors={errorList} tab={tab} appType={appType} allSelectedFinanciers={allSelectedFinanciers} financerOption={financerList} fieldItem={dateOb}/>

                                <div className="et2-config-filed-outer" key={k}>
                                    <table>
                                        <thead>
                                            <tr key={k}>
                                                <th>{t("FORM.MIN_DISBURSAL") + '*'}</th>
                                                <th>{t("FORM.SENIOR") + '*'}</th>
                                                <th>{t("FORM.REGULAR") + '*'}</th>
                                                <th>{t("FORM.JUNIOR") + '*'}</th>
                                                <th> </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {disbursalArray.length ? disbursalArray.map((elm, key) => { 
                                                let incrementedKey = key;
                                                let tabIndexKey = appType+tab+'_'+incrementedKey;
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            <fieldset>
                                                                <div className="material">

                                                                    <InputField
                                                                        inputProps={{ 
                                                                            type: "text",
                                                                            name: "min_disbursal",
                                                                            placeholder: " ",
                                                                            value: elm.min_disbursal,
                                                                            className: "form-input",
                                                                            disabled: element.is_expired || false,
                                                                        }}
                                                                        onChange={(e) =>
                                                                            inputChangeHandler(e,appType,tab,key)
                                                                        }
                                                                    />
                                                                </div>

                                                                {
                                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["min_disbursal_" + tabIndexKey] ? (
                                                                        <span className="error show">
                                                                            {tempErrors["min_disbursal_" + tabIndexKey]}
                                                                        </span>
                                                                    ) : (
                                                                        <span>&nbsp;</span>
                                                                    )
                                                                }
                                                            </fieldset>
                                                        </td>
                                                        <td>
                                                            <fieldset>
                                                                <div className="material">

                                                                    <InputField
                                                                        inputProps={{ 
                                                                            type: "text",
                                                                            name: "senior_amt",
                                                                            placeholder: " ",
                                                                            value: elm.senior_amt,
                                                                            className: "form-input",
                                                                            disabled: element.is_expired || false,
                                                                        }}
                                                                        onChange={(e) =>
                                                                            inputChangeHandler(e,appType,tab,key)
                                                                        }
                                                                    />
                                                                </div>

                                                                {
                                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["senior_amt_" + tabIndexKey] ? (
                                                                        <span className="error show">
                                                                            {tempErrors["senior_amt_" + tabIndexKey]}
                                                                        </span>
                                                                    ) : (
                                                                        <span>&nbsp;</span>
                                                                    )
                                                                }
                                                            </fieldset>
                                                        </td>
                                                        <td>
                                                            <fieldset>
                                                                <div className="material">

                                                                    <InputField
                                                                        inputProps={{ 
                                                                            type: "text",
                                                                            name: "regular_amt",
                                                                            placeholder: " ",
                                                                            value: elm.regular_amt,
                                                                            className: "form-input",
                                                                            disabled: element.is_expired || false,
                                                                        }}
                                                                        onChange={(e) => 
                                                                            inputChangeHandler(e,appType,tab,key)
                                                                        }
                                                                    />
                                                                </div>
                                                                {
                                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["regular_amt_" + tabIndexKey] ? (
                                                                        <span className="error show">
                                                                            {tempErrors["regular_amt_" + tabIndexKey]}
                                                                        </span>
                                                                    ) : (
                                                                        <span>&nbsp;</span>
                                                                    )
                                                                }
                                                            </fieldset>
                                                        </td>
                                                        <td>
                                                            <fieldset>
                                                                <div className="material">

                                                                    <InputField
                                                                        inputProps={{ 
                                                                            type: "text",
                                                                            name: "junior_amt",
                                                                            placeholder: " ",
                                                                            value: elm.junior_amt,
                                                                            className: "form-input",
                                                                            disabled: element.is_expired || false,
                                                                        }}
                                                                        onChange={(e) =>
                                                                            inputChangeHandler(e,appType,tab,key)
                                                                        }
                                                                    />
                                                                </div>
                                                                {
                                                                    tempErrors && Object.keys(tempErrors).length && tempErrors["junior_amt_" + tabIndexKey] ? (
                                                                        <span className="error show">
                                                                            {tempErrors["junior_amt_" + tabIndexKey]}
                                                                        </span>
                                                                    ) : (
                                                                        <span>&nbsp;</span>
                                                                    )
                                                                }
                                                            </fieldset>
                                                        </td>
                                                        <td>
                                                            {
                                                                +key === 0 ? 
                                                                <span className="add-minus" onClick={() => {
                                                                    addMoreDisbursal(appType, tab);
                                                                }}><BiPlusCircle size="1.4em" /> </span>
                                                                :
                                                                <span className="add-minus" onClick={()=>removeDisbursal(appType,tab,key)}> <BiMinusCircle size="1.4em"/></span>

                                                            }  
                                                        </td>

                                                    </tr>
                                                );
                                            })
                                            :
                                            <tr>
                                                <td></td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>

                                    <div>&nbsp;</div>
                                </div>
                            </React.Fragment>
                            : ""
                        }
                       
                        <div className="Configuration-filter"></div>
                    </div>
                    )
                })
            }
            {
                props.element && props.element.application_type && props.element.application_type.length && <div> <button type="button" className="btn-primary" onClick={() => addMore(dateArray)}>{t("FORM.ADD_MORE")}</button></div>
            }
        </React.Fragment>
    );
};
export default ExtraBoosterType2;
