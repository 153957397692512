import React, { useState } from "react"; 
import "react-datepicker/dist/react-datepicker.css"; 
import { useTranslation } from 'react-i18next';
import ConfirmationModal from "../common/ConfirmationModal";
import Modal from "../common/Modal";
import FullPageLoader from "../common/FullPageLoader";
import { IncentiveService } from "../../services";
import { useSelector } from "react-redux"; 
import secureStorage from '../../config/encrypt';

const CalculateButton = (props) => {
    const { t } = useTranslation('common'); 
    const [confimationPopUp, setConfirmationPopup] = useState({ status: false, msg: '' });
    const [popUp, setPopUp] = useState(false); 
    const [loading, setLoading] = useState(false);
    const [csvPath,setCsvPath] = useState(null) 
    const dealerConfig = useSelector((state) => state?.common?.dealerConfigDetail);
  
    const authUserInfo = secureStorage.getItem('authUserInfo') || {}; 
    const showModalConfimationPopUp = (params) => {
        let { status } = params
        setConfirmationPopup(params) 
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }  
    }

    const confirmationModalResponse = (params) => {     
        if (params.response) { 
           setPopUp(true) 
           getCsvLink();
        } 
        showModalConfimationPopUp(params)
    } 


    const getCsvLink = () => {
        setLoading(true);
        let userId = authUserInfo?.user_data?.id || 1
        IncentiveService.getDealerCaculation({user_id:userId})
        .then(function (response) {
            setLoading(false); 
            if (response.data.status === 200 && response.data !='ALREADY_HIT') { 
                setCsvPath(response.data.data) 
            } 
        })
        .catch(function (response) { });
    }

    const hideModalPopUp = () => {
        setPopUp(false)
        document.body.classList.remove("overflow-hidden");
    } 
    const date = new Date();
    date.setMonth(date.getMonth() - 1); 
    const monthValue = " "+date?.toLocaleString('en-US', {month: 'short', year: 'numeric'})+' ?'; 
    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <button className="btn-primary" onClick={() => showModalConfimationPopUp({ status: true, approve: true, msg: t("DEALER.CALCULATE_MESSAGE")+monthValue})} disabled={!dealerConfig?.enableCalculate}>{t('DEALER.CALCULATE_INCENTIVE')}</button> 

            <ConfirmationModal confirmationData={{ show: confimationPopUp.status, confirmationText: confimationPopUp.msg }} confirmationModalResponse={confirmationModalResponse} />

            <div className="model-popup-outer dealer-upload-popup">
                <Modal show={popUp} handleClose={()=>hideModalPopUp()}>
                    <div className="modal-header">
                    <h2><center>{t('DEALER.CAL_PROGRESS')}</center></h2>
                    </div>
                    <div className="modal-body">
                        <div className='bulk-upload-history-content'>
                            <div className='bulk-upload-sec'> 
                            {t('DEALER.CAL_PROGRESS_MSJ')} 
                            </div> 
                        </div>
                        <div>
                        <a href={csvPath} target="_blank"> <button className="btn-primary download-report" disabled={csvPath ? false : true}>{t('DEALER.DOWNLOAD_REPORT')}</button> </a> 
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};
export default CalculateButton;
