
const validUpdateForm = (data, otherIncentiveList, props) => {
    let formIsValid = true;
    let tempErrors = {};
    
    if (data.form_config.FINANCIER_CONFIGURATION_CARD && data.user_incentive_type_2) {
        if(data.form_config.MIN_POINTS && +data.form_config.MIN_POINTS===1){ 
            data.user_incentive_type_2.forEach((element, index) => {
                if(element.disb_minimum_count === "") {
                    if(data.applicable_to.includes('fixed') && index == 3 || index < 3){
                        formIsValid = false;
                        tempErrors["disb_minimum_count_" + index] = props.t('VALIDATION.MIN_POINTS');
                    } 
                }
            });
        }else{
            data.user_incentive_type_2.forEach((element, index) => {
            if (element.disb_minimum_count === "" && element.total_disb_amt === '') {
                formIsValid = false;
    
                tempErrors["disb_minimum_count_" + index] = props.t('VALIDATION.DISBURSAL');
                tempErrors["total_disb_amt_" + index] = props.t('VALIDATION.DISBURSAL_AMOUNT');
                }
            });
        }
    }
    if (!data["product_type"]) {
        formIsValid = false;
        tempErrors["product_type"] = props.t('VALIDATION.PRODUCT_TYPE');
    }
    if(data["file"] || data.image){
        if (!data["banner_name"]) {
            formIsValid = false;
            tempErrors["banner_name"] = props.t('VALIDATION.BANNER_NAME');
        }
    }

    if (!data["scheme_duration"]) {
        formIsValid = false;
        tempErrors["scheme_duration"] = props.t('VALIDATION.SCHEME_DURATION');
    }
    
    if(data["scheme_duration"] && data["product_type"] && otherIncentiveList && otherIncentiveList.length > 0 ){
        let duplicateSchemeProduct = otherIncentiveList.some((list) => {
            let listAppTypeArray = list.application_type ? list.application_type.split(",") : [];
            let dataAppTypeArray = data["application_type"] ? data["application_type"].split(",") : [];
            let matchCount = (dataAppTypeArray.length === listAppTypeArray.length) ? listAppTypeArray.filter((val) => {
                return dataAppTypeArray.includes(val);
            }).length : 0;
           
            if(data.form_config.USER_ROLE && data["user_role"]){
                return ((list.scheme_duration === data["scheme_duration"]) && (list.product_type === data["product_type"]) && (list.user_role === data["user_role"]) && matchCount === listAppTypeArray.length);
            } else {
                return ((list.scheme_duration === data["scheme_duration"]) && (list.product_type === data["product_type"]) && matchCount === listAppTypeArray.length);
            }
        });
        if(duplicateSchemeProduct){
            formIsValid = false;
            tempErrors["scheme_duration"] = props.t('VALIDATION.SCHEME_ALREADY_EXISTS');
            tempErrors["product_type"] = props.t('VALIDATION.SCHEME_ALREADY_EXISTS');
            tempErrors["application_type"] = data["application_type"] ? props.t('VALIDATION.SCHEME_ALREADY_EXISTS') : '';
        }
    }

    if (data.form_config.APPLICATION_TO) {
        if (!data["applicable_to"]) {
            formIsValid = false;
            tempErrors["applicable_to"] = props.t('VALIDATION.APPLICATION_TO');
        }
    }

    if (data.form_config.APPLICATION_TYPE) {
        if (!data["application_type"]) {
            formIsValid = false;
            tempErrors["application_type"] = props.t('VALIDATION.APPLICATION_TYPE');
        }
    }


    if (data.form_config.USER_ROLE) {
        if (!data["user_role"]) {
            formIsValid = false;
            tempErrors["user_role"] = props.t('VALIDATION.USER_ROLE');
        }
    }

    if (data.form_config.BOOSTER_PER_CARD) {
        if (!data["booster_per"]) {
            formIsValid = false;
            tempErrors["booster_per"] = props.t('VALIDATION.BOOSTER');
        }else if(data["booster_per"] ==='0'){
            formIsValid = false;
            tempErrors["booster_per"] = props.t('VALIDATION.BOOSTER_ZERO');
        }
    }

    if (data.form_config.INCENTIVE_AMOUNT_CARD) {
        if (!data["incentive_amount"]) {
            formIsValid = false;
            tempErrors["incentive_amount"] = props.t('VALIDATION.INCENTIVE_AMOUNT');
        }
        if (!data["mid_month"]) {
            formIsValid = false;
            tempErrors["mid_month"] = props.t('VALIDATION.MID_MONTH');
        }
    } 

    if (data.form_config.INCENTIVE_DISBURSAL_CARD) {
        if (data["incentive_amount"] === "") {
            formIsValid = false;
            tempErrors["incentive_amount"] = props.t('VALIDATION.INCENTIVE_AMOUNT');
        }
        if (data["min_disbursal"] === "") {
            formIsValid = false;
            tempErrors["min_disbursal"] = props.t('VALIDATION.MIN_DISBURSAL');
        }
    } 

    if (data.form_config.APP_BOOSTER_CARD) {
        data.extra_booster.forEach((element, index) => {
            if (element.from_value === "") {
                formIsValid = false;
                tempErrors["from_value_" + index] = props.t('VALIDATION.FROM');
            }
            if (element.to_value === "") {
                formIsValid = false;
                tempErrors["to_value_" + index] = props.t('VALIDATION.TO');
            }
            if (element.app_disbursed_range === "") {
                let message  = props.t('VALIDATION.APP_DISBURSED_RANGE');
                if(data.form_config.REDEEMBALE_POINTS){
                    message = props.t('VALIDATION.TOTAL_VALUE');
                }
                if(data.form_config.ROOSTER){
                    message = props.t('VALIDATION.INCENTIVE_PER');
                }
                formIsValid = false;
                tempErrors["app_disbursed_range_" + index] = message;
            }
        });
    }

    if (data.form_config.BASIC_DATE_FILTER) {
        if (!data.disbursed_date) {
            formIsValid = false;
            tempErrors["disbursed_date"] = props.t('VALIDATION.DISBURSED_DATE');
        }
        if (!data.received_date) {
            formIsValid = false;
            tempErrors["received_date"] = props.t('VALIDATION.RECEIVED_DATE');
        }
    }
    if (data.form_config.DATE_RANGE_FILTER && data.date_range) { 
        let tempErrObArray = [];  
        data.date_range.forEach((element)=>{
            let tempErrOb = {
                received_date:'',
                disbursed_date:''
            }; 
            if (!element.disb_from  || !element.disb_to) {
                formIsValid = false;
                tempErrOb["disbursed_date"] = props.t('VALIDATION.DISBURSED_DATE'); 
            }   
            if(data.form_config.EXTRA_BOOSTER_TYPE2){
                tempErrOb.financier_id = ''
                if (!element.financier_id.length) {
                    formIsValid = false;
                    tempErrOb["financier_id"] = props.t('VALIDATION.FINANCIER'); 
                }  
            }
            tempErrObArray.push(tempErrOb);
        })  
        tempErrors['date_range'] = tempErrObArray
    } 

    if (data.form_config.TOP3_PER_AREA_PER_MONTH) {
        if (data.top3_per_area_per_month.length) {
            data.top3_per_area_per_month.forEach((element, index) => {
                if (!element.area_name) {
                    formIsValid = false;
                    tempErrors["area_name_" + index] = props.t('VALIDATION.AREA_NAME');
                }
                if (!element.hasOwnProperty('am_ids')|| element.am_ids === '') {
                    formIsValid = false;
                    tempErrors["area_name_" + index] = props.t('VALIDATION.AM');
                }
                element.items.forEach((element_1, index_1) => {
                    if (!element_1.incentive_amount) {
                        formIsValid = false;
                        tempErrors["incentive_amount_" + index_1] = props.t('VALIDATION.INCENTIVE_AMOUNT');
                    }
                })
            });
        } else {
            formIsValid = false;
            tempErrors["top3_per_area_per_month"] = props.t('VALIDATION.NEW_AREA');
        }
    }

    if (data.form_config.PER_QUARTER) {
        data.per_quarter.forEach((element, index) => {
            if (element.incentive_amount=== "") {
                formIsValid = false;
                tempErrors["incentive_amount_" + index] = props.t('VALIDATION.INCENTIVE_AMOUNT');
            }
        });
    }

    if (data.form_config.APP_SUBMITTED_CARD) {
        data.app_submitted.filter(ob=> +ob.agent_level_id !== 3).forEach((element, index) => {
            if (element.incentive_amount === "") {
                formIsValid = false;
                tempErrors["incentive_amount_" + index] = props.t('VALIDATION.INCENTIVE_AMOUNT');
            }
        });
    }

    if (data.form_config.REDEEMABLE_POINTS_CARD) {
        data.redeemable_points.forEach((element, index) => {
            if (!element.redeemable_points || element.redeemable_points=== "") {
                formIsValid = false;
                tempErrors["redeemable_points_" + index] = props.t('VALIDATION.REEDEEMABLE_POINTS');
            }
            if (!element.total_value || element.total_value === "") {
                formIsValid = false;
                tempErrors["total_value_" + index] = props.t('VALIDATION.TOTAL_VALUE');
            }
        });
    }

    if (data.form_config.ADD_FINANCIER && data.financier_config) {
        data.financier_config.forEach((element, index) => {
            if (!element.financier_id || element.financier_id === "") {
                formIsValid = false;
                tempErrors["financier_" + index] = props.t('VALIDATION.FINANCIER');
            }
        });
    }
    if (data.form_config.ELIGIBLE_FINANCIER && data.elg_financier) {
        data.elg_financier.forEach((element, index) => {
            if (!element.financier_id || element.financier_id === "") {
                formIsValid = false;
                tempErrors["financier_" + index] = props.t('VALIDATION.FINANCIER');
            }
        });
    }
   
    if (data.form_config.EXTRA_INCENTIVE_CARD) {
        data.extra_incentive.forEach((element, index) => {
            if (element.add_disbursed_fee=== "") {
                formIsValid = false;
                tempErrors["add_disbursed_fee_" + index] = props.t('VALIDATION.ADDITIONAL_DISBURSED_FEE');
            }
            if (element.min_disbursed_fee=== "") {
                formIsValid = false;
                tempErrors["min_disbursed_fee_" + index] = props.t('VALIDATION.MIN_DISBURSED_FEE');
            }
            if (element.add_incentive=== "") {
                formIsValid = false;
                tempErrors["add_incentive_" + index] = props.t('VALIDATION.ADDITIONAL_INCENTIVE');
            }
        });
    }
    if (data.form_config.EXTRA_BOOSTER_TYPE2) {  

        data.date_range && data.date_range.length && data.date_range.forEach((dateOb, k) => {
            let tab = (dateOb.tab) ? dateOb.tab : 1   
            let appType = (dateOb.application_type) ? dateOb.application_type : '';  
            let disbursalArray = data.financier_config.filter(ob => ob.application_type === appType && +ob.tab === tab) 

            disbursalArray.forEach((element, index) => {
                let type = element.application_type+element.tab+'_'+index
                if (element.min_disbursal === "") {
                    formIsValid = false;
                    tempErrors["min_disbursal_" + type] = props.t('VALIDATION.MIN_DISBURSAL');
                }else  if (+element.min_disbursal === 0) {
                    formIsValid = false;
                    tempErrors["min_disbursal_" + type] = props.t('VALIDATION.MIN_DISBURSAL_ZERO');
                }  
                if (element.junior_amt === "") {
                    formIsValid = false;
                    tempErrors["junior_amt_" + type] = props.t('VALIDATION.JUNIOR_REQUIRED');
                }
                if (element.senior_amt === "") {
                    formIsValid = false;
                    tempErrors["senior_amt_" + type] = props.t('VALIDATION.SENIOR_REQUIRED');
                }
                if (element.regular_amt === "") {
                    formIsValid = false;
                    tempErrors["regular_amt_" + type] = props.t('VALIDATION.REGULAR_REQUIRED');
                }  
                if (data.applicable_to.includes('fixed') &&  (element.fixed_amt === "" || element.fixed_amt == null) ) { 
                    formIsValid = false;
                    tempErrors["fixed_amt_" + type] = props.t('VALIDATION.FIXED_REQUIRED');
                } 
            }); 
        }) 
    }
    if (data.form_config.FINANCIER_CONFIGURATION_CARD && data.form_config.DATE_RANGE_FILTER) { 
        let tabCount = data.date_range ? data.date_range.length : 0;
        for(let i=1;i<=tabCount;i++){
            let getAllFinanciers = {}
            let record =  data.financier_config.filter(ob=>ob.tab ===i);
            for(let index=0; index< record.length ;index++) {
         
                getAllFinanciers[record[index].application_type] = !getAllFinanciers[record[index].application_type] ? record[index].financier_id.length : getAllFinanciers[record[index].application_type] + record[index].financier_id.length 
                if (!record[index].financier_id.length) {
                    formIsValid = false;
                    tempErrors["financier_id_" + i+"_"+index] = props.t('VALIDATION.FINANCERS_REQUIRED');
                }
                if (record[index].junior_amt === "") {
                    
                   if( !data.form_config.USER_INCENTIVE_TYPE_CARD_2_REGULAR ){
                    formIsValid = false;
                    tempErrors["junior_amt_" + i+"_"+index] = props.t('VALIDATION.JUNIOR_REQUIRED');
                   } 
                }
                if (record[index].senior_amt === "") {
                    
                    if( !data.form_config.USER_INCENTIVE_TYPE_CARD_2_REGULAR){
                        formIsValid = false;
                    tempErrors["senior_amt_" + i+"_"+index] = props.t('VALIDATION.SENIOR_REQUIRED');
                       } 
                }
                if (record[index].regular_amt === "") {
                    formIsValid = false;
                    tempErrors["regular_amt_" + i+"_"+index] = props.t('VALIDATION.REGULAR_REQUIRED');
                } 
                if (data.applicable_to.includes('fixed') &&  (record[index] === "" || record[index] == null) ) { 
                    formIsValid = false;
                    tempErrors["fixed_amt_" + i+"_"+index] = props.t('VALIDATION.FIXED_REQUIRED');
                } 
            };  
            if (data.application_type) { 
                if (props.financierList && props.financierList.length) {
                    for (let type of props.financierList) {
                        let typeName = type.application_type_name.toLowerCase();

                        if ((data.application_type.split(',')).includes(type.application_type_name) && type.financier.length !== getAllFinanciers[typeName]) {
                            formIsValid = false; 
                            tempErrors["selectAllFinanciers" +i+ typeName] = props.t('VALIDATION.SELECT_ALL_FINANCIERS');
                        }  
                    }
                }
            }
        }
         
    }else  if (data.form_config.FINANCIER_CONFIGURATION_CARD) {
        let getAllFinanciers = {}
        
        data.financier_config.forEach((element, index) => { 
            if(element.status !== 0 || element.status ===1){
                getAllFinanciers[element.application_type] = !getAllFinanciers[element.application_type] ? element.financier_id.length : getAllFinanciers[element.application_type] + element.financier_id.length
                if (!element.financier_id.length) {
                    formIsValid = false;
                    tempErrors["financier_id_" + index] = props.t('VALIDATION.FINANCERS_REQUIRED');
                }
                if (element.junior_amt=== "") {
                    
                if( !data.form_config.USER_INCENTIVE_TYPE_CARD_2_REGULAR ){
                    formIsValid = false;
                    tempErrors["junior_amt_" + index] = props.t('VALIDATION.JUNIOR_REQUIRED');
                }
                }
                if (element.senior_amt=== "") {
                    
                    if( !data.form_config.USER_INCENTIVE_TYPE_CARD_2_REGULAR){
                        formIsValid = false;
                    tempErrors["senior_amt_" + index] = props.t('VALIDATION.SENIOR_REQUIRED');
                    }                  
                }
                if (element.regular_amt=== "") {
                    formIsValid = false;
                    tempErrors["regular_amt_" + index] = props.t('VALIDATION.REGULAR_REQUIRED');
                } 
                if (data.applicable_to.includes('fixed') &&  (element.fixed_amt === "" || element.fixed_amt == null) ) {
                    formIsValid = false;
                    tempErrors["fixed_amt_" + index] = props.t('VALIDATION.FIXED_REQUIRED');
                }
            }
        });

        if (data.application_type) {
            if (props.financierList && props.financierList.length) {
                for (let type of props.financierList) {
                    let typeName = type.application_type_name.toLowerCase();

                    if ((data.application_type.split(',')).includes(type.application_type_name) && type.financier.length !== getAllFinanciers[typeName]) {
                        formIsValid = false;
                        tempErrors["selectAllFinanciers" + typeName] = props.t('VALIDATION.SELECT_ALL_FINANCIERS');
                    }

                }
            }
        }
    }

    if (data.form_config.USER_INCENTIVE_TYPE_CARD) {
        data.user_incentive_type && data.user_incentive_type.forEach((element, index) => {
            if (element.disb_minimum_count=== "") {
                formIsValid = false;
                tempErrors["disb_minimum_count_" + index] = props.t('VALIDATION.MIN_DISBURSAL');
            }
            if (element.disb_per=== "") {
                formIsValid = false;
                tempErrors["disb_per_" + index] = props.t('VALIDATION.PER_DISB');
            }
            if (element.incentive_amount=== "") {
                formIsValid = false;
                tempErrors["incentive_amount_" + index] = props.t('VALIDATION.INCENTIVE');
            }
        });
    }

    if (data.form_config.ALLOWANCE_CARD) {
        const duplicates = {};
        data.user_allowances.forEach((element, index) => {
            let unique_id=element.user_level+"_"+element.disb_minimum_count+"_"+element.allowance+"_"+element.operator;
            if (element.allowance=== "") {
                formIsValid = false;
                tempErrors["allowance_" + index] = (data.form_config.ARO_EXTRA_INCENTIVE) ? props.t('VALIDATION.INCENTIVE_AMOUNT'): props.t('VALIDATION.ALLOWANCE');
            }
            if (element.disb_minimum_count=== "") {
                formIsValid = false;
                tempErrors["disb_minimum_count_" + index] = (data.form_config.ARO_EXTRA_INCENTIVE) ? props.t('VALIDATION.MIN_POINTS'): props.t('VALIDATION.MIN_DISBURSAL');
            }
            if(data.form_config.OPERATOR && element.operator===""){
                formIsValid = false;
                tempErrors["operator_" + index] = props.t('VALIDATION.OPERATOR');
            }
            if (duplicates[unique_id] === undefined) {
                duplicates[unique_id] = [index];
              } else {
                duplicates[unique_id].push(index);
              }
        });

        if(data.form_config.OPERATOR && data.form_config.ADD_MORE_LEVEL && formIsValid){
            const duplicateEntries = Object.entries(duplicates).filter(([key, positions]) => positions.length > 1);
            const duplicatePositions = duplicateEntries.map(([key, positions]) => ({ element: key, positions }));
            if(duplicatePositions.length>0){
                if(duplicatePositions.length>0){
                    duplicatePositions.forEach((element, index) => {
                        element.positions.forEach((el, index) => {  
                            formIsValid = false;
                            tempErrors["allowance_" + el] =props.t('VALIDATION.USER_ALLOWANCE_SAME');
                            tempErrors["disb_minimum_count_" + el] = props.t('VALIDATION.USER_ALLOWANCE_SAME');
                            tempErrors["operator_" + el] = props.t('VALIDATION.USER_ALLOWANCE_SAME');
                        }) 
                    })
                }
            }
        }
    }
    

    if (data.form_config.BM_CONFIGURATION_CARD) {
        let get_all_bm=[];
        data.bm_config && data.bm_config.forEach((element, index) => {

            if (!element.bm_id.length) {
                formIsValid = false;
                tempErrors["bm_id_" + index] = props.t('VALIDATION.BM_REQUIRED');
            }else{
                element.bm_id.forEach((element1)=>{get_all_bm.push(element1)});
            }
            if (element.target_amt=== "") {
                formIsValid = false;
                tempErrors["target_amt_" + index] = props.t('VALIDATION.TARGET_AMT_REQUIRED');
            }
        });
        // if(get_all_bm){
        //     if(get_all_bm.length!==props.bmUserList.length){
        //         formIsValid = false;
        //         tempErrors["selectAllBM"] = props.t('VALIDATION.SELECT_ALL_BM');
        //     }
        // }
    }

    if (data.form_config.SPECIAL_SCHEME_CARD) {
        let getAllFinanciers = {}
        data.financier_config.forEach((element, index) => { 
            if(element.status !== 0 || element.status ===1){
              
                getAllFinanciers[element.application_type] = !getAllFinanciers[element.application_type] ? element.financier_id.length : getAllFinanciers[element.application_type] + element.financier_id.length

                if (!element.financier_id.length) {
                    formIsValid = false;
                    tempErrors["financier_id_" + index] = props.t('VALIDATION.FINANCERS_REQUIRED');
                }
                if (element.min_disbursal_amt=== "") {
                    formIsValid = false;
                    tempErrors["min_disbursal_amt_" + index] = props.t('VALIDATION.MIN_DISBURSAL');
                }else{
                    if(parseFloat(element.min_disbursal_amt) <= 0){
                        formIsValid = false;
                        tempErrors["min_disbursal_amt_" + index] = props.t('VALIDATION.MIN_DISBURSAL_GREATER');
                    }
                }
                if (element.incentive_per=== "") {
                    formIsValid = false;
                    tempErrors["incentive_per_" + index] = props.t('VALIDATION.INCENTIVE_PER');
                }else{
                    if(parseFloat(element.incentive_per) > 100){
                        formIsValid = false;
                        tempErrors["incentive_per_" + index] = props.t('VALIDATION.INCENTIVE_GREATER_LESS');
                    }else if(parseFloat(element.incentive_per) <= 0){
                        formIsValid = false;
                        tempErrors["incentive_per_" + index] = props.t('VALIDATION.INCENTIVE_GREATER_LESS');
                    }
                }
            }
        })
        if (data.application_type && formIsValid) {
            if (props.financierList && props.financierList.length) {
                for (let type of props.financierList) {
                    let typeName = type.application_type_name.toLowerCase();

                    if ((data.application_type.split(',')).includes(type.application_type_name) && type.financier.length !== getAllFinanciers[typeName]) {
                        //formIsValid = false;
                      //  tempErrors["selectAllFinanciers" + typeName] = props.t('VALIDATION.SELECT_ALL_FINANCIERS')+'kkk';
                    }

                }
            }
        }
    }
    if (data.form_config.DISBURSAL_ROLE && data.disbursal_role) {
        let counts = {};
        let isDuplicate = false; 
        data.disbursal_role.forEach((el , index) => { 
            if (el.min_disbursal === "") {
                formIsValid = false;
                tempErrors["min_disbursal_" + index] = props.t('VALIDATION.MIN_DISBURSAL');
            } 
            if (el.junior_amt === "") {
                formIsValid = false;
                tempErrors["junior_amt_" + index] = props.t('VALIDATION.JUNIOR_REQUIRED');
            }
            if (el.senior_amt === "") {
                formIsValid = false;
                tempErrors["senior_amt_" + index] = props.t('VALIDATION.SENIOR_REQUIRED');
            }
            if (el.regular_amt === "") {
                formIsValid = false;
                tempErrors["regular_amt_" + index] = props.t('VALIDATION.REGULAR_REQUIRED');
            } 
            if (data.applicable_to.includes('fixed') &&  (el.fixed_amt === "" || el.fixed_amt == null) ) {
                formIsValid = false;
                tempErrors["fixed_amt_" + index] = props.t('VALIDATION.FIXED_REQUIRED');
            } 

            if(formIsValid){
                const value = el.min_disbursal;
                // If the value is already in the counts object, it's a duplicate
                if (counts[value]) {
                    isDuplicate = true;
                    tempErrors["min_disbursal_" + index] = props.t('VALIDATION.DUPLICATE_MIN_DISBURSAL');
                } else {
                    counts[value] = true;
                }
            }
        }) 
        if(isDuplicate){
            formIsValid = false;
        }
    }
    return {tempErrors, formIsValid};
};




export default validUpdateForm;