import React from 'react';
import Select from 'react-select';
import CustomValueContainer from '../common/CustomValueContainer' ;
import { withTranslation } from 'react-i18next'; 
import MultiSelect  from '../common/MultiSelect'; 
 


const BasicDetails = (props) => {
    let  {element,configData,tempErrors}  = props;

    const sales_team = configData.product_type.filter(ob=>ob.incentive_type===1);
    const product_type_app = configData.product_type.filter(ob=>ob.application_type===1);
   
    const handleChangeRole = (RoleTypeOption) => {
        props.onChange("user_role", RoleTypeOption.value, 'role', props.index)
    };

    const handleChangeSalesTeam = (SalesTeamOption) => {
        props.onChange("product_type", SalesTeamOption.value, 'sales', props.index)
    };

    const handleChangeMonths = (MonthOption) => {
        props.onChange("score_duration", MonthOption.value, 'month', props.index)
    }; 

    const handleChangeAppType = (ApplicationTypeOption) => {  
        props.onChange("application_type", ApplicationTypeOption, 'application_type', props.index)
    };


        return (
            <React.Fragment>
                <div className='basic-filter-outer'>

                    <div className="basic-detail-filter"> 

                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element && element.user_role ? configData.user_point_role.filter((el) =>(el.value === element.user_role)) : null}
                                    onChange={handleChangeRole}
                                    options={configData.user_point_role}
                                    placeholder= {props.t('FORM.USER_ROLE')+'*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.user_role) ? <span className="error show">{tempErrors.user_role}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>

                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element && element.product_type ? sales_team.filter(({ value }) => value === element.product_type) : null}
                                    options={sales_team}
                                    onChange={handleChangeSalesTeam}
                                    placeholder= {props.t('FORM.SALE_TEAM') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.product_type) ? <span className="error show">{tempErrors.product_type}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>

                        <fieldset className="single-select">
                            <div className="material">
                                {
                                  !element.is_expired 
                                  ?
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={element && element.score_duration ? configData.scheme_duration.filter(({ value }) => value === element.score_duration) : null}
                                        options={configData.scheme_duration}
                                        onChange={handleChangeMonths}
                                        placeholder={props.t('SCORE_CONFIG_DETAIL.FIELDS.M') + '*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        isDisabled={element.is_expired}
                                    />: 
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={[{value: '', label: element.score_duration}]}
                                        options={configData.scheme_duration}
                                        onChange={handleChangeMonths}
                                        placeholder={props.t('SCORE_CONFIG_DETAIL.FIELDS.M') + '*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        isDisabled={element.is_expired}
                                    />
                                }
                            
                            </div>
                            {
                                (tempErrors && tempErrors.score_duration) ? <span className="error show">{tempErrors.score_duration}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>


                     
                        <fieldset className="single-select">  
                                <div className="material">
                                    <MultiSelect 
                                        isMulti ={true}
                                        value={element.application_type ? product_type_app.filter(ob=>element.application_type.includes(ob.value)): []}
                                        onChange={handleChangeAppType}
                                        options={product_type_app}
                                        isDisabled={element.is_expired}
                                        placeholder={props.t('FORM.APPLICATION_TYPE') + '*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                    /> 
                                </div>
                                
                                <label data-label={"Application Type"} className="form-label"></label>

                                {
                                    (tempErrors && tempErrors.application_type) ? <span className="error show">{tempErrors.application_type}</span> : <span>&nbsp;</span>
                                }
                        </fieldset>
                    </div>
                </div>
            </React.Fragment>
        )
   
}  
 
export default withTranslation('common')(BasicDetails); 
