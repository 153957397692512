import Modal from "../../common/Modal";
import FileUpload from "./FileUpload";
import { useEffect, useState } from "react";
import { SFA_IMAGE_FOLDER } from "../../../config/constant"

function PdfUploader(props) {

    const [fileUplodValue, setPdfUpload] = useState({
        pdfUpload: false,
        pdfUploadStatus: false,
        pdfUploadMsg: false
    })
    const [pdfUploadPopUp, setPdfUploadPopUp] = useState(false);
    useEffect(()=>{
        //SHOW POPUP IF OPENED
        if(props.handlePdfUploader && props.handlePdfUploader.pdfUpload){
            setPdfUpload({
                ...fileUplodValue, pdfUpload: true,type:1
            })
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handlePdfUploader])

    /**
     * UPLOAD POPUP SHOW/HIDE HANDLER
     * @param {*} params 
     */
  

    /**
     * BULK UPLOAD STATUS HANDLER
     * @param {*} params 
     */
    const uploadStatusHandler = (params) => {
        setPdfUpload({
            ...fileUplodValue, ...params
        })
    }

    const showModalPdfUploadPopUp = () => {
        setPdfUploadPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalPdfUploadPopUp = () => {
        setPdfUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }

    

    
    return (

        <>
        <button className="btn-line btn-export" onClick={showModalPdfUploadPopUp} > <span className={(props.checkForPdfUpload) ? "bg-check-ic" :""}><i className={(props.checkForPdfUpload) ? "ic-check" :"ic-upload"}></i> </span>{(props.checkForPdfUpload) ? "Mail Approval" : "Approval Mail"}</button>

        <div className="model-popup-outer">
                <Modal show={pdfUploadPopUp} handleClose={()=>hideModalPdfUploadPopUp()}>
                    <div className="modal-header">
                    <h3>{"Approval Mail"}</h3>
                    </div>
                    <div className="modal-body">
                        <div className='pdf-upload-history-content'>
                            <div className='pdf-upload-sec'>
                               
                                <FileUpload uploadStatusHandler={uploadStatusHandler} fileUplodValue={fileUplodValue}  fileType = {"pdf"} month = {props.month} upload_type_id={SFA_IMAGE_FOLDER}/>

                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        </>
        
  );
}

export default PdfUploader;