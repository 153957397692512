import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { DateFormat } from "../../config/helper"; 
import { useSelector } from "react-redux";

const ReceivedDateRange = (props) => {

    const { t } = useTranslation('common');
    const [received_date, setStartDate] = useState(null);
    const [receive_date_to, setEndDate] = useState(null);

    const stateInfo = useSelector((state) => { 
        return state.common;
    }) 
    useEffect(() => {
        if(props.financerOption){
            let fieldArray = props.element.date_range.filter(ob=>ob.tab ==props.tab && ob.application_type ==props.appType); // eslint-disable-line

            if(fieldArray.length){
                let fieldItem = fieldArray[0];
                if(fieldItem.receive_from && fieldItem.receive_from!=="0000-00-00" ){
                    setStartDate(new Date(fieldItem.receive_from))
                }else{
                    setStartDate(null)
                }
                if(fieldItem.receive_to && fieldItem.receive_to!=="0000-00-00" ){
                    setEndDate(new Date(fieldItem.receive_to)) 
                }else{
                    setEndDate(null) 
                } 
            } 
        }else{
            if (props.element.date_range && props.element.date_range.length>0 && props.tab && props.element.date_range[props.tab-1].receive_from && props.element.date_range[props.tab-1].receive_from!=="0000-00-00") { 
                setStartDate(new Date(props.element.date_range[props.tab-1].receive_from))
            }else{
                setStartDate(null)
            }
            if (props.element.date_range && props.element.date_range.length>0 && props.tab && props.element.date_range[props.tab-1].receive_to && props.element.date_range[props.tab-1].receive_to!=="0000-00-00") { 
                setEndDate(new Date(props.element.date_range[props.tab-1].receive_to))
            }else{
                setEndDate(null) 
            }
        }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])


    const onChange = (dates) => {
        const [start, end] = dates; 
        setStartDate(start);
        setEndDate(end); 
        if(start && end){
            props.onChange("received_date_"+props.tab, [DateFormat(start), DateFormat(end)], '', props.element) 
        }else{
          //  props.onChange("received_date_"+props.tab, ['', ''], '', props.element)
        }  

    } 

    let maxDate, minDate = null;  
    if(stateInfo?.incentiveConfig?.scheme_duration && props.element.scheme_duration){   
        let record = stateInfo?.incentiveConfig?.scheme_duration.filter(ob=>ob.value === +props.element.scheme_duration) 
        if(record.length){
            minDate = record[0].start;
            maxDate = record[0].end;
        }
    }  
    return (
        <div className={"material animation-effect seller-opt " + (received_date ? 'active-label' : '')}  >
            <div className="datepicker">
                <DatePicker
                    selected={received_date}
                    onChange={(date) => onChange(date)}
                    startDate={received_date}
                    endDate={receive_date_to}
                    selectsRange
                    // inline

                    placeholderText={" "}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    peekNextMonth
                    minDate={new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    isClearable={true}
                />
                <label data-label={t('FORM.APP_RECEIVED_DATE')} className={"form-label " + (received_date ? 'top21' : '')}></label>
                {
                    (props.tempErrors && props.tempErrors.received_date) ? <span className="error show">{props.tempErrors.received_date}</span> : <span>&nbsp;</span>
                }
                
            </div>
        </div>
    );
};


export { ReceivedDateRange }