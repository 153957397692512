import { CommonConstants } from '../constants/common.constants';
import { IncentiveService } from "../../services";

const stateList = () => {
    return (dispatch) => {
        dispatch({ type: CommonConstants.STATE_LIST_REQUEST, payload: '' })
    }
}

const schemeList = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getIncentiveSchema(params)
                .then((response) => {
                    if (response && response.status === 200 && response.data.status === 200) {
                        //   let detail = response.data.data
                        let detail = response.data.data.map(ob => {
                            return { ...ob, value: ob.id, label: ob.scheme }
                        });
                        dispatch({ type: CommonConstants.SCHEME_LIST_REQUEST, payload: detail })
                        dispatch({ type: CommonConstants.ROLEWISE_SCHEME_LIST, payload: { [params.role_id]: detail } })
                        resolve(detail);

                    }
                    else {
                        resolve([]);
                    }
                })
        })
    }
}

const financierList = (params) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            IncentiveService.getFinancierlist(params)
                .then(
                    response => {
                        if (response && response.data && response.data.status === 200) {
                            let payload = response.data.data

                            dispatch({ type: CommonConstants.FINANCIER_LIST_REQUEST, payload })
                            resolve(response.data);
                        }
                        else {
                            resolve(response && response.data);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    };
}

const getRoleList = (params) => {
    return (dispatch) => {
        IncentiveService.getRoleList(params)
            .then((response) => {
                if (response && response.status === 200 && response.data.status === 200) {
                    let detail = response.data.data.map(ob => {
                        return { ...ob, value: ob.id, label: ob.name }
                    });
                    dispatch({ type: CommonConstants.GET_ROLE_LIST, payload: detail })
                }
            })
    }
}

const bmUserList = (params) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            IncentiveService.getUserList(params)
                .then(
                    response => {
                        if (response.data && response.data.status === 200) {
                            let payload = (response.data.data) ? response.data.data.user_list : []
                            let detail = payload.map(ob => {
                                return { ...ob, value: ob.id, label: ob.name }
                            });
                            if (response && response.data && response.data.data && response.data.data.user_list) {
                                response.data.data.user_list = detail
                            }
                            dispatch({ type: CommonConstants.BM_USER_LIST, payload: detail })
                            resolve(response.data);
                        }
                        else {
                            resolve(response.data);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    };
}

const getRoleProducts = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getRoleProducts(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data
                        dispatch({ type: CommonConstants.ROLE_PRODUCTS_LIST, payload: detail })
                        resolve(detail);
                    }
                    else {
                        resolve([]);
                    }
                })
        })
    }
}

const updateApproveButtonStatus = (params) => {
    return (dispatch) => {
        dispatch({ type: CommonConstants.APPROVE_BUTTON, payload: params })

    }
}
const checkedAllStatus = (params) => {
    return (dispatch) => {
        dispatch({ type: CommonConstants.CHECKED_ALL_STATUS, payload: params })
    }
}

const pageStatusType = (params) => {
    return (dispatch) => {
        dispatch({ type: CommonConstants.PAGE_STATUS, payload: params })
    }
}

const adminDashboard = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getAdminDashboard(params)
                .then((response) => {

                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        resolve(response.data)
                        dispatch({ type: CommonConstants.ADMIN_DASHBOARD, payload: detail })
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })
    }
}


const payoutDetails = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {

            IncentiveService.getPayoutDetails(params)
                .then((response) => {

                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        detail['pagination'] = response.data.pagination || {};
                        resolve(response.data)
                        dispatch({ type: CommonConstants.PAYOUT_DETAILS, payload: {detail,params} })
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })

    }
}


const getIncentiveConfig = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {

            IncentiveService.getIncentiveConfig(params)
                .then((response) => {

                    if (response && response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        dispatch({ type: CommonConstants.INCENTIVE_CONFIG_FILTERS, payload: detail })
                        resolve(response.data)
                    } else {
                        resolve(response && response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

const getCsvUploadHistory = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getCsvUploadHistory(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        dispatch({ type: CommonConstants.CSV_LOG_HISTORY, payload: detail })
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

const incentiveHistoryList = (params) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            IncentiveService.getIncentiveHistoryList(params)
                .then(
                    response => {
                        if (response && response.data && response.data.status === 200) {
                            resolve(response.data);
                        }
                        else {
                            resolve(response && response.data);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    };
}
const saveIncentiveUserData = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.saveIncentiveUserData(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        dispatch({ type: CommonConstants.SAVE_INCENTIVE_USER_DATA, payload: detail })
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}


const updateStatus = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.updateUserAmountStatus(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        // let detail = response.data.data;
                        // dispatch({ type: CommonConstants.SAVE_INCENTIVE_USER_DATA, payload: detail })
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

const getMultiSelectedLoans = (data) => {
    return (dispatch) => {
        dispatch({ type: CommonConstants.GET_MULTISELECTED_PAYOUTS, payload: data })
    }
}

const getReportingManager = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getReportingRoleManager(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        // let detail = response.data.data;
                        // dispatch({ type: CommonConstants.SAVE_INCENTIVE_USER_DATA, payload: detail })
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

const leadCountByRole = (params) => {
    let user_id=params.user_id;
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.leadCountByRole(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        resolve(response.data);
                        let detail = response.data.data;
                        dispatch({ type: CommonConstants.LEAD_COUNT_PAYOUT_DETAILS, payload:{[`user_${user_id}_${params.scheme_id}`]:detail}})
                    }else{
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })

    }
}

const setExcludeMultiSelectedSfa = (data) => {
    return (dispatch) => {
        dispatch({ type: CommonConstants.SET_MULTISELECTED_EXCLUDED_PAYOUTS, payload: data })
    }
}

const getPdfUploadDtls = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getPdfUploadDtls(params)
                .then((response) => {
                    if (response && response.status === 200) {
                        let detail = response.data ? response.data : "";
                        dispatch({ type: CommonConstants.CHECK_FOR_MAIL_APPROVAL, payload: detail })
                        resolve(response.data)
                    } else {
                        resolve(response && response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

const dealerDetails = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {

            IncentiveService.getDealerDetails(params)
                .then((response) => {

                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        detail['pagination'] = response.data.pagination || {};
                        resolve(response.data)
                        dispatch({ type: CommonConstants.DEALER_DETAILS, payload: {detail,params} })
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })

    }
}

const updateDealerStatus = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.updateDealerStatus(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })

    }
}

const getDealerConfig = (params) => {
    return (dispatch) => {
        IncentiveService.getDealerConfig(params)
            .then((response) => {
               
                if (response && response.status === 200 && response.data.status === 200) {
                    let detail = response.data.data;
                    dispatch({ type: CommonConstants.DEALER_CONFIGS, payload: detail })
                }
            })
    }
}



const getDealerHistory = (params) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            IncentiveService.getDealerHistory(params)
                .then(
                    response => {
                        if (response && response.data && response.data.status === 200) {
                            resolve(response.data);
                        }
                        else {
                            resolve(response && response.data);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    };
}

function submitDealerBulkUpload(params) {

        return new Promise((resolve, reject) => {
            IncentiveService.submitDealerBulkUpload(params)
                .then(
                    resp => {
                        if (resp && resp.data && resp.data.status === 200) {
                            resolve(resp.data)
                        }
                        else {
                            reject((resp && resp.data) || []);
                        }
                    },
                    error => {
                        reject(error)
                    }
                );
        });

}

function updateDealerAmount(params) {

    return new Promise((resolve, reject) => {
        IncentiveService.updateDealerAmount(params)
            .then(
                resp => {
                    if (resp && resp.data && resp.data.status === 200) {
                        resolve(resp.data)
                    }
                    else {
                        reject((resp && resp.data) || []);
                    }
                },
                error => {
                    reject(error)
                }
            );
    });

}



const getDealerCategoryDetails = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {

            IncentiveService.getDealerCategoryDetails(params)
                .then((response) => {

                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        resolve(response.data)
                        dispatch({ type: CommonConstants.DEALER_CATEGORY_DATA, payload: detail })
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })

    }
}

function saveCategoryDetails(params) {

    return new Promise((resolve, reject) => {
        IncentiveService.saveCategoryDetails(params)
            .then(
                resp => {
                    if (resp && resp.data && resp.data.status === 200) {
                        resolve(resp.data)
                    }
                    else {
                        reject((resp && resp.data) || []);
                    }
                },
                error => {
                    reject(error)
                }
            );
    });

}

const getFinancierConfigGroup = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getFinancierConfigGroup(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        resolve(response.data)
                        dispatch({ type: CommonConstants.FINANCIER_GROUP_CONFIG, payload: detail })
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })

    }
}

function saveFinancierConfig(params) {

    return new Promise((resolve, reject) => {
        IncentiveService.saveFinancierConfig(params)
            .then(
                resp => {
                    if (resp && resp.data && resp.data.status === 200) {
                        resolve(resp.data)
                    }
                    else {
                        reject((resp && resp.data) || []);
                    }
                },
                error => {
                    reject(error)
                }
            );
    });

}

const getDeviationLimitConfig = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getDeviationLimitConfig(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        resolve(response.data)
                        dispatch({ type: CommonConstants.FINANCIER_GROUP_CONFIG, payload: detail })
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        })

    }
}

function saveDeviationConfig(params) {

    return new Promise((resolve, reject) => {
        IncentiveService.saveDeviationConfig(params)
            .then(
                resp => {
                    if (resp && resp.data && resp.data.status === 200) {
                        resolve(resp.data)
                    }
                    else {
                        reject((resp && resp.data) || []);
                    }
                },
                error => {
                    reject(error)
                }
            );
    });

}

export const CommonActions = {
    stateList,
    schemeList,
    getRoleList,
    financierList,
    bmUserList,
    getRoleProducts,
    updateApproveButtonStatus,
    adminDashboard,
    getIncentiveConfig,
    payoutDetails,
    getCsvUploadHistory,
    incentiveHistoryList,
    saveIncentiveUserData,
    updateStatus,
    getMultiSelectedLoans,
    getReportingManager,
    leadCountByRole,
    setExcludeMultiSelectedSfa,
    checkedAllStatus,
    getPdfUploadDtls,
    dealerDetails,
    updateDealerStatus,
    getDealerConfig,
    getDealerHistory,
    submitDealerBulkUpload,
    updateDealerAmount,
    getDealerCategoryDetails,
    saveCategoryDetails,
    getFinancierConfigGroup,
    saveFinancierConfig,
    getDeviationLimitConfig,
    saveDeviationConfig,
    pageStatusType

};

