import React from "react";
import { toast } from 'react-toastify';
import { CommonActions } from "../../store/action";
import { INCENTIVE_IMAGE_FOLDER } from "../../config/constant"
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import FullPageLoader from "../common/FullPageLoader";
import {DEALER_FILE_TYPE} from "../../config/id_config/constant"

function DealerFileUpload(props) {

    const { t } = useTranslation('common');
    const [uploadConfig, setUploadConfig] = useState({
        file1: null,
        fileName: null,
        file2: null,
        file3: null,
        loading: false,
        fileType: ""
    });
    const [tempError, setTempError] = useState({});

    useEffect(()=>{
        if(props.bulkUploadPopUp){
            setTempError({});
            setUploadConfig({
                file1: null,
                fileName: null,
                file2: null,
                file3: null,
                loading: false,
                fileType: ""
            });
        }
        document.getElementsByClassName("upload-image-input")[0].value=null;
        document.getElementsByClassName("upload-image-input")[1].value=null;
        document.getElementsByClassName("upload-image-input")[2].value=null;
    }, [props.bulkUploadPopUp])


    /**
    * On Submit filter
    */
    const submitUpload = async (event) => {
        event.preventDefault();
        setUploadConfig({ ...uploadConfig, loading: true });

        const formdata = new FormData();
        formdata.append('upload_type', INCENTIVE_IMAGE_FOLDER);
        formdata.append("file1", uploadConfig?.file1??null);
        formdata.append("file2", uploadConfig?.file2??null);
        formdata.append("file3", uploadConfig?.file3??null);
        formdata.append("type", DEALER_FILE_TYPE);
        formdata.append("month", props.month);

            try {
                let searchResult = await CommonActions.submitDealerBulkUpload(formdata);
                if (searchResult && searchResult.status === 200) {
                    toast.success(searchResult.message);
                    if (searchResult.data && searchResult.data.fileLink) {
                        setTempError({ "dealer_data_err": searchResult.data.fileLink })
                    }
                    setUploadConfig({file1: null, file2: null, file3: null, loading: false });
                    props.getDealerDetailsList(props.searchFilters);
                } else {
                    toast.error(searchResult.message);
                    setUploadConfig({ file1: null, file2: null, file3: null, loading: false });
                }
                document.getElementsByClassName("upload-image-input")[0].value = null;
                document.getElementsByClassName("upload-image-input")[1].value = null;
                document.getElementsByClassName("upload-image-input")[2].value = null;
            } catch (err) {
                toast.error(err.message);
                setUploadConfig({  loading: false, file1: null, file2: null, file3: null });
                document.getElementsByClassName("upload-image-input")[0].value=null;
                document.getElementsByClassName("upload-image-input")[1].value=null;
                document.getElementsByClassName("upload-image-input")[2].value=null;
            }


    }


    const handleAddImage = async (e, fileType) => {

        e.preventDefault();
        let file = e.target?.files[0];
        let errorMsg = false;
        if (file) {
            if (fileType === "email_approval") {
                if (!['application/pdf', 'application/wps-office.pdf', "image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
                    setUploadConfig({ ...uploadConfig, loading: false });
                    errorMsg = true;
                } else {
                    setUploadConfig({
                        ...uploadConfig,
                        file1: file
                    });
                }
            } else if(fileType === "dealer_reward"){
                if (!["text/csv", "application/wps-office.xls", "application/wps-office.xlsx"].includes(file.type)) {
                    setUploadConfig({...uploadConfig, loading: false });
                    errorMsg = true;
                } else {
                    setUploadConfig({
                        ...uploadConfig,
                        file3: file
                    });
                }
            } else if(fileType === "calculation"){
                if (!["text/csv", "application/wps-office.xls", "application/wps-office.xlsx"].includes(file.type)) {
                    setUploadConfig({ loading: false });
                    errorMsg = true;
                } else {
                    setUploadConfig({
                        ...uploadConfig,
                        file2: file
                    });
                }
            }
            let error = tempError;
            if(error?.dealer_data_err){
                delete error.dealer_data_err;
            }
            error[fileType] =  errorMsg ? "Please upload a correct file format" : null;
            setTempError(error);
        }
    }


    const { loading } = uploadConfig;
    
    return (
        <div style={{ textAlign: "center" }} >
            <div className="bulk-uploader-wrapper">
            <FullPageLoader show={loading} />
                <div className="display-box">
                    <div className="upload-text">
                        <div className="upload-txt dealer-upload-text">
                            <div className="">
                                <h4>{t("DEALER.UPLOAD_EMAIL_APPROVAL")} </h4>
                                <span>{t("DEALER.JPEG_PNG_PDF_ALLOWED")}</span>
                                {tempError && tempError['email_approval'] && (
                                    <span className="error">
                                        {t("DEALER.ERROR")} : {tempError['email_approval']}
                                    </span>
                                )}
                            </div>
                            <div className="dealer-upload-btn">
                            <button className="btn-primary file-btn" type="button"  >
                                 {t("DEALER.BROWSE_FILE")}
                            </button>
                                <input
                                    type="file"
                                    id="email_approval"
                                    className="upload-image-input"
                                    accept={".png, .jpg, .jpeg, .pdf"}
                                    onChange={(event) => handleAddImage(event, "email_approval")}
                                />
                            </div>
                        </div>
                        <div className="upload-txt dealer-upload-text">
                            <div className="">
                                <h4> {"Upload Calculation Scheme"}</h4>
                                <span> {"Only xls, xlsx & csv are acceptable"}</span>
                                {tempError && tempError['calculation'] && (
                                    <span className="error">
                                        {t("DEALER.ERROR")} : {tempError['calculation']}
                                    </span>
                                )}
                            </div>
                          
                            <div className="dealer-upload-btn">
                                <button className="btn-primary file-btn" type="button"  >
                                {t("DEALER.BROWSE_FILE")}
                                </button>
                                <input
                                    type="file"
                                    id="calculation"
                                    className="upload-image-input"
                                    accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                                    onChange={(event) => handleAddImage(event, "calculation")}
                                />
                            </div>
                        </div>
                        <div className="upload-txt dealer-upload-text">
                            <div className="">
                                <h4> {"Upload Dealer Reward"}</h4>
                                <span> {"Only xls, xlsx & csv are acceptable"}</span>
                                {tempError && tempError['dealer_reward'] && (
                                      <span className="error">
                                      {t("DEALER.ERROR")} : {tempError['dealer_reward']}
                                     </span>
                                )}
                                {
                                    tempError && tempError['dealer_data_err'] && (
                                        <span className="error">
                                        {t("DEALER.DATA_ERROR")} : 
                                         <a href={tempError['dealer_data_err']} className="error">
                                           {t("DEALER.DOWNLOAD_ERROR_REPORT")}
                                        </a>
                                    </span>
                                  )
                                }
                            </div>

                         
                            <div className="dealer-upload-btn">
                            <button className="btn-primary file-btn" type="button" >
                            {t("DEALER.BROWSE_FILE")}
                            </button>
                                <input
                                    type="file"
                                    id="dealer_reward"
                                    className="upload-image-input"
                                    accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                                    onChange={(event) => handleAddImage(event, "dealer_reward")}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal-footer footer-btn m-lg-t">
                {
                    !loading
                        ?
                        <button className="btn-primary" disabled={!uploadConfig.file1 || !uploadConfig.file2 || !uploadConfig.file3} onClick={(e) => submitUpload(e)}>{t('DEALER.UPLOAD')}</button>
                        :
                        <button className="btn-primary" type='button'>{t('DEALER.PLEASE_WAIT')}</button>

                }
                <div className="download-txt">
                    <a href={props.sampleFile}>
                        {t('DEALER.DOWNLOAD_DEALER_SAMPLE')}
                    </a>
                </div>
            </div>
        </div>
    );

}




export default DealerFileUpload;
