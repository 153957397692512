import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';
import Select from 'react-select';
import CustomValueContainer from '../common/CustomValueContainer' ;
import { BiPlusCircle, BiMinusCircle }   from "react-icons/bi";
 
class AllowanceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh: 1
        };
    }

    onChange = (event,index) => { 
        let { name, value } = event.target
        this.setState({ refresh: this.state.refresh + 1 })
        this.props.element.user_allowances[index][name] = value; 
    }
    addMore = (user_level)=>{  
        let temp_object = {
            disb_minimum_count: "",
            allowance: "",
            operator: "",
            user_level: user_level,
            user_level_flag:1
        };
        this.props.onChange("addNewUserAllowance", temp_object, "", this.props.element);
    }
    removeMore = (e,index,data)=>{  
        e.stopPropagation();
        let params = {
            key: index,
            index : this.props.element_index,
            type:'ALLOWANCE_CARD',
            scheme: this.props.element.scheme_type_id,
            is_deleted : (data.id)? data.id : false
        }  
        this.props.removeMoreIncentive(params)
    }
    operatorChange = (name,value) => {
        this.setState({ [name]: value.value })
        this.props.onChange(name, value.value, '', this.props.element)
    }

    render() {
        const { user_level,operator_array } = this.props.incentive_config
        const { element, tempErrors } = this.props  
      
        let userAllowancelList = element.user_allowances; 
        if(element.form_config.hasOwnProperty('USER_LEVEL_REGULAR')){
            userAllowancelList = userAllowancelList.filter(ob=>(Number(ob.user_level) === 2));
        } 
        return (
            <React.Fragment>
               <div className='add-incentive-table allowance-card-table allowance-card'>
                    <table>
                        <thead>
                            <tr>
                                <th>{this.props.t('FORM.USER_LEVEL')}</th>
                                {
                                  (element.form_config.OPERATOR)?<th>{this.props.t('FORM.OPERATOR')}</th>:''  
                                } 
                                <th>{(element.form_config.ARO_EXTRA_INCENTIVE)?  this.props.t('FORM.MIN_POINTS'): this.props.t('FORM.MIN_DISBURSAL')}</th>
                                <th>{ (element.form_config.ARO_EXTRA_INCENTIVE) ?  this.props.t('FORM.INCENTIVE_AMOUNT'): this.props.t('FORM.ALLOWANCE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            user_level && 
                            user_level.map((userlevel, index1) => (
                                
                                userAllowancelList && userAllowancelList.map((data, index)=> {
                                    if (
                                        data.user_level &&
                                        data.user_level !==userlevel.id
                                    ){
                                        return <tr key={index}></tr>;
                                    }
                                    
                                    return (
                                    <tr key={index1+"_"+index} id={index1+"_"+index}> 
                                        {
                                            (!data.user_level_flag)?<td>{user_level.filter((e)=>(e.id === data.user_level)).map(({user_name}) => user_name)}</td>:<td></td>
                                        }
                                        
                                        {
                                            element.form_config.OPERATOR && 
                                            <td>
                                                    <fieldset className="single-select m-sm-r operator">
                                                        <div className="material">
                                                            <Select components={{ ValueContainer: CustomValueContainer }}
                                                                value={(data.operator) ? operator_array.filter(({ value }) => value === data.operator) : null}
                                                                onChange={(value)=>{this.operatorChange( "operator_" + index,value)}}
                                                                options={operator_array}
                                                                isDisabled={element.is_expired}
                                                                placeholder={''}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable={false}
                                                            />
                                                        </div>
                                                        {
                                                        (tempErrors && tempErrors["operator_" + index]) ? <span className="error show">{tempErrors["operator_" + index]}</span> : ''
                                                    }
                                                    </fieldset>
                                            </td> 
                                        }

                                        <td>
                                            <fieldset className="m-sm-r">
                                                <div className="material">
                                                
                                                    <InputField
                                                        inputProps={{
                                                            id: "disb_minimum_count_" + index,
                                                            type: "text",
                                                            name: "disb_minimum_count",
                                                            placeholder: " ",
                                                            value:  data.disb_minimum_count,
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e) => this.onChange(e,index)}
                                                        />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["disb_minimum_count_" + index]) ? <span className="error show">{tempErrors["disb_minimum_count_" + index]}</span> : ''
                                                }
                                            </fieldset>
                                        </td>
                                  
                                        <td>
                                            <fieldset className="m-sm-r">
                                                <div className="material">
                                                    
                                                    <InputField
                                                        inputProps={{
                                                            id: "allowance_" + index,
                                                            type: "text",
                                                            name: "allowance",
                                                            placeholder: " ",
                                                            value:  data.allowance,
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e) => this.onChange(e,index)}
                                                        />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["allowance_" + index]) ? <span className="error show">{tempErrors["allowance_" + index]}</span> : ''
                                                }
                                            </fieldset>
                                        </td>
                                
                                        {
                                        (element.form_config.ADD_MORE_LEVEL)?    
                                        ((!data.user_level_flag)?
                                        <td>
                                            {
                                            (element.is_expired)?<span className="add-minus"><BiPlusCircle size="1.4em"/></span>:<span className="add-minus" onClick={(e)=>this.addMore(data.user_level)}><BiPlusCircle size="1.4em"/></span>  
                                            }
                                            
                                        </td>:
                                        <td>
                                            {
                                                (element.is_expired)?<span className="add-minus"> <BiMinusCircle size="1.4em"/></span>:<span className="add-minus" onClick={(e)=>this.removeMore(e,index,data)}> <BiMinusCircle size="1.4em"/></span>
                                            }
                                        </td>):''
                                        }
                                    </tr>
                                    )
                                })
                                
                            ))
                            }
                                       
                            
                        </tbody>
                    </table>    
               </div>
           </React.Fragment>


        )
    }
}



export default withTranslation('common')(AllowanceCard);