import React, { Component } from 'react';
import { ReceivedDate, DisbursedDate } from './DatePicker'

class BasicDateFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {

        let { element, tempErrors } = this.props
        return (
            <React.Fragment>
                <div className="basic-detail-filter">

                    <fieldset className="">
                            <ReceivedDate element={element} tempErrors={tempErrors} onChange={(e, i, q, u) => { this.props.onChange(e, i, q, u) }} />
                        
                    </fieldset>

                    <fieldset className="">
                            <DisbursedDate element={element} tempErrors={tempErrors} onChange={(e, i, q, u) => { this.props.onChange(e, i, q, u) }} />
                 
                    </fieldset>


                </div>
            </React.Fragment>
        )
    }
}
export default BasicDateFilter;