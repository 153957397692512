import Modal from "../common/Modal";
import { useTranslation } from 'react-i18next';
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import { ARO_USER_ROLE } from "../../config/constant";

function FileUpload(props) {
    const { t } = useTranslation('common');
    const commonRedux = useSelector((state) => state.common)
    const [tempError, setTempError] = useState({});
    const [loading, setLoading] = useState(false);
    const [seletedFile, setSeletedFile] = useState(null);
    const [fileUploadPopUp, setFileUploadPopUp] = useState(false);
    const sampleFiles = (commonRedux && commonRedux.incentiveConfig && commonRedux.incentiveConfig.sampleFiles)?commonRedux.incentiveConfig.sampleFiles:{}
  
    const showModalFileUploadPopUp = () => {
        setFileUploadPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalFileUploadPopUp = () => {
        setSeletedFile("");
        setFileUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }

    const handleAddImage = async (e) => {    
        e.preventDefault();
        let file = e.target.files[0]; 
        if(file){
            if(!["text/csv"].includes(file.type)) {
                setLoading(false);
                toast.error("Please select valid File");
            } else {
                setSeletedFile(file);
            }
        } 
    }


    const  submitUpload = async (event) => {
        event.preventDefault();
        setLoading(true);
        let fileName = seletedFile ? seletedFile.name : ''; 
        let checkFileType = (seletedFile && fileName && (/\.(csv)$/.test(fileName))); 
        if (checkFileType) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setLoading(false);
                const content = e.target.result;
                Papa.parse(content, {
                    skipEmptyLines: true,
                    header: true ,
                    dynamicTyping: true,
                    complete: (result) => {
                        const validRows = result.data.filter(row => {
                            return Object.values(row).every(value => typeof value === 'number' && !isNaN(value) && value >= 0);
                          }).map(row => ({
                            min_disbursal : +props.element.form_config.USER_ROLE === ARO_USER_ROLE ? row['Score'] : row['Disbursals'],
                            junior_amt: +props.element.form_config.USER_ROLE === ARO_USER_ROLE ? row['Jr. ARO'] : row['Jr. BRO'],
                            regular_amt: +props.element.form_config.USER_ROLE === ARO_USER_ROLE ? row['ARO'] : row['BRO'],
                            senior_amt: +props.element.form_config.USER_ROLE === ARO_USER_ROLE ? row['Sr. ARO'] : row['Sr. BRO'],
                            fixed_amt: row['Fixed Income'],
                            common_id: []  
                          }));
                        if(validRows.length){
                            if(props.element.disbursal_role.length){
                                let n = props.element.disbursal_role.length - 1;
                                while (n >= 0 && props.element.disbursal_role[n]['min_disbursal'] === "" && props.element.disbursal_role[n]['junior_amt'] === "" && props.element.disbursal_role[n]['regular_amt'] === "" && props.element.disbursal_role[n]['senior_amt'] === ""){
                                    props.element.disbursal_role.pop();
                                    n--;
                                }
                            }
                            props.element.disbursal_role = [
                                ...props.element.disbursal_role,
                                ...validRows
                            ];
                            props.setDisbursalFormListData(props.element.disbursal_role);
                            toast.success("Data Uploaded Successfully");
                        } else {
                            toast.error("Please Upload Valid Data");
                        }
                        setSeletedFile("");
                        document.getElementsByClassName('incentiveCsv')[0].value = null
                    }
                   
                });
            };

            reader.readAsBinaryString(seletedFile);
           
        }else {
            toast.error("Please select valid File");
            setLoading(false);
            setSeletedFile("");
            document.getElementsByClassName('incentiveCsv')[0].value = null
        }
        

    }


    const handleCancelUpload = (e) => {
        setSeletedFile("");
        document.getElementsByClassName('incentiveCsv')[0].value = null

    }

    const  openFileUpload = () => {
        setSeletedFile("");
        document.getElementsByClassName('incentiveCsv')[0].click()
    }
    
    let { tempErrors, element } = props;
    return (

        <>
            <button className="btn-line btn-upload" onClick={showModalFileUploadPopUp} > <i className={"ic-upload"}></i> Upload</button>

            <div className="model-popup-outer dealer-upload-popup">
                <Modal show={fileUploadPopUp} handleClose={() => hideModalFileUploadPopUp()}>
                    <div className="modal-header">
                        <h2>{element.parent_name}</h2>
                    </div>
                    <div className="modal-body">
                        <div className='bulk-upload-history-content'>
                            <div className='bulk-upload-sec'>

                                <div style={{ textAlign: "center" }} >

                                    <form method="post" onSubmit={submitUpload} encType="multipart/form-data">


                                        <div className="image-uploader-wrapper">
                                            <div className={"display-box drag-over"}>
                                                <div className="icon-text-box">
                                                    <div className="upload-icon">
                                                        <i className="ic-file-icon" aria-hidden="true" />
                                                    </div>

                                                    <div className="upload-text">
                                                        {seletedFile ?
                                                            <div className="upload-file-name">

                                                                <div className="upload-file-img">
                                                                    <span className="suceess-icn">
                                                                        <i className="ic-check"></i>
                                                                    </span>
                                                                    <h4>
                                                                        {seletedFile?.name}
                                                                    </h4>
                                                                    <i className="ic-clearclose" onClick={(e) => handleCancelUpload(e)}></i>
                                                                </div>

                                                                <button
                                                                    className="upload-button btn btn-primary"
                                                                    type="button" onClick={(event) => openFileUpload(event)}
                                                                >
                                                                    {t('MESSAGE.BROWSE_FILE')}
                                                                </button>
                                                                <input
                                                                    type="file"
                                                                    id="upload-image-input"
                                                                    className="upload-image-input incentiveCsv"
                                                                    accept={".csv"}
                                                                    onChange={(e) => handleAddImage(e)}
                                                                    style={seletedFile ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                                                                />

                                                            </div>
                                                            :
                                                            <div className="upload-txt">
                                                                <h4> {t('MESSAGE.DROP_FILE')}</h4>
                                                                <span> {t('MESSAGE.XLS_ACCEPTED')}</span>
                                                                <div className="or-txt">
                                                                    or
                                                                </div>

                                                                <button
                                                                    className="btn-primary file-btn" type="button" onClick={(e) => handleAddImage(e)}
                                                                >
                                                                    {t('MESSAGE.BROWSE_FILE')}
                                                                </button>
                                                                <input
                                                                    type="file"
                                                                    id="upload-image-input"
                                                                    className="upload-image-input incentiveCsv"
                                                                    accept={".csv"}
                                                                    onChange={(e) => handleAddImage(e)}
                                                                    style={seletedFile ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                                                                />

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                             
                                            </div>
                                        </div>

                                        <div className="modal-footer footer-btn">
                                            {
                                                !loading
                                                    ?
                                                    <button className="btn-primary" >{t('DEALER.UPLOAD')}</button>
                                                    :
                                                    <button className="btn-primary" type='button'>{"Please wait"}</button>

                                            }
                                        </div>
                                    </form>    
                                    <div className="modal-footer footer-btn m-lg-t">
                                        <div className="download-txt">
                                        {t('COMMON.DISB_AMT_RANGE.INPUT_FORMAT')} : 
                                          <a href={+element.form_config.USER_ROLE  === ARO_USER_ROLE ? sampleFiles?.find(item => item.type === "aro_base_incentive_scheme")?.url : sampleFiles?.find(item => item.type === "base_incentive_scheme")?.url}>
                                          {t('COMMON.DISB_AMT_RANGE.CLICK_HERE')}
                                              </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        </>

    );
}

export default FileUpload;