import React, { useEffect, useState } from "react";
import MultiSelect from '../common/MultiSelect'; 
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputField from "../common/InputField";
import DisbursalCountAmountCard from "../cards/DisbursalCountAmountCard";

const FinancerConfigurationFilter = (props) => {
    const [financerOption, setFinancerOption] = useState({});
    // const [financierFormListData, setFinancierFormListData] = useState([
    //     { financier_id: [] },
    // ]);
    const commonRedux = useSelector((state) => state.common);
    const [selecteApplicationTypes, setSelecteApplicationTypes] = useState([]);
    const [allSelectedFinanciers, setAllSelectedFinanciers] = useState({});
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (
            selecteApplicationTypes &&
            selecteApplicationTypes.length &&
            commonRedux &&
            commonRedux.financierList &&
            commonRedux.financierList.length
        ) {
            let allFinanciers = {};
            for (let appType of selecteApplicationTypes) {
                appType = appType.toLowerCase();

                let getFinancierListProductWise = commonRedux.financierList.filter(
                    (elm) => appType === elm.application_type_name.toLowerCase()
                );
                if (getFinancierListProductWise && getFinancierListProductWise.length) {
                    allFinanciers[appType.toLowerCase()] =
                        getFinancierListProductWise[0]["financier"];
                }
            }
            setFinancerOption(allFinanciers);
        }
    }, [commonRedux, selecteApplicationTypes]);

    useEffect(() => {
        if (
            props.element &&
            props.element.application_type &&
            props.element.application_type !== selecteApplicationTypes.join(",")
        ) {
            setSelecteApplicationTypes(props.element.application_type.split(","));
        }

        if (props.element && props.element.financier_config) {
            let selectedFinanciers = {};
            for (let financier of props.element.financier_config) {
                if (!selectedFinanciers[financier["application_type"]])
                    selectedFinanciers[financier["application_type"]] = [];

                selectedFinanciers[financier["application_type"]] = [
                    ...selectedFinanciers[financier["application_type"]],
                    ...financier["financier_id"],
                ];
            }

            setAllSelectedFinanciers(selectedFinanciers);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
 

    const getDropdownButtonLabel = (event, financierIndex) => {
        let { placeholderButtonLabel, value } = event;

        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            let actualVals = value.filter(val=> (val.value !== "all" && Object.keys(val).length)) 
            return `${actualVals.length} selected`;
        } else return `${placeholderButtonLabel}`;
    };

    /**
     * CHANGE HANDLER FOR SELECTBOX/INPUTBOX
     * @param {*} name
     * @param {*} value
     * @param {*} financierIndex
     */
    const changeHandler = (
        name,
        event,
        value,
        financierIndex,
        appType,
        finacierOptionUpdated
    ) => {
 
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");  
        let financierFormListData = props.element.financier_config;
 
        if (event.action === "select-option" && event.option.value === "all") {

            financierFormListData[financierIndex][OrigName] = finacierOptionUpdated.map((elm) => "" + elm.value);
            
        }else if(event.action === "deselect-option" && event.option.value === "all"){
                financierFormListData[financierIndex][OrigName] = [];
        }
        else if (event.action === "deselect-option") {
            financierFormListData[financierIndex][OrigName] = (value.filter((o) => o.value !== "all" && o.value !== event.option.value )).map(v=> ""+v.value);
        } 
        else {

            financierFormListData[financierIndex][OrigName] = value.map(
                (elm) => "" + elm.value
            );

            if (!allSelectedFinanciers[appType]) {
                allSelectedFinanciers[appType] = [];
            }
            allSelectedFinanciers[appType] = [
                ...allSelectedFinanciers[appType],
                ...financierFormListData[financierIndex][OrigName],
            ];
            
            value = value.filter(v=> Object.keys(v).length && v.value !== 'all');

            if (value.length === finacierOptionUpdated.length) {
                financierFormListData[financierIndex][OrigName] = [...financierFormListData[financierIndex][OrigName]];
            }
        } 
        setRefresh(refresh + 1);  
        // props.onChange(
        //     name,
        //     financierFormListData[financierIndex][OrigName],
        //     "",
        //     props.element
        // ); 
    };

    const inputChangeHandler = (event,index) => {
        let { name, value } = event.target 
        setRefresh(refresh + 1); 
        props.element.financier_config[index][name] = value;   
    }

    /**
     * CLEAR SELECTED FINANCER
     * @param {*} val
     * @param {*} key
     */
    // const clearSelectedFinancer = (val, key) => {
    //     let updatedFinanciers = Object.assign([], financierFormListData);
    //     updatedFinanciers[key]["financier_id"] = financierFormListData[key][
    //         "financier_id"
    //     ].filter((elm) => elm !== val);
    //     setFinancierFormListData(updatedFinanciers);
    // };

    /**
     * ADD NEW FINANCER
     */
    const addNewFinancierConfig = (appType) => {
        let temp_object = {
            financier_id: [],
            junior_amt: "",
            regular_amt: "",
            senior_amt: "",
            fixed_amt:"",
            application_type: appType.toLowerCase(),
        };
        if(props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT && +props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT===1){
            temp_object.is_count_amount=1
        } 
        props.element.financier_config = [...props.element.financier_config,temp_object]  
        setRefresh(refresh + 1); 
       // props.onChange("addNewFinacierConfig", temp_object, "", props.element);
    };

    /**
     * CREATE TAGS OF SELECTED FINANCERS
     * @param {*} key
     * @returns
     */
    // getfinancersTags never used.
    // const getfinancersTags = (key) => {
    //     let findFinancierLabels = financerOption.filter((elm) =>
    //         financierFormListData[key]["financier_id"].includes("" + elm.value)
    //     );

    //     return findFinancierLabels.map((el, index) => (
    //         <span className="chips" key={index}>
    //             {el.label}
    //             <span className="mrg-l5">
    //                 <i
    //                     className="ic-clearclose"
    //                     onClick={() => clearSelectedFinancer(el.value, key)}
    //                 ></i>
    //             </span>
    //         </span>
    //     ));
    // };

    const getInputValues = (name, key) => {
        let financierFormListData = props.element.financier_config;
        return financierFormListData[key] && ''+financierFormListData[key][name];
    };

    /**
     * CHANGE HANDLER FOR DISBURSAL COUNT/AMOUNT
     * @param {*} name
     * @param {*} value
     * @param {*} financierIndex
     */
    const disbursal_Count_amount = (name,index,event,value) => {
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");
        let financierFormListData = props.element.financier_config;
        if(event.target.checked){
           
            financierFormListData[index][OrigName] = value; 
            props.element.financier_config = financierFormListData; 
            setRefresh(refresh + 1); 
            //props.onChange(name, financierFormListData[index][OrigName], "", props.element);    
        }
    }

    const { t } = useTranslation("common");

    let { tempErrors, element } = props;

    let allSelectedValues = {} 
 
    let financierFormListData = element.financier_config;
 
    return (
        <React.Fragment>
            {element.application_type && (
                <span className="config-heading">
                    {t("COMMON.FINANCIER_CONFIG_HEADING")}
                </span>
            )}
            {element.application_type &&
                selecteApplicationTypes &&
                selecteApplicationTypes.map((appType, index) => 
                   {
                    appType = appType.toLowerCase();

                    return(<div className="configuration-filed-outer" key={index}>
                            <table>
                                <thead>
                                    <tr key={index}>
                                        <th>
                                            {appType} {t("FORM.FINANCIER")+'*'}
                                        </th>
                                        {
                                             (element.form_config.hasOwnProperty('USER_INCENTIVE_TYPE_CARD_2_REGULAR'))
                                             ?
                                             <> 
                                                <th>{t("FORM.REGULAR") + '*'}</th>
                                                <th>&nbsp;</th>
                                             </>
                                             :
                                             <>
                                                <th>{t("FORM.SENIOR") + '*'}</th>
                                                <th>{t("FORM.REGULAR") + '*'}</th>
                                                <th>{t("FORM.JUNIOR") + '*'}</th>
                                                {
                                                    element.applicable_to?.includes('fixed_income') ?
                                                    <th>{t('COMMON.DISB_AMT_RANGE.FIXED_INCOME')}</th>: <th> </th>
                                                }
                                             </>

                                        }
                                     
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    {financierFormListData.map((elm, key) => {
                                        if (
                                            elm.application_type &&
                                            elm.application_type.toLowerCase() !==
                                            appType
                                        ){
                                            return <tr key={key}></tr>;
                                        }

                                        let financierExclude = [];
                                        if (allSelectedFinanciers[appType]) {
                                            financierExclude = allSelectedFinanciers[
                                                appType
                                            ].filter((type) => !elm.financier_id.includes(type));
                                        }

                                        const finacierOptionUpdated = financerOption && financerOption[appType] && financierExclude
                                                ? 
                                                    financerOption[appType].filter(
                                                        (ob) => (ob.value === 'all' || !financierExclude.includes("" + ob.value))
                                                    )
                                                
                                                : 
                                                    financerOption &&
                                                        financerOption[appType]
                                                        ? financerOption[appType]
                                                        : []
                                                ;

                                        const selectedValues = (financierFormListData[key] && financierFormListData[key]["financier_id"]) && financerOption[appType]
                                            ? 
                                                 
                                                    (financierFormListData[key]["financier_id"].length === finacierOptionUpdated.length) ? 
                                                        [ 
                                                            ...financerOption[appType].filter((opt) => financierFormListData[key]["financier_id"].includes("" + opt.value)) 
                                                        ] :  
                                                        
                                                        financerOption[appType].filter((opt) => financierFormListData[key]["financier_id"].includes("" + opt.value)) 
                                                
                                            : []

                                        allSelectedValues[appType] = (allSelectedValues[appType] || 0) + financierFormListData[key]["financier_id"].length
                                        return (
                                        <>
                                            {
                                              (props?.element?.form_config?.DISBURSAL_COUNT_AMOUNT)?         <DisbursalCountAmountCard elm={elm} index={key} element={element} disbursal_Count_amount={disbursal_Count_amount}  />:''      
                                            }
                                            
                                            <tr key={key}>
                                                <td>
                                                    <fieldset className="single-select">  
                                                        <div className="material" key={key}>
                                                            <input
                                                                type="hidden"
                                                                value={elm.application_type}
                                                                name="application_type"
                                                            />
                                                           
                                                            <MultiSelect
                                                                isMulti={true}
                                                                options={finacierOptionUpdated}
                                                                placeholder="Financer"
                                                                iconAfter="false"
                                                                getDropdownButtonLabel={(value) =>
                                                                    getDropdownButtonLabel(value, key)
                                                                }
                                                                value={
                                                                    selectedValues
                                                                }
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isDisabled={element.is_expired || false}
                                                                onChange={(value, event) =>
                                                                    changeHandler(
                                                                        "financier_id_" + key,
                                                                        event,
                                                                        value,
                                                                        key,
                                                                        appType,
                                                                        finacierOptionUpdated
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        {tempErrors && tempErrors["financier_id_" + key] ? (
                                                            <span className="error show">
                                                                {tempErrors["financier_id_" + key]}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </fieldset>
                                                </td>
                                                {
                                                     (element.form_config.hasOwnProperty('USER_INCENTIVE_TYPE_CARD_2_REGULAR'))
                                                     ?
                                                     <>
                                                       {/* <td>&nbsp;</td> */}
                                                        <td>
                                                    <fieldset>
                                                        <div className="material">
                                                           
                                                            <InputField
                                                                inputProps={{
                                                                    id: "regular",
                                                                    type: "text",
                                                                    name: "regular_amt",
                                                                    placeholder: " ",
                                                                    value: getInputValues("regular_amt", key) || "",
                                                                    className: "form-input",
                                                                    disabled: element.is_expired || false,
                                                                    percentage:(getInputValues("is_count_amount", key)===2)?1:0
                                                                }}
                                                                onChange={(e) => inputChangeHandler(e,key)}
                                                            />
                                                        </div>
                                                        {tempErrors && tempErrors["regular_amt_" + key] ? (
                                                            <span className="error show">
                                                                {tempErrors["regular_amt_" + key]}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </fieldset>
                                                </td> 
                                                      <td>&nbsp;</td>
                                                     </>
                                                     :
                                                     <>
                                                     <td>
                                                    <fieldset>
                                                        <div className="material">
                                                           
                                                            <InputField
                                                                inputProps={{
                                                                    id: "senior",
                                                                    type: "text",
                                                                    name: "senior_amt",
                                                                    placeholder: " ",
                                                                    value: getInputValues("senior_amt", key) || "",
                                                                    className: "form-input",
                                                                    disabled: element.is_expired || false,
                                                                    percentage:(getInputValues("is_count_amount", key)===2)?1:0
                                                                }}
                                                                onChange={(e) => inputChangeHandler(e,key)}
                                                            />
                                                        </div>

                                                        {tempErrors && tempErrors["senior_amt_" + key] ? (
                                                            <span className="error show">
                                                                {tempErrors["senior_amt_" + key]}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </fieldset>
                                                </td>
                                                <td>
                                                    <fieldset>
                                                        <div className="material">
                                                           
                                                            <InputField
                                                                inputProps={{
                                                                    id: "regular",
                                                                    type: "text",
                                                                    name: "regular_amt",
                                                                    placeholder: " ",
                                                                    value: getInputValues("regular_amt", key) || "",
                                                                    className: "form-input",
                                                                    disabled: element.is_expired || false,
                                                                    percentage:(getInputValues("is_count_amount", key)===2)?1:0
                                                                }}
                                                                onChange={(e) => inputChangeHandler(e,key)}
                                                            />
                                                        </div>
                                                        {tempErrors && tempErrors["regular_amt_" + key] ? (
                                                            <span className="error show">
                                                                {tempErrors["regular_amt_" + key]}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </fieldset>
                                                </td>
                                                <td>
                                                    <fieldset>
                                                        <div className="material">
                                                           
                                                            <InputField
                                                                inputProps={{
                                                                    id: "junior",
                                                                    type: "text",
                                                                    name: "junior_amt",
                                                                    placeholder: " ",
                                                                    value: getInputValues("junior_amt", key) || "",
                                                                    className: "form-input",
                                                                    disabled: element.is_expired || false,
                                                                    percentage:(getInputValues("is_count_amount", key)===2)?1:0
                                                                }}
                                                                onChange={(e) => inputChangeHandler(e,key)}
                                                            />
                                                        </div>
                                                        {tempErrors && tempErrors["junior_amt_" + key] ? (
                                                            <span className="error show">
                                                                {tempErrors["junior_amt_" + key]}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </fieldset>
                                                </td>
                                                {
                                                    element.applicable_to?.includes('fixed_income') ?
                                                    <td>
                                                    <fieldset>
                                                        <div className="material">
                                                           
                                                            <InputField
                                                                inputProps={{
                                                                    id: "fixed",
                                                                    type: "text",
                                                                    name: "fixed_amt",
                                                                    placeholder: " ",
                                                                    value: getInputValues("fixed_amt", key) || "",
                                                                    className: "form-input",
                                                                    disabled: element.is_expired || false,
                                                                    percentage:(getInputValues("is_count_amount", key)===2)?1:0
                                                                }}
                                                                onChange={(e) => inputChangeHandler(e,key)}
                                                            />
                                                        </div>
                                                        {tempErrors && tempErrors["fixed_amt_" + key] ? (
                                                            <span className="error show">
                                                                {tempErrors["fixed_amt_" + key]}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </fieldset>
                                                </td> : <td></td>
                                                }
                                                     </>

                                                }
                                                
                                            </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {
                                ((financerOption[appType] && allSelectedValues[appType] && financerOption[appType].length !== allSelectedValues[appType]) || !allSelectedValues[appType])
                                ?
                                    <div className="btn-save-remarks" id={JSON.stringify(allSelectedValues)}>
                                        <button
                                            onClick={(e) => {
                                                addNewFinancierConfig(appType);
                                            }}
                                            type="button"
                                            className="btn-primary"
                                        >{t("FORM.ADD_NEW")}</button>
                                        {tempErrors &&
                                            tempErrors["selectAllFinanciers" + appType] ? (
                                            <span className="error show">
                                                {tempErrors["selectAllFinanciers" + appType]}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                :
                                    ""
                            }
                            
                            <div>&nbsp;</div>
                        </div>) 
                   }
                )}
        </React.Fragment>
    );
};
export default FinancerConfigurationFilter;
