import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ConfirmationModal from "../common/ConfirmationModal";

class DescriptionCard extends Component {
  constructor(props) {
    super(props);
    let { element } = this.props;
    this.state = {
      description: element.description ? element.description : "",
      //  DeleteIncentivepopup: false,
      //  element_selected: {}
    };
  }

  onChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
    this.props.onChange("description", value, "", this.props.element);
  };

  showModalpopup = (value) => {
    this.setState({ DeleteIncentivepopup: true });

    document.body.classList.add("overflow-hidden");
  };

  confirmationModalResponse = (response) => {
    if (response.response) {
      this.props.onCancel(this.props.element);
     
    } else {
      this.setState({ DeleteIncentivepopup: false });
    }
    document.body.classList.remove("overflow-hidden");

    this.setState({ DeleteIncentivepopup: false });
  };

  render() {
    let { element, loading } = this.props;
    //let { description } = this.state;
    return (
      <React.Fragment>
        <div className="description-card-outer">
          <h3> {this.props.t("FORM.DESCRIPTION")}</h3>
          <textarea
            disabled={element.is_expired}
            value={element.description ? element.description : ""}
            name="description"
            onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
            rows={5}
            placeholder={this.props.t("FORM.DESCRIPTION_ENTER")}
            maxLength="250"
          ></textarea>
          <div className="btn-save-remarks">
            {
              (!element.is_expired)? 
                <button
                  disabled={loading || element.is_expired}
                  onClick={(e) => {this.props.onUpdate(element);}}
                  type="button"
                  className="btn-primary"
                >{" "}{this.props.t("FORM.UPDATE")}
                </button>:
                <button
                  disabled={loading || element.is_expired}
                  type="button"
                  className="btn-primary"
                >{" "}{this.props.t("FORM.UPDATE")}
                </button>
            }
            
            {
              (!element.is_expired)?
                  <button
                    disabled={element.is_expired}
                    onClick={() => {this.showModalpopup("DeleteIncentivepopup");}}
                    type="button"
                    className="btn-line m-sm-l"
                   >{" "}{this.props.t("FORM.CANCEL")}
                  </button>:
                  <button
                    disabled={element.is_expired}
                    type="button"
                    className="btn-line m-sm-l"
                    >{" "}{this.props.t("FORM.CANCEL")}
                  </button>
            }
            
          </div>
        </div>

        {this.state.DeleteIncentivepopup ? (
          <ConfirmationModal
            confirmationData={{
              show: this.state.DeleteIncentivepopup,
              confirmationText: this.props.t("MESSAGE.CANCEL_INCENTIVE"),
            }}
            confirmationModalResponse={(data) =>
              this.confirmationModalResponse(data)
            }
          />
        ) : null}
      </React.Fragment>
    );
  }
}
export default withTranslation("common")(DescriptionCard);
