import React from 'react';
import { useLocation } from 'react-router-dom';

const BeforeLogin = ({ component: Component }) => { 
    const location = useLocation();
    return (
        (location.pathname !== '/' && location.pathname.match(/^\/(login|logout|not-found)$/)) ?
            null :
            <Component />
    );
};

export default BeforeLogin;