import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../store/action";
import CurrencyInputField from "../common/CurrencyInputField";
import ConfirmationModal from "../common/ConfirmationModal";
import Pagination from "../common/Pagination";
import * as HELPER from "./../../config/helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FullPageLoader from "../common/FullPageLoader";
import DealerDetailsHistory from "./DealerDetailHistory";

const DealerDataTable = (props) => {
    const { t } = useTranslation("common");

    const [tabelShow, setTabelShow] = useState(false);
    const commonRedux = useSelector((state) => state.common);

    const [multiSelectedLoans, setMultiSelectedLoans] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
   
    const dispatch = useDispatch();
    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [subTableAcceptReject, setSubTableAcceptReject] = useState({
        status: false,
        msg: "",
    });
    const [showInputSave, setShowInputSave] = useState({ loanId: 0, key: "" });
    const [loading, setLoading] = useState(props.loading);
    const [paginationData, setPaginationData] = useState({});

    const [tableData, setTableData] = useState([]);
    const [selectAllDisabled, setSelectAllDisabled] = useState(false);
    const [actionId, setActionId] = useState();


    useEffect(() => { 
        
        if(commonRedux && commonRedux.approveButton && commonRedux.approveButton===true){
          //  setCheckedAll(true);
        }else{
            setMultiSelectedLoans([]);
            setCheckedAll(false);
        }
        setTableData(props.apiResponse.data);

        setPaginationData(props.apiResponse.pagination)

        setShowInputSave({ loanId: 0,key: "" })
        // eslint-disable-next-line
    }, [props.apiResponse])
    /**
     * TOGGLE SUB TABLE
     * @param {*} DealerId
     */
    
    const toggleTable = (DealerId) => {
        setTabelShow(0);

        if (tabelShow !== DealerId) {
            setTabelShow(DealerId);
        }
    };

    /**
     * HANDLE CHECKBOX CHECK/UNCHECK
     * @param {*} e 
     * @param {*} list 
     */
    const handleSingleCheckboxChange = async (e, list) => {
        let getMultiSelectedpayouts = multiSelectedLoans;
        if (e.target.checked) {
            if (
                multiSelectedLoans.length + 1 ===
                commonRedux.dealerDetails.data.length
            ) {
                setCheckedAll(true); 
                dispatch(CommonActions.checkedAllStatus(true));
            }
            getMultiSelectedpayouts = [...getMultiSelectedpayouts, list.dealer_reward_id]
            setMultiSelectedLoans(getMultiSelectedpayouts);
            dispatch(CommonActions.updateApproveButtonStatus(true));

        } else {
            setCheckedAll(false);
            dispatch(CommonActions.checkedAllStatus(false));
            let newSelctedId = multiSelectedLoans.filter((ob) => +ob !== +list.dealer_reward_id);
            //CREATE ARRAY OF IDs WHICH ARE DESELCTED
            getMultiSelectedpayouts = newSelctedId

            setMultiSelectedLoans(getMultiSelectedpayouts);

            let status = newSelctedId.length ? true : false;
            dispatch(CommonActions.updateApproveButtonStatus(status));
        }

        // props.multiSelectedLoans(multiSelectedLoans)
        dispatch(CommonActions.getMultiSelectedLoans(getMultiSelectedpayouts));

    };

    /**
     * AMOUNT CHANGE HANDLER FOR USER SCHEMES
     * @param {*} event
     * @param {*} key
     * @param {*} loanId
     */
    const handleChangeDealerAmount = async (event, key, id) => {
        
        let val = HELPER.removeCurrencyFormatting(event.target.value);
        let inputField = key.split("_"); //GET KEY NAME 
        
        let parentDealerId = inputField.pop();
        let tableDataObj = JSON.parse(JSON.stringify(tableData));

        let getDealer = tableDataObj.filter((dealer) => +dealer.dealer_id === +parentDealerId);
        //UPDATE VALUE IN STATE
      
        for (let row of getDealer[0].items) {
            if (+row.id === +inputField[1]) {
                row[inputField[0]] = val;
            }
        }
        setTableData(tableDataObj);
        setShowInputSave({ loanId: id, key });

    };
    /**
     * HANDLE TIMELINE POPUP
     * @param {*} action
     */
    const showModalViewTimeLine = (action, id) => {
        setActionId(id);
        setViewTimeLine(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    };

    const handleSubTableAcceptReject = (params) => {
        let { status } = params;
        setSubTableAcceptReject(params);
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }

    };

    const confirmationModalResponse = (params) => {
        let value = (subTableAcceptReject.approve) ? 1 : 2;
        //CALL SERVICE TO UPDATE STATUS
        if (params.response) {
            setLoading(true);
            dispatch(CommonActions.updateDealerStatus({ status: value, dealer_reward_id: subTableAcceptReject.parent_id.toString(), child_id: subTableAcceptReject.id, user_id: subTableAcceptReject.user_id, month : props.searchFilters?.month ? props.searchFilters.month  : new Date()})).then((res) => {
                setLoading(false);

                if (res.status === 200) {
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
                props.getDealerDetailsList(props.searchFilters);
            });
        }

        handleSubTableAcceptReject(params)
    }

    const handleRequestData = (params) => {
        props.getDealerDetailsList(params);
        setCheckedAll(false)
        setMultiSelectedLoans([]);
        dispatch(CommonActions.updateApproveButtonStatus(false));
    };

    const runPaginationFunc = () => { };

    const selectAll = (e) => {
        let selectedId = [] 
        
        if(commonRedux.dealerDetails && commonRedux.dealerDetails.data &&  commonRedux.dealerDetails.data.length){
            selectedId =  commonRedux.dealerDetails.data.map(ob=>ob.dealer_reward_id);
        } 
        if (e.target.checked) {
            setMultiSelectedLoans(selectedId);
            setCheckedAll(true);

            dispatch(CommonActions.getMultiSelectedLoans(selectedId));
            dispatch(CommonActions.checkedAllStatus(true));

        } else {
            setMultiSelectedLoans([]);
            setCheckedAll(false);
            dispatch(CommonActions.getMultiSelectedLoans([]));
            dispatch(CommonActions.checkedAllStatus(false));
        }
        dispatch(CommonActions.updateApproveButtonStatus(e.target.checked));

    };

    // If All Item is in PENDING then Select All Work
    useEffect(() => {
        for (var key of tableData) {
            if (key.status_name === "Pending") {
                setSelectAllDisabled(false);
            }
        }
         // eslint-disable-next-line
    }, []);


    /**
     * SAVE SCHEME AMOUNT
     * @param {*} params 
     * @returns 
     */
    const saveSchemeAmountHandler = (params) => {

        if (params.save) {
            const obj = {
                dealer_lead_id : params.item.id,
                dealer_reward_id : params.dealer_reward_id,
                loanId: params.item.loanId,
                dealer_id: params.parent_id,
                amount: params.amount,
                status : Number(params.item.status)
            };

            if (params.amount > 0) {
                setLoading(true)
                CommonActions.updateDealerAmount(obj).then((res) => {
                    setLoading(false)
                    if (res.status === 200) {
                        props.getDealerDetailsList(props.searchFilters);
                        toast.success(res.message);
                    } else {
                        setTableData(commonRedux.dealerDetails.data);
                        toast.error(res.message);
                    }
                }).catch(err => {
                    setLoading(false)
                    toast.error(err)
                });
            } else {
                setLoading(false);
                toast.error(t("VALIDATION.AMOUNT_GREATER_ZERO"))
                return false;
            }

        }

        if (!params.save) {
            setTableData(commonRedux.dealerDetails.data);
        }
        setShowInputSave({ loanId: 0 });
    };


    const getLoanStatusClass = (statusVal) => {
        let className = "approve-status";
        if(+statusVal === 0){
            className = "pending-status"
        }else if(+statusVal === 2){
            className = "reject-status"
        }else if(+statusVal === 3){
            className = "paid-status"
        }

        return className;
    }
    
    return (
        <>
            <div>
                <FullPageLoader show={loading} />
               
                <div className="dealer-detail-table">
                    <div className="data-table">
                        
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        {
                                            tableData.length > 0 && tableData[0]['status_name'] === "Pending" &&
                                            <li className="custom-control custom-checkbox">
                                                {!loading && <input
                                                    id="sli0"
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    onChange={(e) => selectAll(e)}
                                                    checked={checkedAll}
                                                    disabled={selectAllDisabled}
                                                />}
                                                <label
                                                    htmlFor="sli0"
                                                    className="custom-control-label"
                                                ></label>
                                            </li>

                                        }
                                        {t('DEALER.DEALER_ID')}
                                    </th>
                                    <th>{t('DEALER.NAME')}</th>
                                    <th>{t('DEALER.DISBURSAL')}</th>
                                    <th>{t('DEALER.UPLOADED_BY')}</th>
                                    <th>{t('DEALER.UPLOADED_ON')}</th>
                                    <th>{t('DEALER.AMOUNT')}</th>
                                    <th>{t('DEALER.STATUS')}</th>
                                    <th>{t('DEALER.ACTION')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // tableHtml
                                    !loading && tableData.length > 0 ?
                                        tableData.map((item) => (
                                            <React.Fragment  key={item.dealer_reward_id}>
                                                <tr
                                                    className={tabelShow === item.dealer_reward_id ? "active" : ""}
                                                    key={item.dealer_reward_id}
                                                >
                                                    <td onClick={() => toggleTable(item.dealer_reward_id)}>
                                                        {" "}
                                                        <span className="loan-id-arrow"></span>
                                                    </td>
                                                    <td>
                                                        {item['status_name'] === "Pending" && <li className="custom-control custom-checkbox">
                                                            <input
                                                                id="sli0"
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={(e) =>
                                                                    handleSingleCheckboxChange(e, item)
                                                                }
                                                                checked={(checkedAll)? checkedAll: multiSelectedLoans.includes(item.dealer_reward_id)}
                                                                disabled={
                                                                    (item.status_name === "Pending") ? false : true
                                                                }
                                                            />
                                                            <label
                                                                htmlFor="sli0"
                                                                className="custom-control-label"
                                                            ></label>
                                                        </li>}
                                                        {item.dealer_id}
                                                    </td>
                                                    <td>
                                                        <span className="truncate" title={item.name}>{item.name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="truncate" title={item.disbursal}>{item.disbursal}</span>
                                                    </td>
                                                    <td>
                                                        <span className="truncate" title={item.user_name}>{item.user_name}</span>
                                                    </td>
                                                    <td>
                                                        <span className="truncate" title={item.uploaded_on}>{item.uploaded_on}</span>
                                                    </td>
                                                    <td>{HELPER.addCurrencyFormatting(item.amount)}</td>
                                         
                                                <td>
                                                    <span className={getLoanStatusClass(item.status)}>{item.status_name}</span>
                                                </td>
                                                <td>
                                                    <ul className="action-btn">
                                                        <li className="" title={t('DEALER.HISTORY_TITLE')}>
                                                            <i
                                                                className="ic-history"
                                                                onClick={() => showModalViewTimeLine('DEALER_LIST', item.dealer_reward_id)}
                                                            ></i>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            {
                                                //SUB TABLE HTML
                                                tabelShow === item.dealer_reward_id  ? (
                                                    <tr className="subtable" key={"sub" + item.dealer_reward_id}>
                                                        <td colSpan="9">
                                                            <div className="wrapper">
                                                                
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>{t('DEALER.PRODUCT')}</th>
                                                                            <th>{t('DEALER.LOAN_ID_TITLE')}</th>
                                                                            <th>{t('DEALER.TYPE')}</th>
                                                                            <th>{t('DEALER.UPLOADED_BY')}</th>
                                                                            <th>{t('DEALER.UPLOADED_ON')}</th>
                                                                            <th>{t('DEALER.AMOUNT')}</th>
                                                                            <th>{t('DEALER.APPROVE_REJECT')}</th>
                                                                            <th>{t('DEALER.ACTION')}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {item.items && item.items.length ?
                                                                            item.items.map((ob, k) => {
                                                                                
                                                                                return (
                                                                                    <tr key={ob.id} >
                                                                                    <td>
                                                                                        {ob.product}
                                                                                     </td>
                                                                                     <td>
                                                                                        {ob.loanId}
                                                                                     </td>
                                                                                     <td>{ob.isCaclulated ? "Calculated" : "Bulk Upload"}</td>
                                                                                        <td>
                                                                                            {item.user_name}
                                                                                        </td>
                                                                                        <td>
                                                                                        {item.uploaded_on}
                                                                                        </td>
                                                                                        <td>
                                                                                            <CurrencyInputField
                                                                                                inputProps={{
                                                                                                    id: `amount_${ob.id}_${item.dealer_id}`,
                                                                                                    type: "text",
                                                                                                    name: "amount",
                                                                                                    placeholder: " ",
                                                                                                    value: `${ob.amount}`,
                                                                                                    className: `txt-value-input ${showInputSave.key ===
                                                                                                            `amount_${ob.id}_${item.dealer_id}`
                                                                                                            ? "hightlighted-txt"
                                                                                                            : ""
                                                                                                            }`,
                                                                                                    disabled: (+ob.status === 0) ? false : true,
                                                                                                    title : HELPER.addCurrencyFormatting(ob.amount),
                                                                                                    maxLength : 10,
                                                                                                    integer : 1
                                                                                                    }}
                                                                                                    onChange={(e) =>
                                                                                                        handleChangeDealerAmount(
                                                                                                            e,
                                                                                                            `amount_${ob.id}_${item.dealer_id}`,
                                                                                                            ob.id
                                                                                                        )
                                                                                                    }
                                                                                                />


                                                                                                {ob.status_name === "Pending" && showInputSave.loanId === ob.id &&
                                                                                                    showInputSave.key ===
                                                                                                    `amount_${ob.id}_${item.dealer_id}` && (
                                                                                                        <div className="amt-edit-opt">
                                                                                                            <button
                                                                                                                className="btn-primary"
                                                                                                                onClick={() =>
                                                                                                                    saveSchemeAmountHandler({
                                                                                                                        save: true,
                                                                                                                        item: { ...ob, user_id: item.user_id },
                                                                                                                        type: "amount",
                                                                                                                        amount: ob.amount,
                                                                                                                        parent_id: item.dealer_id,
                                                                                                                        dealer_reward_id : item.dealer_reward_id
                                                                                                                    })
                                                                                                                }
                                                                                                            >
                                                                                                            {t('DEALER.SAVE')}
                                                                                                            </button>
                                                                                                            <i
                                                                                                                className="ic-clearclose"
                                                                                                                onClick={() =>
                                                                                                                    saveSchemeAmountHandler({
                                                                                                                        save: false,
                                                                                                                    })
                                                                                                                }
                                                                                                            ></i>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    (ob.status_name === "Pending")
                                                                                                        ?
                                                                                                        <ul className="action-btn">
                                                                                                            <li>
                                                                                                                <i
                                                                                                                    className="ic-check"
                                                                                                                    onClick={() =>
                                                                                                                        handleSubTableAcceptReject({
                                                                                                                            status: true,
                                                                                                                            msg: t('DEALER.ACCEPT_MESSAGE') ,
                                                                                                                            id: ob.id,
                                                                                                                            parent_id: item.dealer_reward_id,
                                                                                                                            user_id: item.user_id,
                                                                                                                            approve: 1
                                                                                                                        })
                                                                                                                    }
                                                                                                                ></i>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <i
                                                                                                                    className="ic-clearclose"
                                                                                                                    onClick={() =>
                                                                                                                        handleSubTableAcceptReject({
                                                                                                                            status: true,
                                                                                                                            msg: t('DEALER.REJECT_MESSAGE'),
                                                                                                                            id: ob.id,
                                                                                                                            parent_id: item.dealer_reward_id,
                                                                                                                            user_id: item.user_id,
                                                                                                                            reject: 2

                                                                                                                        })
                                                                                                                    }
                                                                                                                ></i>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                        :
                                                                                                        <span className={+ob.status === 1 ? "approve-color" : "rejected-color"}>{ob.status_name}</span>
                                                                                                }

                                                                                            </td>
                                                                                        <td>
                                                                                            <ul className="action-btn">
                                                                                                <li className="" title="History">
                                                                                                    <i
                                                                                                        className="ic-history"
                                                                                                        onClick={() => showModalViewTimeLine('LOAN_LIST', ob.id)}
                                                                                                    ></i>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </td>
                                                                                        </tr>
                                                                                    );

                                                                                })
                                                                                : ""
                                                                            }

                                                                        </tbody>
                                                                </table>
                                                            </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={"sub" + item.dealer_id}></tr>
                                                    )
                                                }

                                            </React.Fragment>
                                        ))
                                        :    (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={9} className="no-data"> {t('DEALER.NO_DATA_TO_DISPLAY')}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination-outer">
                        {!loading && paginationData && paginationData.total ? <Pagination
                            paginateData={{
                                page : 1,
                                runPaginationFunc,
                                loading: loading,
                                tableData: tableData,
                                totalLengthWithoutPagination : 0,
                                paginationData,
                                filterData: props.searchFilters,
                            }}
                            action={"DealerDetails"}
                            sendRequestDataToParent={handleRequestData}
                        /> : ''}
                    </div>
                </div>


                {/* HISTORY */}
                {viewTimeLine &&
                    <DealerDetailsHistory
                        viewTimeLine={(viewTimeLine !== '')?true:false}
                        id={actionId}
                        type={viewTimeLine}
                        showModalViewTimeLine={showModalViewTimeLine}
                    />
                }

                {
                    <ConfirmationModal
                        confirmationData={{
                            show: subTableAcceptReject.status,
                            confirmationText: subTableAcceptReject.msg,
                        }}
                        confirmationModalResponse={confirmationModalResponse}
                    />
                }
            </div>
        </>
    );
};

export default DealerDataTable;
