import MasterService from './MasterService';


export const UserService = {
    ...MasterService,
    
    // createOtp(params){
    //     return this.post('/userAdminAuth/adminlogin',params)
    // },
    VerifyOtp(params){
        return this.post('/userAdminAuth/adminlogin',params)
    },
    createOtp(params){
        return this.post('/userAdminAuth/verifyLogin',params)
    },
    loginCaptcha(){
        return this.get('/userAdminAuth/getCaptchaImage')
    },
    submitBulkUploadIncentive(postData, headers) {
        return this.post('userAdmin/uploadCSV', postData, headers)
    },
    submitPdfUpload(postData, headers){
        return this.post('userAdmin/uploadPDF', postData, headers)
    },
    creatLoginOtp(params){
        return this.post('/userAdminAuth/verifyGoogleLogin',params)
    }

}