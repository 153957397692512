import { ARO_USER_ROLE } from "../../../config/constant";
const handleAddIncentive = (value, incentiveName, state, props) => { 
    let { incentive_scheme_selected, incentive_list, incentive_config } =
        state;
    let parent_name = incentiveName;    
    if (incentive_list[value]) {
        incentive_list[value].forEach((el) => {
            if (el.parent_name) {
                parent_name = el.parent_name;
            }
        })
    }
    

    let switch_value = value ? value : incentive_scheme_selected;
    let currentIncentiveCount = (incentive_list[switch_value]) ? incentive_list[switch_value].length : 0;
    let formConfig = (props.schemeList) ? props.schemeList.filter(ob => Number(ob.id) === Number(switch_value)) : [];
    
    let incentive_data = {
        user_role: "",
        applicable_to: "",
        application_type: "",
        description: "",
        image: "",
        is_deleted: 0,
        is_expired: 0,
        product_type: "",
        scheme_duration: "",
        scheme_type_id: switch_value,
        status: 1,
        parent_name: parent_name,
        temp_id: switch_value + '_' + currentIncentiveCount,
        form_config: (formConfig.length) ? formConfig[0].form_config : {}
    };
    switch_value = switch_value.toString();

   
    switch (true) {
        case (incentive_data.form_config.hasOwnProperty('EXTRA_BOOSTER')):
            incentive_data.extra_booster = [
                {
                    from_value: "",
                    to_value: "",
                    app_disbursed_range: "",
                },
                {
                    from_value: "",
                    to_value: "",
                    app_disbursed_range: "",
                },
                {
                    from_value: "",
                    to_value: "",
                    app_disbursed_range: "",
                },
                {
                    from_value: "",
                    to_value: "",
                    app_disbursed_range: "",
                },
            ];
            break;  
        case (incentive_data.form_config.hasOwnProperty('TOP3_PER_AREA_PER_MONTH')):
            incentive_data.top3_per_area_per_month = [];
            break;
        case (incentive_data.form_config.hasOwnProperty('PER_QUARTER')):
            incentive_data.per_quarter = [
                {
                    position: "1",
                    incentive_amount: "",
                },
                {
                    position: "2",
                    incentive_amount: "",
                },
                {
                    position: "3",
                    incentive_amount: "",
                },
                {
                    position: "4",
                    incentive_amount: "",
                },
                {
                    position: "5",
                    incentive_amount: "",
                }
            ];
            break;
        case (incentive_data.form_config.hasOwnProperty('APP_SUBMITTED')):
            incentive_data.app_submitted = [];
            incentive_config.agent_level.forEach((element) => {
                let temp_object = {
                    agent_name: element.agent_name,
                    agent_level_id: element.id,
                    incentive_amount: "",
                };
                incentive_data.app_submitted.push(temp_object);
            });
            break;
        case (incentive_data.form_config.hasOwnProperty('REDEEMBALE_POINTS')):
            incentive_data.redeemable_points = [];
            break;
        case (incentive_data.form_config.hasOwnProperty('EXTRA_INCENTIVE_CARD')):
            incentive_data.extra_incentive = [];
            incentive_config.agent_level.forEach((element) => {
                let temp_object = {
                    agent_name: element.agent_name,
                    agent_level_id: element.id,
                    add_disbursed_fee: "",
                    min_disbursed_fee: "",
                    add_incentive: "",
                };
                incentive_data.extra_incentive.push(temp_object);
            });
            break;
        case (incentive_data.form_config.hasOwnProperty('USER_INCENTIVE_TYPE_CARD')):
            incentive_data.user_incentive_type = [];
            incentive_config.user_level.forEach((element) => {
                let temp_object = {
                    user_level: element.id,
                    disb_minimum_count: "",
                    disb_per: "",
                    incentive_amount: "",
                };
                incentive_data.user_incentive_type.push(temp_object);
            });
            break;
        case (incentive_data.form_config.hasOwnProperty('ALLOWANCE_CARD')):
            incentive_data.user_allowances = []; 
            let userLevelList = incentive_config.user_level;  
            if(incentive_data.form_config.hasOwnProperty('USER_LEVEL_REGULAR')){
                userLevelList = userLevelList.filter(ob=>(ob.id ==='2'));
            } 
            userLevelList.forEach((element) => {
                let temp_object = {
                    user_name: element.user_name,
                    user_level: element.id,
                    allowance: "",
                    disb_minimum_count: ""
                };
                if(incentive_data.form_config.hasOwnProperty('OPERATOR')){
                    temp_object.operator=""
                }
                incentive_data.user_allowances.push(temp_object);
            });
            break;
        case (incentive_data.form_config.hasOwnProperty('USER_INCENTIVE_TYPE_CARD_2')):
            incentive_data.user_incentive_type_2 = [];

            if(incentive_data.form_config.hasOwnProperty('USER_INCENTIVE_TYPE_CARD_2_REGULAR')){ 
                let temp_object = {
                    user_level: 2,
                    disb_minimum_count: "",
                    total_disb_amt: ""
                };
                incentive_data.user_incentive_type_2.push(temp_object); 
            }else{
                incentive_config.user_level.forEach((element) => {
                    let temp_object = {
                        user_level: element.id,
                        disb_minimum_count: "",
                        total_disb_amt: ""
                    };
                    incentive_data.user_incentive_type_2.push(temp_object);
                }); 
            }
          
            break;

        case (incentive_data.form_config.hasOwnProperty('BM_CONFIGURATION_CARD')): 
            incentive_data.bm_config = [];
            incentive_config.user_level.forEach((element) => {
                let temp_object = {
                    bm_id: [],
                    target_amt: ""
                };
                incentive_data.bm_config = [temp_object];
            });

            break;  
    }
    if(incentive_data.form_config.hasOwnProperty('USER_TAGGING')){ 
        const { bro_tagging_list, aro_tagging_list } = incentive_config;
        const USER_TAGGING_LIST = +incentive_data.form_config.USER_ROLE === ARO_USER_ROLE ? aro_tagging_list : bro_tagging_list; 
       // const defaultSelectedTagging = USER_TAGGING_LIST?.filter(option => option.isDisabled)?.map(ob => ob.value).join(','); 
        let selectedValue =  [...USER_TAGGING_LIST];
        incentive_data.applicable_to = selectedValue?.splice(0,3)?.map(ob => ob.value).join(',');
    }
    if (incentive_data.form_config.hasOwnProperty('FINANCIER_CONFIGURATION_CARD')|| incentive_data.form_config.hasOwnProperty('SPECIAL_SCHEME_CARD')) {
        incentive_data.financier_config = [];
    }
    if (incentive_list[parseInt(switch_value)]) {
        incentive_list[parseInt(switch_value)].push(incentive_data);
    } else {
        incentive_list[parseInt(switch_value)] = [];
        incentive_list[parseInt(switch_value)].push(incentive_data);
    }
    return {incentive_list, incentive_scheme_selected};

};


export default handleAddIncentive;