import React, { useEffect, useState } from 'react'
import Modal from '../common/Modal';
import { useDispatch } from "react-redux";
import { CommonActions } from "../../store/action";
import DateFormat from 'dateformat';
import { useTranslation } from "react-i18next";

function DealerDetailsHistory(props) {
    const dispatch = useDispatch();
    const [historylist, setList] = useState([]);
    const [inputField, setInputField] = useState({
        id: '',
        comment: ''
    })
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common");


    useEffect(() => {
        setInputField({ ...inputField, id: props.id })
        getHistoryList();
        // eslint-disable-next-line
    }, [])

    const getHistoryList = () => {
        setLoading(true);
        let params = {};
        if(props.type === "LOAN_LIST"){
           params.dealer_lead_id = props.id; 
        } else {
            params.dealer_reward_id = props.id; 
        }
        dispatch(CommonActions.getDealerHistory(params)).then(function (response) {
            setLoading(false)
            if (response && response.status === 200) {
                setList(response.data);
            }
        }).catch(err=> setLoading(false));
    }



    return (
        <div>
            <div className="view-timeline-popup">
                <Modal show={props.viewTimeLine} handleClose={() => props.showModalViewTimeLine(false)} >
                    {
                        (historylist.length || props.type) ?
                            <div className="modal-header">
                                <h2>
                                    {
                                  
                                        t('DEALER.HISTORY')
                                    } 
                                    </h2>
                            </div>
                            : null
                    }
                    {
                        !loading && historylist.length ? 
                        <span>{t('DEALER.TIMELINE')}</span>
                        : null
                    }

                    <div className="modal-body" >
                        <div className="timeline-history-tabel">
                            <table>
                                <tbody>
                                    {
                                        !loading && historylist.length ? historylist.map((item) => {
                                            return (<tr key={item.id}>
                                                <td>
                                                    <span className="date">{DateFormat(item.created_at, "dd mmm")}</span>
                                                    <span className="time">{DateFormat(item.created_at, "h:MM TT")}</span>
                                                </td>
                                                <td>
                                                    <span className="heading wordWrap">{item.comment || 'NA'}</span>
                                                    {
                                                        item.created_by || item.created_user
                                                        ?
                                                            <span className="Subheading">{'By ' + (item.created_by || item.created_user)}</span>
                                                        :
                                                            ""
                                                    }
                                                </td>

                                            </tr>)
                                        })
                                        : 
                                            <tr><td colSpan={2} className="no-data">{loading ? t('DEALER.LOADING') : t('DEALER.NO_DATA_FOUND')}</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>


                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default DealerDetailsHistory