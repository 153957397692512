import React, {Component} from 'react';
// import * as HELPER from './../../config/helper';

class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            type: '',
            name: '',
            label:'',
            dataerror:'',
            value: this.props.inputProps.value || "",
            onChange: '',
            className:'form-control',
            maxdigits: 12,
            maxLength: "",
            percentage:0,
            integer : 0
        }
    }

    changeHandel = (event)=>{
        if (typeof this.props.onChange === 'function'  && this.props.inputProps && +this.props.inputProps.percentage === 1) {
            /* input for handle percentage less then 100 and upto 2 decimal values */
            let { value } = event.target
            const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/)
            if (validated && value <= 100) {
                this.setState({value: event.target.value})
                this.props.onChange(event)
            } 
        }
        else if(typeof this.props.onChange === 'function' && this.props.inputProps && +this.props.inputProps.integer === 1){
             /* input for handle integer without decimal from 0 to 1,000,000,000 */
             let { value } = event.target;
             const validated = /^\d*$/.test(value)
            if (validated && value <= 1000000000) {
                this.setState({value: event.target.value})
                this.props.onChange(event)
            }
        }
        else if (typeof this.props.onChange === 'function') {
            //ALLOW NUMBERS
            let pattern =/^(?:0(\.\d+)?|[1-9]\d*(\.\d+)?)$/;
            let dot=".";
            let tagetvalue=event.target.value;
            if(tagetvalue.indexOf(dot)!==-1 && tagetvalue.indexOf(dot)!==0){
               let valueSplit=tagetvalue.split('.');
               tagetvalue=valueSplit[0];
               
            }
            //REMOVE DOT
            //event.target.value = event.target.value.replace('.','')
        
            let maxdigitsCheck = this.props.maxdigits || this.state.maxdigits;
            //CHECK DIGIT COUNT
            if(tagetvalue.length > maxdigitsCheck){
                return false;
            }
            
            if(this.props.maxLength && event.target.value > this.props.maxLength){
                return false;
            }
            
            if(pattern.test(+event.target.value)){
                this.setState({value: event.target.value})
        
                this.props.onChange(event);
            }
        }

    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedState = prevState;
        if(prevState.id !== nextProps.inputProps.id || (nextProps.inputProps.value !== prevState.value)/*&& nextProps.inputProps.value*/){
            updatedState = {...nextProps.inputProps, value: (''+nextProps.inputProps.value)? nextProps.inputProps.value :""};
            
            return updatedState;
        }
        // } else if(prevState.id==='' && nextProps.inputProps.id){
        //     updatedState = {...nextProps.inputProps, value: HELPER.addCurrencyFormatting(nextProps.inputProps.value)};
        //     return updatedState;
        // }else if(nextProps.inputProps.value!=0){
        //     updatedState = {value: HELPER.addCurrencyFormatting(nextProps.inputProps.value)}; 
        //     return updatedState;
        // }
        return null;
    }

    chkValFocusOut = (event) => {
        if(event.target.value){
            // event.target.value =  HELPER.addCurrencyFormatting(event.target.value);
            this.setState({value: event.target.value})
            this.props.onChange(event)
    
        }
        
    }
    chkValFocus = (event) => {
        if(event.target.value){
            // event.target.value = HELPER.removeCurrencyFormatting(event.target.value)
            this.setState({value: event.target.value})
    
            this.props.onChange(event)
        }
        
    }
        
    render() {
        let props = this.props;
        return (

                <>  
                    <input {...this.state} onBlur={this.chkValFocusOut} onFocus={this.chkValFocus} onChange={this.changeHandel} className={props.inputProps.className || this.state.className} autoComplete="off"  />          
                </>
        );
    }
}

export default InputField;
