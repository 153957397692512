import React,{ useEffect, useState } from "react"; 
import MultiSelect from '../common/MultiSelect'; 
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputField from "../common/InputField";
import { BiPlusCircle, BiMinusCircle }   from "react-icons/bi";

const DisbursalAmountFinancierCard = (props) => {
    const [refresh, setRefresh] = useState(false);
    const [financerOption, setFinancerOption] = useState({});
    const [financierFormListData, setFinancierFormListData] = useState([
        { financier_id: [] },
    ]);
    const commonRedux = useSelector((state) => state.common);
    const [selecteApplicationTypes, setSelecteApplicationTypes] = useState([]);
    const [allSelectedFinanciers, setAllSelectedFinanciers] = useState({});
    
    useEffect(() => {
        if (
            selecteApplicationTypes &&
            selecteApplicationTypes.length &&
            commonRedux &&
            commonRedux.financierList &&
            commonRedux.financierList.length
        ) {
            let allFinanciers = {};
            for (let appType of selecteApplicationTypes) {
                appType = appType.toLowerCase();

                let getFinancierListProductWise = commonRedux.financierList.filter(
                    (elm) => appType === elm.application_type_name.toLowerCase()
                );
                if (getFinancierListProductWise && getFinancierListProductWise.length) {
                    allFinanciers[appType.toLowerCase()] =
                        getFinancierListProductWise[0]["financier"];
                }
            }
            setFinancerOption(allFinanciers);
        }
    }, [commonRedux, selecteApplicationTypes]);

    useEffect(() => {
        if (
            props.element &&
            props.element.application_type &&
            props.element.application_type !== selecteApplicationTypes.join(",")
        ) {
            setSelecteApplicationTypes(props.element.application_type.split(","));
        }

        if (props.element && props.element.financier_config) {
            let selectedFinanciers = {};
            for (let financier of props.element.financier_config) {
                if (!selectedFinanciers[financier["application_type"]])
                    selectedFinanciers[financier["application_type"]] = [];

                selectedFinanciers[financier["application_type"]] = [
                    ...selectedFinanciers[financier["application_type"]],
                    ...financier["financier_id"],
                ];
            }

            setAllSelectedFinanciers(selectedFinanciers);
        }
    }, [props]);  // eslint-disable-line


    useEffect(() => {
        if (
            props.element.financier_config &&
            props.element.financier_config.length
        ) {
            setFinancierFormListData(props.element.financier_config);
        }

    }, [props]);

    const getDropdownButtonLabel = (event, financierIndex) => {
        let { placeholderButtonLabel, value } = event;

        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            let actualVals = value.filter(val=> (val.value !== "all" && Object.keys(val).length)) 
            return `${actualVals.length} selected`;
        } else return `${placeholderButtonLabel}`;
    };

    /**
     * CHANGE HANDLER FOR SELECTBOX/INPUTBOX
     * @param {*} name
     * @param {*} value
     * @param {*} financierIndex
     */
    const changeHandler = 
        (name,event,value,financierIndex,appType,finacierOptionUpdated) => {

        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");

        if (event.action === "select-option" && event.option.value === "all") {

            financierFormListData[financierIndex][OrigName] = finacierOptionUpdated.map((elm) => "" + elm.value);
            
        }else if(event.action === "deselect-option" && event.option.value === "all"){
                financierFormListData[financierIndex][OrigName] = [];
        }
        else if (event.action === "deselect-option") {
            financierFormListData[financierIndex][OrigName] = (value.filter((o) => o.value !== "all" && o.value != event.option.value )).map(v=> ""+v.value);  // eslint-disable-line
        } 
        else {
            financierFormListData[financierIndex][OrigName] = value.map(
                (elm) => "" + elm.value
            );

            if (!allSelectedFinanciers[appType]) {
                allSelectedFinanciers[appType] = [];
            }
            allSelectedFinanciers[appType] = [
                ...allSelectedFinanciers[appType],
                ...financierFormListData[financierIndex][OrigName],
            ];
            
            value = value.filter(v=> Object.keys(v).length && v.value !== 'all');

            if (value.length === finacierOptionUpdated.length) {
                financierFormListData[financierIndex][OrigName] = [...financierFormListData[financierIndex][OrigName]];
            }
        }
        let selectedFinancier={}
        if(financierFormListData.length){
            let finacierData=financierFormListData.filter(el=>el.application_type===appType).map(el=>el.financier_id);

            const finacierFlat = [...new Set(finacierData.flat())];

            selectedFinancier[appType]=(finacierFlat.length)?finacierFlat:0;
        }
        setAllSelectedFinanciers(selectedFinancier);
        setFinancierFormListData(financierFormListData);
        setRefresh(refresh + 1);
        props.element.financier_config=financierFormListData
        
    };

    const inputChangeHandler = (name, value, financierIndex, appType) => {
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");

        if (!financierFormListData[financierIndex])
                financierFormListData[financierIndex] = {};

        financierFormListData[financierIndex][OrigName] = value.target.value;

        setFinancierFormListData(financierFormListData);
        setRefresh(refresh + 1);
        props.element.financier_config=financierFormListData
        
    }

    /**
     * ADD NEW FINANCER
    */
    const addNewFinancierConfig = (appType) => {
        let financierData=(financierFormListData.length)?financierFormListData:[];
       
        financierData.push({
            financier_id: [],
            min_disbursal_amt: "",
            incentive_per: "",
            application_type: appType.toLowerCase(),
            status:1,
            financier_flag:1
        });
        setFinancierFormListData(financierData);
        setRefresh(refresh + 1);       
        props.element.financier_config=financierData
    };


    const removeMore = (e,index,data)=>{  
        e.stopPropagation();
        let params = {
            key: index,
            index : props.element_index,
            type:'SPECIAL_SCHEME_CARD',
            scheme: props.element.scheme_type_id,
            is_deleted : (data.id)? data.id : false
        }  
        props.removeMoreIncentive(params)
    }
    
    const getInputValues = (name, key) => {
        return financierFormListData[key] && financierFormListData[key][name];
    };
  
    const { t } = useTranslation("common");
    let { tempErrors, element } = props;
    let allSelectedValues = {}
    return (
        <React.Fragment>
            {element.application_type && (
                <span className="config-heading">
                    {t("COMMON.DISB_AMT_RANGE.HEADING")}
                </span>
            )}
            {
                element.application_type &&
                selecteApplicationTypes &&
                selecteApplicationTypes.map((appType, index) =>{
                    appType = appType.toLowerCase();
                    if(financierFormListData.length){
                        let finacierData=financierFormListData.filter(el=>el.application_type===appType).map(el=>el.financier_id);

                        const finacierFlat = [...new Set(finacierData.flat())];

                        allSelectedValues[appType]=(finacierFlat.length)?finacierFlat.length:0;
                    }

                return(<div className="configuration-filed-outer" key={index}>
                    <table>
                        <thead>
                            <tr key={index}>
                                <th>
                                    {appType} {t("FORM.FINANCIER")+'*'}
                                </th>
                                <th>{t("FORM.MIN_DISBURSAL")}</th>
                                <th>{t("FORM.INCENTIVE") + " %"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {   
                                financierFormListData.map((elm, key) => {
                                if (
                                    elm.application_type &&
                                    elm.application_type.toLowerCase() !==
                                    appType
                                ){
                                    return <tr key={key}></tr>;
                                }

                                let financierExclude = [];
                                if (allSelectedFinanciers[appType]) {
                                    financierExclude = allSelectedFinanciers[
                                        appType
                                    ].filter((type) => !elm.financier_id.includes(type));
                                }

                                if (financerOption && financerOption[appType] && financerOption[appType][0].value !== 'all') {
                                    financerOption[appType] = financerOption[appType]    // eslint-disable-line
                                }

                                const finacierOptionUpdated = financerOption && financerOption[appType] && financierExclude? 
                                    financerOption[appType].filter((ob) => (ob.value === 'all' || !financierExclude.includes("" + ob.value))
                                    ): 
                                    financerOption &&financerOption[appType]? financerOption[appType]: [];

                                const selectedValues = (financierFormListData[key] && financierFormListData[key]["financier_id"]) && financerOption[appType]? 
                                    (financierFormListData[key]["financier_id"].length === finacierOptionUpdated.length) ? 
                                        [ 
                                            ...financerOption[appType].filter((opt) => financierFormListData[key]["financier_id"].includes("" + opt.value)) 
                                        ] :  
                                                            
                                        financerOption[appType].filter((opt) => financierFormListData[key]["financier_id"].includes("" + opt.value)): []

                                        return (
                                        <tr key={key}>
                                            <td>
                                                <fieldset className="single-select">  
                                                    <div className="material" key={key}>
                                                        <input
                                                            type="hidden"
                                                            value={elm.application_type}
                                                            name="application_type"
                                                        />
                                                            
                                                        <MultiSelect
                                                        isMulti={true}
                                                        options={finacierOptionUpdated}
                                                        placeholder="Financer"
                                                        iconAfter="false"
                                                        getDropdownButtonLabel={(value) =>
                                                        getDropdownButtonLabel(value, key)}
                                                        value={selectedValues}
                                                        isDisabled={element.is_expired || false}
                                                        onChange={(value, event) =>
                                                        changeHandler("financier_id_" + key,event,value,key,appType,finacierOptionUpdated)}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        />
                                                    </div>

                                                    {tempErrors && tempErrors["financier_id_" + key] ? 
                                                    (
                                                        <span className="error show">
                                                            {tempErrors["financier_id_" + key]}
                                                        </span>
                                                    ) : ("")}
                                                </fieldset>
                                            </td> 
                                            <td>
                                                <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                        id: "min_disbursal_amt",
                                                        type: "text",
                                                        name: "min_disbursal_amt",
                                                        placeholder: " ",
                                                        value:getInputValues("min_disbursal_amt", key),
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                        }}
                                                        onChange={(e, i, q, u) => inputChangeHandler("min_disbursal_amt_" + key, e, key)}
                                                    />
                                                </div>
                                                {
                                                    tempErrors && tempErrors["min_disbursal_amt_" + key] ? 
                                                    (
                                                        <span className="error show">
                                                            {tempErrors["min_disbursal_amt_" + key]}
                                                        </span>
                                                    ) : ("")
                                                }
                                                </fieldset>
                                            </td> 
                                            <td>
                                                <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                        id: "incentive_per",
                                                        type: "text",
                                                        name: "incentive_per",
                                                        placeholder: " ",
                                                        value:getInputValues("incentive_per", key),
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                        }}
                                                        onChange={(e, i, q, u) => inputChangeHandler("incentive_per_" + key, e, key)}
                                                    />
                                                </div>
                                                {
                                                    tempErrors && tempErrors["incentive_per_" + key] ? 
                                                    (
                                                        <span className="error show">
                                                            {tempErrors["incentive_per_" + key]}
                                                        </span>
                                                    ) : ("")
                                                }
                                                </fieldset>
                                            </td>
                                            {
                                               (!elm.financier_flag)? 
                                               <td>
                                                {
                                                (element.is_expired)?<span className="add-minus"><BiPlusCircle size="1.4em"/></span>:((financerOption[appType] && allSelectedValues[appType] && financerOption[appType].length !== allSelectedValues[appType]) || !allSelectedValues[appType])?<span className="add-minus" onClick={(e) => {
                                                    addNewFinancierConfig(appType);
                                                }}><BiPlusCircle size="1.4em"/></span>:''  
                                                }
                                               </td>:
                                               <td>
                                                {
                                                    (element.is_expired)?<span className="add-minus"> <BiMinusCircle size="1.4em"/></span>:<span className="add-minus" onClick={(e)=> removeMore(e,key,elm)}> <BiMinusCircle size="1.4em"/></span>
                                                }
                                                </td>
                                            }
                                            <td></td>       
                                        </tr>       
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    {
                        tempErrors &&
                        tempErrors["selectAllFinanciers" + appType] ? 
                        (
                            <span className="error show">
                                {tempErrors["selectAllFinanciers" + appType]}
                            </span>
                        ) : ("")
                    } 
                </div>)
               })
            }
        </React.Fragment>  
    );    
}


export default DisbursalAmountFinancierCard;
