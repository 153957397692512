import React, { Component } from 'react';
import { ReceivedDateRange } from './ReceivedDateRange'
import { DisbursedDateRange } from './DisbursedDateRange'  
import MultiSelect from '../common/MultiSelect'

class BasicDateRangeFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh : 1
        };
    }

    getDropdownButtonLabel = (event) => {
        let { placeholderButtonLabel, value } = event; 
        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            let actualVals = value.filter(val => (val.value !== 0 && val.value !== 'all' && Object.keys(val).length))
            return `${actualVals.length} selected`;
        } else return `${placeholderButtonLabel}`;
    };

    changeHandler = (itemOb,event) => {  
        if(event.option.value ==='all'){
            if(event.action ==='select-option'){ 
                this.props.fieldItem.financier_id = this.props.financerOption.map(ob=>ob.value)
            }else{
                this.props.fieldItem.financier_id = []
            }
        }else{
            this.props.fieldItem.financier_id =  itemOb.map(ob=>ob.value); 
        }  
        this.setState({refresh : this.state.refresh + 1})
    }


    render() {

        let { element, tempErrors ,tab,fieldItem,appType,financerOption} = this.props 
        let errorList = {} 
        let finacierList =  []
        let selectedValues = []
        if(financerOption){
            finacierList = financerOption
            selectedValues = finacierList.length ? finacierList.filter(ob=>fieldItem.financier_id.includes(ob.value)):[]  
            errorList = tempErrors;
        }else{
            errorList = (tempErrors && tempErrors.date_range && tempErrors.date_range[tab-1])? tempErrors.date_range[tab-1] :{} 
        } 
        
        return (
            <React.Fragment>
                <div className="basic-detail-filter m-lg-t">
                    {
                        fieldItem &&
                            <fieldset className="single-select">  
                                <div className="material">
                                    <MultiSelect
                                        isMulti={true}
                                        options={finacierList}
                                        getDropdownButtonLabel={(value) =>
                                            this.getDropdownButtonLabel(value)
                                        }
                                        value={selectedValues}
                                        placeholder={appType.toUpperCase()+' Financier *'}
                                        onChange={(value, event) => this.changeHandler(value, event)} 
                                        placeholderButtonLabel=""
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isDisabled={element.is_expired || false}
                                    /> 

                                    {
                                        (errorList && errorList.financier_id) ? <span className="error show">{errorList.financier_id}</span> : <span>&nbsp;</span>
                                    }
                                </div> 
                        </fieldset>
                    }
                    
                    <fieldset className="">
                        <div className="material">
                            <ReceivedDateRange element={element} onChange={(e, i, q, u) => { this.props.onChange(e, i, q, u) }} tab={tab} tempErrors ={errorList} financerOption={financerOption} appType={appType}/> 
                        </div>
                    </fieldset>
                    <fieldset className="">
                        <div className="material">
                            <DisbursedDateRange element={element} onChange={(e, i, q, u) => { this.props.onChange(e, i, q, u) }} tab={tab} tempErrors ={errorList} financerOption={financerOption} appType={appType}/> 
                        </div>
                    </fieldset>
                </div>
            </React.Fragment>
        )
    }
}
export default BasicDateRangeFilter;