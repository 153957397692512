import React from "react";  
import { FcGoogle } from "react-icons/fc";   
import {REDIRECT_URL} from '../../../src/config/constant';
 

function GoogleLoginComponent(props) {   
  const handleClick = (e) => { 
    e.preventDefault();
    // Perform any additional logic here if needed
    window.location.href = REDIRECT_URL + window.location.href;
  }; 
    return (
        <button type="button" className="btn-line m-md-l btn-google" >
            <FcGoogle className=""/>
            <a className="m-xs-l" href={REDIRECT_URL + window.location.href} onClick={handleClick}>
                Sign in with Google
            </a> 
        </button> 
    );
}

export default GoogleLoginComponent;
