import Modal from "../common/Modal";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import ToggleSwitch from "../common/ToggleSwitch";
import Select from 'react-select';
import { CommonActions } from "../../store/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch, } from "react-redux";
import FullPageLoader from "../common/FullPageLoader";


function FinancierGroupModel(props) {
    const { t } = useTranslation('common');
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [financerConfigList , setFinancerConfigList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const commonRedux = useSelector((state) => state.common);
    const dispatch = useDispatch();



    useEffect(() => {
        getFinancierConfigGroup();
        // eslint-disable-next-line
    }, []);


    const showModalPopUp = async() => {
        setShowPopup(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalPopUp = async() => {
        setShowPopup(false)
        document.body.classList.remove("overflow-hidden");
    }

    const changeHandler = async(value, data, name) => {
        let financerData = financerConfigList;
        const index = financerData.findIndex(obj => obj.financier_id === data.value);
        if (index !== -1) {
            if(name === "group"){
                financerData[index].group = value.value;
            } else if(name === "toggle"){
                financerData[index].status = financerData[index].status ? 0 : 1;
            }
        } else {
            financerData.push({financier_id : data.value, status : 1, group : 1 });
        }
        
        setFinancerConfigList(financerData);
        setRefresh(refresh+1);
    }

    const saveFinancierConfig = async(e) => {
        let isValid = true;
        if (isValid) {
            setLoading(true)
            CommonActions.saveFinancierConfig({financier_group : financerConfigList}).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    getFinancierConfigGroup();
                    toast.success(res.message);
                } else {
                    setFinancerConfigList(commonRedux.financierGroupConfig.data);
                    toast.error(res.message);
                }
            }).catch(err => {
                setLoading(false)
                toast.error(err)
            });
        }
    }

    const getFinancierConfigGroup = ()=>{
        setLoading(true);
        dispatch(CommonActions.getFinancierConfigGroup()).then((res) => {
            if (res && res.status === 200) {
                setFinancerConfigList(res.data?.financerList);
                setGroupList(res.data?.groupList)
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    
    let { financierList } = props;
    return (

        <>
            <button className="btn-line" onClick={showModalPopUp}>{t("DEALER.FINANCIER_TITLE")}</button>
            <FullPageLoader show={loading} />
            <div className="model-popup-outer dealer-financer-popup">
                <Modal show={showPopup} handleClose={() => hideModalPopUp()}>
                    <div className="modal-header">
                        <h2>{t("DEALER.FINANCIER_HEADING")}</h2>
                    </div>
                    <div className="modal-body">
                    <ul className="financier-group-config">
                        {financierList.length > 0 ? financierList.map((financer, idx) => {
                            let financerObj = financerConfigList.find((el) => +el.financier_id === +financer.value);
                            return (
                              <li key={idx}>
                                <span className="financer-group-label">{financer.label}</span>
                                <div className="on-off-switch m-sm-l m-sm-r">
                                    {
                                       <ToggleSwitch
                                       status={financerObj?.status || 0}
                                       onChange={(val) => {changeHandler(val, financer, "toggle")}}
                                       />
                                    }
                                </div>
                                <fieldset className="single-select">
                                <div className="material">
                                    <Select
                                        value={ financerObj ? groupList.filter((grp) => grp.value === financerObj.group) : groupList.filter((grp) => grp.value === 1)}
                                        options={groupList}
                                        placeholder={""}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        isDisabled={!financerObj?.status || false}
                                        onChange={(val) => changeHandler(val, financer, "group")}
                                    />
                                </div>
                                </fieldset>
                              
                              </li>
                           
                            )
                        })
                        :  (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={9} className="no-data"> {t('DEALER.NO_DATA_FOUND')}</td></tr>

                        }
                         </ul>
                    
                    </div>
                    {
                            financierList.length > 0 &&
                            <div className="modal-footer m-lg-t t-center">
                                {
                                    !loading
                                        ?
                                        <button className="btn-primary"  onClick={(e) => {saveFinancierConfig(e)}}>{t("DEALER.SAVE_DETAILS")}</button>
                                        :
                                        <button className="btn-primary" type='button'>{t('DEALER.PLEASE_WAIT')}</button>

                                }
                            </div>
                        }
                </Modal>
            </div>

        </>

    );
}

export default FinancierGroupModel;