import React, { useEffect,useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../../store/action";
import FullPageLoader from '../../common/FullPageLoader'; 
import DateFormat from 'dateformat';


const BulkHistoryTable = (props) => {
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch(); 
    const uploadhistoryList = useSelector((state) => { 
        return (state.common && state.common.getCsvUploadHistory) ? (state.common.getCsvUploadHistory.data).filter(Data=> +Data.upload_type=== +props.fileUploadType) : [] 
        
    })
   
    useEffect(() => {
        if (!uploadhistoryList.length) {
            getCsvUploadHistory();
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCsvUploadHistory = () => {
        setLoading(true)
        dispatch(CommonActions.getCsvUploadHistory()).then(res=> setLoading(false)).catch(err=> setLoading(false));
    };

    const exportXls = (file) => {
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <React.Fragment>
          <FullPageLoader show={loading} />
        <div className='bulk-history-sec'>
            <h3>{t('COMMON.UPLOAD_HISTORY')}</h3>

            <div className='bulk-history-table'>
                {
                    uploadhistoryList.length
                    ?
                        <div className='data-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t('FORM.DATE_TIME')}</th>
                                        <th>{t('FORM.WHO')}</th>
                                        <th>{t('FORM.RECORDS')}</th>
                                        <th>{t('FORM.ACTION')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        uploadhistoryList.map((history,index)=>
                                            <tr key= {index}>
                                                <td> {DateFormat(history.created_at, "dd mmm yyyy, h:MM TT")}</td>
                                                <td>
                                                    <span className='truncate'>{history.created_by}</span></td>
                                                <td>
                                                    <ul className='record-list-data'>
                                                        <li>{history.success_count}</li>
                                                        <li>{history.failure_count}</li>
                                                        <li>{history.total_count}</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul className='action-btn'>
                                                        <li>
                                                            <i className='ic-save_alt' onClick={() => exportXls(history.processed_s3_path)}></i>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    :
                        <div className='history-no-data-txt'>
                            No History Made Yet
                        </div>
                }
                
            </div>

        </div>
        </React.Fragment>
    )

}
export default BulkHistoryTable;