import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';
import { BiPlusCircle, BiMinusCircle }   from "react-icons/bi";
import AppRangeUpload from "./DisbursalRangeUpload";
 
class AppsBoosterCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disbursalFormListData: []
        };
    }
   

      // Method to update the state
    setDisbursalFormListData = (newData) => {
        this.setState({ disbursalFormListData: newData });
    } 

    // Lifecycle method for mounting and updating
    componentDidMount() {
        this.updateDisbursalFormListData(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.updateDisbursalFormListData(this.props);
        }
    }

    updateDisbursalFormListData(props) { 
        if (
            props.element.disbursal_role &&
            props.element.disbursal_role.length
        ) {
            this.setDisbursalFormListData(props.element.disbursal_role);
        }  
    }

    onChange = (event) => {
        let { name, value } = event.target
        this.setState({ [name]: value })
        this.props.onChange(name, value, '', this.props.element) 
    }
    addMore = (e,index)=>{  
        e.stopPropagation(); 
        let params = {
            key: index+1,
            index : this.props.element_index,
            type:'EXTRA_BOOSTER',
            scheme: this.props.element.scheme_type_id
        } 
        this.props.addMoreIncentive(params)
    }
    removeMore = (e,index,data)=>{  
        e.stopPropagation();
        let params = {
            key: index,
            index : this.props.element_index,
            type:'EXTRA_BOOSTER',
            scheme: this.props.element.scheme_type_id,
            is_deleted : (data.id)? data.id : false
        }  
        this.props.removeMoreIncentive(params)
    }

   

    render() {
        let { element, tempErrors,element_index } = this.props    
        let mainTitle = this.props.t('FORM.APP_DISBURSED_RANGE');
        let thirdRow = this.props.t('FORM.APP_DISBURSED_RANGE');
        let isPercentage = 0;
        if(element.form_config.REDEEMBALE_POINTS){
            mainTitle = this.props.t('FORM.REDEEMABLE_POINTS');
            thirdRow = this.props.t('FORM.TOTAL_VALUE');
        }
        if(element.form_config.ROOSTER){
            mainTitle = this.props.t('FORM.DISBURSAL_AMOUNT_RANGE');
            thirdRow = this.props.t('FORM.INCENTIVE_PER');
            isPercentage = 1
        }
        return (
            <React.Fragment>
                <div className='app-booster-outer add-incentive-table'>
                    <div className="add-banner-btn-outer">
                        <div className="add-banner-txt">
                            <h3>{mainTitle}</h3>
                        </div>
                        {
                            element.form_config.APP_RANGE_UPLOAD &&
                            <div className="browse-btn">
                                <AppRangeUpload setDisbursalFormListData={(e) => this.setDisbursalFormListData(e)} element={element}></AppRangeUpload>
                            </div>
                        } 
                    </div> 
                    <table>
                        <thead>
                            <tr>
                                <th>{this.props.t('FORM.FROM') +'*'}</th>
                                <th></th>
                                <th>{this.props.t('FORM.TO') +'*'}</th>
                                <th>{thirdRow+'*'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {element.extra_booster && element.extra_booster.length > 0 && element.extra_booster.map((data, index) => (
                                <tr key={(element.id)?element.id+'_'+element_index+'_'+index: element_index+'_'+index}>
                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "from_value_" + index,
                                                        type: "text",
                                                        name: "from_value_" + index,
                                                        placeholder: " ",
                                                        value:  data.from_value,
                                                        className: "form-input",
                                                        disabled: element.is_expired || false
                                                    }}
                                                    onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                />
                                            </div>
                                            {
                                                (tempErrors && tempErrors["from_value_" + index]) ? <span className="error show">{tempErrors["from_value_" + index]}</span> : ''
                                            }
                                        </fieldset>
                                    </td>
                                    <td><span className='to-txt'>to</span></td>
                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "to_value_" + index,
                                                        type: "text",
                                                        name: "to_value_" + index,
                                                        placeholder: " ",
                                                        value:  data.to_value,
                                                        className: "form-input",
                                                        disabled: element.is_expired || false
                                                    }}
                                                    onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                />
                                            </div>
                                            {
                                                (tempErrors && tempErrors["to_value_" + index]) ? <span className="error show">{tempErrors["to_value_" + index]}</span> : ''
                                            }
                                        </fieldset>
                                    </td>
                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "app_disbursed_range_" + index,
                                                        type: "text",
                                                        name: "app_disbursed_range_" + index,
                                                        placeholder: " ",
                                                        value:   data.app_disbursed_range,
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                        percentage: isPercentage
                                                    }}
                                                    onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                                />
                                            </div>
                                            {
                                                (tempErrors && tempErrors["app_disbursed_range_" + index]) ? <span className="error show">{tempErrors["app_disbursed_range_" + index]}</span> : ''
                                            }
                                        </fieldset>
                                    </td>
                                    {
                                        index === 3 ? 
                                        <td>
                                            <span className="add-minus" onClick={(e)=>this.addMore(e,index)}><BiPlusCircle size="1.4em"/> </span>
                                        </td>
                                        : 
                                        (index >3)?
                                        <td>
                                            <span className="add-minus" onClick={(e)=>this.removeMore(e,index,data)}> <BiMinusCircle size="1.4em"/></span>
                                        </td>
                                        : null
                                    }
                                   
                                </tr>
                            ))}

                        </tbody>
                    </table>



                </div>

            </React.Fragment>
        )
    }
} 
export default withTranslation('common')(AppsBoosterCard);
