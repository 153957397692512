 
const handleChange = (name, value, is_confirm, element, state) => {
    let { incentive_list } = state;
    element.scheme_type_id = '' + element.scheme_type_id || 1
    let index = incentive_list[parseInt(element.scheme_type_id)].findIndex(
        (p) => p.id === element.id
    );
    if (index === -1) return false;
    let splitted_name = name.split("_");
    
    // --------- Date Range ---------//
    let inputFieldName = JSON.parse(JSON.stringify(splitted_name));
    let back_index =  inputFieldName[inputFieldName.length-1] - 1;
    inputFieldName.pop();
    let front_name = inputFieldName.join('_')
     
    if (name === "banner_name" || name === "description" || name === "image" || name === "file") {
        incentive_list[parseInt(element.scheme_type_id)][index][name] = value;
    } else if (name === "application_type") {
        let appType = (value.length) ? value.map(ob => ob.value).join(',') : '';

        incentive_list[parseInt(element.scheme_type_id)][index][name] = appType;

        if (element.form_config.ADD_FINANCIER) {
            let financierArr = (element.financier_config && element.financier_config !== undefined) ? element.financier_config : [];
            let financierNewArr = [];
            for (let data of value) {
                let { value} = data;

                const index = financierArr.findIndex(object => {
                    return object.application_type === value
                });
                if (financierArr.length > 0 && index !== -1) {
                    financierNewArr.push(financierArr[index]);
                } else {
                    let finacierobj = { application_type: value};
                    financierNewArr.push(finacierobj);
                }

            }

                incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = financierNewArr;
        }
        if (element.form_config.ELIGIBLE_FINANCIER) {
            let financierArr = (element.elg_financier && element.elg_financier !== undefined) ? element.elg_financier : [];
            let financierNewArr = [];
            for (let data of value) {
                let { value} = data;

                const index = financierArr.findIndex(object => {
                    return object.application_type === value
                });
                if (financierArr.length > 0 && index !== -1) {
                    financierNewArr.push(financierArr[index]);
                } else {
                    let finacierobj = { application_type: value};
                    financierNewArr.push(finacierobj);
                }

            } 
            incentive_list[parseInt(element.scheme_type_id)][index]['elg_financier'] = financierNewArr;
        }
        if (element.form_config.EXTRA_BOOSTER_TYPE2) {
       
            let financierConfigArr = (element.financier_config && element.financier_config !== undefined) ? element.financier_config : [];
            let financierConfigNewArr = [];
            let dateRangeArray = [] 
            let selectAppType = value.map(ob=>ob.value.toLowerCase()); 
            for (let data of value) {
                let { value } = data;

                const index = financierConfigArr.findIndex(object => {
                    return object.application_type.toLowerCase() === value.toLowerCase()
                });
                let tempData = {
                    financier_id: [],
                    junior_amt: "",
                    regular_amt: "",
                    senior_amt: "",
                    fixed_amt:"",
                    application_type: value.toLowerCase(),
                    min_disbursal : "",
                    status: 1,
                    tab:1
                }
                if (financierConfigArr.length > 0 && index !== -1) {
                    // financierConfigNewArr.push(financierConfigArr[index]);
                } else {
                    if(element.financier_config && element.financier_config.length){
                        let tabData = [...new Set(element.financier_config.map(item => item.tab))];
                        for(let i=1;i<=tabData.length;i++){
                            tempData = {...tempData,tab:i}
                            financierConfigNewArr.push(tempData);
                        }  
                    }else{ 
                        financierConfigNewArr.push(tempData);
                    } 
                }  
                dateRangeArray.push({ 
                    financier_id: [],
                    receive_from : '',
                    receive_to : '',
                    disb_from : '',
                    disb_to : '',
                    application_type:value.toLowerCase(),
                    tab:1,
                })
            }
            
            if(element.form_config.DATE_RANGE_FILTER) {
                incentive_list[parseInt(element.scheme_type_id)][index]['date_range'] = dateRangeArray
            } 
            incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = financierConfigArr.map(ob=>{ 
                return { ...ob,status: selectAppType.includes(ob.application_type) ? 1:0 }
            })
            if (financierConfigNewArr.length > 0) {
                incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = [...incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'], ...financierConfigNewArr];
            } 
        }else if (element.form_config.FINANCIER_CONFIGURATION_CARD) {
       
            let financierConfigArr = (element.financier_config && element.financier_config !== undefined) ? element.financier_config : [];
            let financierConfigNewArr = [];
            let dateRangeArray = [] 
            let selectAppType = value.map(ob=>ob.value.toLowerCase()); 
            for (let data of value) {
                let { value } = data;

                const index = financierConfigArr.findIndex(object => {
                    return object.application_type.toLowerCase() === value.toLowerCase()
                });
                if (financierConfigArr.length > 0 && index !== -1) {
                    // financierConfigNewArr.push(financierConfigArr[index]);
                } else {
                    if(element.financier_config && element.financier_config.length){
                        let tabData = [...new Set(element.financier_config.map(item => item.tab))];
                        for(let i=1;i<=tabData.length;i++){
                            let finacierobj = {
                                financier_id: [],
                                junior_amt: "",
                                regular_amt: "",
                                senior_amt: "",
                                fixed_amt:"",
                                application_type: value.toLowerCase(),
                                status: 1,
                                tab:i
                            };
                            if(element.form_config.DISBURSAL_COUNT_AMOUNT && 
                                +element.form_config.DISBURSAL_COUNT_AMOUNT===1){
                                finacierobj.is_count_amount=1
                            }
                            financierConfigNewArr.push(finacierobj);
                        }  
                    }else{
                        let finacierobj = {
                            financier_id: [],
                            junior_amt: "",
                            regular_amt: "",
                            senior_amt: "",
                            fixed_amt:"",
                            application_type: value.toLowerCase(),
                            status: 1,
                            tab:1
                        };
                        if(element.form_config.DISBURSAL_COUNT_AMOUNT && +element.form_config.DISBURSAL_COUNT_AMOUNT===1){
                            finacierobj.is_count_amount=1
                        }
                        financierConfigNewArr.push(finacierobj);
                    }
                }  
            }
            if(element.date_range && element.date_range.length){
                dateRangeArray=element.date_range;
            }else{
                dateRangeArray.push({  
                    receive_from : '',
                    receive_to : '',
                    disb_from : '',
                    disb_to : ''
                })
            } 
            if(element.form_config.DATE_RANGE_FILTER) {
                incentive_list[parseInt(element.scheme_type_id)][index]['date_range'] = dateRangeArray
            } 
            incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = financierConfigArr.map(ob=>{ 
                return { ...ob,status: selectAppType.includes(ob.application_type) ? 1:0 }
            })
            if (financierConfigNewArr.length > 0) {
                incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = [...incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'], ...financierConfigNewArr];
            } 
        }

        if (element.form_config.SPECIAL_SCHEME_CARD) {
            let financierConfigArr = (element.financier_config && element.financier_config !== undefined) ? element.financier_config : [];
            let financierConfigNewArr = [];
            let selectAppType = value.map(ob=>ob.value.toLowerCase());
            for (let data of value) {
                let { value} = data;
                const index = financierConfigArr.findIndex(object => {
                    return object.application_type.toLowerCase() === value.toLowerCase()
                });
                if (index === -1) {
                    let finacierobj = {
                        financier_id: [],
                        min_disbursal_amt: "",
                        incentive_per: "",
                        application_type: value.toLowerCase(),
                        status: 1,
                        financier_flag:0,

                    };
                    financierConfigNewArr.push(finacierobj); 
                }
            }
            
            incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = financierConfigArr.map(ob=>{ 
                return { ...ob,status: selectAppType.includes(ob.application_type) ? 1:0 }
            })
            if (financierConfigNewArr.length > 0) {
                incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = [...incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'], ...financierConfigNewArr];
            } 
        }

    }else if (element.form_config.ADD_FINANCIER && name === "financier") {
       let financierArr = (element.financier_config) ? element.financier_config : [];
        if (value.length){
            for (let data of value) {
                let { application_type, financier_id } = data;

                const index = financierArr.findIndex(object => {
                    return object.application_type === application_type
                });
                if (index !== -1) {
                    let financier_ids = (financier_id.length) ? financier_id.map(ob => ob.value).join(',') : '';
                    financierArr[index].financier_id = financier_ids;
                }
            }
            if (financierArr.length > 0) {
                incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = financierArr;
            }
        }
    }else if (element.form_config.ELIGIBLE_FINANCIER && name === "financier") {
        let financierArr = (element.elg_financier) ? element.elg_financier : [];
         if (value.length){
             for (let data of value) {
                 let { application_type, financier_id } = data;
 
                 const index = financierArr.findIndex(object => {
                     return object.application_type === application_type
                 });
                 if (index !== -1) {
                     let financier_ids = (financier_id.length) ? financier_id.map(ob => ob.value).join(',') : '';
                     financierArr[index].financier_id = financier_ids;
                 }
             }
             if (financierArr.length > 0) {
                 incentive_list[parseInt(element.scheme_type_id)][index]['elg_financier'] = financierArr;
             }
         }
     } else if (name === "status") {

        if (value === false && !is_confirm) {
        } else {
            incentive_list[parseInt(element.scheme_type_id)][index][name] = value;
        }
    } else if ((name === "disbursed_date" || name === "received_date") && element.form_config.BASIC_DATE_FILTER) {

        incentive_list[parseInt(element.scheme_type_id)][index][name] = (value);

    }
    else if (element.form_config.DATE_RANGE_FILTER && (front_name ==='received_date' || front_name ==='disbursed_date')) {  
        if(front_name ==='received_date'){ 
            incentive_list[parseInt(element.scheme_type_id)][index]['date_range'][back_index] = {
                ...incentive_list[parseInt(element.scheme_type_id)][index]['date_range'][back_index],
                receive_from : value[0],
                receive_to : value[1]
            }  
        }
        if(front_name ==='disbursed_date'){ 
            incentive_list[parseInt(element.scheme_type_id)][index]['date_range'][back_index] = {
                ...incentive_list[parseInt(element.scheme_type_id)][index]['date_range'][back_index],
                disb_from : value[0],
                disb_to : value[1]
            } 
        } 
    }else if (element.form_config.USER_INCENTIVE_TYPE_CARD &&
        (splitted_name[0] === "incentive" || splitted_name[0] === "disb")) {
        let element_index = name.charAt(name.length - 1);
        let element_name = name.slice(0, -2);
        incentive_list[parseInt(element.scheme_type_id)][index].user_incentive_type[
            element_index
        ][element_name] = value;
    } else if (element.form_config.USER_INCENTIVE_TYPE_CARD_2 &&
        (splitted_name[0] === "disb" || splitted_name[0] === "total")) {
        let element_index = name.charAt(name.length - 1);
        let element_name = name.slice(0, -2);
        incentive_list[parseInt(element.scheme_type_id)][index].user_incentive_type_2[element_index][element_name] = value;
    } else if (splitted_name[0] === "incentive") {
        let element_index = name.charAt(name.length - 3);
        let element_child_index = name.charAt(name.length - 1);

        if (element.form_config.PER_QUARTER) {
            incentive_list[parseInt(element.scheme_type_id)][index].per_quarter[
                splitted_name[2]
            ].incentive_amount = value;
        }
        if (element.form_config.APP_SUBMITTED_CARD) {
            incentive_list[parseInt(element.scheme_type_id)][index].app_submitted[
                splitted_name[2]
            ].incentive_amount = value;
        }
        if (element.form_config.TOP3_PER_AREA_PER_MONTH) {
            incentive_list[parseInt(element.scheme_type_id)][
                index
            ].top3_per_area_per_month[element_index].items[
                element_child_index
            ].incentive_amount = value;
        }
        if (element.form_config.INCENTIVE_AMOUNT_CARD) {
            incentive_list[parseInt(element.scheme_type_id)][index].incentive_amount = value;
        }
    } else if (
        element.form_config.EXTRA_INCENTIVE_CARD &&
        (splitted_name[0] === "add" || splitted_name[0] === "min")
    ) {
        let element_index = name.charAt(name.length - 1);
        let element_name = name.slice(0, -2);
        incentive_list[parseInt(element.scheme_type_id)][index].extra_incentive[
            element_index
        ][element_name] = value;
    } else if (
        element.form_config.REDEEMABLE_POINTS_CARD &&
        (splitted_name[0] === "total" || splitted_name[0] === "redeemable")
    ) {
        let element_index = name.charAt(name.length - 1);
        let element_name = name.slice(0, -2);
        if (splitted_name[0] === "total") {
            if (
                incentive_list[parseInt(element.scheme_type_id)][index]
                    .redeemable_points[element_index]
            ) {
                incentive_list[parseInt(element.scheme_type_id)][
                    index
                ].redeemable_points[element_index][element_name] = parseInt(value);
            } else {
                incentive_list[parseInt(element.scheme_type_id)][
                    index
                ].redeemable_points[element_index] = {};
                incentive_list[parseInt(element.scheme_type_id)][
                    index
                ].redeemable_points[element_index][element_name] = parseInt(value);
            }
        } else if (splitted_name[0] === "redeemable") {
            if (
                incentive_list[parseInt(element.scheme_type_id)][index]
                    .redeemable_points && incentive_list[parseInt(element.scheme_type_id)][index]
                        .redeemable_points[element_index]
            ) {
                incentive_list[parseInt(element.scheme_type_id)][
                    index
                ].redeemable_points[element_index]["redeemable_points"] = parseInt(
                    value.value
                );
            } else {
                if (incentive_list[parseInt(element.scheme_type_id)][
                    index
                ].redeemable_points) {
                    incentive_list[parseInt(element.scheme_type_id)][
                        index
                    ].redeemable_points[element_index] = {};
                    incentive_list[parseInt(element.scheme_type_id)][
                        index
                    ].redeemable_points[element_index]["redeemable_points"] = parseInt(
                        value.value
                    );
                }
            }
        }
    } else if (
        element.form_config.APP_BOOSTER_CARD &&
        (splitted_name[0] === "from" ||
            splitted_name[0] === "to" ||
            splitted_name[0] === "app")
    ) { 
        let nameSplit = name.split('_');
        let element_index =  nameSplit[nameSplit.length-1]; 
        nameSplit.pop();
        let element_name = nameSplit.join('_');
        incentive_list[parseInt(element.scheme_type_id)][index].extra_booster[
            element_index
        ][element_name] = value;
    }else if (element.form_config.FINANCIER_CONFIGURATION_CARD && element.form_config.DATE_RANGE_FILTER && name === 'financier_config') {
        incentive_list[parseInt(element.scheme_type_id)][index].financier_config = value;
    } else if (
        element.form_config.FINANCIER_CONFIGURATION_CARD &&
        (splitted_name[0] === "financier" || splitted_name[0] === "senior" || splitted_name[0] === "junior" || splitted_name[0] === "regular")
    ) {
        let nameSplit = name.split('_');
        let element_index =  nameSplit[nameSplit.length-1]; 
        nameSplit.pop();
        let element_name = nameSplit.join('_');
        if (!incentive_list[parseInt(element.scheme_type_id)]) incentive_list[parseInt(element.scheme_type_id)] = [];
        if (!incentive_list[parseInt(element.scheme_type_id)][index]["financier_config"]) {
            incentive_list[parseInt(element.scheme_type_id)][index]["financier_config"] = []
        }

        if (!incentive_list[parseInt(element.scheme_type_id)][index].financier_config[element_index]) {
            incentive_list[parseInt(element.scheme_type_id)][index].financier_config[element_index] = {}
        }
        incentive_list[parseInt(element.scheme_type_id)][index].financier_config[
            element_index
        ][element_name] = value;
    } else if (
        element.form_config.ALLOWANCE_CARD &&
        (splitted_name[0] === "allowance" || splitted_name[0] === "disb" || splitted_name[0]==="operator")
    ) {
        let nameSplit = name.split('_');
        let element_index =  nameSplit[nameSplit.length-1]; 
        nameSplit.pop();
        let element_name = nameSplit.join('_');
        incentive_list[parseInt(element.scheme_type_id)][index].user_allowances[
            element_index
        ][element_name] = value;


    } else if (name === 'addNewFinacierConfig') { 
        incentive_list[parseInt(element.scheme_type_id)][index].financier_config.push(value);
    }else if (name === 'addNewUserAllowance') { 
        incentive_list[parseInt(element.scheme_type_id)][index].user_allowances.push(value);
    }

    else if(name === 'addNewBMConfig'){
        incentive_list[parseInt(element.scheme_type_id)][index].bm_config.push(value);
    }else if (element.form_config.BOOSTER_PER_CARD && name === 'booster_per') {
        incentive_list[parseInt(element.scheme_type_id)][index].booster_per = value;
    }else if(front_name === 'is_count_amount' && element?.form_config?.DISBURSAL_COUNT_AMOUNT && +element?.form_config?.DISBURSAL_COUNT_AMOUNT===1){
        if(element.form_config.FINANCIER_CONFIGURATION_CARD){
            let configindex= name[name.length-1];

            let financierConfigArr = (element.financier_config && element.financier_config !== undefined) ? (element?.financier_config).map((el,key)=>{ return { ...el,junior_amt:(key===+configindex)?'':el.junior_amt,regular_amt:(key===+configindex)?'':el.regular_amt,senior_amt:(key===+configindex)?'':el.senior_amt,fixed_amt:(key===+configindex)?'':el.fixed_amt,financier_id:(key===+configindex)?[]:el.financier_id}
            }) : [];
 
            incentive_list[parseInt(element.scheme_type_id)][index]['financier_config'] = financierConfigArr
        
        }
          
    }else  { 
        let product_type = ''
        if(name === 'product_type' && value.length > 0){
            for (let index = 0; index < value.length; index++) {
                let comma = product_type ? ',':'';
                product_type = product_type + comma + value[index].value;
            }
            element.product_type = product_type
        }
        // Reset Selected Financier
        if (name === 'product_type' && incentive_list[parseInt(element.scheme_type_id)][index].product_type !== value.value && incentive_list[parseInt(element.scheme_type_id)][index].financier_config) {
            incentive_list[parseInt(element.scheme_type_id)][index].financier_config = incentive_list[parseInt(element.scheme_type_id)][index].financier_config.map((val, index) => {
                return { ...val, financier_id: [] }
            })
        }  
        if(name === 'product_type' && [4,28].includes(+element.scheme_type_id) && value.value ==='UCF'){
            let areaList = element.top3_per_area_per_month.slice(0,4); 
            incentive_list[parseInt(element.scheme_type_id)][index].top3_per_area_per_month = areaList;
        }
        if(name === 'scheme_duration' && incentive_list[parseInt(element.scheme_type_id)][index].form_config.BM_CONFIGURATION_CARD){
            incentive_list[parseInt(element.scheme_type_id)][index]['mid_month'] = '';
        }

        if(name === 'scheme_duration' && value?.start){
            incentive_list[parseInt(element.scheme_type_id)][index]["scheme_duration_date"] = value.start; 
        } 
        let data = value.value    
        if(value.length > 0){
            data = product_type
        }
        incentive_list[parseInt(element.scheme_type_id)][index][name] = data;
        
        if(name === 'applicable_to' && value){ 
            incentive_list[parseInt(element.scheme_type_id)][index]["applicable_to"] = value; 
            let userIncentive2 = incentive_list[parseInt(element.scheme_type_id)][index]?.['user_incentive_type_2']
            if(userIncentive2 && !value.includes('fixed')){
                incentive_list[parseInt(element.scheme_type_id)][index]['user_incentive_type_2'] = userIncentive2.filter(ob=>ob.user_level !=4)
            }
            if(userIncentive2 && value.includes('fixed')){
                if(userIncentive2.length !=4){
                    incentive_list[parseInt(element.scheme_type_id)][index]['user_incentive_type_2'] = [...userIncentive2,{
                        user_level:4,disb_minimum_count:''
                    }] 
                } 
            } 
        } 
    }
    return incentive_list
};


export default handleChange;