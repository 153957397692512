import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputField from '../common/InputField';
import { CommonActions } from "../../store/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import FullPageLoader from "../common/FullPageLoader";
import ConfirmationModal from "../common/ConfirmationModal";
import CurrencyInputField from "../common/CurrencyInputField";
import * as HELPER from "./../../config/helper";

const CategoryConfigTable = (props) => {
    const { t } = useTranslation("common");
    const [categoryData, setCategoryData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [tempErrors, setTempErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const commonRedux = useSelector((state) => state.common);
    const [showConfirmation, setShowConfirmation] = useState(false);


    useEffect(() => {
        if(props.categoryDetails){
            setCategoryData(props.categoryDetails)
        }
    }, [props]);


    const handleInputs = async (event, category, i) => {
        let {name, value} = event.target;
        let categories = categoryData;
        
        if(name !== "min_disbursal" || name !== "max_disbursal"){
            value = HELPER.removeCurrencyFormatting(value);
        }
        categories[i][name] = value || "";
        let tempErr = tempErrors;
        if(i<4 && name === "max_disbursal"){
            tempErr["max_disbursal_" + i] = (categories[i]["min_disbursal"] >= value && value) ? t("VALIDATION.HIGHER_MAX_DISB") : "";
            categories[i+1]["min_disbursal"] = value ? (+value + 1) : "";
            
        } 
        if(Object(tempErr).length){
            setTempErrors(tempErr);
        }
        setCategoryData(categories);
        setRefresh(refresh+1)
    };

    const handleConfirmation = async (e) => {
        let isValid = await dataValidation(categoryData);
        if(isValid){
            setShowConfirmation(true);
        }
    };

    const saveCategoryConfig = async(data) =>{
        if(data.response){
            setLoading(true)
            CommonActions.saveCategoryDetails({configData : categoryData}).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    props.getDealerConfigDetails();
                    toast.success(res.message);
                } else {
                    setCategoryData(commonRedux.dealerCategoryConfig.data);
                    toast.error(res.message);
                }
            }).catch(err => {
                setLoading(false)
                toast.error(err)
            });
        }
        setShowConfirmation(false);
    }

    const dataValidation = async(data) => {
        let formIsValid = true;
        let tempErrors = {};
        let len = data.length;
        for(let i = 0; i< len ; i++){
            if(i<(len - 1) && (data[i].max_disbursal === "" || data[i].max_disbursal === null) ){
                formIsValid = false;
                tempErrors["max_disbursal_" + i] = t("VALIDATION.MAX_DISBURSAL");
            }
            if(data[i].group1 === ""){
                formIsValid = false;
                tempErrors["group1_" + i] = t("VALIDATION.AMOUNT");
            }
            if(data[i].group2 === ""){
                formIsValid = false;
                tempErrors["group2_" + i] =  t("VALIDATION.AMOUNT");
            }
            if(data[i].group3 === ""){
                formIsValid = false;
                tempErrors["group3_" + i] =  t("VALIDATION.AMOUNT");
            }
            if(data[i].group4 === ""){
                formIsValid = false;
                tempErrors["group4_" + i] =  t("VALIDATION.AMOUNT");
            }
            if(data[i].group5 === ""){
                formIsValid = false;
                tempErrors["group5_" + i] = t("VALIDATION.AMOUNT");
            }
            if(i > 0 && data[i].min_disbursal && data[i].min_disbursal <= data[i-1].max_disbursal){
                formIsValid = false;
                tempErrors["max_disbursal_" + (i-1)] = t("VALIDATION.ENTER_MAX_DISB");
            }

            if(i< len-1 && data[i].min_disbursal && data[i].min_disbursal >= data[i].max_disbursal){
                formIsValid = false;
                tempErrors["max_disbursal_" + i] = t("DEALER.MAX_DISBURSAL_GREATER");
            }
        }
        
        setTempErrors(tempErrors);
        return formIsValid;
    }

    return (
        <>
            <div>
            <FullPageLoader show={loading} />
                <div className="">
                    <div className="data-table bg-white">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t("DEALER.CATEGORY")}</th>
                                    <th>{t("DEALER.NAME")}</th>
                                    <th>{t("DEALER.MINIMUM")}</th>
                                    <th>{t("DEALER.MAXIMUM")}</th>
                                    <th>{t("DEALER.GROUP")} 1</th>
                                    <th>{t("DEALER.GROUP")} 2</th>
                                    <th>{t("DEALER.GROUP")} 3</th>
                                    <th>{t("DEALER.GROUP")} 4</th>
                                    <th>{t("DEALER.GROUP")} 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categoryData.length ? 
                                    categoryData.map((cat, index) => (
                                        <React.Fragment  key={cat.category}>
                                    <tr className={"active"}>
                                        <td>{cat.category}</td>
                                        <td>{cat.name}</td>
                                        <td>  
                                            <div className="material">
                                            <InputField
                                                inputProps={{
                                                    id: "min_disbursal_" + index,
                                                    type: "text",
                                                    name: "min_disbursal",
                                                    placeholder: " ",
                                                    value: (cat.min_disbursal && index=== categoryData.length-1) ? '>='+cat.min_disbursal : cat.min_disbursal,
                                                    className: "form-input",
                                                    disabled:  true,
                                                    integer : 1
                                                }}
                                            />
                                            </div>
                                        </td>
                                        <td>
                                        <div className="material">
                                            { index < 4 && 
                                            <InputField
                                                inputProps={{
                                                    id: "max_disbursal_" + index,
                                                    type: "text",
                                                    name: "max_disbursal",
                                                    placeholder: " ",
                                                    value: cat.max_disbursal || "",
                                                    className: "form-input",
                                                    disabled:  false,
                                                    integer : 1
                                                }}
                                                onChange={(e) => {handleInputs(e, cat, index)}}
                                            />}
                                            {
                                                (tempErrors && tempErrors["max_disbursal_" + index]) ? <span className="error show validation-error">{tempErrors["max_disbursal_" + index]}</span> : ''
                                            }
                                            </div>
                                        </td>
                                        <td>
                                        <div className="material">
                                           <CurrencyInputField
                                                inputProps={{
                                                    id: "group1_" + index,
                                                    type: "text",
                                                    name: "group1",
                                                    placeholder: " ",
                                                    value: cat.group1,
                                                    className: "form-input",
                                                    disabled:  false,
                                                    integer : 1,
                                                    maxLength : 10
                                                }}
                                                onChange={(e) => {handleInputs(e, cat, index)}}
                                            />
                                            {
                                                (tempErrors && tempErrors["group1_" + index]) ? <span className="error show validation-error">{tempErrors["group1_" + index]}</span> : ''
                                            }
                                            </div>
                                        </td>
                                        <td>
                                        <div className="material">
                                        <CurrencyInputField
                                                inputProps={{
                                                    id: "group2_" + index,
                                                    type: "text",
                                                    name: "group2",
                                                    placeholder: " ",
                                                    value: cat.group2,
                                                    className: "form-input",
                                                    disabled:  false,
                                                    integer : 1,
                                                    maxLength : 10
                                                }}
                                                onChange={(e) => {handleInputs(e, cat, index)}}
                                            />
                                             {
                                                (tempErrors && tempErrors["group2_" + index]) ? <span className="error show validation-error">{tempErrors["group2_" + index]}</span> : ''
                                            }
                                            </div>
                                        </td>
                                        <td>
                                        <div className="material">
                                        <CurrencyInputField
                                                inputProps={{
                                                    id: "group3_" + index,
                                                    type: "text",
                                                    name: "group3",
                                                    placeholder: " ",
                                                    value: cat.group3,
                                                    className: "form-input",
                                                    disabled:  false,
                                                    integer : 1,
                                                    maxLength : 10
                                                }}
                                                onChange={(e) => {handleInputs(e, cat, index)}}
                                            />
                                             {
                                                (tempErrors && tempErrors["group3_" + index]) ? <span className="error show validation-error">{tempErrors["group3_" + index]}</span> : ''
                                            }
                                            </div>
                                        </td>
                                        <td>
                                        <div className="material">
                                        <CurrencyInputField
                                                inputProps={{
                                                    id: "group4_" + index,
                                                    type: "text",
                                                    name: "group4",
                                                    placeholder: " ",
                                                    value: cat.group4,
                                                    className: "form-input",
                                                    disabled:  false,
                                                    integer : 1,
                                                    maxLength : 10
                                                }}
                                                onChange={(e) => {handleInputs(e, cat, index)}}
                                            />
                                              {
                                                (tempErrors && tempErrors["group4_" + index]) ? <span className="error show validation-error">{tempErrors["group4_" + index]}</span> : ''
                                            }
                                            </div>
                                        </td>
                                        <td>
                                        <div className="material">
                                        <CurrencyInputField
                                                inputProps={{
                                                    id: "group5_" + index,
                                                    type: "text",
                                                    name: "group5",
                                                    placeholder: " ",
                                                    value: cat.group5,
                                                    className: "form-input",
                                                    disabled:  false,
                                                    integer : 1,
                                                    maxLength : 10
                                                }}
                                                onChange={(e) => {handleInputs(e, cat, index)}}
                                            />
                                             {
                                                (tempErrors && tempErrors["group5_" + index]) ? <span className="error show validation-error">{tempErrors["group5_" + index]}</span> : ''
                                            }
                                            </div>
                                        </td>
                                        
                                    </tr>
                                    </React.Fragment>
                                    ))
                                    :  (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={9} className="no-data"> {t('DEALER.NO_DATA_FOUND')}</td></tr>
                                    
                                }
                            </tbody>
                          
                            
                        </table>
                        {
                                categoryData.length &&
                                <div className="category-config-save p-lg">
                                    <button className="btn-line btn-primary" onClick={(e) => handleConfirmation(e)}>{t("DEALER.SAVE_DETAILS")}</button>
                                </div>
                            }
                        </div>
                </div>

                {showConfirmation ? (
                    <ConfirmationModal
                        confirmationData={{
                            show: showConfirmation,
                            confirmationText: t("DEALER.SURE_TO_SAVE_CONFIG")
                        }}
                        confirmationModalResponse={(data) =>
                            saveCategoryConfig(data)
                        }
                    />
                ) : null}
            </div>
        </>
    );
};

export default CategoryConfigTable;
