import Incentive from "../view/incentive/Incentive"
import Login from "../view/auth/Login"
import Dashboard from "../view/dashboard/Dashbaord" 
import PayoutDetails from "../view/dashboard/PayoutDetails"
import PointConfiguration from "../view/point-configuration/PointConfiguration"
import DealerDetails from "../view/incentive-dealer/DealerDetails"
import ScoreConfiguration from "../view/score-configuration/ScoreConfiguration"
import NotFoundPage from "../view/common/404Page";
import { useRoutes } from "react-router-dom";
import DealerIncentiveConfig from "../view/dealer-config/DealerIncentiveConfig"

// const Router = [
// 	{ path: "/", exact: true, component: Incentive },
// 	{ path: "/incentive", component: Incentive },
// 	{ path: "/login", component: Login },
// 	{ path: "/dashboard", component: Dashboard },
// 	{ path: "/payout-details", component: PayoutDetails },
//     { path: "*", component: NotFoundPage }
// ];

// export default Router;
function AppRoutes() {
	let Router = useRoutes([
		{ path: "/",  element: <Incentive /> },
		{ path: "/incentive", element: <Incentive /> },
		{ path: "/login", element: <Login /> },
		{ path: "/dashboard", element: <Dashboard /> },
		{ path: "/payout-details", element: <PayoutDetails /> }, 
		{ path: "/point-configuration", element: <PointConfiguration/>},
		{ path: "/dealer-incentive", element: <DealerDetails/>},
		{ path: "/dealer-incentive-config", element: <DealerIncentiveConfig/>},
		{ path: "/score-configuration", element: <ScoreConfiguration/>},
		{ path: "*", element: <NotFoundPage /> },
	  ]);
	return Router;
}


  export default AppRoutes;
