/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { API_URL, APIV } from './../config/constant';
import secureStorage from './../config/encrypt';
import { toast } from 'react-toastify';

const Axios = axios.create();
Axios.interceptors.response.use(
    (response) => {
        if (response.data && response.data.status !== 200) {
            //console.error(`${response.data.status} - ${response.data.message}`, response.data)
            // if(response.data.status == 400
            //     window.commonErrorToast(response.data.message)
            //     toast.error(`${response.data.status} - ${response.data.message}`);
        }
        return response;
    },
    (error) => {
        // handle error
        if (error.response) {
            //console.error(`${error.response.status} - ${error.response.statusText}`, error.response)
            if (+error.response.status === 503) {
                // toast.error(`${error.response.status} - ${error.response.statusText}`);
            } else if (+error.response.status === 401) {
                secureStorage.removeItem('authUserInfo');
                window.location.href = '/login';
                // if(error.response.data.errors == "TokenEmptyInCache") 
                //toast.error(`${error.response.data.status} - ${error.response.data.message}`);
                // window.location.href = '/logout';
            }
            
        }

        return error.response;
    });

window.commonErrorToast = function (error) {
    if (typeof error == "string") toast.error(error);
    else if (error.message) toast.error("Helllo", error.message);
}
export default {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        return (secureStorage.getItem('authUserInfo') && secureStorage.getItem('authUserInfo')['token'])
            ? secureStorage.getItem('authUserInfo')['token']
            : ''
    },
    delete(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.delete(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    post(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
      //  headers['apikey'] = API_URL.API_KEY;
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.post(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    put(URL, body, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.put(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    get(URL, query, headers) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        //headers['apikey'] = API_URL.API_KEY;
        URL = (URL[0] === '/') ? URL.substr(1) : URL;
        return Axios.get(`${API_URL.GETWAY_API}${URL}`,
            { params: query, headers })
    },
    uploadFilesToS3(postdata, headers) {
        headers = headers || {};
        return axios.post(
          `${API_URL.COMMON_GATEWAY_API}core/commonservice/docs_upload`,
          postdata,
          { headers }
        );
      },
}