import React from 'react';

const NotFoundPage = () => (

    <div className="page-not-found white-bg">
        <div className="container">
                <h1 className='t-center'>404 </h1>         
                <h2 className='t-center'>Page not found </h2>         

        </div>

    </div>

);

export default NotFoundPage
