import React from 'react';
import Select from 'react-select';
import CustomValueContainer from '../common/CustomValueContainer' ;
import { withTranslation } from 'react-i18next'; 
 


const BasicDetails = (props) => {
    let  {element,configData,tempErrors}  = props;

    const sales_team = configData.product_type.filter(ob=>ob.incentive_type===1);
   
    const handleChangeRole = (RoleTypeOption) => {
        props.onChange("user_role", RoleTypeOption.value, 'role', props.index)
    };

    const handleChangeSalesTeam = (SalesTeamOption) => {
        props.onChange("product_type", SalesTeamOption.value, 'sales', props.index)
    };

    const handleChangeMonths = (MonthOption) => {
        props.onChange("point_duration", MonthOption.value, 'month', props.index)
    }; 

  
        return (
            <React.Fragment>
                <div className='basic-filter-outer'>

                    <div className="basic-detail-filter"> 

                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element && element.user_role ? configData.user_point_role.filter((el) =>(el.value === element.user_role)) : null}
                                    onChange={handleChangeRole}
                                    options={configData.user_point_role}
                                    placeholder= {props.t('FORM.USER_ROLE')+'*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.user_role) ? <span className="error show">{tempErrors.user_role}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>

                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element && element.product_type ? sales_team.filter(({ value }) => value === element.product_type) : null}
                                    options={sales_team}
                                    onChange={handleChangeSalesTeam}
                                    placeholder= {props.t('FORM.SALE_TEAM') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.product_type) ? <span className="error show">{tempErrors.product_type}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>

                        <fieldset className="single-select">
                            <div className="material">
                                {
                                  !element.is_expired 
                                  ?
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={element && element.point_duration ? configData.scheme_duration.filter(({ value }) => value === element.point_duration) : null}
                                        options={configData.scheme_duration}
                                        onChange={handleChangeMonths}
                                        placeholder={props.t('POINT_CONFIG_DETAIL.FIELDS.M') + '*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        isDisabled={element.is_expired}
                                    />: 
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={[{value: '', label: element.point_duration_value}]}
                                        options={configData.scheme_duration}
                                        onChange={handleChangeMonths}
                                        placeholder={props.t('POINT_CONFIG_DETAIL.FIELDS.M') + '*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        isDisabled={element.is_expired}
                                    />
                                }
                            
                            </div>
                            {
                                (tempErrors && tempErrors.point_duration) ? <span className="error show">{tempErrors.point_duration}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>
                    </div>
                </div>
            </React.Fragment>
        )
   
}  
 
export default withTranslation('common')(BasicDetails); 
