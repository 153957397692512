import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

class BannerPreview extends Component {
    constructor(props) {
        super(props);
        let { element } = this.props;
        this.state = {
            isOpen: false,
            banner_name: element.banner_name ? element.banner_name : "",
        };
    }

    onChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.props.element.banner_name = value
        //this.props.onChange("banner_name", value, "", this.props.element);
      };
      
      render() {
          let { element,tempErrors } = this.props;
          const { isOpen } = this.state;
        return (
            <React.Fragment>
                <div className='banner-preview-outer'>
                    <h3>{this.props.t('COMMON.BANNER_PREVIEW')}</h3>
                    <div className='banner-preview-bg'>
                        {(element.image === "no image" || element.image === "") ?
                            <div className='banner-previw-img'>
                                {this.props.t('COMMON.BANNER_PREVIEW')}
                            </div> :
                            <img alt='banner_image' src={element.image} onClick={() => this.setState({ isOpen: true })} />
                        }
                    </div>
                    <div className="material" style={{marginTop:"10px"}}>
                    <input
                        type= "text"
                        name= "banner_name"
                        value= {element.banner_name ? element.banner_name : ""}
                        placeholder= {this.props.t('COMMON.BANNER_NAME')}
                        disabled={element.is_expired == false ? element.image ? false :true : element.is_expired}  // eslint-disable-line
                        className= "form-input"
                        onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                    />
                     {tempErrors.banner_name && (
                        <span className="error">{tempErrors.banner_name}</span>
                    )}
                </div>
                </div>
                <Lightbox
                    open={isOpen}
                    close={() => this.setState({ isOpen: false })}
                    slides={[
                        { src: element.image }
                    ]}
                />
            </React.Fragment>
        )
    }
} 
export default withTranslation('common')(BannerPreview);

