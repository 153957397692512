import React, { useState, useEffect } from "react";
import DealerFilter from "./DealerFilter";
import { useTranslation } from "react-i18next";
import DealerHeader from "./DealerHeader";
import { Tab, Nav } from "react-bootstrap";
import { useSelector, useDispatch, } from "react-redux";
import { CommonActions } from "../../store/action";
import { useLocation } from 'react-router-dom';
import FullPageLoader from "../common/FullPageLoader";
import { STATUS_LIST} from "../../config/constant"
import * as HELPER from './../../config/helper';
import DealerDataTable from "./DealerDataTable";

const DealerDetails = (props) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);
    const [refresh, setRefresh] = useState(false);
    const [searchFilters, setSearchFilters] = useState({page_no: 1,pagination: true, month : new Date()});
    const [searchKey, setSearchKey] = useState();
    const [apiResponse, setApiResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    let { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const [productType, setProductType] = useState("all");
    
    useEffect(() => {
       
            setIsMounted(true)
            
            getDealerDetailsList();
             // eslint-disable-next-line 
    }, []);

  


    const componentDidMount = (filters={}) => {
        let updatedSearchFilter = Object.assign({}, filters)
        
       
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        
        setSearchFilters(updatedSearchFilter)
        getDealerDetailsList(updatedSearchFilter)
    }


    //FOR RESET SEARCH BOX
    const resetSearchFilter = () => {
        setRefresh(refresh + 1);
        setSearchKey('');
    }

    const getSearchFilterData = (params) => {

        delete params['search_key']
        delete searchFilters['search_key']
        if (searchKey) {
            params['search_key'] = searchKey;
        }
        
        componentDidMount(params)

    }

    /**
     * PAYOUT DETAILS API
     * @param {*} param 
     */

    const getDealerDetailsList = (param = {}) => {
        
        if(typeof param.status !== "undefined" ){
            if(isNaN(+param.status)){
                param['status'] = STATUS_LIST[param.status]
            }
        } else {
            param['status'] = 0;
        }

        if(typeof param.month === "undefined" ){
          param['month'] = new Date();
        } 
        
        let updatedSearchParams = param;
        let paramOb = {};
        

        if (updatedSearchParams.product_type && updatedSearchParams.product_type.toLowerCase() === 'all') {
            delete updatedSearchParams['product_type']
            paramOb = updatedSearchParams
        } else {
            paramOb = updatedSearchParams//{ ...searchFilters, ...param };
        }

        if (typeof paramOb["product_type"] !== "undefined" && paramOb["product_type"] === 'all') {
            delete paramOb["product_type"]
        }
        
        if(typeof paramOb.page_no === "undefined"){
            paramOb.page_no = 1;
            paramOb.pagination = true;
        }
        if(commonRedux && commonRedux.checkedallStatus && commonRedux.checkedallStatus!==true){
            dispatch(CommonActions.updateApproveButtonStatus(false));
            //FOR MULTISELECTED USERS
            dispatch(CommonActions.getMultiSelectedLoans([]));
            dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        }
        setLoading(true);
        setSearchFilters(paramOb);
        dispatch(CommonActions.dealerDetails(paramOb)).then(res => {
            setLoading(false);
            if (res.status === 200 && res.data && Object.keys(res.data).length) {
                setApiResponse(res.data);
            } else {
                setApiResponse(res.data);
            }
        });

    };

    /**
     * RESET FILTER
     */
    const resetFilters = (params = {}) => {
        let updatedFilters = { page_no: 1, reset: true, ...params };
        setProductType("all");
        componentDidMount(updatedFilters);
    }
    
    const setFilter = (params) => {
        setSearchKey('');
        if (params.search_key && params.search_key.trim()) {
            setSearchKey(params.search_key);
        }

    }



    const handleTypeClick = (params, action, typeName) => {
        if(action === "productType"){
            setProductType(typeName);
            if(typeName.toLowerCase() === "all"){
                delete params.type;
            }
        }
        componentDidMount(params);
    }
    
    return (
        isMounted ? <React.Fragment>
            <FullPageLoader show={loading} />

            <div className="dashboard-outer">
                <div className="container-fluid">
                    <div className="top-heading">
                        <h1>{t('DEALER.DEALER_DASHBOARD')}</h1>
                        
                    </div>
                    <div className="loan-filter-bg dashboard-filter-top">
                        {/* FILTERS AND SEARCH - RESET  BUTTON */}
                        <DealerFilter
                            resetFilters={resetFilters}
                            setSearchFilters={setSearchFilters}
                            setProductType={setProductType}
                            resetSearchFilter={resetSearchFilter}
                            getDealerDetailsList={getSearchFilterData}
                            searchFilters={JSON.parse(JSON.stringify(searchFilters))}

                        />
                    </div>


                    <DealerHeader action="dealerDetails"
                        title={t('COMMON.PAYOUT_DETAILS')}
                        showActionButtons={true}
                        refresh={refresh}
                        setFilter={setFilter}
                        getDealerDetailsList={getDealerDetailsList}
                        searchFilters={JSON.parse(JSON.stringify(searchFilters))} 
                        routeParams={JSON.parse(JSON.stringify(queryParams))}
                        setLoading={setLoading}
                        apiResponse={apiResponse}
                    />

                    <div className="payout-summary-tabs">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={'all'} >
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line" >
                                <div className="tab-list">
                                    {

                                        apiResponse && Object.keys(apiResponse).length > 0 &&
                                        apiResponse.total_counts && apiResponse.total_counts.map((item, index) => {

                                            return (<Nav.Item key={item.key} id={item.key} onClick={() => handleTypeClick({ ...searchFilters, type: item.key, page_no: 1 }, "productType", item.key)}>
                                                <Nav.Link className={((!productType && item.key.toLowerCase() === "all") || (productType && productType.toLowerCase() === item.key.toLowerCase())) ? "active" : ""} >
                                                    {item.key} ({item.value})
                                                </Nav.Link>
                                            </Nav.Item>)
                                        })
                                    }
                                </div>
                                {
                                    apiResponse && (Object.keys(apiResponse).length > 0 && apiResponse.paid && Object.keys(apiResponse.paid).length)
                                    && 
                                    <div className="payout-summery-right-panel">
                                    <ul>
                                        <li>
                                            <span className="total-payout-txt"> {HELPER.addCurrencyFormatting(apiResponse.paid.total)}</span>
                                            <label>{t("DEALER.TOTAL_PAYOUT")}</label>
                                            <span className="paid-rp-txt"> (Paid {HELPER.addCurrencyFormatting(apiResponse.paid.total_paid_amount)})</span>

                                        </li>
                                    </ul>
                                    </div>
                                }
                            

                            </Nav>
                            {
                                apiResponse && Object.keys(apiResponse).length > 0 &&
                                apiResponse.total_counts.map((item, index) => {

                                    return (<Tab.Content key={item.key} id={item.key}>
                                        <Tab.Pane eventKey={item.key.toLowerCase()}>
                                            <div className='approve-status-tabs'>
                                                <Tab.Container id="left-tabs-example">

                                                    <Nav variant="pills" className="flex-column tab-bg">
                                                        <div className="tab-list">
                                                            {
                                                                apiResponse.statusList.length &&
                                                                apiResponse.statusList.map((ob, index) => {

                                                                    return (
                                                                        // SUB HEADING
                                                                        <Nav.Item key={ob.key} id={ob.key}>
                                                                            <Nav.Link onClick={() => handleTypeClick({ ...searchFilters, status: ob.status_id, page_no: 1 }, 'typeStatus', ob.key)} active={((!searchFilters.status && !ob.status_id) || (searchFilters.status > 0 && +searchFilters.status === ob.status_id) || (ob.key === searchFilters.status)) ? "active" : ""}>
                                                                                {t("DASHBOARD." + ob.key)}({ob.value})
                                                                            </Nav.Link>
                                                                        </Nav.Item>


                                                                    )

                                                                })
                                                            }
                                                        </div>
                                                    </Nav>
                                                    {/* PAYOUT TABLE */}
                                                    <DealerDataTable apiResponse={apiResponse} routeParams={queryParams} getDealerDetailsList={getDealerDetailsList} loading={loading} key={index} searchFilters={searchFilters} />
                                                </Tab.Container>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    )
                                })
                            }
                        </Tab.Container>

                    </div>

                </div>
            </div>
        </React.Fragment> : ""
    );
};

export default DealerDetails;
