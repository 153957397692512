import React, { useState, useEffect} from "react"; 
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import secureStorage from "./../../config/encrypt";
import { UserActions } from "../../store/action";
import VerifyOtp from './VerifyOtp';
import Captcha from '../../view/common/Captcha'; 
import GoogleLogin from "./GoogleLogin"; 
import { useSearchParams } from 'react-router-dom';
import FullPageLoader from '../common/FullPageLoader';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from "react-redux";

function EmailUser(props) {
// eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    const dispatch = useDispatch();

    const captchaData = useSelector((state) => { 
        return state.auth;
    })
    
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const [inputField, setInputField] = useState({
        email: "",
        password: "",
        captcha : "",
        hash : ""
    });
    const [errField, setErrField] = useState({});
    const [showVerify, setshowVerify] = useState(false);
    const [userId, setUserId] = useState('');
    const history = useNavigate();
    const [reloadCaptcha, setReloadCaptcha] = useState(1);
    const [googleLogin, setGoogleLogin] = useState(false); 
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            captchErr: "",
            captcha: ""
        });
        if (inputField.email === "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                email: t('VALIDATION.EMAIL'),
            }));
        } else if (!validEmailRegex.test(inputField.email)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                email: t('VALIDATION.VALID_EMAIL'),
            }));
        }
        if (inputField.password === "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                password: t('VALIDATION.PASSWORD'),
            }));
        }
        if (!inputField['captcha'].trim()) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                captcha: t('VALIDATION.CAPTCHA_FAILED'),
            }));
            setReloadCaptcha((prev)=> prev+1)
        }
        return formIsValid;
    };
    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value});
    };

    useEffect(() => {
        const loggedInUser = secureStorage.getItem("authUserInfo");
        if (loggedInUser && loggedInUser.user_data) {
            history("/dashboard")
        }
    })

    const submitButton = async () => {
        setLoading(true);
        if (validForm()) {
            dispatch(UserActions.createOtp(inputField)).then((res) => {
                setLoading(false)
                if (res && res.status === 200) {
                    //DELETE CAPTCHA HASH FROM CACHE 
                    setUserId(res.data.user_id);
                    setshowVerify(true);
                }
                setReloadCaptcha((prev)=> prev+1)
                setInputField({ email: inputField.email, password: inputField.password, captcha: ''})

            });     
        } else {
            setReloadCaptcha((prev)=> prev+1)
            setLoading(false);
        }
    };
    const handleKeyPress = (e) => {
        if (e.charCode === 13) submitButton();
        return true
    } 

    const captchaFiledHandler = (childData) => {
        if(captchaData.captchaValue){
            setInputField({ ...inputField, captcha: childData,hash :captchaData.captchaValue})
        }
    }

   
  
    useEffect(() => {
      const userDataParam = searchParams.get('userdata');
      if (userDataParam) {
        try {
          const parsedData = JSON.parse(decodeURIComponent(userDataParam));  
          if((!parsedData.domain && parsedData.email) || parsedData.domain !== "Invalid"){
            setLoading(true);
            setInputField({ email: parsedData.email, password: "", captcha: ''}); 
            dispatch(UserActions.creatLoginOtp({email : parsedData.email})).then((res) => {
                setLoading(false);
                if (res && res.status === 200) { 
                    history("/dashboard")
                }
                /* if (res && res.status === 200) { 
                    setUserId(res.data.user_id); 
                    setshowVerify(true);
                    setGoogleLogin(true);
                } else {   
                    setshowVerify(false);
                    setGoogleLogin(false); 
                    const urlParams = new URLSearchParams(location.search); 
                    if (urlParams.has('userdata')) {
                      urlParams.delete('userdata');
                      const newUrl = `${location.pathname}`;  
                      // Redirect to the cleaned URL
                   //   history(newUrl);
                    //   window.location.reload(); 
                    }
                }    */
            });
          } else {
            setshowVerify(false);
            setGoogleLogin(false); 
            toast.error("Domain is Invalid");
            const urlParams = new URLSearchParams(location.search); 
            if (urlParams.has('userdata')) {
              urlParams.delete('userdata');
              const newUrl = `${location.pathname}`;   
              history(newUrl); 
            }
          }

        } catch (error) {
          console.error('Failed to parse userdata:', error);
        }
      }
    }, [searchParams]);


    return (
        <div className="login-form">
            <FullPageLoader show={loading} />
            {showVerify ?
                (<VerifyOtp submitButton={submitButton} userId={userId} inputField={inputField} captchaData={ captchaData }  reloadCaptcha={reloadCaptcha} googleLogin={googleLogin} setReloadCaptcha={setReloadCaptcha} setUserId={setUserId} setshowVerify={setshowVerify} setGoogleLogin={setGoogleLogin}/>)
                :
                <form autoComplete="on">
                    <fieldset className="">
                        <div className={"material " + (errField.email ? "error" : "")}    >
                            <input
                                type="text"
                                placeholder=" "
                                name="email"
                                value={inputField.email}
                                className="form-input"
                                onChange={inputHandler}
                            />
                            <i className="ic-email1 icn-login"></i>
                            <label data-label={t('FORM.EMAIL')} className="form-label"></label>
                            {errField.email && (
                                <span className="erroe-txt">
                                    {errField.email}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="">
                        <div className={"material " + (errField.password ? "error" : "")}>
                            <input
                                type="password"
                                placeholder=" "
                                name="password"
                                value={inputField.password}
                                className="form-input password-txt"
                                onChange={inputHandler}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="ic-password icn-login"></i>
                            <label data-label={t('FORM.PASSWORD')} className="form-label"></label>
                            {errField.password && (
                                <span className="erroe-txt">
                                    {errField.password}
                                </span>
                            )}
                        </div>
                    </fieldset> 
                    <fieldset className=" captcha-filed">
                        <Captcha onKeyPressCaptcha={ captchaFiledHandler } errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha}/>
                    
                    </fieldset>

                    <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                        {t('FORM.LOGIN')}
                    </button>
                    <GoogleLogin setUserId={setUserId} setshowVerify={setshowVerify} setGoogleLogin={setGoogleLogin} setInputField={setInputField}/> 
                </form> 
            }
        </div>
    );
}

export default EmailUser;
