import React, { useState, useEffect } from "react";
import Select from "react-select"; 
import MultiSelect from '../../common/MultiSelect'
import { MonthYear } from "./DatePicker";
import MoreFilter from "./MoreFilterOption";
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../../store/action";
import { toast } from "react-toastify";
import CustomValueContainer from "../../common/CustomValueContainer";
import * as HELPER from './../../../config/helper';
import { ROLE_LABEL_ID } from "../../../config/constant"
import { useParams, useLocation, useNavigate } from 'react-router-dom';

const LeadListFilter = (props) => {
    const [incentiveNameOption, setIncentiveNameOption] = useState([]);
    const [reportManagerOption, setReportManagerOption] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(props.searchFilters || {});
    const [incentiveConfigData, set_incentive_config_data] = useState([]);
    const [uploadScheme, setUploadScheme] = useState([])
    const { roleName } = useParams();
    const [reportingRoleList, setReportingRoleList] = useState([]);

    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);
    let roleOption = commonRedux.getRoleList ? commonRedux.getRoleList : [];
    const [resetStateFilter, setResetStateFilter] = useState(false);

    // For taking value from URL
    let { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const history = useNavigate();


    let month = queryParams.get('month');

    //CREATE DEFAULT DATE FOR MONTH FILTER
    let makeDate = month ? new Date(month) : (props.searchFilters.month ? new Date(props.searchFilters.month) : new Date());

    useEffect(()=>{
        getUrlParams()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUrlParams = () => {
    
        let queryParamsObj = {}
        
        for(const [urlParamKey, value] of queryParams) {
            if(urlParamKey === 'type' && value !== "all"){
                queryParamsObj['type'] = value;
            }else if (urlParamKey === 'scheme_id' && typeof value == 'string') {
                queryParamsObj['scheme_id'] = value.split(',').map(Number);
            }else{
                queryParamsObj[urlParamKey] = value;
            }
        }

        setSelectedFilters((prev)=> { return {...prev, ...queryParamsObj} })
      
        
    }

    useEffect(() => {

        if(selectedFilters.role_id && roleOption.length){
            let selectedRole = roleOption.filter(r => +r.sfa_role_id === +selectedFilters.role_id);
            
            dispatch(CommonActions.schemeList({ role_id: selectedRole[0]['id'], name: selectedRole[0]['name'] })).then((res) => {
                setIncentiveNameOption(res);
            });
        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleName, roleOption]);



    useEffect(() => {
        if (commonRedux && commonRedux.incentiveConfig && commonRedux.incentiveConfig.reporting_to_role) {
            setReportingRoleList(commonRedux.incentiveConfig.reporting_to_role);
        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonRedux]);

    
    /**
     * COMMON METHOD TO TRIGGER ROLE
     */
    // const roleFilterTrigger = () => {
    //     if (roleName && roleOption.length) {
    //         let selectedRole = roleOption.filter(r => r.name.toLowerCase() === roleName);
    //         changeHandler(selectedRole[0], 'role_id')
    //     }
    // }

    useEffect(() => {

        dispatch(CommonActions.getRoleList());
        // SET MORE FILTER VALUES
        dispatch(CommonActions.getIncentiveConfig()).then(function (response) {
            if (response && response.status === 200) {
                let configResponse = response.data;

                configResponse.agent_level = configResponse.agent_level.map((ob) => {
                    return {
                        ...ob,
                        value: ob.id,
                        label: ob.agent_name,
                    };
                });

                set_incentive_config_data(configResponse);


            } else {
                toast.error(response && response.message);
            }
        });
        
        setSelectedFilters((val) => { return { ...val, month: makeDate } });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUploadedDocs = (date) => {
        let parseMonth = new Date(date).toISOString().split('T');
        parseMonth = parseMonth[0].split("-");

        getUploadedCsvFiles({ month_year: parseMonth[0] + '-' + parseMonth[1] })
    }

    const changeHandler = (value, name) => {
        let selectedFiltersObj = selectedFilters;
        let searchFiltersObj = props.searchFilters;
        
        if (name === "role_id") {
            // If condition run Normal case, else condition for cross button 
            if (value) {
                // SET ROLE VALUE
                dispatch(CommonActions.schemeList({ role_id: value.id, name: value.name })).then((res) => {
                    setIncentiveNameOption(res);
                });

                value = value.sfa_role_id;

                let getRoleType = HELPER.GET_USER_ROLE(+value);

                //RESET SCHEME IDs/Level/Tagging ON ROLE CHANGE
                delete selectedFiltersObj['scheme_id'];
                delete selectedFiltersObj['level'];
                delete selectedFiltersObj['tagging'];
                
                setSelectedFilters({ ...selectedFiltersObj, "role_type": getRoleType });
                  
                // For deleted Scheme id during Role change
                delete searchFiltersObj.scheme_id; 
                props.setSearchFilters(searchFiltersObj);

            }
            else {
                value = undefined;
                setIncentiveNameOption([]);
                let newRecord = selectedFilters; 

                // Delete record from payload when we click cross icon
                delete newRecord.scheme_id;
                delete newRecord.role_type;
                delete newRecord.role_id;

                delete searchFiltersObj.scheme_id;
                delete searchFiltersObj.role_type;
                delete searchFiltersObj.role_id; 
                props.setSearchFilters(searchFiltersObj);
                setSelectedFilters(newRecord);
            }
        }
        if (name === "reporting_role") {

            // If condition run Normal case, else condition for cross button           
            if (value) {
                value.name = value.name.toLowerCase()
                // For Reset Reportee Manger value before selecting Reporting to Role
                setReportManagerOption([]);
                let newRecord = selectedFilters; 
                delete newRecord.reporting_id;
                delete newRecord.reporting_role;

                delete searchFiltersObj.reporting_id;
                delete searchFiltersObj.reporting_role;
                props.setSearchFilters(searchFiltersObj);
                setSelectedFilters(newRecord);
               

                //GET REPORTING MANAGERS
                getReportingManagerName(value.name, selectedFilters.month)
            
            } else {
                // SET Empty when cross Reporting to Role
                value = undefined;
                setReportManagerOption([]);
                let newRecord = selectedFilters; 
                delete newRecord.reporting_id;
                delete newRecord.reporting_role;

                delete searchFiltersObj.reporting_id;
                delete searchFiltersObj.reporting_role;
                props.setSearchFilters(searchFiltersObj);
                setSelectedFilters(newRecord);
            }

        }
        if (["reporting_role", "tagging", "upload_csv"].includes(name)) {
            if (value) {
                value = value.name;
            } else {
                value = undefined;
            }
        }

        else if (["scheme_id"].includes(name)) {
            value = value.map((v) => v.value);
        } else if (["reporting_id"].includes(name)) {
            if (value) {
                value = value.value;
            } else {
                value = undefined;
            }
        } 

        setSelectedFilters((prev) => { 
            return { ...prev, [name]: value };
        });


    };

    /**
     * UPDATE FILTERS
     * @param {*} params 
     */
    const updateFilters = (params) => {
        let parseMonth = makeDate;
        //IF NO FILTER SELECTED SET DEFAULT DATE
        if (!Object.keys(params).length) {
            params = { month: parseMonth }
        }

        setSelectedFilters(params);
    };


    /**
     * RESET FILTER
     */

    const resetFilters = () => {
        if(!queryParams.get('reporting_id')){
            setReportManagerOption([])
        }
     
       setIncentiveNameOption([])
       updateFilters({})
        let resetparams = { month: makeDate, status: 0, reset: true, pagination: true };

        if (props.action === 'dashboard') {
            makeDate = new Date();

            const currentDate = String(makeDate.getDate()).padStart(2);

            if (currentDate <= 20) {
                makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
            }
        

            setSelectedFilters({ month: makeDate, status: 0 })
            props.resetFilters(resetparams);

        }else{
             
            // reset Field - Empty;
           let newUrlParams = props.urlQueryParamsRef;
           delete newUrlParams.reporting_id;
           delete newUrlParams.reporting_role;
           delete newUrlParams.upload_csv;
           
           // when we reset -  New Url hit without unmandatry Field.
            let changeUrl = '';
            let role_type = queryParams.get('role_type');
            let role_id = queryParams.get('role_id');
            let type = queryParams.get('type');
            let status = queryParams.get('status');
            let month = queryParams.get('month');
            let scheme_id = queryParams.get('scheme_id');
            

             if(role_type){
                changeUrl +=`/payout-details?role_type=${role_type}`
             } 
             if(role_id){
                changeUrl +=`&role_id=${role_id}`
             }
             if(type){
                changeUrl +=`&type=${type}`
             }
             if(status){
                changeUrl +=`&status=${status}`
             } 
             if(month){
                changeUrl +=`&month=${month}`
             }
             if(scheme_id){
                changeUrl +=`&scheme_id=${scheme_id}`
             }

           history(changeUrl);
          

           setSelectedFilters(newUrlParams);
           props.resetFilters(newUrlParams);   
                
            
            if(props.urlQueryParamsRef.role_id && roleOption.length){
                let selectedRole = roleOption.filter(r => +r.sfa_role_id === +props.urlQueryParamsRef.role_id);
                
                dispatch(CommonActions.schemeList({ role_id: selectedRole[0]['id'], name: selectedRole[0]['name'] })).then((res) => {
                    setIncentiveNameOption(res);
                });
            }

        }

        setResetStateFilter(true);

            setTimeout(() => {
                setResetStateFilter(false);

            }, 1000)
    }

    /**
     * GET UPLOADED CSV API
     * @param {*} params 
     */
    const getUploadedCsvFiles = async (params) => {
        dispatch(CommonActions.getCsvUploadHistory(params)).then(res => {
            if (res.data && res.data.data.length > 0) {
                let record = res.data.data.filter(Data=>Data.upload_type===1).map((ob) => {
                    return {
                        label: ob.csv_filename,
                        value: ob.id, name: ob.id
                    }
                });
                setUploadScheme(record)
            } else {
                setUploadScheme([]);
            }

        }).catch(err => setUploadScheme([]));
    }

    let pageAction = true;
    if (props.action === "dashboard") {
        pageAction = false;
    }

    /**
     * GET REPORTING MANAGERS BASED ON CHILD COMPONENT DATA CHANGE
     * @param {*} params 
     */
    const getReportingManagers = (params) => {
        if (props.searchFilters.reporting_role && params.month) {

            getReportingManagerName(props.searchFilters.reporting_role, new Date(params.month));
        }
    }

    /**
     * GET REPORTING MANAGERS LISTING
     * @param {*} reporting_name 
     * @param {*} month 
     */
    const getReportingManagerName = (reporting_name, month) => {

        setReportManagerOption([]);

        getReportingManagerList(reporting_name, month);

    }

    const getReportingManagerList = (reporting_name, month) => {
        let role_id = ROLE_LABEL_ID[reporting_name];

        dispatch(CommonActions.getReportingManager({ role_id: role_id, month: month })).then((res) => {
            let managerList = (res && res.data && res.data.user_list) || [];
            setReportManagerOption(managerList);
        });
    }
 
    return (
        <React.Fragment>
            <div className="lead-list-filter">
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                (selectedFilters.role_id)
                                    ? roleOption.filter(
                                        (role) => (+role.sfa_role_id === +selectedFilters.role_id)
                                    )
                                    : (roleName ? roleOption.filter((role) => role.name.toLowerCase() === roleName) : [])
                            }
                            onChange={(val) => changeHandler(val, "role_id")}
                            options={roleOption}
                            placeholder="Role"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={props.action === 'dashboard' ? true : false}
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">  
                    <div className="material">
                        <MultiSelect
                            isMulti={true}
                            options={incentiveNameOption}
                            placeholder="Incentive Name"
                            iconAfter="false"
                            value={
                                selectedFilters.scheme_id
                                    ? incentiveNameOption.filter((incentive) =>
                                        selectedFilters.scheme_id.includes(incentive.value)
                                    )
                                     : []
                            }
                            onChange={(val, event) =>
                                changeHandler(val, "scheme_id", event)
                            }
                            className="react-select"
                            classNamePrefix="react-select"
                            // menuIsOpen={true}
                        />
                        {/* <label data-label={"Insurance Partner"} className="form-label"></label> */}

                    </div>
                </fieldset>

                <fieldset className="dateragefiled">
                    <div className="material">
                        <MonthYear
                            updateFilters={updateFilters}
                            selectedFilters={selectedFilters}
                            setSearchFilters={setSelectedFilters}
                            getUploadedDocs={getUploadedDocs}
                            searchFilters={props.searchFilters}
                            action={Object.keys(selectedFilters).length ? props.action : ''}
                            resetStateFilter={resetStateFilter}
                            getReportingManagers={getReportingManagers}

                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                selectedFilters.reporting_role
                                    ? reportingRoleList.filter(
                                        (ob) =>
                                            ob.name.toLowerCase() === selectedFilters.reporting_role.toLowerCase()
                                    )
                                    : []
                            }
                            onChange={(val) => changeHandler(val, "reporting_role")}
                            options={reportingRoleList}
                            placeholder="Reporting to Role"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                selectedFilters.reporting_id
                                    ? reportManagerOption.filter(
                                        (e) =>
                                            +e.value ===
                                            +selectedFilters.reporting_id
                                    )
                                    : []
                            }
                            onChange={(val) => changeHandler(val, "reporting_id")}
                            options={reportManagerOption}
                            placeholder="Reportee Manager"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>




                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                selectedFilters.upload_csv
                                    ? uploadScheme.filter(
                                        (ob) => +ob.value === +selectedFilters.upload_csv)
                                    : []
                            }
                            onChange={(val) => changeHandler(val, "upload_csv")}
                            options={uploadScheme}
                            placeholder="Uploaded Schemes"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>
                {/* SEARCH RESET BUTTON  */}
                <div className="more-filter">
                    <MoreFilter
                        updateFilters={updateFilters}
                        selectedFilters={selectedFilters}
                        incentiveConfigData={incentiveConfigData}
                        pageAction={pageAction}
                        getDashboardList={props.getDashboardList}
                        resetSearchFilter={props.resetSearchFilter}
                        resetFilters={resetFilters}
                        action={props.action}

                    />
                </div>
            </div>
        </React.Fragment>
    );
};
export default LeadListFilter;
