import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';

class UserIncentiveTypeCard2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            element: this.props.element,
            refresh: 1
        };
    }

    onChange = (event,index) => {
        let { name, value } = event.target 
        this.setState({ refresh: this.state.refresh + 1 }) 
        this.props.element.user_incentive_type_2[index][name] = value;  
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(nextProps?.element?.user_incentive_type_2 !== prevState?.element.user_incentive_type_2){
            return ({ element: {...prevState.element, user_incentive_type_2: nextProps?.element?.user_incentive_type_2} })
        } 
        return null;
    }

    render() { 
        const { user_level } = this.props.incentive_config
        const { element, tempErrors,t } = this.props  
        let userLevelList = element.user_incentive_type_2; 
        if(element.form_config.hasOwnProperty('USER_INCENTIVE_TYPE_CARD_2_REGULAR')){
            userLevelList = userLevelList.filter(ob=>(Number(ob.user_level) === 2));
        } 
        if(!element?.applicable_to?.includes('fixed')){
            userLevelList = userLevelList.filter(ob=>+ob.user_level !=4)
        } 
        let min_points = false;
        if(element.form_config.hasOwnProperty('MIN_POINTS') && +element.form_config["MIN_POINTS"]===1){
            min_points = true
        }  
        return (
            <React.Fragment>
                <div className='extra-incentive-outer add-incentive-table user-incentive-type2'>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('FORM.USER_LEVEL')}</th>
                                <th>{(min_points && element.scheme_type_id !=37)?t('FORM.MIN_POINTS'):t('FORM.MIN_DISBURSAL')+'*'}
                                    <span>{t('FORM.TO_BE_ELIGIBLE_FOR_SCHEME')}</span>
                                </th>
                                {
                                (!min_points) && <>
                                    <th>
                                        <span></span>
                                    </th>
                                    <th>{t('FORM.TOTAL_DISB_AMOUNT') + '*'}
                                    <span>{t('FORM.TO_BE_ELIGIBLE_FOR_SCHEME')}</span>
                                    </th>
                                </>
                                }
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userLevelList.map((data, index) => (
                                    <tr key={index}>
                                         <td>{user_level.filter((e)=>(e.id === data.user_level)).map(({user_name}) => user_name)}</td> 
                                      
                                        <td>
                                            <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "disb_minimum_count_" + index,
                                                            type: "text",
                                                            name: "disb_minimum_count",
                                                            placeholder: " ",
                                                            value: data.disb_minimum_count || "",
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e) => this.onChange(e,index)}
                                                    />
                                                </div> 
                                                {
                                                    (tempErrors && tempErrors["disb_minimum_count_" + index]) ? <span className="error show">{tempErrors["disb_minimum_count_" + index]}</span> : <span>&nbsp;</span>
                                                }

                                            </fieldset>
                                        </td>
                                        { 
                                        (!min_points) && <>
                                            <td>
                                                <span className="to-txt">OR</span>
                                            </td>
                                            <td>
                                                <fieldset>
                                                    <div className="material">
                                                        <InputField
                                                            inputProps={{
                                                                id: "total_disb_amt_" + index,
                                                                type: "text",
                                                                name: "total_disb_amt",
                                                                placeholder: " ",
                                                                value: data.total_disb_amt || "",
                                                                className: "form-input",
                                                                disabled: element.is_expired || false
                                                            }} 
                                                            onChange={(e) => this.onChange(e,index)}
                                                        />
                                                    </div>
                                                    {
                                                        (tempErrors && tempErrors["total_disb_amt_" + index]) ? <span className="error show">{tempErrors["total_disb_amt_" + index]}</span> : <span>&nbsp;</span>
                                                    }
                                                </fieldset>
                                            </td>
                                        </>
                                        }
                                        
                                    </tr>
                                ))
                            }
 
                        </tbody>
                    </table>
                </div>

            </React.Fragment>
        )
    }
}
export default withTranslation('common')(UserIncentiveTypeCard2); 