import React, { Component } from "react";
import Select from 'react-select';
import { Tab, Nav } from "react-bootstrap";
import incentive_img from "../../webroot/images/incentive_scheme_img.svg";
import { IncentiveService } from "../../services";
import { toast } from "react-toastify";
import AddIncentive from './AddIncentive'
import MasterIncentive from './MasterIncentive'
import { withTranslation } from 'react-i18next';
// import Loader from "../common/loader";
import { connect } from 'react-redux';
import { CommonActions } from "../../store/action";
import { bindActionCreators } from 'redux';
import ConfirmationModal from '../common/ConfirmationModal'; 
import CustomValueContainer from '../common/CustomValueContainer';
import FullPageLoader from '../common/FullPageLoader'; 
import FormValidation from "../incentive/widgets/FormValidation";
import DataValidationForZero from "../incentive/widgets/DataValidationForZero";
import ChangeHandler from "../incentive/widgets/ChangeHandler";
import HandleAddIncentive from "../incentive/widgets/HandleAddIncentive";
import { AM_ROLE_ID } from "../../config/constant";
 

class Incentive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scheme_type_id: localStorage.getItem("scheme_type_id"),
            AddIncentivepopup: false,
            //  incentive_schemes: [],
            incentive_scheme_selected: "",
            incentive_list: {},
            expired_incentive_list: {},
            DeleteIncentivepopup: false,
            ModifyStatusPopup: false,
            status: true,
            incentive_config: {},
            element_selected: {},
            tempErrors: {},
            activeTab: 1,
            loading: false,
            activeItem: 1,
            preExpandedTabs: -1,
            subExpandedTabs: -1,
            financier_list:[],
            AreaAM:[],
            scheme_role_id:1,
            SubmitStatusPopup:false,
            removeDetail : false,
            removeBanner : false,
            removeInfo: {},
            removeBan: {}
        };
    }

    //  For Validation

    validUpdateForm = (data, indexKey, otherIncentiveList) => { 
        let {tempErrors, formIsValid} = FormValidation(data, otherIncentiveList, this.props); 
        this.setState({
            tempErrors: {
                [data.scheme_type_id]: {
                    [indexKey]: tempErrors
                }
            }
        });
        return formIsValid;
    };

    validateDataForZero=async(data)=>{
        return DataValidationForZero(data);
    };
    handleUpdate = async (element, is_delete, childRefKey = '', indexKey = null) => {
        let self = this;
        self.setState({ loading: true })
        if (childRefKey) {
            this.setState({ subExpandedTabs: childRefKey })
        }
        let { incentive_list } = this.state;
        let inputField = incentive_list[parseInt(element.scheme_type_id)][indexKey];
        let otherIncentiveList = incentive_list[parseInt(element.scheme_type_id)].filter((list, index) => index !== indexKey);
        if (self.validUpdateForm(inputField, indexKey, otherIncentiveList)) {
            let validateDataForZero= await self.validateDataForZero(inputField,indexKey);
            if(!validateDataForZero){
                self.saveIncentiveData(inputField);
            }else{
                this.setState({ loading: false })
                this.setState({ element_selected: inputField,SubmitStatusPopup: validateDataForZero })
            }
        } else {
            this.setState({ loading: false })
        }
    };
    SubmitStatusResponse(response){
        this.setState({SubmitStatusPopup: false });
        if(response.response){
            this.setState({ loading: true })
            this.saveIncentiveData(response.type);
            
        }
    }
    saveIncentiveData=async(inputField)=>{
        let self = this;
        var formData = new FormData(); 
        formData.append("scheme_type_id", inputField.scheme_type_id);
        formData.append("product_type", inputField.product_type);
        formData.append("scheme_duration", inputField.scheme_duration);
        formData.append("description", inputField.description);
        formData.append("status", (inputField.status) ? 1 : 0);
        formData.append("images", inputField.file);

        if(inputField.copy_id){
            formData.append("copy_id" , inputField.copy_id); 
        }

        if(inputField.scheme_duration_date){
            formData.append("scheme_duration_date", inputField.scheme_duration_date);
        }
        if (inputField.scheme_duration_value) {
            formData.append("scheme_duration_value", inputField.scheme_duration_value);

        }
        if (inputField.id) {
            formData.append("id", inputField.id);
        }

        if (inputField.banner_name) {
            formData.append("banner_name", inputField.banner_name);
        }

        if (inputField.applicable_to) {
            formData.append("applicable_to", inputField.applicable_to);
        }
        if (inputField.user_role) {
            formData.append("user_role", +inputField.user_role);
        }
        if (inputField.application_type) {
            formData.append("application_type", inputField.application_type);
        }
        if (inputField.booster_per) {
            formData.append("booster_per", inputField.booster_per);
        }
        if (inputField.incentive_amount) {
            formData.append("incentive_amount", inputField.incentive_amount);
        }
        if (inputField.received_date) {
            formData.append("received_date", inputField.received_date);
        }
        if (inputField.disbursed_date) {
            formData.append("disbursed_date", inputField.disbursed_date);
        }

        if (inputField.receive_date_to) {
            formData.append("receive_date_to", inputField.receive_date_to);
        }
        if (inputField.disb_date_to) {
            formData.append("disb_date_to", inputField.disb_date_to);
        }
        if (inputField.mid_month) {
            formData.append("mid_month", inputField.mid_month);
        }
        if (inputField.form_config.EXTRA_BOOSTER) {
            formData.append("extra_booster", JSON.stringify(inputField.extra_booster)); 
        }  
        if (inputField.form_config.TOP3_PER_AREA_PER_MONTH) {
            formData.append("top3_per_area_per_month", JSON.stringify(inputField.top3_per_area_per_month));
        }
        if (inputField.form_config.PER_QUARTER) {
            formData.append("per_quarter", JSON.stringify(inputField.per_quarter));
        }
        if (inputField.form_config.APP_SUBMITTED) {
            formData.append("app_submitted", JSON.stringify(inputField.app_submitted.filter(ob=> +ob.agent_level_id !==3)));
        } 
        if (inputField.form_config.EXTRA_INCENTIVE_CARD) {
            formData.append("extra_incentive", JSON.stringify(inputField.extra_incentive));   
        } 
        if (inputField.form_config.USER_INCENTIVE_TYPE) {
            formData.append("user_incentive_type", JSON.stringify(inputField.user_incentive_type));
        }
        if (inputField.form_config.USER_INCENTIVE_TYPE_CARD_2) {
            formData.append("user_incentive_type_2", JSON.stringify(inputField.user_incentive_type_2));
        }
        if (inputField.form_config.FINANCIER_CONFIGURATION_CARD || inputField.form_config.ADD_FINANCIER || inputField.form_config.EXTRA_BOOSTER_TYPE2 || inputField.form_config.SPECIAL_SCHEME_CARD) {
            formData.append("financier_config", JSON.stringify(inputField.financier_config));
        }
        if (inputField.form_config.ELIGIBLE_FINANCIER  ) {
            formData.append("elg_financier", JSON.stringify(inputField.elg_financier));
        }
        if (inputField.form_config.BM_CONFIGURATION_CARD) {
            
            formData.append("bm_config", JSON.stringify(inputField.bm_config));
        }
        if (inputField.form_config.ALLOWANCE_CARD) {
            formData.append("user_allowances", JSON.stringify(inputField.user_allowances));
        }
        if (inputField.form_config.DATE_RANGE_FILTER) {
            formData.append("date_range", JSON.stringify(inputField.date_range)); 
        }
        if (inputField.min_disbursal) {
            formData.append("min_disbursal", inputField.min_disbursal);  
        }
       
        if (inputField.form_config.DISBURSAL_ROLE) {
            formData.append("disbursal_role", JSON.stringify(inputField.disbursal_role)); 
        }

        IncentiveService.saveIncentive(formData).then(function (response){
            self.setState({ loading: false })
            if(response.data.status === 200){
                toast.success(response.data.message);
                self.getAllIncentiveSchemes();
                self.getAreaAM();
             
            }else{
                toast.error(response.data.message); 
            }
        }).catch(function (response) {
            self.setState({ loading: false })
        });
    }
    componentDidMount = () => {
        this.setState({ loading: true })
        this.getAllIncentiveSchemes();
        this.props.actions.schemeList({ 'role_id': this.state.scheme_role_id })
    };

    componentWillMount = () => {
        this.getIncentiveConfig();
        this.getFinancierList();
        this.getAreaAM();
    };

    showModalpopup = (value, element) => {
        if (!element.is_expired) {
            if (value === "DeleteIncentivepopup") {
                this.setState({ element_selected: element });
            }
            this.setState({ [value]: true });
            document.body.classList.add("overflow-hidden");
        }
    };

    hideModalpopup = (value) => {
        this.setState({ [value]: false });
        document.body.classList.remove("overflow-hidden");
    };

    getAllIncentiveSchemes = () => {
        let self = this;
        self.setState({ loading: true })
        IncentiveService.getAllIncentive({ scheme_role_id: this.state.scheme_role_id })
            .then(function (response) {
                self.setState({ loading: false })
                if (response.data.status === 200) {
                    if (Object.keys(response.data.data).length > 0) {
                        let activeResponse = response.data.data.active_grouped_incentive_data;

                        self.setState({
                            incentive_list: activeResponse,
                        });
                        localStorage.setItem(
                            "main_incentive_list",
                            JSON.stringify(response.data.data.active_grouped_incentive_data)
                        );
                        self.setState({
                            expired_incentive_list:
                                response.data.data.expired_grouped_incentive_data,
                        });
                    }
                }
            }).catch(function (response) { self.setState({ loading: false }) });
    };

    showModalAddIncentivepopup = () => {
        let { activeTab } = this.state;
        if (activeTab !== 2) {
            this.setState({ AddIncentivepopup: true });
            document.body.classList.add("overflow-hidden");
        }
    };

    hideModalAddIncentivepopup = () => {
        this.setState({ AddIncentivepopup: false });
        document.body.classList.remove("overflow-hidden");

    };

    handleAddIncentive = (value, incentiveName = null) => { 
        let {incentive_list, incentive_scheme_selected} = HandleAddIncentive(value, incentiveName, this.state, this.props)
 
        localStorage.setItem("scheme_type_id", incentive_scheme_selected);
        this.setState({ incentive_list });
        this.hideModalAddIncentivepopup();

    };
 
    handleChange = (name, value, is_confirm, element, activeItem = 0) => { 
        let incentive_list = ChangeHandler(name, value, is_confirm, element, this.state);  
        this.setState({ incentive_list, activeItem });
    };

    handleAddArea = (index, schemeTypeId) => {
        let { incentive_scheme_selected, incentive_list, incentive_config } = this.state;

        if (schemeTypeId) {
            incentive_scheme_selected = schemeTypeId
        }
        let element = incentive_list[parseInt(incentive_scheme_selected)][index];

        let fieldCount = (element.form_config.TOP_COUNT) ? +element.form_config.TOP_COUNT:3
        let itemArray = [];
        for(let i=1;i<=fieldCount;i++){
            itemArray.push({
                position: i.toString(),
                incentive_amount: "",
            })
        }
        if(!element || !element.product_type){ 
            this.setState({
                tempErrors: {
                    [schemeTypeId]: {
                        [index]:  {
                            top3_per_area_per_month: this.props.t('VALIDATION.NO_PRODUCT_TYPE')
                        }
                    }
                }
            });
            return true;
        }

        let top3_per_area_per_month = {
            area_name: 'area_' + element.top3_per_area_per_month.length,
            items: itemArray
        };
        for (let index = 0; index < top3_per_area_per_month.items.length; index++) {
            top3_per_area_per_month.items[index].agent_level_info = [];
            incentive_config.agent_level.forEach((data) => {
                let temp_object = {
                    agent_name: data.agent_name,
                    agent_level_id: data.id,
                    amount: 0,
                    checked: false,
                };
                top3_per_area_per_month.items[index].agent_level_info.push(
                    temp_object
                );
            });
        }
        element.top3_per_area_per_month.push(top3_per_area_per_month);
        incentive_list[parseInt(incentive_scheme_selected)][index] = element;
        localStorage.setItem("scheme_type_id", incentive_scheme_selected);
        this.setState({ incentive_list, incentive_scheme_selected });
    };

    getIncentiveConfig = () => {
        let self = this;
        this.props.actions.getIncentiveConfig().then(function (response) {
            if (response && response.status === 200) {
                let configResponse = response.data;
                if (Object.keys(configResponse).length > 0) {
                    self.setState({ incentive_config: configResponse });
                }
            } else {
                toast.error(response && response.message);
            }
        }); 
    };

    getAreaAM = () => {
        let self = this;
        IncentiveService.getSchemeArea({role_id: [AM_ROLE_ID]})
        .then(function (response) {
            if (response.data.status === 200) {
                if (Object.keys(response.data.data).length > 0) {
                    self.setState({ AreaAM: response.data.data });
                }
            } else {
                toast.error(response.data.message);
            }
        })
        .catch(function (response) { });
    }

    getFinancierList = () => {
        // let self = this;
        if (!this.state.financier_list.length) {
            this.props.actions.financierList().then(response => {
                if (response && response.status === 200) {
                    if (Object.keys(response.data).length > 0) {
                        this.setState({ financier_list: response.data });
                    }
                } else {
                    toast.error(response && response.data && response.data.message);
                }
            });
        }
    };

    handleDeleteYes = (element) => {
        element.is_deleted = 1;
        let is_delete = 1;
        this.handleUpdate(element, is_delete);
    };

    handleCancel = (element) => {

        this.setState({ subExpandedTabs: "" }); //CLOSE CURRENT TAB

        let { scheme_type_id, id } = element;
        let { incentive_list } = this.state;
        let main_incentive_list = localStorage.getItem("main_incentive_list")
            ? JSON.parse(localStorage.getItem("main_incentive_list"))
            : {};
        let main_index = 0;
        if (id) {
            main_index = main_incentive_list[parseInt(scheme_type_id)].findIndex(
                (p) => p.id === id
            );
        } else {
            main_index = main_incentive_list[parseInt(scheme_type_id)].length;
        }
        let main_data = main_incentive_list[parseInt(scheme_type_id)][main_index];

        let index = 0;
        if (id) {
            index = incentive_list[parseInt(scheme_type_id)].findIndex(
                (p) => p.id === id
            );
        } else {
            index = incentive_list[parseInt(scheme_type_id)].length - 1;
        }
        if (main_index === main_incentive_list[parseInt(scheme_type_id)].length) {
            // delete incentive_list[parseInt(scheme_type_id)][index];
            incentive_list[parseInt(scheme_type_id)] = incentive_list[parseInt(scheme_type_id)].filter((obj, key)=> key !== index)
            this.setState({ incentive_list });
        } else {
            incentive_list[parseInt(scheme_type_id)][index] = main_data;
            this.setState({ incentive_list });
        }
    };


    UpdateIncentiveList = (scheme_type_id, scheme_id, add_object, scheme_name) => {
        let { incentive_list } = this.state;
        let index = incentive_list[parseInt(scheme_type_id)].findIndex(
            (p) => p.id === scheme_id
        );
        if (parseInt(scheme_type_id) === 4) {
            incentive_list[parseInt(scheme_type_id)][index][scheme_name] = []
            incentive_list[parseInt(scheme_type_id)][index][scheme_name] = add_object;
        } else {
            incentive_list[parseInt(scheme_type_id)][index][scheme_name] = add_object;
        }
        localStorage.setItem("scheme_type_id", scheme_type_id);
        this.setState({ incentive_list });
    };

    setIncentiveType(data) {
        this.setState({ incentive_scheme_selected: data.id });
        this.handleAddIncentive(data.id, data.name);
    }

    confirmationModalResponse = (response) => {
        document.body.classList.remove("overflow-hidden");
        this.setState({ DeleteIncentivepopup: false });


        if (response.response) {
            let newIncentives = this.state.incentive_list[response.type.scheme_type_id].filter(ob => (ob.id !== response.type.id || ob.temp_id !== response.type.temp_id));

            let finalOb = {}
            if (newIncentives.length) {
                finalOb = {
                    ...this.state.incentive_list,
                    [response.type.scheme_type_id]: newIncentives
                }
            } else {
                let newRecord = this.state.incentive_list;
                delete newRecord[response.type.scheme_type_id];
                finalOb = { ...newRecord }
            }
            this.setState({
                incentive_list: { ...finalOb }
            })
            //this.handleDeleteYes(response.type)
        }
    }

    activeModalResponse(response) {
        this.setState({ ModifyStatusPopup: false });
        if (response.response) {
            let element_selected = response.type;
            this.handleChange(
                "status",
                !element_selected.status,
                true,
                element_selected
            );
        }
    }

    showExpandedTabUuidTabs = (uuid) => {
        this.setState({ preExpandedTabs: uuid })
    }

    showSubExpandedTabUuid = (uuid) => {
        this.setState({ subExpandedTabs: uuid })

    }

    handleChangeSchemeRole = (SchemeRoleOption) => {
        this.setState({ scheme_role_id: SchemeRoleOption.value }, async () => {
            await this.props.actions.schemeList({ 'role_id': SchemeRoleOption.value })
            await this.getAllIncentiveSchemes();

        })

        //GET PRODUCT TYPE FOR ROLES
        if((+SchemeRoleOption.value !== 1) && (!this.props.roleProductList || (this.props.roleProductList && !this.props.roleProductList.length))) {
            // dispatch(CommonActions.getRoleProducts())
            this.props.actions.getRoleProducts()
        }
    };
    emptyErrors = ()=>{
        this.setState({
            tempErrors :{}
        })
    }
    addMoreIncentive = (params)=>{   
        let incentiveData =  this.state.incentive_list[params.scheme][params.index];
        let { incentive_list } = this.state; 
        if(params.type ==='EXTRA_BOOSTER'){
            let ob = {
                from_value: "",
                to_value: "",
                app_disbursed_range: "",
            };
            incentiveData.extra_booster.push(ob);  
        }else if(params.type ==='EXTRA_BOOSTER_SCHEME'){ 
            let tempOb = []

            for(let newConfig of incentiveData.financier_config){
                let ifExists = tempOb.length>0 ? tempOb.filter(op=> (op.tab===params.tab && op.application_type===newConfig.application_type) ) : []
                if(!ifExists.length){
                    let tempobject={
                        tab:params.tab,
                        application_type:newConfig.application_type,
                        financier_id:[],
                        junior_amt:'',
                        regular_amt:'',
                        senior_amt:'',
                        fixed_amt:''
                    }
                    if(params && params.hasOwnProperty("is_count_amount")){
                        tempobject.is_count_amount=params.is_count_amount 
                    }
                    tempOb.push(tempobject)
                }
            }
            let date_range_ob ={
                receive_from : '',
                receive_to : '',
                disb_from : '',
                disb_to : '',
                tab: params.tab
            }
            incentiveData.date_range = [...incentiveData.date_range,date_range_ob]
            incentiveData.financier_config = [...incentiveData.financier_config,...tempOb]
        }
        incentive_list[params.scheme][params.index] = incentiveData; 
        this.setState({ incentive_list }); 
    }
    removeMoreIncentive = (params)=>{
        let incentiveData =  this.state.incentive_list[params.scheme][params.index];
        let { incentive_list } = this.state;
        if(params.type ==='EXTRA_BOOSTER'){  
            if(params.is_deleted){
                this.setState({removeDetail:true,removeInfo:params}) 
            }else{
                incentiveData.extra_booster.splice(params.key, 1);
                incentive_list[params.scheme][params.index] = incentiveData;
                this.setState({ incentive_list }); 
            } 
        }else if(params.type ==='ALLOWANCE_CARD'){  
            if(params.is_deleted){
                this.setState({removeDetail:true,removeInfo:params}) 
            }else{
                incentiveData.user_allowances.splice(params.key, 1);
                incentive_list[params.scheme][params.index] = incentiveData;
                this.setState({ incentive_list }); 
            } 
        }else if(params.type ==='SPECIAL_SCHEME_CARD'){
            if(params.is_deleted){
                this.setState({removeDetail:true,removeInfo:params}) 
            }else{
                incentiveData.financier_config.splice(params.key, 1);
                incentive_list[params.scheme][params.index] = incentiveData;
                this.setState({ incentive_list }); 
            } 
        }     
    }

    deleteIncetiveDetails = (data)=>{ 
        if(data.response){
            this.setState({ loading: true })
            let params = data.type
            let incentiveData =  this.state.incentive_list[params.scheme][params.index];
            let { incentive_list } = this.state;
            if(params.type==="SPECIAL_SCHEME_CARD"){
                IncentiveService.deleteFinancierDetails({id:params.is_deleted}).then( (response)=>{
                    this.setState({ loading: false })
                    if(response.data.status === 200){
                        toast.success(response.data.message); 
                        incentiveData.financier_config.splice(params.key, 1);
                        incentive_list[params.scheme][params.index] = incentiveData;
                        this.setState({ incentive_list }); 
                    }else{
                        toast.error(response.data.message); 
                    }
                }) 
            }else{
                IncentiveService.deleteIncetiveDetails({id:params.is_deleted}).then( (response)=>{
                    this.setState({ loading: false })
                    if(response.data.status === 200){
                        toast.success(response.data.message); 
                        if(params.type==="ALLOWANCE_CARD"){
                            incentiveData.user_allowances.splice(params.key, 1);
                        }else{
                            incentiveData.extra_booster.splice(params.key, 1);
                        }
                        incentive_list[params.scheme][params.index] = incentiveData;
                        this.setState({ incentive_list }); 
                    }else{
                        toast.error(response.data.message); 
                    }
                }) 
            } 
        }  
        this.setState({removeDetail:false,removeInfo:{}}); 
    }

    deleteBanner = (data)=>{ 
        let self = this;
        if(data.response){
            this.setState({ loading: true })
            IncentiveService.deleteBanner(data.type).then(function (response){
                self.setState({ loading: false })
                if(+response.data.status === 200){ 
                    self.emptyErrors()
                    toast.success(response.data.message);
                    self.getAllIncentiveSchemes();
                }else{
                    toast.error(response.data.message); 
                }
            }).catch(function (response) {
                self.setState({ loading: false })
            });
        }  
        this.setState({removeBanner:false,removeBan:{}}); 
    }

    handleDeleteImage = async (element) => {
        this.setState({removeBanner:true,removeBan:element}) 
    };


    handleCopyIncentive = (data, value) => { 
        let {incentive_list, incentive_scheme_selected} = this.state
        let switch_value = value ? value : incentive_scheme_selected;

        let {id, ...dataToCopy} = data;
       
        let currentIncentiveCount = (incentive_list[switch_value]) ? incentive_list[switch_value].length : 0;
        dataToCopy["temp_id"] = switch_value + '_' + currentIncentiveCount;
        dataToCopy["copy_id"] = id; 
      
        if (dataToCopy.extra_booster?.length) { 
            dataToCopy.extra_booster = dataToCopy.extra_booster?.map(({ id, ...rest }) => rest);
        } 
        if (dataToCopy.top3_per_area_per_month?.length) { 
            dataToCopy.top3_per_area_per_month = dataToCopy.top3_per_area_per_month?.map(area => ({
                ...area,
                items: area.items.map(({ id, ...rest }) => rest)
            }));
        } 
        if (dataToCopy.per_quarter?.length) {
            dataToCopy.per_quarter = dataToCopy.per_quarter?.map(({ id, ...rest }) => rest);
        }  
        if (dataToCopy.app_submitted?.length) {
            dataToCopy.app_submitted = dataToCopy.app_submitted?.map(({ id, ...rest }) => rest);
        } 
        if (dataToCopy.redeemable_points?.length) {
            dataToCopy.redeemable_points = dataToCopy.redeemable_points?.map(({ id, ...rest }) => rest);
        } 
        if (dataToCopy.extra_incentive?.length) {
            dataToCopy.extra_incentive = dataToCopy.extra_incentive?.map(({ id, ...rest }) => rest);
        } 
        if (dataToCopy.user_incentive_type?.length) {
            dataToCopy.user_incentive_type = dataToCopy.user_incentive_type?.map(({ id, ...rest }) => rest);
        } 
        if (dataToCopy.user_allowances?.length) {
            dataToCopy.user_allowances = dataToCopy.user_allowances?.map(({ id, ...rest }) => rest);
        } 
        if (dataToCopy.user_incentive_type_2?.length) {
            dataToCopy.user_incentive_type_2 = dataToCopy.user_incentive_type_2?.map(({ id, ...rest }) => rest);
        } 
        if (dataToCopy.bm_config?.length) {
            dataToCopy.bm_config = dataToCopy.bm_config?.map(({ id, ...rest }) => rest);
        }
        
        if (dataToCopy.financier_config?.length) {
            dataToCopy.financier_config = dataToCopy.financier_config?.map(({ id, ...rest }) => rest);
        } 

        if (incentive_list[parseInt(switch_value)]) {
            incentive_list[parseInt(switch_value)].push(dataToCopy);
        } 

        localStorage.setItem("scheme_type_id", incentive_scheme_selected);
        this.setState({ incentive_list });
 
    };
    render() {
       
        let { activeTab, incentive_config, tempErrors, incentive_list, expired_incentive_list, loading, preExpandedTabs, subExpandedTabs,financier_list,SubmitStatusPopup,element_selected,removeDetail,removeBanner,AreaAM } = this.state; 
        let { GetRoleList,userInfo }=this.props;   
         
        return (<React.Fragment>
            <FullPageLoader show={loading} />
            <div className='container-fluid '>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="tab-line white-bg p-l-r-15 minus-l-r-15 hading-tab-top">
                        <div className="top-heading">
                            <h1>{this.props.t('COMMON.ADD_INCENTIVE')}</h1>

                            <Nav variant="pills" className="flex-column">
                                <div className="tab-list">
                                    <Nav.Item
                                        onClick={() => {
                                            this.setState({ activeTab: 1 });
                                        }}
                                    >
                                        <Nav.Link eventKey="first">{this.props.t('COMMON.ACTIVE_SCHEME')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item
                                        onClick={() => {
                                            this.setState({ activeTab: 2 });
                                        }}
                                    >
                                        <Nav.Link eventKey="second">{this.props.t('COMMON.EXPIRED_SCHEME')}</Nav.Link>
                                    </Nav.Item>
                                    <div className="basic-detail-filter">
                                    <fieldset className="single-select">
                                        <div className="material">
                                        <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={GetRoleList.filter(({ value }) => value === this.state.scheme_role_id)}
                                        onChange={this.handleChangeSchemeRole}
                                        options={GetRoleList.filter(ob=>ob.status===1)}
                                        placeholder="Scheme Role"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        />
                                        </div>

                                        </fieldset>
                                    </div>
                                </div>
                            </Nav>
                        </div>


                        {activeTab === 1 && userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('SCHEME_ADD') && (
                            <button
                                onClick={() => {
                                    this.showModalAddIncentivepopup();
                                }}
                                className="btn-line btn-add-incentive"
                            >
                                <i className="ic-add"></i>
                                <span>{this.props.t('FORM.ADD_NEW_SCHEME')}</span> 
                            </button>
                        )}
                    </div>

                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            {Object.keys(incentive_list).length > 0 ? (
                                <div className="add-incentive-accordion">
                                    {Object.keys(incentive_list).map((element, index) => (
                                        <div key={"parent" + index} id={"parent" + index}>

                                            {element.length > 0 && Object.keys(incentive_config).length > 0 && (
                                                <MasterIncentive
                                                    schemaId={element}
                                                    activeTab={activeTab}
                                                    element={incentive_list[element]}
                                                    index={index}
                                                    hideModalpopup={this.hideModalpopup}
                                                    incentive_config={incentive_config}
                                                    financier_list={financier_list}

                                                    tempErrors={tempErrors[element] && tempErrors[element]}
                                                    handleChange={(e, i, q, u) => {
                                                        this.handleChange(e, i, q, u);
                                                    }}
                                                    handleUpdate={(e, i, q, u) => {
                                                        this.handleUpdate(e, i, q, u);
                                                    }}

                                                    handleDeleteImage={(e, i, q, u) => {
                                                        this.handleDeleteImage(e, i, q, u);
                                                    }}

                                                    handleCancel={(e) => {
                                                        this.handleCancel(e);
                                                    }}
                                                    handleAddIncentive={(e) => {
                                                        this.handleAddIncentive(e);
                                                    }}
                                                    activeItem={this.state.activeItem}
                                                    confirmationModalResponse={this.confirmationModalResponse}
                                                    AreaAM={AreaAM}

                                                    preExpandedTabs={preExpandedTabs}

                                                    showExpandedTabUuid={this.showExpandedTabUuidTabs}

                                                    subExpandedTabs={subExpandedTabs}
                                                    showSubExpandedTabUuid={this.showSubExpandedTabUuid}
                                                    handleAddArea={this.handleAddArea}
                                                    incentive_list={incentive_list}
 
                                                    loading={loading}
                                                    emptyErrors={this.emptyErrors}
                                                    addMoreIncentive = {this.addMoreIncentive}
                                                    removeMoreIncentive = {this.removeMoreIncentive}
                                                    handleCopyIncentive = {(e, q) => {
                                                        this.handleCopyIncentive(e, q);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <img src={incentive_img} className="" alt="" />
                                        <span>{this.props.t('COMMON.NO_INCENTIVE_FOUND')}</span>
                                        <button
                                            className="btn-primary"
                                            onClick={this.showModalAddIncentivepopup}
                                        >
                                            {this.props.t('FORM.ADD_INCENTIVE')}                                    </button>
                                    </div>
                                </div>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            {Object.keys(expired_incentive_list).length > 0 ? (
                                <div className="add-incentive-accordion">
                                    {Object.keys(expired_incentive_list).map((element, index) => (
                                        <div key={"parent" + index}>
                                            {element.length > 0 && Object.keys(incentive_config).length > 0 && (
                                                <MasterIncentive
                                                    schemaId={element}
                                                    activeTab={activeTab}
                                                    element={expired_incentive_list[element]}
                                                    index={index}
                                                    hideModalpopup={this.hideModalpopup}
                                                    incentive_config={incentive_config}
                                                    financier_list={financier_list}
                                                    tempErrors={tempErrors[element] && tempErrors[element]}
                                                    handleChange={(e, i, q, u) => {
                                                        this.handleChange(e, i, q, u);
                                                    }}
                                                    handleUpdate={(e, i, q, u) => {
                                                        this.handleUpdate(e, i, q, u);
                                                    }}
                                                    handleCancel={(e) => {
                                                        this.handleCancel(e);
                                                    }}
                                                    handleAddIncentive={(e) => {
                                                        this.handleAddIncentive(e);
                                                    }}

                                                    preExpandedTabs={preExpandedTabs}

                                                    showExpandedTabUuid={this.showExpandedTabUuidTabs}

                                                    subExpandedTabs={subExpandedTabs}

                                                    showSubExpandedTabUuid={this.showSubExpandedTabUuid}

                                                    handleAddArea={this.handleAddArea}
                                                    incentive_list={expired_incentive_list}
                                                    AreaAM={AreaAM}
                                                    addMoreIncentive = {this.addMoreIncentive}
                                                    removeMoreIncentive = {this.removeMoreIncentive}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <img src={incentive_img} className="" alt="" />
                                        <span>{this.props.t('COMMON.NO_EXPIRE_SCHEME')}</span>
                                        {/* <button className='btn-primary' onClick={this.showModalAddIncentivepopup} >Add Incentive</button> */}
                                    </div>
                                </div>
                            )}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

                {/* For Role Listing */}
                <AddIncentive handleClose={this.hideModalAddIncentivepopup} handleChangeSchemeRole={(SchemeRoleOption) => this.handleChangeSchemeRole(SchemeRoleOption)} setIncentiveType={(data) => this.setIncentiveType(data)} isOpen={this.state.AddIncentivepopup} />


            </div>
            {
                SubmitStatusPopup  ?
                    <ConfirmationModal confirmationData={{ show: SubmitStatusPopup, confirmationText:this.props.t('MESSAGE.ZERO_CHECK_ICENTIVE'), type: element_selected }} confirmationModalResponse={(data) => this.SubmitStatusResponse(data)} />
                    :  null
            }
            {
                removeDetail  ?
                    <ConfirmationModal confirmationData={{ show: removeDetail, confirmationText:this.props.t('MESSAGE.DELETE_RECORD'), type: this.state.removeInfo }} confirmationModalResponse={(data) => this.deleteIncetiveDetails(data)} />
                    :  null
            }
            {
                removeBanner  ?
                    <ConfirmationModal confirmationData={{ show: removeBanner, confirmationText:this.props.t('MESSAGE.DELETE_BANNER'), type: this.state.removeBan }} confirmationModalResponse={(data) => this.deleteBanner(data)} />
                    :  null
            }
            {/* {loading ? <Loader /> : null} */}
        </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        schemeList: state.common.schemeList || [],
        GetRoleList: state.common.getRoleList || [],
        financierList: state.common.financierList || {},
        roleProductList: state.common.roleProductList || [],
        bmUserList: state.common.bmUserList || [],
        userInfo: (state.auth.authUser)? state.auth.authUser.user_data : {}
    };
}

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            financierList: bindActionCreators(
                CommonActions.financierList,
                dispatch
            ),
            schemeList: bindActionCreators(
                CommonActions.schemeList,
                dispatch
            ),
            getRoleProducts:bindActionCreators(
                CommonActions.getRoleProducts,
                dispatch
            ),
            bmUserList:bindActionCreators(
                CommonActions.bmUserList,
                dispatch
            ),
            getIncentiveConfig:bindActionCreators(
                CommonActions.getIncentiveConfig,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Incentive)); 
 