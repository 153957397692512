import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';
 
class BoosterPerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            booster_per:''
        };
    }


    onChange = (event) => {
        let { name, value } = event.target
        this.props.onChange(name, value, '', this.props.element)
    }

    render() {
        const { element, tempErrors } = this.props
    
        return (
            <React.Fragment>
                <div className='app-submit-outer add-incentive-table'>

                    <table>
                        <thead>
                            <tr> 
                                <th>{this.props.t('FORM.BOOSTER')} %</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr>  
                                <td>
                                    <fieldset>
                                        <div className="material">
                                            <InputField
                                                inputProps={{
                                                    id: "booster_per",
                                                    type: "text",
                                                    name: "booster_per",
                                                    placeholder: " ",
                                                    value: this.state["booster_per"] || element.booster_per || "",
                                                    className: "form-input",
                                                    disabled: element.is_expired || false,
                                                    percentage:1,
                                                    maxLength: "100"
                                                }}
                                                onChange={(e, i, q, u) => this.onChange(e, i, q, u)}
                                            />
                                        </div>
                                        {
                                            (tempErrors && tempErrors["booster_per"]) ? <span className="error show">{tempErrors["booster_per"]}</span> : ''
                                        }
                                    </fieldset>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>



                </div>

            </React.Fragment>
        )
    }
} 
export default withTranslation('common')(BoosterPerCard);
