import Modal from "../common/Modal";
import { useTranslation } from 'react-i18next';
import DealerFileUpload from "./DealerFileUpload";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

function BulkUpload(props) {
    const { t } = useTranslation('common');
    const commonRedux = useSelector((state) => state.common);
    const [fileUplodValue, setBulkUpload] = useState({
        bulkUpload: false,
        bulkUploadStatus: false,
        bulkUploadMsg: false
    })
    const [bulkUploadPopUp, setBulkUploadPopUp] = useState(false);
    const [sampleFile, setSampleFile] = useState("");

    useEffect(()=>{
        //SHOW POPUP IF OPENED
        if(props.handleBulkUploader && props.handleBulkUploader.bulkUpload){
            setBulkUpload({
                ...fileUplodValue, bulkUpload: true,type:1
            })
        }
        // eslint-disable-next-line
    }, [props.handleBulkUploader])

    useEffect(() => {
        if(commonRedux.incentiveConfig?.sampleFiles && commonRedux.incentiveConfig.sampleFiles.length){
            const dealerItem = commonRedux.incentiveConfig.sampleFiles?.find(item => item.type === "dealer");
            const dealerUrl = dealerItem ? dealerItem.url : "";
            setSampleFile(dealerUrl)
        }
        // eslint-disable-next-line
    }, [commonRedux && commonRedux.incentiveConfig])


    const showModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }

    const date = new Date(props.searchFilters?.month);
    const monthName = date?.toLocaleString('en-US', {month: 'short', year: 'numeric'});
    return (

        <>
        <button className="btn-line btn-bulk-upload" onClick={showModalBulkUploadPopUp} >{t('DEALER.BULK_UPLOAD')}</button>

        <div className="model-popup-outer dealer-upload-popup">
                <Modal show={bulkUploadPopUp} handleClose={()=>hideModalBulkUploadPopUp()}>
                    <div className="modal-header">
                    <h2>{t('DEALER.UPLOAD_HEADER') + monthName}</h2>
                    </div>
                    <div className="modal-body">
                        <div className='bulk-upload-history-content'>
                            <div className='bulk-upload-sec'>
                               
                                <DealerFileUpload getDealerDetailsList={props.getDealerDetailsList}  searchFilters={props.searchFilters} fileType = {"csv"} month = {props.month} sampleFile = {sampleFile} bulkUploadPopUp = {bulkUploadPopUp}/>

                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        </>
        
  );
}

export default BulkUpload;