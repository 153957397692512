import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { IncentiveActions,CommonActions } from "../../../store/action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FullPageLoader from "../../common/FullPageLoader";
import Select from "react-select";
import CustomValueContainer from "../../common/CustomValueContainer";


const PpdDeviation = (props) => {
    const [EditedStatus, setEditedStatus] = useState([]);
    const [loanId, setLoanId] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [message, setMessage] = useState('');
    const [searchClass, setSearchClass] = useState('');
    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);
    const { t } = useTranslation('common');
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(()=>{
        if (commonRedux && commonRedux.incentiveConfig && commonRedux.incentiveConfig.edited_status) {
            setEditedStatus(commonRedux.incentiveConfig.edited_status);
        }else{
            dispatch(CommonActions.getIncentiveConfig()).then(function (response) {
                if (response && response.status === 200) {
                    let editedStatus = response.data.edited_status;
                    if(editedStatus.length){
                        setEditedStatus(editedStatus);
                    }
                } else {
                    toast.error(response && response.message);
                }
            });
        }
        // eslint-disable-next-line
    },[])


    const SearchLoan=(val)=>{
        const isValidInput = /^[0-9,]*$/.test(val);
        if (isValidInput) {
            setLoanId(val);   
        }
         
    }
    
    /*
    * Get List of loan id on the basis of search
    */
    const ppdSearch = () => { 
        if(loanId){
            let lead_ids=loanId.split(",");
            if(lead_ids.length>0){
                setLoading(true)
                dispatch(IncentiveActions.getLoanIdList({lead_ids:lead_ids,month:props.month})).then((res) => {
                    setLoading(false)
                    if (res.status === 200 && res.data.length) {
                        let data=res.data;
                        setTableData(data);
                        let msg=``;
                        let searchclass=``;
                        if(+lead_ids.length===+data.length){
                            searchclass= `datacount datacountMatch`;
                            msg = `${data.length}/${lead_ids.length} Found`;
                        }else{
                            let leadNotFound  = lead_ids.filter(o=> !data.some(i=> +i.lead_id === +o));
                            searchclass= `datacount datacountMisMatch`;
                            msg = `${(lead_ids.length-data.length)}/${lead_ids.length} Not Found (${leadNotFound.toString()})`;
                           
                        }
                        setSearchClass(searchclass);
                        setMessage(msg);
                        toast.success(res.message);
                    } else {
                        setMessage("");
                        setTableData([])
                        toast.error(res.message);
                    }
                });
            }
        }
        
    } 
    
    /*
    * reset Data
    */
    const resetPpd = async () => {
        setLoanId('');
        setMessage('');
        setSearchClass('');
        setTableData([])
    }

    const changeUpdatedPpdStatus = (value, name,index) => {
        let data=tableData;
        if(data.length){
            data[index][name]=value.value;
            setTableData([...data]);
        }
    }

    const updatePpdStatus = () => {
        if(tableData.length>0){
            setLoading(true)
            dispatch(IncentiveActions.updatePpdStatus({ppdData:tableData})).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    ppdSearch();
                } else {
                    setTableData([])
                    toast.error(res.message);
                }
            });
        }
    }

    const uploadPpdCSv=(e)=>{
        e.preventDefault();
        let file = e.target.files[0];
        setSelectedFile(file);
        if(file){
            if(!["application/wps-office.xls", "application/wps-office.xlsx", "text/csv"].includes(file.type)) {
                toast.error("Please select valid File");
            }else{
                const data = new FormData();
                data.append("filename", e.target.files[0].name);
                data.append("images", file);
                const formdata = data;
                let headers = {
                    'Content-Type': `multipart/form-data;boundary=${formdata._boundary}`,
                }
                setLoading(true);
                IncentiveActions.uploadPpdCSV(formdata, headers).then((res) => {
                    setLoading(false)
                    if (res.status === 200 && res.data.length) {
                        setLoanId(res.data.toString());
                    }else{
                        toast.error(res.message);
                    }
                    setMessage('');
                    setSearchClass('');
                })
            }
            setSelectedFile(null);
            document.getElementsByClassName("upload-csv-ppd")[0].value=null;
            file=null;
        }
    }

    return (
        <div>
        <FullPageLoader show={loading} />
            <div className='ppdSearchBox search-bx'>           
                <div className='material'> 
                
                    <input type="text" placeholder='Search Loan ID' className='form-input' maxLength={200} value={loanId} onChange={(e) => SearchLoan(e.target.value)} />
                    <i className='ic-search'></i>
                    
                    <div className="ppd-upload-csv-div">
                    <i className='ic-upload ppd-upload-ic'></i>    
                    <input
                        type="file"
                        id="upload-image-input"
                        className="upload-csv-ppd"
                        accept= {".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}  
                        onChange={(event)=>uploadPpdCSv(event)} value={selectedFile}
                    />
                    </div> 

                </div>
                <div className=" btn-submit-reset top-btn-none m-sm-l">
                    <button className="btn-primary" onClick={ppdSearch}>Search</button>
                    <button className="btn-reset" style= {{cursor:'pointer'}} onClick = {resetPpd}>Reset</button>
                </div>
                <span className={searchClass}>{message}</span>
            </div>
            <div className='payout-summary-table'>
                <div className='data-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('PPD_DEVIATION.LOAN_ID')}</th>
                                <th>{t('PPD_DEVIATION.CURRENT_STATUS')}</th>
                                <th>{t('PPD_DEVIATION.Updated_STATUS')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.length > 0 ?
                                tableData.map((item,index) => {
                                    let colorClass=``
                                    if(item.type.toLowerCase()==='ucf'){
                                        colorClass=`finance`;
                                    }else if(item.type.toLowerCase()==='ucrf'){
                                        colorClass=`re-finance`;
                                    }else{
                                        colorClass=`ubrf`;
                                    }
                                   
                                    return(
                                    <tr key={index}>
                                        <td><div className="d-flex">{item.lead_id}<span className={colorClass}>{item.type}</span></div></td>
                                        <td>{(item.is_ppd)?"Yes":"No"}</td>
                                        <td>
                                        <fieldset className="single-select ppd-option">
                                            <div className="material">
                                                <Select
                                                    components={{ ValueContainer: CustomValueContainer }}
                                                    value={(EditedStatus.length)?EditedStatus.filter(el=> +el.value ===+item.ppd_updated_status):[]}
                                                    onChange={(val)=>changeUpdatedPpdStatus(val, 'ppd_updated_status',index)}
                                                    options={EditedStatus}
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />
                                            </div>
                                        </fieldset>
                                        </td>
                                    </tr>)
                                 }):
                                (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={3} className="no-data"></td></tr>
                            }

                        </tbody>
                    </table>    
                </div>
            </div>
            <div className="ppdUpdate">
                {
                    (tableData.length > 0)? <button className="btn-primary" onClick={updatePpdStatus}>Update PPD</button>:
                    <button className="btn-primary" disabled={true}>Update PPD</button>
                }
                
            </div>
       </div>
    );
}

export default PpdDeviation;