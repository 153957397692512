import React, { Component } from 'react';
// import { withTranslation } from 'react-i18next';
import MultiSelect  from '../common/MultiSelect'; 

class AddFinancier extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            FinancerOption:[] ,
            financier_config: [],
            select_all:{
                "value": 0,
                "label": "Select All"
            }
        };
    }
    
    componentDidMount = () => {
    let { element } = this.props;
       this.setState({financier_config: element.elg_financier?  element.elg_financier: (element.financier_config) ? element.financier_config : []
       })
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(nextProps?.element?.financier_config.length!==prevState?.financier_config.length){
            return ({ financier_config: nextProps?.element?.financier_config })
        } 
        return null;
    }

    getDropdownButtonLabel = (event) => {
        let { placeholderButtonLabel, value } = event;
        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            let actualVals = value.filter(val => (val.value !== 0 && Object.keys(val).length))
            return `${actualVals.length} selected`;
        } else return `${placeholderButtonLabel}`;
    };

    handleChangeFinancer = (application_type, financier_id, event) => {
        let { FinancerOption } = this.state;
        let { financier_list } = this.props;
      
        let financeobj = { application_type, financier_id };
        let finList = financier_list.filter((ele) => ele.application_type_name == application_type)[0]; // eslint-disable-line
        
        if (FinancerOption.length > 0) {
          const index = FinancerOption.findIndex((object) => {
            return object.application_type === application_type;
          });
          if (index !== -1) {
            if (event.action === 'select-option' && +event.option.value === 0) {
              FinancerOption[index].financier_id = [...finList.financier];
            } else if (event.action === 'deselect-option' && +event.option.value === 0) {
              FinancerOption[index].financier_id = [];
            } else if (event.action === 'deselect-option') {
              FinancerOption[index].financier_id = financier_id.filter((o) => +o.value !== 0);
            } else {
              FinancerOption[index].financier_id = financier_id;
            }
          } 
        } 
        
        if (event.action === 'select-option' && +event.option.value === 0) {
            FinancerOption.push({ application_type, financier_id: finList && finList.financier ? [...finList.financier] : [] });
        } else if (event.action === 'deselect-option' && +event.option.value === 0) {
            FinancerOption.push({ application_type, financier_id: [] });
        } else if (event.action === 'deselect-option') {
            FinancerOption.push({ application_type, financier_id: financier_id.filter((o) => +o.value !== 0) });
        } else {
            FinancerOption.push(financeobj);
        }
          
        this.setState({ FinancerOption });
        this.props.onChange('financier', FinancerOption, '', this.props.element);
      };

      

    render() { 
         let { tempErrors,financier_list,element} = this.props
         let financier_config = (element.elg_financier) ? element.elg_financier: (element.financier_config) ? element.financier_config : [] ; 

        let financierRecord =  financier_list;

         
       
        // if(element.form_config.ROOSTER){ 
        //     let newFinancierList = [] 
        //     for(let item of financier_list){ 
        //         let newFinancieTemp = {}
        //         let financierData = item.financier.filter(ob=>ob.type =='HO Partnership');
        //         if(financierData.length){  
        //             newFinancieTemp['application_type_name'] = item.application_type_name;
        //             newFinancieTemp['financier'] = financierData;
        //             newFinancierList.push(newFinancieTemp);
        //         }
        //     }
        //     financierRecord = newFinancierList;  
        //  }
         
        return (
            <React.Fragment>
                {financier_config && financier_config.length>0 &&
                financier_config.map((item,index)=>{
                    let optionData={};
                    let filter=financierRecord.filter(obj=>obj.application_type_name===item.application_type);
                    if(filter.length>0){
                        optionData[item.application_type]= filter[0].financier;
                    }
                    
                    return(
                        <div className="col-4" key={`financier_${index}`}>
                            <fieldset className="single-select">  
                                <div className="material">
                                <MultiSelect
                                    isMulti={true} 
                                    options={optionData[item.application_type] ? [...optionData[item.application_type]] : []}
                                    getDropdownButtonLabel={(value) =>
                                        this.getDropdownButtonLabel(value)
                                    }
                                    value={item.financier_id && optionData[item.application_type] ? ([...optionData[item.application_type]]).filter(ob=> typeof item.financier_id == 'string' ? item.financier_id.split(',').map(Number).includes(ob.value):''): []}
                                    onChange={(value, event) =>
                                        this.handleChangeFinancer(
                                            item.application_type,
                                            value,
                                            event
                                        )
                                    }
                                    placeholder={item.application_type+'*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isDisabled={element.is_expired || false}
                                /> 
                            </div>
                            {
                            (tempErrors && tempErrors["financier_" + index]) ? <span className="error show">{tempErrors["financier_" + index]}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>
                        </div>
                   
                )})}
               
            </React.Fragment>
        )
    }
}
export default AddFinancier;