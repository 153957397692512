import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// import { Redirect } from 'react-router-dom';
class Footer extends Component {
    constructor(props) {
        super(props);

        // this.toggle = this.toggle.bind(this);
        this.state = {

        };
    }

    render() {

        return (

            <footer>
                <div className="container-fluid">
                    <div className="footer-txt">
                        <span className="copyright-txt">© Incentivebox. All Rights Reserved</span>
                        <ul className="footer-link">
                            <li className=""><NavLink to="#">Terms & Conditions</NavLink> </li>
                            <li className=""><NavLink to="#">Privacy Policy</NavLink> </li>
                        </ul>
                    </div>
                </div>
            </footer>
        )
    }

}

export default Footer;