import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import CategoryConfigTable from "./CategoryConfigTable";
import { CommonActions } from "../../store/action";
import { useDispatch, } from "react-redux";
import FullPageLoader from "../common/FullPageLoader";
import FinancierGroupModel from "./FinancierGroupConfig";
import DeviationLimitConfig from "./DeviationLimitConfig";

const DealerIncentiveConfig = (props) => {
    const { t } = useTranslation("common");
    const [loading, setLoading] = useState(false);
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [financierList, setFinancierList] = useState([]);
    const dispatch = useDispatch();
        
    useEffect(() => {
        getDealerConfigDetails();
        getFinancierList();
         // eslint-disable-next-line 
}, []);

    const getDealerConfigDetails = () => { 
        setLoading(true);
        dispatch(CommonActions.getDealerCategoryDetails()).then(res => {
            setLoading(false);
            if (res.status === 200 && res.data && Object.keys(res.data).length) {
                let data = res.data;
                data[0].min_disbursal = 1
                setCategoryDetails(data);
            } 
        });

    };

    const getFinancierList=()=>{
        setLoading(true);
        dispatch(CommonActions.financierList()).then((res) => {
            if (res && res.status === 200) {
                const ucfObject = res.data?.find(item => item.application_type_name === "UCF");
                const ucfFinanciers = ucfObject ? ucfObject.financier : [];
                setFinancierList(ucfFinanciers);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }
 
    return (
         <React.Fragment>
                <FullPageLoader show={loading} />
               <div className="dashboard-outer">
                    <div className="container-fluid">
                    <div className="top-heading d-flex">
                        <h1>{t('DEALER.DEALER_CONFIG')}</h1> 
                        <div className="dealer-config-btn d-flex">
                            <DeviationLimitConfig financierList={financierList}/>
                            <FinancierGroupModel financierList={financierList}/>
                           
                        </div>
                    </div>
                    <div className="sub-heading p-lg-b dealer-config-subheading">
                    <h2>{t("DEALER.CATEGORY_CONFIG")}</h2>
                    </div>

                    <CategoryConfigTable categoryDetails={categoryDetails} getDealerConfigDetails={getDealerConfigDetails}/>
                    </div>
                </div>

          
        </React.Fragment> 
    );
};

export default DealerIncentiveConfig;
