import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ToggleSwitch from "../common/ToggleSwitch";
import IncentiveAccordionItemPanel from "./IncentiveAccordionItemPanel";
import ConfirmationModal from "../common/ConfirmationModal";
import { IncentiveService } from "../../services";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

class IncentiveReferAndEarn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      areaExpandedParent: false,
      areaExpandedChild: false,
      DeleteIncentivepopup: false,
      ModifyStatusPopup: false,
      element_selected: {},
    };
  }

  showModalpopup = (value, element) => {
    if (!element.is_expired) {
      this.setState({ element_selected: element, [value]: true });
      document.body.classList.add("overflow-hidden");
    }
  };

  handleAddIncentive = (element) => { 
    this.props.showExpandedTabUuid(this.props.index);
    this.props.handleAddIncentive(element[0].scheme_type_id);
  };

  handleCopyIncentive = (element, data) => { 
    this.props.showExpandedTabUuid(this.props.index);
    this.props.handleCopyIncentive(data, element[0].scheme_type_id);
  };

  handleParentClicks = (e) => {
    e.stopPropagation();
    if (e.target.className.indexOf("accordion__button") !== -1) {
      if (e.target["ariaExpanded"] === "true") {
        this.props.showExpandedTabUuid(-1);
        this.setState({ areaExpandedParent: false });
      } else {
        this.props.showExpandedTabUuid(this.props.index);
        this.setState({ areaExpandedParent: true });
      }
    }

    //ON HEADING CLICK OPEN/CLOSE HANDLER
    if (
      e.target.attributes &&
      e.target.attributes.accordionstatus &&
      e.target.attributes.accordionstatus.nodeValue
    ) {
      if (e.target.attributes.accordionstatus.nodeValue === "open") {
        this.props.showExpandedTabUuid(-1);
        this.setState({ areaExpandedParent: false });
      } else {
        this.props.showExpandedTabUuid(this.props.index);
        this.setState({ areaExpandedParent: true });
      }
    }
  };

  handleSubParentClicks = (e, childRefUUid) => {
    e.stopPropagation(); 
    //ON TAB CLICK OPEN/CLOSE HANDLER
    if (typeof e.target.className =='string' && e.target.className.indexOf("accordion__button") !== -1) {
      // eslint-disable-next-line
      if (e.target["ariaExpanded"] == "true") {
        this.props.showSubExpandedTabUuid(-1);
        this.setState({ areaExpandedChild: false });
      } else {
        this.props.showSubExpandedTabUuid(childRefUUid);
        // this.setState({areaExpandedChild: true})
      }
    }

    //ON HEADING CLICK OPEN/CLOSE HANDLER
    if (
      e.target.attributes &&
      e.target.attributes.accordionstatus &&
      e.target.attributes.accordionstatus.nodeValue
    ) {
      if (e.target.attributes.accordionstatus.nodeValue === "open") {
        this.props.showSubExpandedTabUuid(-1);
        this.setState({ areaExpandedChild: false });
      } else {
        this.props.showSubExpandedTabUuid(childRefUUid);
      }
    }
  };

  handleNestedClicks = (e) => {
    e.stopPropagation();
  };
  activeModalResponse(response, selectedElement) {
    this.setState({ ModifyStatusPopup: false });
    if (response.response) {
      let element_selected = response.type;
      if (response.type.id) {
        IncentiveService.updateStatus({
          id: response.type.id,
          is_active: element_selected.status ? 0 : 1,
        }).then(function (response) {
          if (response.data.status === 200) {
            toast.success(response.data.message);
          }
        });
      }
      this.props.handleChange(
        "status",
        !element_selected.status,
        true,
        element_selected
      );
    }
    document.body.classList.remove("overflow-hidden");
  }

  confirmationModalResponse(response) { 
    this.setState({ DeleteIncentivepopup: false });
    if (response.response) { 
      this.props.emptyErrors();
      if (response.type.id) {
        IncentiveService.updateStatus({
          id: response.type.id,
          is_deleted: "1",
        }).then(function (response) {
          if (response.data.status === 200) {
            toast.success(response.data.message);
          }
        });
        
      }
      this.props.confirmationModalResponse(response);
    }
  }
  productName(type) {
    let { product_type } = this.props.incentive_config;

    let pdName = "";
    if (product_type && product_type.length) {
      let item = product_type.filter(ob=>type.includes(ob.value))
      for (let index = 0; index < item.length; index++) {
        let space = pdName ? ' ':'';
        pdName = pdName + space + item[index].value;
      }
      pdName = item.length ? pdName : "";
    }
    return pdName;
  }

  expiryMonth(data) {
    let { scheme_expiry_date } = data;
    let month = scheme_expiry_date ? new Date(scheme_expiry_date).toLocaleString('default', { month: 'long' }) :'';
     return month
  }

  render() {
    let {
      activeTab,
      element,
      index,
      tempErrors,
      incentive_config,
      preExpandedTabs,
      subExpandedTabs,
      incentive_list,
      loading,
      financier_list,
      userInfo,
      addMoreIncentive,
      removeMoreIncentive,
      AreaAM
    } = this.props;

    let {
      areaExpandedParent,
      areaExpandedChild,
      DeleteIncentivepopup,
      ModifyStatusPopup,
      element_selected,
    } = this.state;

    if(userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('VIEW') && element.length){
      element[0].is_expired = 1;
    }
 
     
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Accordion allowZeroExpanded="true">
            <AccordionItem
              key={index}
              uuid={index}
              onClick={this.handleParentClicks}
              dangerouslySetExpanded={
                preExpandedTabs === index || areaExpandedParent
              }
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2
                    accordionstatus={
                      preExpandedTabs === index || areaExpandedParent
                        ? "open"
                        : "close"
                    }
                  >
                    {element.length && element[0].parent_name} (
                    {element.filter(String).length})
                  </h2>
                  {activeTab === 1 && userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('ADD') && (
                    <button
                      onClick={() => {
                        this.handleAddIncentive(element);
                      }}
                      className="btn-line"
                    >
                      {" "}
                      {this.props.t("FORM.ADD_NEW")}
                    </button>
                  )}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="sub-accordion">
                  <Accordion allowZeroExpanded="true">
                    {element.length > 0 &&
                      element.map((data, index) => (
                        <AccordionItem
                          key={"parent" + index}
                          uuid={data.id}
                          onClick={(e) =>
                            this.handleSubParentClicks(
                              e,
                              index + "__" + data.id
                            )
                          }
                          dangerouslySetExpanded={
                            subExpandedTabs === index + "__" + data.id ||
                            areaExpandedChild
                          }
                        >
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <h2
                                accordionstatus={
                                  subExpandedTabs === index + "__" + data.id
                                    ? "open"
                                    : "close"
                                }
                              >
                                {element.length && element[0].parent_name}{" "}
                                {data.product_type
                                  ? this.productName(data.product_type)
                                  : ""}{" "}
                                 - { data.scheme_expiry_date ? this.expiryMonth(data): ''} [{this.props.t("COMMON.SCHEME_ID")} : {data.id ? data.id : "NA"} | {this.props.t("COMMON.SCHEME_TYPE_ID")} : {data.scheme_type_id ? data.scheme_type_id : ""}]  
                              </h2>
                              <ul
                                className="right-action-item"
                                onClick={this.handleNestedClicks}
                              > 

                                {
                                  !data.is_expired && data.id &&
                                  <li>
                                    <i className="ic-content_copy"
                                      onClick={() => {
                                        this.handleCopyIncentive(element, data);
                                      }}></i>
                                  </li>
                                 
                                }

                                <div className="on-off-switch">
                                  {
                                    (!data.is_expired)?
                                    <ToggleSwitch
                                    status={data.status}
                                    is_expired={data.is_expired}
                                    onChange={() => {this.showModalpopup("ModifyStatusPopup",data);}}
                                    />:
                                    <ToggleSwitch
                                      status={data.status}
                                      is_expired={data.is_expired}
                                      onChange={''}
                                    />
                                  }
                                 
                                </div> 
 
                              
                                { 
                                  (!data.is_expired)?
                                  userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('DELETE') &&
                                  <li
                                    onClick={() => {this.showModalpopup("DeleteIncentivepopup",data); }}
                                  >
                                    <i className="ic-delete-icn"></i>
                                  </li>
                                  :
                                  userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('DELETE') &&
                                  <li>
                                    <i className="ic-delete-icn"></i>
                                  </li>
                                }

                              </ul>
                            </AccordionItemButton>
                          </AccordionItemHeading>

                          <IncentiveAccordionItemPanel
                            tempErrors={
                              tempErrors && tempErrors[index]
                                ? tempErrors[index]
                                : {}
                            }
                            incentive_config={incentive_config}
                            financier_list={financier_list}
                            AreaAM={AreaAM}
                            element={data}
                            element_index={index}
                            handleChange={(e, i, q, u) => {
                              this.props.handleChange(e, i, q, u);
                            }}
                            handleDeleteImage={(e, i, q, u) => {
                              this.props.handleDeleteImage(e, i, q, u);
                            }}
                            
                            handleUpdate={(e) => {
                              this.props.handleUpdate(
                                e,
                                "",
                                index + "__" + data.id,
                                index
                              );
                            }}
                            handleCancel={(e) => {
                              this.props.handleCancel(e);
                            }} 
                            handleAddArea={(elmIdx, schemeTypeId) => {
                              this.props.handleAddArea(index, schemeTypeId);
                            }}
                            indexKey={index}
                            record={element}
                            incentive_list={incentive_list}
                            loading={loading}
                            addMoreIncentive = {addMoreIncentive}
                            removeMoreIncentive = {removeMoreIncentive}
                          />
                        </AccordionItem>
                      ))}
                  </Accordion>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          {/* </div> */}
        </div>

        {DeleteIncentivepopup ? (
          <ConfirmationModal
            confirmationData={{
              show: DeleteIncentivepopup,
              confirmationText: this.props.t("MESSAGE.DELETE_INCENTIVE"),
              type: element_selected,
            }}
            confirmationModalResponse={(data) =>
              this.confirmationModalResponse(data)
            }
          />
        ) : null}

        {ModifyStatusPopup ? (
          <ConfirmationModal
            confirmationData={{
              show: ModifyStatusPopup,
              confirmationText: this.state.element_selected.status
                ? this.props.t("MESSAGE.INACTIVE_INCENTIVE")
                : this.props.t("MESSAGE.ACTIVE_INCENTIVE"),
              type: element_selected,
            }}
            confirmationModalResponse={(data) =>
              this.activeModalResponse(data, element_selected)
            }
          />
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    userInfo: (state.auth.authUser) ? state.auth.authUser.user_data : {}
  };
}
export default withTranslation('common')(connect(mapStateToProps)(IncentiveReferAndEarn)); 