import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import InputField from '../common/InputField';
import { useTranslation } from "react-i18next";

const IncentiveDisbursalCard = (props) => {
   
    const [incentiveDisbursal, setincentiveDisbursal] = useState({});
    const [refresh, setRefresh] = useState(0);
    const { t } = useTranslation("common");

    const onChange = (event) => {
        let { name, value } = event.target
        props.element[name] = value;
        setincentiveDisbursal({...incentiveDisbursal, [name] : value})
        setRefresh(refresh + 1); 
    }
 
    useEffect(() => {
        if (props.element) {
            setincentiveDisbursal({
                incentive_amount : props.element.incentive_amount,
                min_disbursal : props.element.min_disbursal 
            })
        }
    }, [props]); // eslint-disable-line

    const { element, tempErrors } = props

    return (
        <React.Fragment>
            <div className='amount-submit-outer add-incentive-table'>

                <table>
                    <thead>
                        <tr>
                            <th>{t('FORM.MIN_DISBURSAL')}</th>
                            <th>{t('FORM.INCENTIVE_AMOUNT')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="pd20">
                                <fieldset>
                                    <div className="material">
                                        <InputField
                                            inputProps={{
                                                type: "text",
                                                name: "min_disbursal",
                                                placeholder: " ",
                                                value: incentiveDisbursal?.min_disbursal !== "" ? incentiveDisbursal?.min_disbursal : "" || element.min_disbursal !== "" ? element.min_disbursal : "" || "",
                                                className: "form-input",
                                                disabled: element.is_expired || false
                                            }}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                    {
                                        (tempErrors && tempErrors["min_disbursal"]) ? <span className="error show">{tempErrors["min_disbursal"]}</span> : ''
                                    }
                                </fieldset>
                            </td>


                            <td>
                                <fieldset>
                                    <div className="material">
                                        <InputField
                                            inputProps={{
                                                type: "text",
                                                name: "incentive_amount",
                                                placeholder: " ",
                                                value: incentiveDisbursal?.incentive_amount !== "" ? incentiveDisbursal?.incentive_amount : "" || element.incentive_amount !== "" ? element.incentive_amount : "" || "",
                                                className: "form-input",
                                                disabled: element.is_expired || false
                                            }}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </div>
                                    {
                                        (tempErrors && tempErrors["incentive_amount"]) ? <span className="error show">{tempErrors["incentive_amount"]}</span> : ''
                                    }
                                </fieldset>
                            </td>
                        </tr>
                    </tbody>
                </table>



            </div>

        </React.Fragment>
    )

}
export default withTranslation('common')(IncentiveDisbursalCard);
