import React, { Component } from 'react';
import Select from 'react-select'; 
import { withTranslation } from 'react-i18next';
import AddFinancier from '../incentive/AddFinancier' 
import { connect } from 'react-redux'; 
import CustomValueContainer from '../common/CustomValueContainer' ;
import MultiSelect from '../common/MultiSelect'
import { ARO_USER_ROLE } from "../../config/constant";

class BasicFilter extends Component {
    constructor(props) {
        super(props);
        let { element, roleProductList } = this.props 
        this.state = {
            refresh: 1,
            ProductTypeOption: element.product_type ? { value: element.product_type, label: element.product_type } : null,
            SchemeDurationOption: element.scheme_duration ? { value: element.scheme_duration, label: element.scheme_duration } : null,
            ApplicabletoOption: element.applicable_to ? { value: element.applicable_to, label: element.applicable_to } : null,
            ApplicationTypeOption: element.application_type ? { value: element.application_type, label: element.application_type } : null,
            UserRoleOption: element.user_role ? { value: element.user_role, label: element.user_role } : null,
            RoleProducts: element.user_role ? roleProductList.filter(list=> +list.role_id === +element.user_role) : []
        };
    }

    getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {

            return `${value.length} selected`;
        }
        else
            return `${placeholderButtonLabel}`;

    }

    handleChangeUserRole = (UserRoleOption) => {
        this.setState({ UserRoleOption })
        this.props.onChange("user_role", UserRoleOption, '', this.props.element)
 
        // if(UserRoleOption.value)
        //GET PRODUCTS
        const RoleProducts = this.props.roleProductList ? this.props.roleProductList.filter(list=> +list.role_id === +UserRoleOption.value) : [];
        this.setState({RoleProducts})
      
    };

    handleChangeProductType = (ProductTypeOption) => {
         
         this.setState({ ProductTypeOption })
        this.props.onChange("product_type", ProductTypeOption, '', this.props.element)
    };

    handleChangeAppType = (ApplicationTypeOption) => {  
        this.setState({ ApplicationTypeOption })
        this.props.onChange("application_type", ApplicationTypeOption, '', this.props.element)
    };

    handleChangeSchemeDuration = (SchemeDurationOption) => {
        this.setState({ SchemeDurationOption })
        this.props.onChange("scheme_duration", SchemeDurationOption, '', this.props.element)
        if(this.props.element.form_config.SCHEME_DURATION ){
            this.props.onChange("scheme_duration_value", {...SchemeDurationOption, value: SchemeDurationOption['label']}, '', this.props.element)
        }
    };

    handleChangeApplicableto = (value) => {
        this.setState({ refresh: this.state.refresh + 1 }) 
        this.props.element.applicable_to =  value.map(ob=>ob.value).join(','); 
    };

    handleChangeUserTagging = (value, userTagging,role_id="") => {  
        const { bro_tagging_list, aro_tagging_list } = this.props.incentive_config;
        let TAGGING_LIST = (role_id ==2) ? aro_tagging_list: bro_tagging_list;
        let MUST_KEY = TAGGING_LIST.filter(ob=>!ob.value.includes('fixed_income')).map(ob=>ob.value);
        let GET_KEY = value.map(ob=>ob.value) 
        let allFound = MUST_KEY.every( ai => GET_KEY.includes(ai) ); 

        if(value.length >= 3 && allFound){
            let resultValue = ''
            if(value?.length){
                resultValue = value.map(ob=>ob.value).join(','); 
                this.props.element.applicable_to = resultValue;
            } else {
                resultValue = userTagging.map(ob=>ob.value).join(','); 
                this.props.element.applicable_to = resultValue;
            } 
            this.props.onChange("applicable_to", resultValue, '', this.props.element) 
            this.setState({ refresh: this.state.refresh + 1 }) 
        } 
    };


      
    render() {
        const { product_type, scheme_duration, quater_list,agent_tagging_list,aro_tagging_list, bro_tagging_list, aro_user_tagging } = this.props.incentive_config;
        const { tempErrors, element,indexKey,record,financier_list, rolesList } = this.props  
        const { RoleProducts } = this.state; 
        
        let productExclude = [];
        record.forEach((item,key) => {
            if(key !== indexKey && item.product_type){
                productExclude.push(item.product_type);
            }
        });    
        const product_type_app =product_type.filter(ob=>+ob.application_type===1);

        let product_type_incentive = RoleProducts;
        if(!element.form_config.USER_ROLE){
            product_type_incentive = product_type.filter(ob=>ob.incentive_type===1);
        } 

        if(element.form_config.USER_ROLE){ 
            product_type_incentive =  this.props.roleProductList.filter(ob=>ob.role_id === element.user_role)
        }
        let durationSchemeOptions =  element.form_config.SCHEME_DURATION ? quater_list : scheme_duration;

        let AGENT_TAGGING_TYPE = (element.scheme_type_id == 42)? aro_tagging_list: agent_tagging_list || [] 

        const USER_TAGGING_LIST = +element.form_config.USER_ROLE === ARO_USER_ROLE ? aro_tagging_list : bro_tagging_list;
        const defaultSelectedTagging = USER_TAGGING_LIST?.filter(option => option.isDisabled);
        
   
        return (
            <React.Fragment>
                <div className='basic-filter-outer'>

                    <div className="basic-detail-filter">
                        {
                          element.form_config.USER_ROLE && 
                          <>
                            <fieldset className="single-select">
                                <div className="material">
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={element.user_role ? rolesList.filter(({ value }) => value === element.user_role) : null}
                                        onChange={this.handleChangeUserRole}
                                        options={rolesList.filter(ob=>ob.bro_status===1)}
                                        isDisabled={element.is_expired}
                                        placeholder={this.props.t('FORM.USER_ROLE')+'*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                    />
                                </div>
                                {
                                    (tempErrors && tempErrors.user_role) ? <span className="error show">{tempErrors.user_role}</span> : <span>&nbsp;</span>
                                }
                            </fieldset>
                          </> 
                        }
                       
                        {
                            element && element.form_config && +element.form_config.APPLICATION_TO_MULTI === 1 ? 
                            (
                                <fieldset className="single-select">  
                                    <div className="material">
                                    <MultiSelect
                                    isMulti={true}
                                    value={element.product_type ? product_type_incentive.filter(ob=>element.product_type.includes(ob.value)): []}
                                    onChange={this.handleChangeProductType}
                                    options={product_type_incentive}
                                    isDisabled={element.is_expired}
                                    placeholder={this.props.t('FORM.PRODUCT_TYPE') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    /> 
                                </div>
                                    {tempErrors && tempErrors.product_type ? <span className="error show">{tempErrors.product_type}</span> : <span>&nbsp;</span>}
                                </fieldset>
                                )
                            :(
                                <fieldset className="single-select">
                                <div className="material">
                                    <Select
                                    components={{ ValueContainer: CustomValueContainer }}
                                    value={element.product_type ? product_type_incentive.filter(({ label }) => label == element.product_type) : null}  // eslint-disable-line
                                    onChange={this.handleChangeProductType}
                                    options={product_type_incentive}
                                    isDisabled={element.is_expired}
                                    placeholder={this.props.t('FORM.PRODUCT_TYPE') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    />
                                </div>
                                {tempErrors && tempErrors.product_type ? <span className="error show">{tempErrors.product_type}</span> : <span>&nbsp;</span>}
                                </fieldset>
                            )  
                            }



                        <fieldset className="single-select">
                            <div className="material">

                                {
                                    !element.is_expired 
                                    ?
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={element.scheme_duration ? durationSchemeOptions.filter(({ value }) => Number(value) === Number(element.scheme_duration)) : null}
                                        onChange={this.handleChangeSchemeDuration}
                                        options={durationSchemeOptions}
                                        isDisabled={element.is_expired || false}
                                        placeholder={this.props.t('FORM.SCHEME_DURATION') + '*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                    />
                                    :
                                    
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={[{value: '', label: element.scheme_duration_value}]}
                                    onChange={this.handleChangeSchemeDuration}
                                    options={scheme_duration}
                                    isDisabled={element.is_expired}
                                    placeholder={this.props.t('FORM.SCHEME_DURATION')+ '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                />

                                }
                                
                            </div>
                            {
                                (tempErrors && tempErrors.scheme_duration) ? <span className="error show">{tempErrors.scheme_duration}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>
                       {/*  {
                            element.form_config.APPLICATION_TO &&
                            <>
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select components={{ ValueContainer: CustomValueContainer }}
                                            value={applicable_to.filter(({ value }) => Number(value) === Number(element.applicable_to))}
                                            // value={applicable_to[0]}
                                            onChange={this.handleChangeApplicableto}
                                            options={applicable_to}
                                            isDisabled={element.is_expired}
                                            placeholder={this.props.t('FORM.APPLICABLE_TO') + '*'}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isSearchable={false}
                                        />
                                    </div>
                                    {
                                        (tempErrors && tempErrors.applicable_to) ? <span className="error show">{tempErrors.applicable_to}</span> : <span>&nbsp;</span>
                                    }
                                </fieldset>
                            </>
                        } */}

{
                            element.form_config.APPLICATION_TO &&
                            <>
                                <fieldset className="seller-opt multiselect-dropDown">
                                <div className={'material animation-effect ' + (element.applicable_to !== '' ? 'active-label' : '')}>
                                    <MultiSelect
                                     isMulti ={true}
                                    value={element.applicable_to ? AGENT_TAGGING_TYPE.filter(ob=>element.applicable_to.split(',').includes(ob.value)): []}
                                    onChange={this.handleChangeApplicableto}
                                    options={AGENT_TAGGING_TYPE}
                                    isDisabled={element.is_expired}
                                    placeholder={this.props.t('FORM.APPLICABLE_TO') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    />
                                    <label data-label={this.props.t('FORM.APPLICABLE_TO') + '*'} className="form-label"></label>
                                </div>
                                    {tempErrors && tempErrors.applicable_to ? <span className="error show">{tempErrors.applicable_to}</span> : <span>&nbsp;</span>}
                                </fieldset>
                            </>
                        }

                        
                        {
                            element.form_config.APPLICATION_TYPE &&
                            <>
                                <fieldset className="single-select">  
                                    <div className="material">
                                        <MultiSelect 
                                            isMulti ={true}
                                            value={element.application_type ? product_type_app.filter(ob=>element.application_type.includes(ob.value)): []}
                                            onChange={this.handleChangeAppType}
                                            options={product_type_app}
                                            isDisabled={element.is_expired}
                                            placeholder={this.props.t('FORM.APPLICATION_TYPE') + '*'}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        /> 
                                    </div>
                                    
                                    <label data-label={"Application Type"} className="form-label"></label>

                                    {
                                        (tempErrors && tempErrors.application_type) ? <span className="error show">{tempErrors.application_type}</span> : <span>&nbsp;</span>
                                    }
                                </fieldset></>
                        }

                        {
                            element.form_config.USER_TAGGING &&
                            <>
                                <fieldset className="seller-opt multiselect-dropDown">
                                <div className={'material animation-effect ' + (element.applicable_to !== '' ? 'active-label' : '')}>
                                    <MultiSelect
                                    isMulti ={true}
                                    value={element.applicable_to ? USER_TAGGING_LIST.filter(ob=>element.applicable_to.split(',').includes(ob.value)): defaultSelectedTagging}
                                    onChange={(val) => this.handleChangeUserTagging(val, defaultSelectedTagging,element?.form_config?.USER_ROLE)}
                                    options={USER_TAGGING_LIST}
                                    isDisabled={element.is_expired}
                                    placeholder={this.props.t('FORM.USER_TAGGING') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isOptionDisabled={option => option.isDisabled} // This line handles disabling
                                    />
                                    <label data-label={this.props.t('FORM.USER_TAGGING') + '*'} className="form-label"></label>
                                </div>
                                    {tempErrors && tempErrors.applicable_to ? <span className="error show">{tempErrors.applicable_to}</span> : <span>&nbsp;</span>}
                                </fieldset>
                            </>
                        }


                    </div>
                </div>
                {
                  ((element.form_config.ADD_FINANCIER && element.financier_config && element.financier_config.length > 0) || (element.form_config.ELIGIBLE_FINANCIER && element.elg_financier && element.elg_financier.length > 0) )  && (financier_list.length>0) && 
                  <>
                    <div className='redeemable-select-finacer-list'>
                        <span className='sub-heading'>Select Financiers </span>
                           <div className='basic-detail-filter'>                       
                            <AddFinancier element={element} financier_list={financier_list}  onChange={(e, i, q, u) => { this.props.onChange(e, i, q, u) }} tempErrors={tempErrors}/> 
                            </div>                           
                    </div>
                      
                  </> 
                }
            </React.Fragment>
        )
    }
} 
const mapStateToProps = (state) => {
    return {
        rolesList: state.common.getRoleList || [],
        roleProductList: state.common.roleProductList || []
    };
} 
export default withTranslation('common')(connect(mapStateToProps)(BasicFilter)); 
