import React, { useEffect,useState} from 'react'
import Modal from "../../common/Modal";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import { CommonActions } from "../../../store/action";
import { IncentiveService } from "../../../services";
import { toast } from "react-toastify";
import FullPageLoader from '../../common/FullPageLoader';

function DataExport(props) {
    // const dispatch = useDispatch();  - never used
    const [userData, setUserData] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [schemeData, setSchemeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common");
    const commonRedux = useSelector((state) => state.common);
    let roleId= commonRedux.payoutReqParams ? JSON.parse(JSON.stringify(commonRedux.payoutReqParams.role_id)):'';
    const schemeheaders = [
        { label: "SFA ID", key: "sfa_id" },
        { label: "User Name", key: "user_name" },
        { label: "SFA Status", key: "status" },
        { label: "Date of Joining", key: "doj" },
        { label: "NPWP", key: "npwp" },
        { label: "Sales Team", key: "sale_team" },
        { label: "Role", key: "role" },
        { label: "Tagging/Level", key: "tagging" },
        { label: "Area", key: "area" },
        { label: "Hiring Source", key: "hiring_source" },
        { label: "Incentive Type", key: "scheme" },
        { label: "UCF (Disbursals - HO)", key: "ucf_ho" },
        { label: "UCRF  (Disbursals - HO)", key: "ucrf_ho" },
        { label: "UBRF  (Disbursals - HO)", key: "ubrf_ho" },
        { label: "Amount", key: "amount" },
        { label: "Approval Status", key: "approval_status" },
    ];
    const Userheaders =(+roleId===53)?[
        { label: "SFA ID", key: "sfa_id" },
        { label: "User Name", key: "user_name" },
        { label: "SFA Status", key: "status" },
        { label: "Date of Joining", key: "doj" },
        { label: "NPWP", key: "npwp" },
        { label: "Sales Team", key: "sale_team" },
        { label: "Role", key: "role" },
        { label: "Area", key: "area" },
        { label: "UCF", key: "total_ucf" },
        { label: "UCRF", key: "total_ucrf" },
        { label: "UBRF", key: "total_ubrf" },
        { label: "Hiring Source", key: "hiring_source" },
        { label: "Amount", key: "amount" },
        { label: "Salary Arrear", key: "salary_arrear" },
        { label: "Deduction", key: "deduction" },
        { label: "Net Amount", key: "net_amount" },
        { label: "Approval Status", key: "approval_status" },
        
    ]:[
        { label: "SFA ID", key: "sfa_id" },
        { label: "User Name", key: "user_name" },
        { label: "SFA Status", key: "status" },
        { label: "Date of Joining", key: "doj" },
        { label: "NPWP", key: "npwp" },
        { label: "Sales Team", key: "sale_team" },
        { label: "Role", key: "role" },
        { label: "Area", key: "area" },
        { label: "UCF", key: "total_ucf" },
        { label: "UCRF", key: "total_ucrf" },
        { label: "UBRF", key: "total_ubrf" },
        { label: "Hiring Source", key: "hiring_source" },
        { label: "Amount", key: "amount" },
        { label: "BPJS", key: "bpjs" },
        { label: "Salary Arrear", key: "salary_arrear" },
        { label: "Deduction", key: "deduction" },
        { label: "Net Amount", key: "net_amount" },
        { label: "Approval Status", key: "approval_status" },
        
    ];
    
 
 
    useEffect(() => {
        let params= commonRedux.payoutReqParams ? JSON.parse(JSON.stringify(commonRedux.payoutReqParams)) : {};
            params.pagination=false;
        exportpayoutdata(params);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const exportpayoutdata = (params) => {
        setLoading(true);
        IncentiveService.getPayoutDetails(params).then(function (response) {
            setLoading(false);
            if (response.data.status === 200) { 
                if(response.data.data && response.data.data.data){
                  let totaldata=response.data.data.data;
                    if(totaldata.length>0){
                        setButtonDisable(false);
                   
                        let userLevelArray = [];
                        let schemeArray = [];
                        for(let i=0;i<totaldata.length;i++){ 
                            let userRecord = totaldata[i];
                            let net_amount = Number(userRecord.approved_amount) + Number(userRecord.salary_arrear) - Number(userRecord.deduction);
                            if(userRecord.user_status===1){
                                net_amount += Number(userRecord.bpjs);
                            }
                            let ucf_ho = 0;
                            let ucrf_ho = 0;
                            let ubrf_ho = 0;

                            let hiring_source = '';
                            let area = '';
                            userRecord.items.forEach((ob)=>{
                                ucf_ho += Number(ob.partnership_ucf_count);
                                ucrf_ho += Number(ob.partnership_ucrf_count);
                                ubrf_ho += Number(ob.partnership_ubrf_count);

                                hiring_source = ob.hiring_source;
                                area=ob.area;
                                let schemeRecord = {
                                    sfa_id: userRecord.user_id,
                                    user_name: userRecord.name,
                                    status: userRecord.user_status_name,
                                    doj:userRecord.doj, 
                                    npwp:userRecord.NPWP,
                                    sale_team: ob.product_type,
                                    role: userRecord.user_role,
                                    tagging: userRecord.user_role.toLowerCase() === 'aro' ? ob.user_latest_tagging : (userRecord.user_role.toLowerCase() === 'agent' ? ob.agent_level : ob.tagging),
                                    area: ob.area,
                                    hiring_source: ob.hiring_source,
                                    scheme: ob.scheme,
                                    ucf_ho: ob.partnership_ucf_count,
                                    ucrf_ho: ob.partnership_ucrf_count,
                                    ubrf_ho: ob.partnership_ubrf_count, 
                                    amount: ob.amount,
                                    approval_status: ob.status_name,
                                    scheme_id:ob.scheme_id,
                                    scheme_type_id:ob.scheme_type_id
                                }
                                if(schemeArray.length>0){
                                    let foundIndex = schemeArray.findIndex(x => x.sfa_id ===userRecord.user_id && x.scheme_id===ob.scheme_id && x.scheme_type_id===ob.scheme_type_id);
                                    if(foundIndex!==-1){
                                        let schemelevel=schemeArray[foundIndex];
                                        schemelevel.amount=Number(schemelevel.amount)+Number(ob.amount);

                                        schemeArray[foundIndex]=schemelevel;
                                    }else{
                                        schemeArray.push(schemeRecord); 
                                    }
                                }else{
                                    schemeArray.push(schemeRecord);
                                }
                            });

                            let userLevelData ={
                                sfa_id: userRecord.user_id,
                                user_name: userRecord.name,
                                status: userRecord.user_status_name,
                                doj: userRecord.doj, 
                                npwp: userRecord.NPWP,
                                sale_team: userRecord.business_line,
                                role: userRecord.user_role,
                                area:area,
                                ucf_ho: ucf_ho,
                                ucrf_ho: ucrf_ho,
                                ubrf_ho: ubrf_ho,
                                total_ucf: userRecord.total_ucf,
                                total_ucrf: userRecord.total_ucrf,
                                total_ubrf: userRecord.total_ubrf,
                                hiring_source: hiring_source,
                                amount: userRecord.user_incentive_amount, 
                            }
                            if(+userRecord.user_role_id!==53){
                                userLevelData.bpjs=userRecord.bpjs;
                            }
                            userLevelData.salary_arrear= userRecord.salary_arrear;
                            userLevelData.deduction= userRecord.deduction;
                            userLevelData.net_amount= userRecord.net_amount || net_amount;
                            userLevelData.approval_status= userRecord.status_name;

                            if(userLevelArray.length>0){
                                let foundIndex = userLevelArray.findIndex(x => x.sfa_id ===userRecord.user_id);
                                
                                if(foundIndex!==-1){
                                    let userlevel=userLevelArray[foundIndex];
                                    
                                    userlevel.amount=Number(userlevel.amount)+Number(userRecord.user_incentive_amount);
                            
                                    userlevel.bpjs=Number(userlevel.bpjs)+Number(userRecord.bpjs);
                            
                                    userlevel.salary_arrear=Number(userlevel.salary_arrear)+Number(userRecord.salary_arrear);
                            
                                    userlevel.deduction=Number(userlevel.deduction)+Number(userRecord.deduction);
                            
                                    userlevel.net_amount=Number(userlevel.net_amount)+Number(userRecord.net_amount);
                                   
                                    userLevelArray[foundIndex]=userlevel;
                                }else{
                                    userLevelArray.push(userLevelData);
                                }
                            }else{
                                userLevelArray.push(userLevelData);
                            }
                        }   
                        setUserData(userLevelArray); 
                        setSchemeData(schemeArray);
                    }
                }
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch(function () {
            setLoading(false);
        });
    };
    return (
        <div className="model-popup-outer confirmation-popup">
            <FullPageLoader show={loading} />
            <Modal show={props.show} handleClose={props.handleClose} >
                <div className="modal-header">
                    <h2>{t('DETAIL.DATA_EXPORT_AS')}</h2>
                </div>
                <div className="modal-body" >
                    <div className='confirmation-btn'>
                        <CSVLink data={userData} headers={Userheaders} filename={"user-level.csv"}>
                            <button className='btn-primary' disabled={buttonDisable}>{t('FORM.USER_LEVEL')}
                            </button>
                        </CSVLink> 
                        <CSVLink data={schemeData} headers={schemeheaders} filename={"scheme-level.csv"}>
                            <button className='btn-primary' disabled={buttonDisable}>{t('DETAIL.SCHEME_LEVEL')}</button>
                        </CSVLink> 
                        
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DataExport